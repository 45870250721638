import React from 'react';

const Sidebar = ({ children }) => {
  return (
    <div className="bg-bright-color rounded-lg flex flex-col p-4">
      {children}
    </div>
  );
};

export default Sidebar;
