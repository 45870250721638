import AxiosService from "../../AxiosService";

export const addPlayerAgentHistory = async data => {
  const response = await AxiosService.post("/player/agent-history/", data);
  return response.data;
};

export const getPlayerAgentHistory = async () => {
  const response = await AxiosService.get("/player/agent-histories/");
  return response.data;
};

export const editPlayerAgentHistory = async ({ data, id }) => {
  const response = await AxiosService.put(`/player/agent-history/${id}`, data);
  return response.data;
};

export const deletePlayerAgentHistory = async ({ id }) => {
  const response = await AxiosService.delete(`/player/agent-history/${id}`);
  return response.data;
};
