import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Search from './Search';
import UserChats from './UserChats';
import { useSelector } from 'react-redux';
import './userMessage.scss';

const Sidebar = () => {
  const userChatsSlice = useSelector(state => state.userChats.userInfo);

  const groupChatSlice = useSelector(state => state.userChats.groupChat);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    if (userChatsSlice?.length !== 0 || groupChatSlice?.length !== 0) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [groupChatSlice, userChatsSlice]);

  return (
    <div
      className={`${
        isMobile ? 'sidebar ' : 'sidebar mobile'
      } md:overflow-y-scroll overflow-hidden bg-white p-2`}
    >
      <span className="  text-brand-dark text-lg font-medium">Messages</span>
      <Navbar />
      <Search />
      <UserChats />
    </div>
  );
};

export default Sidebar;
