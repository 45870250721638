import React, { useState, createRef } from 'react';
import { ReactComponent as CameraSvg } from '../../../../img/misc/camera-gray.svg';
import { ReactComponent as VideoSvg } from '../../../../img/misc/video-gray.svg';
import { ReactComponent as PublicSvg } from '../../../../img/misc/public.svg';
import { ReactComponent as ContactsSvg } from '../../../../img/misc/contacts.svg';
import { ReactComponent as AgentSvg } from '../../../../img/misc/agent.svg';
import DefaultBackgroundPng from '../../../../img/misc/Default_pfp.png';
import SharePostModal from '../../../../components/Modal/SharePostModal';
import ReactSelect from 'react-select';
import { useMutation, useQueryClient } from 'react-query';
import { fetchUser } from '../../../../redux/userSlice';
import { addMyPost } from '../../../../services/routes/Post';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from '../../../../components/Carousel/Carousel';
import { ReactComponent as CrossMarkSvg } from '../../../../img/misc/crossed-mark.svg';
import { toast } from 'react-toastify';
import profileImageUrl from '../../../../config/config';
import './CreatePost.scss';

const postOptions = [
  {
    value: 'PUBLIC',
    label: (
      <div className="select-label cursor-pointer">
        <PublicSvg className="primary-svg " /> Public
      </div>
    )
  },
  {
    value: 'CONTACTS',
    label: (
      <div className="select-label cursor-pointer">
        <ContactsSvg className="primary-svg" /> Contacts
      </div>
    )
  },
  {
    value: 'AGENTS',
    label: (
      <div className="select-label cursor-pointer">
        <AgentSvg className="primary-svg" />
        Agents
      </div>
    )
  }
];

const CreatePost = () => {
  //

  const queryClient = useQueryClient();
  const data = useSelector(
    state => state.auth?.user?.contact?.profile_image?.url
  );

  const dispatch = useDispatch();
  const [video, setVideo] = useState('');
  const [content, setContent] = useState('');
  const imageUploadBtnRef = createRef();
  const videoUploadBtnRef = createRef();
  const [visibility, setVisibility] = useState('PUBLIC');
  const [showShareModel, setShowShareModel] = useState(false);

  const [selectedImage, setSelectedImage] = useState([]);
  const [buildImage, setBuildImage] = useState([]);

  const { mutate, isLoading } = useMutation(addMyPost, {
    onSuccess: () => {
      dispatch(fetchUser());
      queryClient.invalidateQueries('fetch_allPost');
      toast.success('You have successfully created a post');
      setContent('');
      setSelectedImage([]);
      setBuildImage([]);
    },
    onError: err => {
      console.log(err);
      toast.error('There was an error while creating a post');
    }
  });

  const onChange = value => {
    setVisibility(value.value);
  };

  const onImageChange = e => {
    let files = e.target.files;

    const updatedImages = [...selectedImage];

    const updatedBuildImages = [...buildImage];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      let url = URL.createObjectURL(file);
      updatedImages.push(file);
      updatedBuildImages.push({ url, id: Math.random() * 10000000000000000 });
    }

    setSelectedImage(updatedImages);

    setBuildImage(updatedBuildImages);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formData = new FormData();

    if (selectedImage) {
      selectedImage.forEach(images => {
        formData.append('images', images);
      });
    }

    formData.append('body_text', content);

    mutate(formData);
  };

  return (
    <div
      className={
        buildImage
          ? 'CreatePost bg-white rounded-lg max-h-full'
          : 'CreatePost bg-white rounded-lg '
      }
    >
      <p className="py-2 px-4 text-gray-400 font-roboto ">Create a New Post</p>
      {showShareModel && (
        <SharePostModal
          setVisibility={setVisibility}
          visibility={visibility}
          onCloseClick={() => setShowShareModel(false)}
        />
      )}

      <form onSubmit={handleSubmit}>
        <div
          className="p-2 w-full"
          style={{ backgroundColor: 'white', borderRadius: '0px 0px 8px 8px' }}
        >
          <div className="w-full h-full flex items-center gap-3">
            <img
              className="w-12 h-12 rounded-full object-cover"
              alt=""
              src={(data && profileImageUrl + data) || DefaultBackgroundPng}
            />
            <ReactSelect
              classNamePrefix="react-select"
              className="select-wrapper shadow-sm  flex items-center w-1/2  rounded-3xl font-normal"
              defaultValue={postOptions[1]}
              options={postOptions}
              onChange={onChange}
              styles={{
                control: provided => ({
                  ...provided,
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: 'none'
                })
              }}
            />
          </div>

          <div className="max-w-full max-h-full ">
            <Carousel postImage={selectedImage}>
              {buildImage &&
                buildImage.map((img, index) => (
                  <>
                    <img
                      src={img.url}
                      key={index}
                      className="h-full mt-2 min-w-full"
                      style={{ maxHeight: '450px' }}
                      alt=""
                    />

                    <CrossMarkSvg
                      className="absolute top-0 right-0 p-1 bg-white rounded-full cursor-pointer"
                      onClick={() => {
                        setBuildImage(
                          buildImage.filter(image => image.id !== img.id)
                        );
                        console.log('selectedImage', buildImage);
                        setSelectedImage(
                          selectedImage.filter(image => image !== img)
                        );
                      }}
                    />
                  </>
                ))}
            </Carousel>
          </div>

          {video !== '' && (
            <div className="video-container">
              <video controls>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          )}
          <textarea
            name="postInput"
            value={content}
            rows="10"
            cols="30"
            className="w-full bg-gray-100 rounded-md text-gray-700  text-sm px-2 py-2 mt-2 h-28"
            placeholder="Write something here..."
            onChange={e => setContent(e.target.value)}
          />
          <div className="flex items-center justify-between my-0 px-2">
            <input
              onChange={onImageChange}
              multiple
              type="file"
              ref={imageUploadBtnRef}
              className="hidden"
            />
            <input type="file" ref={videoUploadBtnRef} className="hidden" />

            <div className="flex gap-4 px-2 items-center">
              <VideoSvg
                onClick={() => videoUploadBtnRef.current.click()}
                className="primary-svg w-8 h-8"
              />
              <CameraSvg
                onClick={() => imageUploadBtnRef.current.click()}
                className="primary-svg w-8 h-8"
              />
            </div>

            <button
              type="submit"
              className="bg-brand-dark rounded-sm shadow-md font-medium text-white py-1 px-6 text-sm text-center"
            >
              {isLoading ? 'Publishing... ' : 'Publish'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePost;
