import AxiosService from '../AxiosService';

export const getFriendRequests = async pageParam => {
  const response = await AxiosService.get(
    `/user/friendship/find/requests?limit=3&page=${pageParam}`
  );
  return response.data;
};

export const getSentFriendRequests = async pageParam => {
  const response = await AxiosService.get(
    `/user/friendship/find/requests/sent?limit=3&page=${pageParam}`
  );
  return response.data;
};

export const sendFriendRequest = async ({ id }) => {
  const response = await AxiosService.put(`/user/friendship/send/${id}`, id);
  return response.data;
};

export const checkFriendRequest = async ({ id }) => {
  const response = await AxiosService.get(`/user/friendship/find/${id}`);
  return response.data;
};

export const getMyFriends = async pageParam => {
  const response = await AxiosService.get(
    `/user/friendship?limit=3&page=${pageParam}`
  );
  return response.data;
};

export const deleteFriendRequest = async ({ id }) => {
  const response = await AxiosService.put(`/user/friendship/delete/${id}`);
  return response.data;
};

export const rejectFriendRequest = async ({ id }) => {
  const response = await AxiosService.put(`/user/friendship/reject/${id}`, id);
  return response.data;
};

export const cancelOngoingRequest = async ({ id }) => {
  const response = await AxiosService.put(`/user/friendship/delete/${id}`, id);
  return response.data;
};

export const acceptFriendRequest = async ({ id }) => {
  const response = await AxiosService.put(`/user/friendship/accept/${id}`, id);
  return response.data;
};

export const getOtherRequest = async () => {
  const response = await AxiosService.get('/user/friendship/find/requests');
  return response.data;
};
