import moment from 'moment';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import profileImageUrl from '../../config/config';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const CommentNotification = ({
  handleMarkasread,
  profileImage,
  content,
  commentedDate,
  id,
  postId
}) => {
  return (
    <Link
      to={`/post/${postId}`}
      onClick={() => handleMarkasread(id)}
      className="relative w-4/5 flex flex-col gap-2 mt-1 py-2   p-1 cursor-pointer  "
    >
      <div className="flex items-center gap-2">
        <img
          src={
            (profileImage && profileImageUrl + profileImage) ||
            DefaultProfilePng
          }
          className="w-14 h-14 object-cover rounded-full"
          alt=""
        />
        <h3 className="flex flex-col font-medium text-sm  font-roboto">
          {content}
          <span className="font-normal font-roboto text-xs text-gray-500">
            {moment(commentedDate)?.fromNow()}
          </span>
        </h3>
      </div>
    </Link>
  );
};

export default CommentNotification;
