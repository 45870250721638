import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manageUser: null
};

const ManagedUserSlice = createSlice({
  name: "ManagedUser",
  initialState,
  reducers: {
    setManagedUser: (state, action) => {
      state.manageUser = action.payload;
    }
  }
});

export const { setManagedUser } = ManagedUserSlice.actions;

export default ManagedUserSlice.reducer;
