import { ReactComponent as Edit } from '../../../../img/misc/edit.svg';
import React from 'react';

const Clients = ({ isEditable = true, totalClients = 0 }) => {
  return (
    <div className="bio rounded-lg font-roboto">
      <div className="flex justify-between items-center px-4 rounded-t-md">
        <div className="h-9 flex items-center font-semibold text-brand-dark text-xl">
          Clients
        </div>
        {isEditable && (
          <Edit
            // onClick={() => setEditAboutModal(true)}
            className="w-[21px] h-[21px] cursor-pointer"
            style={{ color: '#ffffff' }}
          />
        )}
      </div>

      <p className="w-full p-4 break-words text-gray-700 text-base flex justify-start">
        <p className="flex flex-1 flex-col">
          <b>Total Clients</b>
          <p>{totalClients}</p>
        </p>
        <p className="flex flex-1 flex-col">
          <b>Total Market Value</b>
          <p>0</p>
        </p>
      </p>
    </div>
  );
};

export default Clients;
