import HidePostSvg from '../../../../img/misc/hide_post.svg';
import UnfollowSvg from '../../../../img/misc/unfollow.svg';
import { useSelector } from 'react-redux';

function DefaultPostOption({ id, setShowPostOption, username, post, userId }) {
  //
  //
  const showUnfollowOption = useSelector(state => state.auth.user);

  return (
    <div
      className="w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
      style={{ zIndex: '9999' }}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      {/*  */}
      <div className="w-full flex flex-col text-sm ">
        <div
          className="flex items-center gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer primary-svg"
          onClick={() => setShowPostOption(true)}
        >
          <img className="w-5 h-5 object-contain" src={HidePostSvg} alt="" />
          Hide Post
        </div>
        {showUnfollowOption?.id !== userId && (
          <div
            className="flex items-center gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer primary-svg rounded-b-md"
            onClick={() => setShowPostOption(false)}
          >
            <img className="w-5 h-5 object-contain" src={UnfollowSvg} alt="" />
            Unfollow {username}
          </div>
        )}
      </div>
      {/*  */}
    </div>
  );
}

export default DefaultPostOption;
