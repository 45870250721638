import "./About.css";
import { Element } from "react-scroll";
import playerImg from "../../../img/landing/player-img.png";
import playerFullImg from "../../../img/landing/player-full.jpg";

const About = () => {
  return (
    <Element name="about" className="About mt-10 mx-auto flex">
      <div className="image-area desktop-image">
        <img src={playerImg} className="" alt="" />
      </div>
      <div className="image-area mobile-image">
        <img src={playerFullImg} className="" alt="" />
      </div>
      <div className="text-area bg-brand text-white p-6">
        <p className="text-2xl font-bold font-acumin">ABOUT US</p>
        <p className="text-xl mt-4">
          Eleven Hype is an athlete marketing agency and networking platform
          focusing on football players of all ages with aspirations of playing
          at all levels. Eleven Hype was born through the athletes’ need for a
          curated digital portfolio that is ready to showcase during any step of
          their career.
        </p>
        <p className="text-xl mt-6">
          Our team of designers and social media managers will be closely in
          touch with your busy schedule and will make sure you capture and store
          on our platform only your most important moments, the ones you will
          want to remember years from now. Much like other online platforms, our
          website stores media and content - the only difference is that you
          won’t have to update it yourself, as we do all the work for you.
        </p>
        <p className="text-xl mt-6">
          Far too often, players don’t have the skill set to market themselves
          and miss a team of marketing professionals behind them to create
          personal websites, social media campaigns, CVs, player elevator
          pitches, etc.
        </p>
        <p className="extra-paragraph text-xl mt-6">
          Eleven Hype platform works as an athlete profile page that helps the
          player improve his career prospects by finding agents, schools, and
          football clubs. Relying on us, you get to focus on what is important
          to you—playing the beautiful game.
        </p>
      </div>
    </Element>
  );
};

export default About;
