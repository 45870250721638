import moment from 'moment';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';

const EventScheduledNotification = ({
  eventName,
  date,
  handleMarkasread,
  id
}) => {
  return (
    <div
      onClick={() => handleMarkasread(id)}
      className="relative w-4/5 flex flex-col gap-2 mt-1 py-2  p-1 cursor-pointer "
    >
      <div className="flex items-center gap-2">
        <img
          src={DefaultProfilePng}
          className="w-14 h-14 object-cover"
          alt=""
        />

        <div className=" flex  flex-1 ">
          <h3 className="font-medium text-sm font-roboto">
            {`Event scheduled for ${moment(date).format('MMMM Do YYYY')}`}
            <br />
            <span className="font-normal text-xs text-gray-500 font-roboto">
              {moment(date).fromNow()}
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default EventScheduledNotification;
