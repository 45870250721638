import React, { useState } from "react";
import "../../styles/main.css";
import "./FAQ.css";
import Header from "../../components/Header";
import Footer from "../Landing/Footer/Footer";
import down from "../../img/down.png";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
//import 'react-accessible-accordion/dist/fancy-example.css';

const FAQ = () => {
  return (
    <div className="FAQ">
      <div className="faq-top">
        <Header whiteLogo={true} />

        <div className="faq-title1">Questions</div>
        <div className="faq-title2"> Answers</div>
      </div>
      <div className="accordion-wrapper">
        <div className="faq-text"> F A Q </div>
        <div className="faq-question-text"> QUESTIONS</div>

        <Accordion>
          <AccordionItem className="accordion-item">
            <AccordionItemHeading>
              <AccordionItemButton className=" accordion-text-height ">
                <div className="flex flex-wrap">
                  <div className="faq-row-division faq-yellow-div"> Q</div>
                  <div className="faq-row-division faq-yellow-div faq-row-width flex flex-wrap">
                   PLAYERS PORTFOLIO
                    <img className="ml-2" src={down} alt="" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-list-item">
              <p className="faq-question">Q: What is the cost to setup a profile?</p>
             <p className="faq-answer"> A: To setup a profile there is not cost for a simple profile. </p>
             <p className="faq-question">Q: Is a personal Website and domain included in free profile?</p>
             <p className="faq-answer"> A: No, A personal website is included with our premium monthly package. </p>
             <p className="faq-question">Q: Is social media management included with the free profile?</p>
             <p className="faq-answer"> A: No, It can be purchased as an option individual or you can purchase one of our monthly premium package. </p>
             
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem className="accordion-item">
            <AccordionItemHeading>
              <AccordionItemButton className=" accordion-text-height ">
                <div className="flex flex-wrap ">
                  <div className="faq-row-division faq-yellow-div"> Q</div>
                  <div className="faq-row-division faq-yellow-div faq-row-width flex flex-wrap">
                    PLAYER PROFILE
                    <img className="ml-2" src={down} alt="" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-list-item">
            <p className="faq-question">Q. How do I change my profile picture?</p>
             <p className="faq-answer"> A: On the player dashboard clicking on the edit button on the players card. This will allow the user to change their profile picture.  </p>
             <p className="faq-question">Q: How do I update my cover photo?</p>
             <p className="faq-answer"> A: On the player profile dashboard select the camera on the top left. This will allow you to upload or change your cover photo.  </p>
             <p className="faq-question">Q: How do I add my awards to my profile?</p>
             <p className="faq-answer"> A:On the player profile dashboard, scroll to Awards and select the Add button. Specify the title, location and date of your award and then click ‘save’.  </p>
             <p className="faq-question">Q:  How do I add testimonials from my coaches?</p>
             <p className="faq-answer"> A:This one will be for now you ask for coach testimonial if your coach is not a contact then it will ask for an email address to send him an invitation.  For now since the site doesn’t support coaches it will only send invitations with a form to collect the data. To add a testimonial the user will request one from their contact list, if the user is not on their contact we will request their email address so that we can send an invitation </p>
             <p className="faq-question">Q:  How can I add my skills? </p>
             <p className="faq-answer"> A:On your player profile scroll down to Skills and positions. Select the Add skills and positions  button, and then fill in your skills and positions. Add them one by one.  </p>
              



            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem className="accordion-item">
            <AccordionItemHeading>
              <AccordionItemButton className=" accordion-text-height ">
                <div className="flex flex-wrap">
                  <div className="faq-row-division faq-yellow-div"> Q</div>
                  <div className="faq-row-division faq-yellow-div faq-row-width flex flex-wrap">
                  MESSAGES
                    <img className="ml-2" src={down} alt="" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-list-item"> 
            <p className="faq-question">Q: Can I send messages to my contacts? </p>
             <p className="faq-answer"> A: Yes, Eleven Hype will have an in platform message system. </p>
             <p className="faq-question">Q: How do I view the profile of someone I’m messaging? </p>
             <p className="faq-answer"> A: To see someone’s profile what you’re currently messaging you will need to click on their picture. </p>
           
          
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem className="accordion-item">
            <AccordionItemHeading>
              <AccordionItemButton className=" accordion-text-height ">
                <div className="flex flex-wrap">
                  <div className="faq-row-division faq-yellow-div"> Q</div>
                  <div className="faq-row-division faq-yellow-div faq-row-width flex flex-wrap">
                  GENERAL UTILITY
                    <img className="ml-2" src={down} alt="" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-list-item"> 
            <p className="faq-question">Q: How can I add a new contact? </p>
             <p className="faq-answer"> A: To add a new contact you will go to a user profile and click on the “Send request” button. </p>
             <p className="faq-question">Q: Is there a limit for the number of contacts I can add?  </p>
             <p className="faq-answer"> A: One of Eleven Hype goals is to allow the users the ability to network, so there’s no number limitation for contacts. </p>
             <p className="faq-question">Q: How can I publish a new post or update? What image and video formats can I attach to my post?  </p>
             <p className="faq-answer"> A: To publish a new post the user will go to their home section or activity section and create a new post, these can either be a simple text for a status update or can be a photo or video, the platform allows for picture formats such as Png and Jpg, for video Mp4 formats. </p>
             <p className="faq-question">Q: Can I add a Gif or a video as my cover photo or as my profile picture?  </p>
             <p className="faq-answer"> A: Currently only images are allowed as cover photos. </p>
             <p className="faq-question">Q: I forgot my password. What can I do?  </p>
             <p className="faq-answer"> A: In the case of forgetting a password to the user on the login section will click on the forgot password button which, a form will appear requesting the email address, after inputting the address the user will receive a mail on their inbox with the steps to follow. </p>
             <p className="faq-question">Q: Am I able to sort updates in my feed?   </p>
             <p className="faq-answer"> A: 1Hype does not allow this feature yet. Maybe in the future!  </p>
             <p className="faq-question">Q: Will I receive notifications on my email about contact requests or messages? </p>
             <p className="faq-answer"> A: Yes, in the future.
 </p>
           
          
            </AccordionItemPanel>
          </AccordionItem>

         


         

          <AccordionItem className="accordion-item">
            <AccordionItemHeading>
              <AccordionItemButton className=" accordion-text-height ">
                <div className="flex flex-wrap">
                  <div className="faq-row-division faq-yellow-div"> Q</div>
                  <div className="faq-row-division faq-yellow-div faq-row-width flex flex-wrap">
                  SOCIAL MEDIA
                    <img className="ml-2" src={down} alt="" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-list-item"> 
              <p className="faq-question">Q: Will my 11Hype posts be automatically shared on my social media profiles?</p>
             <p className="faq-answer"> A: This depends on the account type they have. </p>
             <p className="faq-question">Q: Will 11Hype reply to comments and messages I receive on my Social Media profiles?.</p>
             <p className="faq-answer"> A: NO. but it does depend on the account profile selected.  Ednaldo Leitefor now that is not an option but we will offer that. </p>
             
            </AccordionItemPanel>
          </AccordionItem>

         

         

         
        </Accordion>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
