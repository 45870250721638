import AxiosService from "../AxiosService";

export const getClubPlayers = async () => {
  const response = await AxiosService.get("/club/players");
  return response.data;
};

export const getClubAgents = async () => {
  const response = await AxiosService.Club("/club/agents");
  return response.data;
};

export const getClubCoaches = async () => {
  const response = await AxiosService.get("/club/coaches");
  return response.data;
};

//update club profile
export const updateClubProfile = async data => {
  const response = await AxiosService.put("/club/", data);
  return response.data;
};
