import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import AsyncSelect from "react-select/async";
import InviteAgentModal from "../../../components/InviteAgentModal/InviteAgentModal";
import {
  createRoleRequest,
  searchAgent,
  updateAgent
} from "../../../services/routes/User";
import { useMutation } from "react-query";
import { fetchUser } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";
import "./modal.scss";

const AddClubAgentModal = ({ onCloseClick }) => {
  //
  //
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const [inviteAgent, setInviteAgent] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  // Updating Agent to Send the request
  const { mutate } = useMutation(createRoleRequest, {
    onSuccess: () => {
      dispatch(fetchUser());
    },
    onError: err => {
      console.log(err.response.data.messsage);
    }
  });

  useEffect(() => {
    getSearchAgent();
  }, []);

  const handleSelect = selectedAgent => {
    setSelectedAgent(selectedAgent);
  };

  const getSearchAgent = async (inputValue = "", callback) => {
    const filteredAgents = await searchAgent(inputValue);
    const options = filteredAgents.data.map(agent => ({
      label: agent.contact.first_name + " " + agent.contact.last_name,
      value: agent.id
    }));
    callback(options);
  };

  const sendRequest = () => {
    mutate({ toUser: selectedAgent.value });
    onCloseClick();
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title={agents.length > 0 ? "Edit Agent" : "Add Agent"}
    >
      {!inviteAgent ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            onChange={handleSelect}
            isSearchable={true}
            value={selectedAgent}
            loadOptions={getSearchAgent}
            classNamePrefix="react-select"
            name="agentOptions"
            placeholder="Search for Agents..."
          />

          {selectedAgent ? (
            <div className="w-full flex justify-center mt-4">
              <button
                type="submit"
                className="text-center px-12 focus:outline-none text-lg py-1 text-white bg-brand-light hover:bg-brand-dark theme-button mt-4"
                onClick={sendRequest}
              >
                Send Request
              </button>
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-xl flex-col justify-center mt-6 mb-2">
              Didn't Found an Agent?
              <button
                onClick={() => setInviteAgent(!inviteAgent)}
                className="bg-brand-light hover:bg-brand-dark px-6 py-2  text-white rounded-md mt-2"
              >
                Invite Agent
              </button>
            </span>
          )}
        </div>
      ) : (
        <InviteAgentModal
          onCloseClick={onCloseClick}
          setInviteAgent={setInviteAgent}
          inviteAgent={inviteAgent}
        />
      )}
    </Modal>
  );
};
export default AddClubAgentModal;
