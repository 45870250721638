import { useForm } from 'react-hook-form';
import NotificationSettings from './NotificationSettings';
import { ReactComponent as SaveSvg } from '../../../img/landing/save.svg';
import { ReactComponent as CancelSvg } from '../../../img/landing/cancel.svg';
import { useState } from 'react';
import './notifications.scss';

export default function Notifications() {
  const { register, handleSubmit, reset } = useForm();
  const [showCancel, setShowCancel] = useState(false);

  const onsubmit = data => {
    // TODO: SEND THE DATA TO THE SERVER
  };

  return (
    <div className="md:w-full w-11/12  mt-4 md:mt-0 ">
      <div className="Notifications">
        <div className="flex flex-col gap-5">
          <NotificationSettings
            onChange={() => setShowCancel(true)}
            register={register}
            name="pushNotification"
            title="Enable push notifications?"
          />
          <NotificationSettings
            onChange={() => setShowCancel(true)}
            register={register}
            name="emailNotification"
            title="Enable email notifications?"
          />
        </div>
        <button className="mt-8 flex justify-center  items-center gap-4">
          {showCancel && (
            <CancelSvg
              className="w-36 object-contain"
              onClick={() => {
                setShowCancel(false);
                reset();
              }}
            />
          )}
          <SaveSvg
            className="w-36 object-contain"
            type="submit"
            onClick={handleSubmit(onsubmit)}
          />
        </button>
      </div>
    </div>
  );
}
