import { useState } from 'react';
import { useQuery } from 'react-query';
import { getBookmarkPosts, getMyPost } from '../../services/routes/Post';
import SideNavWrapper from '../SideNavWrapper';
import DeletePostModal from '../Player/PlayerHome/DeletePostModal/DeletePostModal';
import EditPost from '../Player/PlayerHome/EditPost/EditPost';
import PostLeftNav from '../Player/PlayerPost/PostLeftNav';
import Post from '../Player/PlayerHome/Post/Post';
import LoaderGif from '../../img/busy.gif';
import InfiniteScroll from 'react-infinite-scroll-component';
import './myPost.scss';

const Loader = () => {
  return (
    <div className="flex h-full mt-5 justify-center items-center w-70rem">
      <img src={LoaderGif} alt="" className="w-28 h-28 object-contain" />
    </div>
  );
};

const MyPost = () => {
  const [myPostIndex, setMyPostIndex] = useState(1);
  const [savedPostIndex, setSavedPostIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [post, setPostsData] = useState([]);
  const [selectedNav, setSelectedNav] = useState('your-posts');

  const [showDeletePostModal, setShowDeletePostModal] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [editPost, setEditPost] = useState({});

  const [savedPost, setSavedPost] = useState([]);

  const { refetch: myPostRefetch } = useQuery(
    'fetch_mypost',
    () => getMyPost(myPostIndex),
    {
      onSuccess: data => {
        setPostsData(prevData => [...prevData, ...data?.data?.posts]);
        data?.data?.hasMore ? setHasMore(true) : setHasMore(false);
        setMyPostIndex(prevPage => prevPage + 1);
      },
      onError: () => {
        setHasMore(false);
      }
    }
  );

  const { refetch: savedPostRefetch } = useQuery(
    'saved_post',
    () => getBookmarkPosts(savedPostIndex),
    {
      onSuccess: data => {
        setSavedPost(prevData => [...prevData, ...data?.data?.posts]);
        data?.data?.hasMore ? setHasMore(true) : setHasMore(false);
        setSavedPostIndex(prevIndex => prevIndex + 1);
      },
      onError: () => {
        setHasMore(false);
      },
      enabled: false
    }
  );

  const onDeletePostClick = id => {
    setShowDeletePostModal(true);
    setPostId(id);
  };

  const onEditPostClick = data => {
    setShowEditPostModal(true);
    setEditPost(data);
  };

  return (
    <SideNavWrapper isFooter={true} width={'100%'}>
      {showDeletePostModal && (
        <DeletePostModal
          onCloseClick={() => setShowDeletePostModal(false)}
          refetch={myPostRefetch}
          post_id={postId}
        />
      )}
      {showEditPostModal && (
        <EditPost
          onCloseClick={() => setShowEditPostModal(false)}
          postData={editPost}
          refetch={myPostRefetch}
        />
      )}

      <div className=" min-w-full flex flex-col justify-center items-center md:items-start  md:flex-row space-x-8  md:gap-x-0">
        <div className="md:mt-5 ">
          <PostLeftNav
            selectedNav={selectedNav}
            setSelectedNav={setSelectedNav}
            fetchSavedPost={savedPostRefetch}
          />
        </div>

        <div className="mt-4 flex justify-center ">
          <div className=" flex flex-col ">
            {/* My Post */}
            {selectedNav === 'your-posts' && (
              <div className="my_post_container ">
                <InfiniteScroll
                  dataLength={post?.length}
                  next={myPostRefetch}
                  hasMore={hasMore}
                  loader={<Loader />}
                  endMessage={
                    post?.length > 0 ? (
                      <p className="text-center mb-5 text-gray-800">
                        <b>Yay! You have seen it all</b>
                      </p>
                    ) : (
                      <p className="text-center my-5 text-gray-800">
                        <b>You have not posted anything yet!</b>
                      </p>
                    )
                  }
                >
                  {post
                    ?.sort((b, a) => {
                      return new Date(a.createdAt) - new Date(b.createdAt);
                    })
                    ?.map(post => (
                      <Post
                        key={post?.id}
                        post={post}
                        onDeletePostClick={onDeletePostClick}
                        onEditPostClick={onEditPostClick}
                        feedPage={false}
                      />
                    ))}
                </InfiniteScroll>
              </div>
            )}

            {/* Bookmark or Saved Post */}
            {selectedNav === 'saved-posts' && (
              <div className="my_post_container">
                <InfiniteScroll
                  dataLength={savedPost?.length}
                  next={savedPostRefetch}
                  hasMore={hasMore}
                  loader={<Loader />}
                  endMessage={
                    savedPost?.length > 0 ? (
                      <p className="text-center mb-5 text-gray-800">
                        <b>Yay! You have seen it all</b>
                      </p>
                    ) : (
                      <p className="text-center my-5 text-gray-800">
                        <b>
                          You have no saved Post!
                          <br /> Save any post to see here
                        </b>
                      </p>
                    )
                  }
                >
                  {savedPost?.map((post, index) => (
                    <Post
                      key={index}
                      post={post}
                      onDeletePostClick={onDeletePostClick}
                      onEditPostClick={onEditPostClick}
                      feedPage={false}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default MyPost;
