import { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddMyList from '../../../components/MyList/AddMyList';
import EditlistModal from '../../Agent/AgentProfile/EditListModal/EditListModal';
import PersonRequestCard from '../../../components/PersonRequestCard/PersonRequestCard';
import AddAwardsModal from '../../../components/AddAwardsModal/AddAwardsModal';
import EditAwardModal from '../../../components/EditAwardModal/EditAwardModal';
import DeleteAwardModal from '../../../components/DeleteAwardModal/DeleteAwardModal';
import ProfileAwards from '../../../pages/Player/PlayerProfile/PlayerAwards/PlayerAwards';
import { getCoachPlayers } from '../../../services/routes/CoachProfile';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CoachClub from './CoachClub/CoachClub';
import EditCoachInfoModal from './CoachInfoModal/EditCoachInfoModal';
import { fetchUser } from '../../../redux/userSlice';
import AgentClubHistory from '../../../components/AgentClubHistory/AgentClubHistory';
import {
  deleteCoachAgentHistory,
  getCoachAgentHistory
} from '../../../services/routes/CoachHistories/coachAgentHistories';
import AddCoachAgentHistoryModal from './CoachAgentHistory/AddCoachAgentHistoryModal';
import EditCoachAgentHistoryModal from './CoachAgentHistory/EditCoachAgentHistoryModal';
import UserHistoryTable from '../../../components/UserHistoryTable/UserHistoryTable';
import {
  deleteCoachClubHistory,
  getallCoachClubHistory
} from '../../../services/routes/CoachHistories/coachClubistories';
import AddClubHistoryModal from './CoachClubHistory/AddClubHistoryModal';
import EditClubHistoryModal from './CoachClubHistory/EditClubHistoryModa';
import DeleteHistoryModal from '../../../components/DeleteHistoryModal/DeleteHistoryModal';
import {
  getAllManagedPlayers,
  getMyAllPlayerList
} from '../../../services/routes/User';
import { toast } from 'react-toastify';
import MyList from '../../../components/MyList/MyList';
import DeleteMyList from '../../../components/MyList/DeleteMyList';
import EditMyList from '../../../components/MyList/EditMyList';
import './coachProfile.scss';
import AddManagedPlayerModal from '../../Club/AddClubPlayerModal/AddClubPlayerModal';
import Sidebar from '../../../components/Profile/Sidebar';
import ProfileBio from '../../../components/Profile/ProfileBio';
import TabSwitcher from '../../../components/Profile/TabSwitcher';
import PlayerPosition from '../../Player/PlayerProfile/PlayerPosition/PlayerPosition';
import AddCoachAgentModal from './AddCoachAgentModal';
import Rooster from '../ManagedPlayer';

const CoachProfile = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useSelector(state => state.auth.user);

  // Sending the Accepted friend request as a props on person request card component
  let data = user;

  // getting managed players/roaster
  const { data: managedPlayersData } = useQuery(
    'fetch_managedPlayers',
    getAllManagedPlayers
  );

  const [selectedPlayerList, setSelectedPlayerList] = useState();
  const [editListModalOpen, setEditListModal] = useState(false);
  const [createListModalOpen, setCreateListModalOpen] = useState(false);
  const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);
  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState(false);
  const [addAward, setAddAward] = useState(false);
  const [showEditAwardModal, setShowEditAwardModal] = useState(false);
  const [showDeleteAwardModal, setShowDeleteAwardModal] = useState(false);
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [currentAgentAwards, setCurrentAgentAwards] = useState({});

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  //deleteClubHistory
  const { mutate: mutateClubHistory, isLoading: loadingClub } = useMutation(
    deleteCoachClubHistory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Club-History');
        refetchClubHistory();
      },

      onError: err => {
        toast.error(err.response.data.message);
      }
    }
  );

  //delete agent history
  const { mutate: mutateAgentHistoory, isLoading: loadingAgent } = useMutation(
    deleteCoachAgentHistory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Agent-history');
        refetchAgent();
      },
      onError: err => {
        console.error(err);
      }
    }
  );

  // Club history
  const [addClubHistory, setAddClubHHistory] = useState(false);
  const [editClubHistoryModal, setEditClubHistoryModal] = useState(false);
  const [currentClubHistory, setCurrentClubHistory] = useState({});
  const [deleteClubHistoryById, setDeleteClubHistoryById] = useState({});
  const [showDeleteClubHistoryModal, setShowDeleteClubHistoryModal] =
    useState(false);

  // Agent history
  const [addCoachAgentHistoryModal, setAddCoachAgentHistoryModal] =
    useState(false);
  const [currentAgentHistory, setCurrentAgentHistory] = useState({});
  const [editCoachAgentHistoryModal, setEditCoachAgentHistoryModal] =
    useState(false);
  const [deleteAgentHistoryModal, setDeleteAgentHistoryModal] = useState(null);
  const [deleteAgentHistory, setDeleteAgentHistory] = useState({});

  // My Player List
  const [editMyPlayerListModal, setEditMyPlayerListModal] = useState(false);
  const [currentMyList, setCurrentMyList] = useState({});
  const [showMyListDeleteModal, setShowMyListDeleteModal] = useState(false);
  const [deleteMyPlayerListById, setDeleteMyPlayerListById] = useState({});
  const [activeTab, setActiveTab] = useState('information');

  //get club history
  const { data: ClubHistory, refetch: refetchClubHistory } = useQuery(
    'Club-history',
    getallCoachClubHistory
  );

  // Getting myPlayers List
  const { data: myPlayerList } = useQuery(
    'fetch_myPlayerList',
    getMyAllPlayerList
  );

  //get agent history
  const { data: agentHistory, refetch: refetchAgent } = useQuery(
    'Agent-history',
    getCoachAgentHistory
  );

  // Getting Coach that has added players
  const { data: coachPlayers } = useQuery(
    'fetch-coach-players',
    getCoachPlayers
  );

  //for awards
  const editAwards = data => {
    setShowEditAwardModal(true);
    setCurrentAgentAwards(data);
  };

  const deleteAwards = data => {
    setShowDeleteAwardModal(true);
    setCurrentAgentAwards(data);
  };

  //for agent history
  const onEditAgentHistory = data => {
    setEditCoachAgentHistoryModal(true);
    setCurrentAgentHistory(data);
  };

  const onDeleteAgentHistory = id => {
    setDeleteAgentHistory(id);
    setDeleteAgentHistoryModal(true);
  };

  //for club history
  const onEditClubHistoryClick = data => {
    setEditClubHistoryModal(true);
    setCurrentClubHistory(data);
  };

  const onDeletePlayerClubHistoryClick = id => {
    setDeleteClubHistoryById(id);
    setShowDeleteClubHistoryModal(true);
  };

  // for my-playerList
  const onEditMyListClick = data => {
    setEditMyPlayerListModal(true);
    setCurrentMyList(data);
  };

  const onDeleteMyPlayerListClick = id => {
    setDeleteMyPlayerListById(id);
    setShowMyListDeleteModal(true);
  };

  // Managed Players states and functions

  const [currentManagedPlayer, setCurrentManagedPlayer] = useState([]);

  const onEditManagedPlayerClick = data => {
    setAddPlayerModalOpen(true);
    setCurrentManagedPlayer(data);
  };
  return (
    <>
      {/* Delete Modal */}
      {showDeleteAwardModal && (
        <DeleteAwardModal
          onCloseClick={() => setShowDeleteAwardModal(false)}
          award={currentAgentAwards}
        />
      )}
      {deleteAgentHistoryModal === true && (
        <DeleteHistoryModal
          onCloseClick={() => setDeleteAgentHistoryModal()}
          id={deleteAgentHistory}
          mutate={mutateAgentHistoory}
          isLoading={loadingAgent}
        />
      )}
      {showDeleteClubHistoryModal && (
        <DeleteHistoryModal
          id={deleteClubHistoryById}
          onCloseClick={() => setShowDeleteClubHistoryModal(false)}
          mutate={mutateClubHistory}
          isLoading={loadingClub}
        />
      )}

      {showMyListDeleteModal && (
        <DeleteMyList
          onCloseClick={() => setShowMyListDeleteModal(false)}
          id={deleteMyPlayerListById}
        />
      )}

      {/* Edit's  Modal */}
      {editListModalOpen && (
        <EditlistModal
          selected={selectedPlayerList}
          onCloseClick={() => setEditListModal(false)}
        />
      )}
      {showEditAwardModal && (
        <EditAwardModal
          onCloseClick={() => setShowEditAwardModal(false)}
          award={currentAgentAwards}
        />
      )}

      {showEditInfoModal && (
        <EditCoachInfoModal onCloseClick={() => setShowEditInfoModal(false)} />
      )}

      {editCoachAgentHistoryModal && (
        <EditCoachAgentHistoryModal
          onCloseClick={() => setEditCoachAgentHistoryModal(false)}
          setData={currentAgentHistory}
        />
      )}

      {editClubHistoryModal && (
        <EditClubHistoryModal
          setEditData={currentClubHistory}
          onCloseClick={() => setEditClubHistoryModal(false)}
          refetch={refetchClubHistory}
        />
      )}
      {editMyPlayerListModal && (
        <EditMyList
          setData={currentMyList}
          onCloseClick={() => setEditMyPlayerListModal(false)}
        />
      )}

      {/* Adds Modals */}

      {addAward && <AddAwardsModal onCloseClick={() => setAddAward(false)} />}

      {createListModalOpen && (
        <AddMyList onCloseClick={() => setCreateListModalOpen(false)} />
      )}

      {addPlayerModalOpen && (
        <AddManagedPlayerModal
          data={currentManagedPlayer}
          onCloseClick={() => setAddPlayerModalOpen(false)}
        />
      )}

      {addAgentModalOpen && (
        <AddCoachAgentModal onCloseClick={() => setAddAgentModalOpen(false)} />
      )}

      {addCoachAgentHistoryModal && (
        <AddCoachAgentHistoryModal
          onCloseClick={() => setAddCoachAgentHistoryModal(false)}
          refetch={refetchAgent}
        />
      )}

      {addClubHistory && (
        <AddClubHistoryModal
          onCloseClick={() => setAddClubHHistory(false)}
          refetch={refetchClubHistory}
        />
      )}

      {/* Components */}
      <div className="flex w-full mt-8 gap-8">
        <div className="w-96">
          <Sidebar>
            <ProfileBio isEditable={true} />
            <div className="border-b-2 border-brand-dark mx-8 my-6 border-opacity-100"></div>
            <PlayerPosition />
          </Sidebar>
        </div>

        <div className="flex flex-col flex-1">
          {/* Coach Info and Club */}
          <TabSwitcher
            tabs={[
              {
                title: 'Information',
                name: 'information',
                component: <div>Information</div>
              },
              {
                title: 'Rooster',
                name: 'rooster',
                component: <div>Agent</div>
              },
              {
                title: 'My List',
                name: 'my-list',
                component: <div>Coach</div>
              },
              {
                title: 'Agent',
                name: 'agent',
                component: <div>Award</div>
              },
              {
                title: 'Awards',
                name: 'awards',
                component: <div>Award</div>
              },
              {
                title: 'History',
                name: 'history',
                component: <div>Award</div>
              }
            ]}
            activeTab={activeTab}
            setActiveTab={name => {
              setActiveTab(name);
            }}
          />

          {activeTab === 'information' && (
            <CoachClub
              setShowEditInfoModal={setShowEditInfoModal}
              infoData={user}
            />
          )}

          {activeTab === 'rooster' && (
            <div>
              <Rooster
                title="Roster"
                type="players"
                addModalOpen={setAddPlayerModalOpen}
                data={managedPlayersData?.data}
                onEditManagedPlayerClick={onEditManagedPlayerClick}
                isEditable={true}
              />
            </div>
          )}

          {
            // My List
            activeTab === 'my-list' && (
              <div>
                <MyList
                  myPlayerList={myPlayerList?.data}
                  setCreateListModalOpen={setCreateListModalOpen}
                  editMyList={onEditMyListClick}
                  deleteMyList={onDeleteMyPlayerListClick}
                  isEditable={true}
                />
              </div>
            )
          }
          {
            // My List
            activeTab === 'agent' && (
              <div>
                <PersonRequestCard
                  personType="Agent"
                  type="agent"
                  role="coach"
                  data={data}
                  setShowModalOpen={setAddAgentModalOpen}
                  isEditable={true}
                />
              </div>
            )
          }

          {
            // Awards
            activeTab === 'awards' && (
              <div>
                <ProfileAwards
                  awards={user?.awards}
                  onEditPlayerAwardClick={editAwards}
                  setAddAwardModal={setAddAward}
                  deleteAwards={deleteAwards}
                  isEditable={true}
                />
              </div>
            )
          }
          {activeTab === 'history' && (
            <div className="flex flex-col">
              <div>
                <UserHistoryTable
                  role="coach"
                  title="Club History"
                  isEditable={true}
                  histories={ClubHistory?.data}
                  addHistory={() => setAddClubHHistory(true)}
                  editHistory={onEditClubHistoryClick}
                  deleteHistory={onDeletePlayerClubHistoryClick}
                />
              </div>

              <div className="mt-5">
                <AgentClubHistory
                  isEditable={true}
                  role="Agent"
                  showAddHistoryModal={() => setAddCoachAgentHistoryModal(true)}
                  histories={agentHistory?.data}
                  editHistory={onEditAgentHistory}
                  deleteHistory={onDeleteAgentHistory}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CoachProfile;
