import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisitPlayerProfile from '../../components/PlayerProfile/VisitPlayerProfile';
import VisitAgentProfile from '../../components/VisitAgentProfile/VisitAgentProfile';
import VisitCoachProfile from '../../components/VisitCoachProfile/VisitCoachProfile';
import VisitClubProfile from '../../components/VisitClubProfile/VisitClubProfile';
import { useParams } from 'react-router-dom';
import { loadProfileWithUsername } from '../../services/routes/User';
import { setVisitProfile } from '../../redux/visitProfileSlice/VisitProfileReducer';
import Loader from '../../img/busy.gif';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import SideNavWrapper from '../SideNavWrapper';
import MainProfileFrame from '../../components/PlayerProfile/MainProfileFrame';
import VisitProfileBio from '../../components/PlayerProfile/VisitProfileBio';
import UserContext from '../../components/PlayerProfile/UserContext';
import FileAComplaint from '../../components/FileAComplaint/FileAComplaint';
import './visitProfile.scss';

const VisitUserProfile = () => {
  const dispatch = useDispatch();
  const { username } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.visitProfile.userProfile);
  const myProfileId = useSelector(state => state.auth.user.id);
  const [addComplainModal, setAddComplainModal] = useState(false);

  useEffect(() => {
    searchWithUsername();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const searchWithUsername = async () => {
    setIsLoading(true);
    await loadProfileWithUsername(username)
      .then(data => {
        dispatch(setVisitProfile(data?.data));
        setIsLoading(false);
      })
      .catch(err => {});
  };

  if (myProfileId === user?.id) {
    return <Redirect to="/my-profile" />;
  }

  if (isLoading) {
    return (
      <div className="flex w-full h-screen justify-center items-center">
        <img src={Loader} alt="" className="w-28 h-28 object-contain" />
      </div>
    );
  }

  return (
    <SideNavWrapper width={'100%'} isFooter={true}>
      {addComplainModal && (
        <FileAComplaint onCloseClick={() => setAddComplainModal(false)} />
      )}
      <div className="min-w-full flex justify-center items-center">
        <div className="visit_profile_container">
          <UserContext.Provider value={setAddComplainModal}>
            <MainProfileFrame />
          </UserContext.Provider>

          {user?.role === 'player' && <VisitPlayerProfile />}
          {user?.role === 'agent' && <VisitAgentProfile />}
          {user?.role === 'coach' && <VisitCoachProfile />}
          {user?.role === 'club' && <VisitClubProfile />}
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default VisitUserProfile;
