import React from 'react';
import DefaultProfilePicture from '../../../img/misc/Default_pfp.png';
import { useQuery } from 'react-query';
import { getAllBlockUsers } from '../../../services/routes/blockUser';

const BlockUsers = () => {
  const { data: blockedUsers } = useQuery('blockUsers', getAllBlockUsers);

  console.log('blockedUsers', blockedUsers);

  const handleUnblock = async () => {
    // await unblockUser(id);
    // queryClient.invalidateQueries('blockUsers');
  };

  return (
    <div className="md:w-full w-11/12  mt-4 md:mt-0 ">
      <div className="flex flex-col gap-5">
        <div className="flex items-center gap-6">
          <img
            src={DefaultProfilePicture}
            className="w-14 h-14 bg-white object-cover rounded-full"
            alt=""
          />
          <div>
            <h3 className="text-sm font-medium">John Doe</h3>
            <p className="text-xs text-gray-400">@johndoe</p>
          </div>

          <button
            onClick={handleUnblock}
            className="px-3 py-1 text-xs text-white bg-red-500 rounded-full"
          >
            Unblock
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlockUsers;
