import React from 'react';
import profileImageUrl from '../../../config/config';
import DefaultJersery1 from '../../../img/jersey/default_1.jpg';
import DefaultJersery2 from '../../../img/jersey/default_2.jpg';
import { ReactComponent as Edit } from '../../../img/misc/edit.svg';

function Equipment({ isEditable, data, setEditEquipmentModal }) {
  return (
    <div
      className="w-full bg-white rounded-tr-lg rounded-tl-lg rounded-b-md m-0"
      style={{ height: '300px' }}
    >
      <div className="flex justify-between items-center px-4 rounded-t-md">
        <div className="h-9 flex items-center font-semibold text-brand-dark text-xl">
          Equipment
        </div>
        {isEditable && (
          <Edit
            onClick={() => setEditEquipmentModal(true)}
            className="w-[21px] h-[21px] cursor-pointer"
            style={{ color: '#ffffff' }}
          />
        )}
      </div>

      <div className="w-full flex items-center md:justify-between justify-around my-3 px-8">
        <div className=" flex items-baseline md:flex-row flex-col md:space-x-16 space-y-8">
          <div className="flex flex-col items-center    ">
            <p className="text-sm font-medium">Home</p>
            <img
              className="md:h-52 md:max-w-xs w-32 h-32 object-contain "
              src={
                (data?.data?.primaryJersey?.url !== null &&
                  profileImageUrl + data?.data?.primaryJersey?.url) ||
                DefaultJersery1
              }
              alt=""
            />
          </div>
          <div className="flex flex-col items-center ">
            <p className="text-sm font-medium">Away</p>
            <img
              className="md:h-52 md:max-w-xs w-32 h-32 object-contain"
              src={
                (data?.data?.secondaryJersey?.url !== null &&
                  profileImageUrl + data?.data?.secondaryJersey?.url) ||
                DefaultJersery2
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Equipment;
