import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Modal from '../../../../components/Modal/Modal';
import {
  createRoleRequest,
  searchAgent
} from '../../../../services/routes/User';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { fetchUser } from '../../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import AcceptButton from '../../../../components/Buttons/AcceptButton';
import InviteAgentModal from '../../../../components/InviteAgentModal/InviteAgentModal';

const AddCoachAgentModal = ({ onCloseClick }) => {
  //
  //
  const dispatch = useDispatch();
  const [agent, setAgent] = useState([]);
  const [inviteAgent, setInviteAgent] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    getSearchAgent();
  }, []);

  //updating agent to send the request
  const { mutate } = useMutation(createRoleRequest, {
    onSuccess: () => {
      dispatch(fetchUser());
    }
  });

  const getSearchAgent = async (inputValue = '', callback) => {
    try {
      const filteredAgent = await searchAgent(inputValue);
      const options = filteredAgent.data.map(agent => ({
        label: agent.contact.username,
        value: agent.id
      }));
      callback(options);
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  const handleSelect = selectedAgent => {
    setSelectedAgent(selectedAgent);
  };

  const sendRequest = () => {
    mutate({ toUser: selectedAgent.value });
    onCloseClick();
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title={agent.length > 0 ? 'Edit Agent' : 'Add Agent'}
    >
      {!inviteAgent ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            loadOptions={getSearchAgent}
            value={selectedAgent}
            onChange={handleSelect}
            placeholder="Search for Agent by username..."
            classNamePrefix="react-select"
            name="agentOptions"
          />

          {selectedAgent ? (
            <div className="w-full flex justify-center mt-4">
              <button type="submit" className="mt-4" onClick={sendRequest}>
                <AcceptButton title="Send Request" />
              </button>
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-xl flex-col justify-center mt-6 mb-2">
              Didn't Found the Agent?
              <button
                onClick={() => setInviteAgent(!inviteAgent)}
                className="mt-2"
              >
                <AcceptButton title="Invite Agent" />
              </button>
            </span>
          )}
        </div>
      ) : (
        <InviteAgentModal
          onCloseClick={onCloseClick}
          setInviteAgent={setInviteAgent}
          inviteAgent={inviteAgent}
        />
      )}
    </Modal>
  );
};

export default AddCoachAgentModal;
