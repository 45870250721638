import AxiosService from '../AxiosService';

export const getClubEquipment = async () => {
  const response = await AxiosService.get('/club/equipment');
  return response.data;
};

export const updateClubEquipment = async data => {
  const response = await AxiosService.put('/club/equipment', data);
  return response.data;
};
