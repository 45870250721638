import React, { useRef, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import profileImageUrl from '../../../config/config';
import { useForm } from 'react-hook-form';
import { ReactComponent as CameraSvg } from '../../../img/misc/camera.svg';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import { useMutation, useQueryClient } from 'react-query';
import { updateClubEquipment } from '../../../services/routes/club';
import { toast } from 'react-toastify';

function EditEquipmentModal({ onCloseClick, data, refetch }) {
  const queryClient = useQueryClient();

  const uploadInputEl = useRef(null);
  const uploadSecondaryInputEl = useRef(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadSecondaryImage, setUploadSecondaryImage] = useState(null);
  const [uploadedImageDataURL, setUploadedImageDataURL] = useState(null);
  const [uploadedSecondaryImageDataURL, setUploadedSecondaryImageDataURL] =
    useState('');

  const { mutate, isLoading } = useMutation(updateClubEquipment, {
    onSuccess: () => {
      refetch();
      queryClient.invalidateQueries('fetch-equipment');
      onCloseClick();
    },
    onError: error => {
      toast.error(error?.message);
    }
  });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      brand: data?.data?.brand,
      sponsors: data?.data?.sponsors
    }
  });
  const uploadBtnClick = () => {
    uploadInputEl.current.click();
  };

  const uploadSecondaryBtnClick = () => {
    uploadSecondaryInputEl.current.click();
  };

  const handleImageUpload = event => {
    const file = event.target.files[0];
    setUploadImage(file);
    const reader = new FileReader();
    reader.onload = e => {
      setUploadedImageDataURL(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSecondaryImgUpload = event => {
    const file = event.target.files[0];
    setUploadSecondaryImage(file);
    const reader = new FileReader();
    reader.onload = e => {
      setUploadedSecondaryImageDataURL(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmitForm = async formData => {
    const form = new FormData();
    form.append('brand', formData.brand);
    form.append('sponsors', formData.sponsors);

    // Append primary images
    if (uploadImage && Array.isArray(uploadImage)) {
      uploadImage.forEach(image => {
        form.append('primaryJersey', image);
      });
    } else if (uploadImage) {
      form.append('primaryJersey', uploadImage);
    }

    // Append secondary images
    if (uploadSecondaryImage && Array.isArray(uploadSecondaryImage)) {
      uploadSecondaryImage.forEach(image => {
        form.append('secondaryJersey', image);
      });
    } else if (uploadSecondaryImage) {
      form.append('secondaryJersey', uploadSecondaryImage);
    }

    mutate(form);
  };

  return (
    <Modal
      title="Edit Player Equipment"
      onCloseClick={onCloseClick}
      width="600px"
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="bg-white grid grid-cols-1 md:grid-cols-2 gap-6 py-6 rounded-b-lg">
          <div className=" flex itmes-center space-x-6 ml-5 pb-5">
            <div className="relative w-72 h-52">
              <img
                className="h-full w-full object-cover "
                src={
                  uploadImage === null
                    ? profileImageUrl + data?.data?.primaryJersey?.url
                    : uploadedImageDataURL
                }
                alt="Primary Jersey"
              />
              <p className="flex justify-center">Primary</p>
              <div className="absolute top-0 left-0 p-1.5 flex items-center justify-center">
                <input
                  type="file"
                  accept="image/*"
                  ref={uploadInputEl}
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <CameraSvg
                  className="p-1.5 cursor-pointer primary-svg bg-gray-700 rounded-full text-white"
                  onClick={uploadBtnClick}
                />
              </div>
            </div>
            <div className="relative w-72 h-52">
              <img
                className="w-full h-full object-cover "
                src={
                  uploadSecondaryImage === null
                    ? profileImageUrl + data?.data?.secondaryJersey?.url
                    : uploadedSecondaryImageDataURL
                }
                alt="Secondary Jersey"
              />
              <p className="flex justify-center">Secondary</p>
              <div className="absolute top-0 left-0 p-1.5 flex items-center justify-center">
                <input
                  type="file"
                  accept="image/*"
                  ref={uploadSecondaryInputEl}
                  onChange={handleSecondaryImgUpload}
                  className="hidden"
                />
                <CameraSvg
                  className="p-1.5 cursor-pointer primary-svg bg-gray-700 rounded-full text-white"
                  onClick={uploadSecondaryBtnClick}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-6">
            <div className="flex w-full  flex-col py-2 pb-2 pr-6">
              <label className="text-base font-normal text-black">Brand</label>
              <input
                type="text"
                name="brand"
                className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
                ref={register({
                  type: 'brand'
                })}
              />
            </div>
            <div className="flex w-full  flex-col py-2 pb-2 pr-6">
              <label className="text-base font-normal text-black">
                Sponsors
              </label>
              <input
                type="text"
                name="sponsors"
                className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
                ref={register({
                  type: 'sponsors'
                })}
              />
            </div>
          </div>
          <div className="w-full flex justify-center mt-5">
            <button type="submit" className="mb-2">
              <AcceptButton title={isLoading ? 'Updating...' : 'Update'} />
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default EditEquipmentModal;
