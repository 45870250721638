import { Fragment } from "react";
import Hero from "./Hero";
import About from "./About";
import WhatWeDo from "./WhatWeDo";
import Footer from "./Footer";
import "./Landing.css";

const Landing = () => {
  return (
    <Fragment>
      <Hero />
      {/*About */}
      <About />

      {/* WHAT DO WE DO*/}
      <WhatWeDo />
      <Footer />
    </Fragment>
  );
};

export default Landing;
