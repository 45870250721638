import { useEffect, useState } from 'react';
import DefaultAvatar from '../../../img/misc/Default_pfp.png';
import { ReactComponent as AddPersonSvg } from '../../../img/activity/addPerson.svg';
import { ReactComponent as CrossmarkSvg } from '../../../img/misc/crossed-mark.svg';
import { toast } from 'react-toastify';
import {
  checkFriendRequest,
  deleteFriendRequest,
  sendFriendRequest
} from '../../../services/routes/UserFriendRequest';
import profileImageUrl from '../../../config/config';

const SuggestedPerson = ({ profile }) => {
  //
  //

  const [isAdded, setIsAdded] = useState(false);
  const [requestId, setRequestId] = useState(null);

  useEffect(() => {
    try {
      checkFriendRequests();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const checkFriendRequests = async () => {
    await checkFriendRequest({ id: profile?.contact?.id }).then(res => {
      setRequestId(res?.data?.id);
      setIsAdded(true);
    });
  };

  const handleSendFriendRequest = async id => {
    try {
      await sendFriendRequest({ id }).then(async () => {
        await checkFriendRequests();
        toast.success('You have sent a friend request');
        setIsAdded(true);
      });
    } catch (error) {
      toast.error('The request has been sent already');
    }
  };

  const handleCancelRequest = async () => {
    try {
      // TODO: cancel request from server
      await deleteFriendRequest({ id: requestId }).then(async () => {
        await checkFriendRequests();

        toast.success('You have canceled the friend request');
        setIsAdded(false);
      });
    } catch (error) {
      setIsAdded(false);
    }
  };

  return (
    <div className="flex items-center justify-between border-brand-dark border-b mt-4">
      <div className="flex items-center gap-4">
        <img
          src={
            (profile?.contact?.profile_image &&
              profileImageUrl + profile?.contact?.profile_image?.url) ||
            DefaultAvatar
          }
          alt="profile"
          className="w-10 h-10 mb-3 rounded-full "
        />
        <h1
          style={{ marginTop: '-10px' }}
          className="text-sm text-black font-semibold"
        >
          {profile?.contact?.first_name + ' ' + profile?.contact?.last_name}
          <br />
          <p className="max-w-full text-sm font-light">
            {profile?.role?.charAt(0)?.toUpperCase() + profile?.role?.slice(1)}
          </p>
        </h1>
      </div>

      <div className=" w-10 h-10  flex justify-center items-center   ">
        {isAdded ? (
          <CrossmarkSvg
            className="cursor-pointer"
            onClick={() => handleCancelRequest(profile?.contact?.id)}
          />
        ) : (
          <AddPersonSvg
            className="cursor-pointer "
            onClick={() => handleSendFriendRequest(profile?.contact?.id)}
          />
        )}
      </div>
    </div>
  );
};

export default SuggestedPerson;
