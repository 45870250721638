/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import AsyncSelect from 'react-select/async';
import InvitePlayerModal from './InvitePlayerModal';
import { addManagedPlayer, searchPlayer } from '../../../services/routes/User';
import { useMutation, useQueryClient } from 'react-query';
import { fetchUser } from '../../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import { toast } from 'react-toastify';

const AddManagedPlayerModal = ({ onCloseClick, data }) => {
  //
  //

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const me = useSelector(state => state.auth.user);
  const [invitePlayer, setInvitePlayer] = useState(false);

  const alreadyManagedPlayers = data?.map(player => ({
    label: player?.contact?.first_name + ' ' + player?.contact?.last_name,
    value: player?.id
  }));

  const [managedPlayers, setManagedPlayers] = useState(
    alreadyManagedPlayers || []
  );

  useEffect(() => {
    getSearchPlayer();
  }, []);

  const { mutate, isLoading } = useMutation(addManagedPlayer, {
    onSuccess: () => {
      dispatch(fetchUser());
      queryClient.invalidateQueries('fetch_managedPlayers');
      toast.success('Roaster Created Successfully');
      onCloseClick();
    },
    onError: err => {
      toast.error('Error Occured While Creating Roaster');
      console.log(err.response.data.messsage);
    }
  });

  const getSearchPlayer = async (inputValue = '', callback) => {
    const filteredPlayers = await searchPlayer(inputValue);

    const options = filteredPlayers?.data
      ?.filter(user => user.id !== me.id)
      ?.map(player => ({
        label: player.contact.first_name + ' ' + player.contact.last_name,
        value: player.player.id
      }));

    callback(options);
  };

  const handleSelect = selectedPlayer => {
    setManagedPlayers(selectedPlayer);
  };

  const onClickCreateRoaster = () => {
    const players = managedPlayers?.map(player => player.value);
    mutate({ players });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Add Roaster">
      {!invitePlayer ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            isMulti
            isSearchable={true}
            value={managedPlayers}
            onChange={handleSelect}
            loadOptions={getSearchPlayer}
            classNamePrefix="react-select"
            name="playerOptions"
            placeholder="Search for Players..."
          />
          {managedPlayers?.length > 0 ? (
            <div className="w-full flex justify-center mt-4">
              {data?.length !== 0 ? (
                <AcceptButton
                  title={isLoading ? 'Updating ...' : 'Update Roaster'}
                  onClick={onClickCreateRoaster}
                />
              ) : (
                <AcceptButton
                  title={isLoading ? 'Creating ...' : 'Create Roaster'}
                  onClick={onClickCreateRoaster}
                />
              )}
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-base flex-col gap-1 justify-center mt-6 mb-2">
              Didn't Found any Player?
              <AcceptButton
                title={'Invite Player'}
                onClick={() => setInvitePlayer(!invitePlayer)}
              />
            </span>
          )}
        </div>
      ) : (
        <InvitePlayerModal
          onCloseClick={onCloseClick}
          setInvitePlayer={setInvitePlayer}
          invitePlayer={invitePlayer}
        />
      )}
    </Modal>
  );
};
export default AddManagedPlayerModal;
