import React from 'react';

const HeadingTitle = ({ title, Icon, onClick, ref, children }) => {
  return (
    <div
      ref={ref}
      className="relative md:w-full w-11/12 flex items-center justify-between "
    >
      <h3 className=" text-brand  font-medium text-xl ">{title}</h3>

      {Icon && (
        <Icon
          className="  text-gray-600 font-medium hover:text-black cursor-pointer w-5 h-5 "
          onClick={onClick}
        />
      )}

      {
        // show the children component
        children
      }
    </div>
  );
};

export default HeadingTitle;
