import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CameraSvg } from '../../../img/misc/camera-gray.svg';
import { ReactComponent as FacebookSvg } from '../../../img/social/facebook.svg';
import { ReactComponent as InstagramSvg } from '../../../img/social/instagram.svg';
import { ReactComponent as LinkedinSvg } from '../../../img/social/linkedin.svg';
import { ReactComponent as TwitterSvg } from '../../../img/social/twitter.svg';
import EditProfileModal from './EditProfileModal';
import classNames from 'classnames';
import ProfileCard from '../../../components/ProfileCard/ProfileCard';
import './MainProfile.css';

const userRole = role => {
  return (
    <span className="bg-brand-dark text-white px-12 py-2 font-roboto font-light rounded-sm">
      {role.charAt(0).toUpperCase() + role.slice(1)}
    </span>
  );
};

const MainProfile = () => {
  //
  //

  const ProfileStatus = ({ title, SocialSvg }) => {
    return (
      <a
        target="_blank"
        href={`${user.user?.contact?.socials?.$title}`}
        rel="noreferrer"
        className={`cursor-pointer ${
          title === 'youtube' && 'bg-white rounded-full'
        }`}
      >
        <SocialSvg className="w-6 h-6 primary-svg dark-grey-svg" />
      </a>
    );
  };

  const user = useSelector(state => state.user);

  const [editProfileModalVisible, setEditProfileModalVisible] = useState();

  const [editCoverImage, setEditCoverImage] = useState(false);

  return (
    <div className="MainProfile-wrapper ">
      <div
        className={classNames({
          MainProfile: true
        })}
      >
        <div className="absolute mt-4 ml-3">{userRole(user?.user?.role)}</div>

        {editProfileModalVisible && (
          <EditProfileModal
            title={'Update Profile Picture'}
            onCloseClick={() => setEditProfileModalVisible(false)}
          />
        )}

        {editCoverImage && (
          <EditProfileModal
            title={'Update Cover Picture'}
            onCloseClick={() => setEditCoverImage(false)}
          />
        )}

        <div className="relative ">
          <ProfileCard
            setEditProfileModalVisible={setEditProfileModalVisible}
            role={user?.user.role}
          />
        </div>
        <div className="h-36 w-full MainProfile-background-area MainProfile-background-pic ">
          <div className="flex justify-end">
            <CameraSvg
              onClick={() => setEditCoverImage(true)}
              className={classNames({
                'flex justify-end cursor-pointer primary-svg m-2 w-6 h-6': true
              })}
            />
          </div>
        </div>
        <div className="flex ">
          <div className="h-10 w-full flex justify-between items-center rounded-b-lg bg-brand-dark  px-2 ">
            <div className="w-full min-h-full flex items-center text-xl text-white font-medium gap-3 pl-4 whitespace-pre">
              {user?.user?.contact?.first_name +
                ' ' +
                user?.user?.contact?.last_name}
              <span className="font-light text-base">
                {user?.user?.contact?.country.charAt(0).toUpperCase() +
                  user?.user?.contact?.country.slice(1) +
                  ', ' +
                  user?.user?.contact?.city}
              </span>
            </div>

            {/* Show when user has linked thier other social account on his/her profile */}
            {user?.user?.contact?.socials && (
              <div className="w-full flex justify-end text-white">
                {user?.user?.contact?.socials?.instagram !== '' && (
                  <ProfileStatus title="instagram" SocialSvg={InstagramSvg} />
                )}

                {user?.user?.contact?.socials?.facebook !== '' && (
                  <ProfileStatus title="facebook" SocialSvg={FacebookSvg} />
                )}

                {user?.user?.contact?.socials?.linkedin !== '' && (
                  <ProfileStatus title="linkedin" SocialSvg={LinkedinSvg} />
                )}

                {user?.user?.contact?.socials?.twitter !== '' && (
                  <ProfileStatus title="twitter" SocialSvg={TwitterSvg} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainProfile;
