import React, { useState } from 'react';
import { ReactComponent as SearchSvg } from '../../img/misc/search.svg';
import { searchUsername } from '../../services/routes/User';
import { useDispatch, useSelector } from 'react-redux';
import { accessChatToUser } from '../../services/routes/chats';
import { useQueryClient } from 'react-query';
import { setUserChat } from '../../redux/userChats/UserChats';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import profileImageUrl from '../../config/config';
import './userMessage.scss';

const Search = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');

  const me = useSelector(state => state.auth.user);
  const chatUsers = useSelector(state => state.userChats.previousChats);

  const participateFriends = chatUsers?.map(participant => ({
    ...participant, // Keep other participant properties as they are
    participants: participant.participants?.filter(
      friend => friend.id !== me?.id
    )
  }));

  const [filteredUsers, setFilteredUsers] = useState([]);

  const onTextchange = async e => {
    let fullName = e?.target?.value;

    setSearchValue(fullName);

    if (fullName !== '') {
      const filteredData = participateFriends?.map(participant => ({
        ...participant,
        participants: participant?.participants?.filter(user =>
          user.contact?.first_name
            ?.toLowerCase()
            .includes(fullName.toLowerCase())
        )
      }));

      setFilteredUsers(filteredData);
    } else {
      setFilteredUsers([]);
    }
  };

  const handleKey = e => {
    // Handle Search with Enter Button
    e.code === 'Enter' && onTextchange();
  };

  const handleSelectUser = async friendId => {
    try {
      await accessChatToUser(friendId).then(data =>
        dispatch(setUserChat(data?.data))
      );
      setFilteredUsers([]);
      queryClient.invalidateQueries('fetch_allChats');
      setSearchValue('');
    } catch (error) {}
  };

  return (
    <>
      <div className="search bg-gray-100 flex items-center mt-1 gap-2 px-2 h-10 w-full  rounded-lg">
        <SearchSvg className="search-svg" />
        <input
          type="text"
          className="w-full bg-transparent text-sm"
          placeholder="Search by Players"
          onChange={e => onTextchange(e)}
          onKeyDown={handleKey}
          value={searchValue}
        />
      </div>
      {filteredUsers?.map((participant, index) => (
        <div
          className="absolute flex flex-col overflow-y-scroll mt-1 shadow-lg max-h-full bg-normalGray-light z-100"
          style={{ width: '23rem' }}
          key={index}
        >
          {participant?.participants?.map((user, userIndex) => (
            <div
              className="max-w-lg "
              key={userIndex}
              onClick={() => handleSelectUser(user?.id)}
            >
              <div className="flex items-center gap-3 p-4  cursor-pointer hover:bg-white transition-colors duration-200 ease-in-out">
                <img
                  src={
                    profileImageUrl + user?.contact?.profile_image?.url ||
                    DefaultProfilePng
                  }
                  className="w-14 h-14 object-cover rounded-full"
                  alt=""
                />
                <h2 className="text-base font-medium">
                  {user?.contact?.first_name + ' ' + user?.contact?.last_name}
                </h2>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default Search;
