import moment from 'moment';
import { ReactComponent as EditSvg } from '../../../img/misc/edit.svg';
import InfoSetting from '../../../components/InfoSetting/InfoSetting';

function ClubInfo({ isEditable, setEditInfoModalOpen, club }) {
  return (
    <div
      className="w-full  rounded-tr-lg rounded-tl-lg rounded-b-md"
      style={{ height: '300px' }}
    >
      <div className="flex justify-between items-end h-11  rounded-t-lg  font-roboto">
        <p className="flex items-end flex-col w-full">
          {isEditable !== false && (
            <EditSvg
              className="w-5 h-6 cursor-pointer"
              onClick={() => setEditInfoModalOpen(true)}
            />
          )}
        </p>
      </div>

      <div className="flex justify-around divide-x-2 divide-brandGray w-full">
        <div className="flex flex-col gap-y-11 mr-12">
          <InfoSetting
            title="Country"
            value={club?.country ? club?.country : 'None'}
          />
          <InfoSetting title="City" value={club?.city ? club?.city : 'None'} />
        </div>
        <div className="flex flex-col gap-y-11  px-8">
          <InfoSetting
            title="Year of foundation"
            value={
              club?.foundation_year
                ? moment(club?.foundation_year).format('YYYY-MM-DD')
                : 'None'
            }
          />
          <InfoSetting
            title="Official Website"
            value={club?.website ? club?.website : 'None'}
          />
        </div>
        <div className="flex flex-col gap-y-11  px-8">
          <InfoSetting
            title="President"
            value={club?.presidentName ? club?.presidentName : 'None'}
          />
          <InfoSetting
            title="Ranking"
            value={club?.rankings ? club?.rankings : 'None'}
          />
        </div>
      </div>
    </div>
  );
}

export default ClubInfo;
