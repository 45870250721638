import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AcceptButton from '../../../components/Buttons/AcceptButton';

const Form2 = ({ moveProgressForward }) => {
  const { register, handleSubmit, getValues, reset } = useForm({});

  const onSubmit = () => {
    localStorage.setItem('registerData', JSON.stringify(getValues()));
    console.log(getValues());
    moveProgressForward();
  };

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('registerData'));
    reset(saved);
  }, []);

  const userTypes = [
    {
      label: 'An Agent/ Scout',
      value: 'agent'
    },
    {
      label: 'A Player/ Parent',
      value: 'player'
    },
    {
      label: 'A Coach/ Mentor',
      value: 'coach'
    },
    {
      label: 'A Team/ Club',
      value: 'club'
    }
  ];

  return (
    <>
      <form className="mb-4 p-2" onSubmit={e => e.preventDefault()}>
        <p className="yellow-text text-2xl md:text-lg font-roboto font-medium">
          Are you..
        </p>

        {/* Radio button */}
        <div className="mt-5">
          {userTypes.map((type, index) => (
            <div className="mt-2" key={index}>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio h-6 w-6 bg-normalGray-light border-brand-light yellow-text"
                  name="role"
                  value={type.value}
                  ref={register({
                    required: true
                  })}
                />
                <span className="ml-4 font-roboto text-sm font-light">
                  {type.label}
                </span>
              </label>
            </div>
          ))}
        </div>

        {/* Continue Button */}
        <div className="block w-full mt-10 text-center">
          <button
            className="block w-2/6  m-auto "
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <AcceptButton title="Continue" />
          </button>
        </div>
      </form>
    </>
  );
};

export default Form2;
