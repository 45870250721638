const Tag = ({ tags }) => {
  return (
    <div className="mr-4 flex overflow-y-scroll scrollbar-hide ">
      {tags?.map((t, index) => (
        <div
          key={index}
          className="bg-green-600 rounded-md text-white whitespace-pre py-2 px-3 ml-1"
        >
          {t?.name}
        </div>
      ))}
    </div>
  );
};

export default Tag;
