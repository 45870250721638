import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OptionModal from './OptionModal';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ReactComponent as Location } from '../../../img/misc/location.svg';
import { useMutation, useQueryClient } from 'react-query';
import {
  eventMarkGoing,
  eventMarkMaybe,
  eventMarkNo
} from '../../../services/routes/Schedule/Events/player';
import { setEventDetail } from '../../../redux/schedule/gameDetail';
import Popup from 'reactjs-popup';

const BreakLine = () => {
  return (
    <div className="w-full px-5 my-2">
      <hr className="w-full  text-gray-500" />
    </div>
  );
};

const PlayerOnEvents = ({ title, number, className, updateMark, id }) => {
  const onMarkCLick = () => {
    updateMark(id);
  };

  return (
    <div
      onClick={onMarkCLick}
      className={`p-1 pl-6 text-sm pr-6 my-3 ${
        className && className
      } border-black border-opacity-25 box-border cursor-pointer`}
    >
      {title}({number || 0})
    </div>
  );
};

const EventLayoutDetail = ({
  game,
  refetchEvent,
  onClickDeleteEvent,
  onClickEditEvent
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useSelector(state => state.auth.user);
  const [markCount, setMarkCount] = useState(0);
  const [expandEventDetail, setExpandEventDetail] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);
  const [showEventOption, setShowEventOption] = useState(false);

  useEffect(() => {
    if (user?.role !== 'player') {
      setShowEditOption(true);
    }
  }, [user?.role]);

  const showOptions = type => {
    if (type === 'game') {
      return null;
    } else {
      return setShowEventOption(!showEventOption);
    }
  };

  //fetching the mark as apis update for event
  const { mutate: markGoingEvent } = useMutation(eventMarkGoing, {
    onSuccess: () => {
      refetchEvent();
      queryClient.invalidateQueries('fetch_allEvents');
      setMarkCount(markCount + 1);
    }
  });

  const { mutate: markMaybeEvent } = useMutation(eventMarkMaybe, {
    onSuccess: () => {
      refetchEvent();
      queryClient.invalidateQueries('fetch_allEvents');
      setMarkCount(markCount + 1);
    }
  });

  const { mutate: markNoEvent } = useMutation(eventMarkNo, {
    onSuccess: () => {
      refetchEvent();
      queryClient.invalidateQueries('fetch_allEvents');
      setMarkCount(markCount + 1);
    }
  });

  return (
    <div className="relative bg-white max-h-full rounded-lg shadow-md mb-2">
      <div className="relative w-full flex justify-between items-center py-2 px-3">
        <span
          style={{ color: '#5A6B34', borderColor: '#5A6B34' }}
          className=" rounded-md py-1 border text-sm font-roboto px-4 font-medium   "
        >
          Next Event
        </span>
        {showEditOption && (
          <Popup
            trigger={
              <MoreHorizIcon
                className=" text-gray-500 hover:text-black cursor-pointer"
                onClick={() => showOptions('event')}
              />
            }
            closeOnDocumentClick
            keepTooltipInside={false}
            position={['bottom center']}
          >
            <OptionModal
              onDeleteOptionClick={() => {
                onClickDeleteEvent(game?.id);
              }}
              onEditOptionClick={() => {
                dispatch(setEventDetail([game]));
                onClickEditEvent();
              }}
            />
          </Popup>
        )}
      </div>

      <div className="pl-4">
        <p className="next-game">
          {moment(game?.scheduledDate).format('dddd, MMMM Do [at] h:mm a')}
        </p>
        <p className="text-gray-600 text-sm">{`(arrive 20 minutes early)`}</p>
        <div className=" flex items-center gap-3 my-2">
          <div className="flex items-baseline gap-2">
            <Location className="w-5 h-5" />
            <p className="text-xs text-gray-600">
              {game?.location + ', ' + game?.locationDetails}
            </p>
          </div>
        </div>
      </div>

      {!expandEventDetail && (
        <div
          className="w-full rounded-b-lg flex justify-center cursor-pointer"
          style={{ backgroundColor: '#5A6B34' }}
          onClick={() => setExpandEventDetail(true)}
        >
          <ExpandMoreIcon className="text-white" />
        </div>
      )}

      {/* Show this when on expand */}
      {expandEventDetail && (
        <>
          <div className="flex justify-center">
            <PlayerOnEvents
              title={'Going'}
              number={game?.scheduleAvailability?.going?.length + markCount}
              className="border-r-0 border-2 rounded-l-xl hover:bg-green-400 "
              updateMark={markGoingEvent}
              id={game?.id}
            />
            <PlayerOnEvents
              title={'Maybe'}
              number={game?.scheduleAvailability?.maybe?.length + markCount}
              className="border-2 border-r-0  hover:bg-brand-light "
              updateMark={markMaybeEvent}
              id={game?.id}
            />
            <PlayerOnEvents
              title={'No'}
              number={game?.scheduleAvailability?.no?.length + markCount}
              className="border-2 rounded-r-xl hover:bg-red-500 "
              updateMark={markNoEvent}
              id={game?.id}
            />
          </div>
          <BreakLine />
          <div className="flex flex-col px-4">
            <label
              htmlFor=""
              className="text-sm font-roboto text-gray-600 font-medium"
            >
              Optional Game Info
            </label>
            <span className="py-3 px-2 text-sm text-gray-800">None</span>
          </div>
          <BreakLine />
          <div className="flex flex-col px-4">
            <label
              htmlFor=""
              className="text-sm font-roboto text-gray-600 font-medium"
            >
              Assignments
            </label>

            {game?.assignments?.map(assignment => (
              <div key={assignment?.id} className="flex items-center gap-16">
                <span className="py-3 px-2 text-sm text-gray-800">
                  {assignment?.name}
                </span>
                <p className="text-sm text-black font-normal">
                  {assignment?.player?.User?.contact?.first_name +
                    ' ' +
                    assignment?.player?.User?.contact?.last_name}
                </p>
              </div>
            ))}
          </div>
          <BreakLine />
          <div className="flex flex-col px-4">
            <label
              htmlFor=""
              className="text-sm font-roboto text-gray-600 font-medium"
            >
              Team Members
            </label>
            <div className="grid grid-cols-3">
              {game?.players?.length !== 0 ? (
                game?.players?.map(player => (
                  <span
                    key={player?.id}
                    className="py-3 px-2 text-sm text-gray-600"
                  >
                    {player?.User?.contact?.first_name +
                      ' ' +
                      player?.User?.contact?.last_name}
                  </span>
                ))
              ) : (
                <span className="py-3 whitespace-pre px-2 text-sm text-gray-600">
                  No players are assigned to this event
                </span>
              )}
            </div>
          </div>

          <div
            className="w-full rounded-b-lg flex justify-center cursor-pointer"
            style={{ backgroundColor: '#5A6B34' }}
            onClick={() => setExpandEventDetail(false)}
          >
            <ExpandLessIcon className="text-white" />
          </div>
        </>
      )}
    </div>
  );
};

export default EventLayoutDetail;
