import React from 'react';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';
import { deleteAwards } from '../../services/routes/Awards';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { fetchUser } from '../../redux/userSlice';

const DeleteAwardModal = ({ onCloseClick, award }) => {
  //
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(deleteAwards, {
    onSuccess: () => {
      dispatch(fetchUser());
      toast.success('Award deleted successfully');
      onCloseClick();
    },
    onError: err => {
      toast.error('Error while deleting award');
    }
  });

  const handleDelete = () => {
    mutate({ id: Number(award) });
  };

  return (
    <Modal title="Delete Award" onCloseClick={onCloseClick}>
      <div className="w-full  py-5 px-5 bg-white">
        <div className="flex flex-wrap w-full">
          <p
            className="font-light font-roboto"
            style={{ color: '#58595B', fontSize: '16px' }}
          >
            Are you sure you want to delete this Award?
          </p>
          <div className="w-full justify-center mt-8 items-center flex gap-6">
            <button
              className="mx-1 my-2 bg-brand-dark rounded-md text-white text-base tracking-widest flex justify-center items-center "
              style={{ width: '152px', height: '36px' }}
              onClick={handleDelete}
            >
              {isLoading ? 'Confirming...' : 'Confirm'}
            </button>
            <button
              className="mx-1 my-2 bg-normalGray rounded-md text-base text-white flex items-center justify-center "
              style={{ width: '152px', height: '36px' }}
              onClick={() => onCloseClick()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAwardModal;
