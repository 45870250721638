import { useMutation, useQueryClient } from "react-query";
import { deleteMessage } from "../../../services/routes/chats";

function MessageFeatureModel({ onCloseClick, messageId, chatId }) {
  //
  //
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(deleteMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries("fetch_allChats");
      onCloseClick();
    }
  });

  return (
    <div
      className="absolute right-0 bottom-5 max-w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div
        className="py-2 w-full text-sm px-4  text-gray-700 justify-center flex items-center gap-3"
        role="none"
      >
        <button onClick={() => mutate({ id: messageId, chatId })}>
          {isLoading ? "Deleting..." : "Delete"}
        </button>
        {/* <button>Copy</button> */}
      </div>
    </div>
  );
}

export default MessageFeatureModel;
