import classNames from 'classnames';

const SettingsLeftNav = ({ selectedNav, setSelectedNav }) => {
  return (
    <div className="flex flex-col gap-3 items-start w-40">
      <div
        className={classNames({
          'nav-item hover:text-brand text-base cursor-pointer': true,
          'text-brand font-semibold': selectedNav === 'account-settings'
        })}
        onClick={() => setSelectedNav('account-settings')}
      >
        Account Settings
      </div>
      <div
        className={classNames({
          'nav-item hover:text-brand text-base cursor-pointer': true,
          'text-brand font-semibold': selectedNav === 'social-media'
        })}
        onClick={() => setSelectedNav('social-media')}
      >
        Social Media
      </div>
      <div
        className={classNames({
          'nav-item hover:text-brand text-base cursor-pointer': true,
          'text-brand font-semibold': selectedNav === 'privacy-settings'
        })}
        onClick={() => setSelectedNav('privacy-settings')}
      >
        Privacy settings
      </div>
      <div
        className={classNames({
          'nav-item hover:text-brand text-base cursor-pointer': true,
          'text-brand font-semibold': selectedNav === 'notifications'
        })}
        onClick={() => setSelectedNav('notifications')}
      >
        Notifications
      </div>
      <div
        className={classNames({
          'nav-item hover:text-brand text-base cursor-pointer': true,
          'text-brand': selectedNav === 'block-users'
        })}
        onClick={() => setSelectedNav('block-users')}
      >
        Block Users
      </div>
    </div>
  );
};

export default SettingsLeftNav;
