import React, { useState, useRef } from "react";
import Modal from "../../../Modal/Modal";
import DefaultGroupPfp from "../../../../img/misc/Default_pfp.png";
import AcceptButton from "../../../Buttons/AcceptButton";
import DeclineButton from "../../../Buttons/DeclineButton";
import AsyncSelect from "react-select/async";
import { ReactComponent as CameraSvg } from "../../../../img/misc/camera-gray.svg";
import { searchUsername } from "../../../../services/routes/User";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { addNewMemberToGroup } from "../../../../services/routes/chats";
import "./groupModal.scss";

const UpdateGroupModal = ({ onCloseClick }) => {
  const groupChatSlice = useSelector(state => state.userChats.groupChat);
  const queryClient = useQueryClient();
  const me = useSelector(state => state.auth.user);

  const alreadyParticipants = groupChatSlice?.participants?.map(
    participant => ({
      label: participant?.contact?.username,
      value: participant?.id
    })
  );

  const [selectedUsers, setSelectedUsers] = useState(alreadyParticipants);
  const [groupName, setGroupName] = useState(groupChatSlice?.chatName);
  const uploadInputEl = useRef(null);

  const { mutate, isLoading } = useMutation(addNewMemberToGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries("fetch_allChats");
      onCloseClick();
    },
    onError: err => {
      console.log("Error", err);
    }
  });

  const getSearchUsers = async (inputValue = "", callback) => {
    const filteredUsers = await searchUsername(inputValue);

    const options = filteredUsers.data
      .filter(user => user.id !== me.id)
      .map(user => ({
        label: user?.contact?.username,
        value: user?.id
      }));

    callback(options);
  };

  const handleSelectChange = selectedOptions => {
    setSelectedUsers(selectedOptions);
  };

  const handleSave = () => {
    if (selectedUsers.length > 1) {
      const participants = selectedUsers.map(option => option.value);

      mutate({ id: groupChatSlice?.id, data: participants });
    }
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Add New Members">
      <div className="w-full py-5 bg-white rounded-b-lg">
        <div className="flex items-center mx-6 gap-14 ">
          <div className="mb-6">
            <img
              src={DefaultGroupPfp}
              className="w-24 h-24 rounded-full object-cover absolute "
              alt="Uploaded"
            />

            <div className="relative left-16 top-5">
              {/* <input
                type="file"
                accept="image/*"
                ref={uploadInputEl}
                style={{ display: "hidden" }}
              /> */}
              <CameraSvg className="p-1.5 flex justify-end cursor-pointer primary-svg m-2 w-10 h-10 border bg-gray-700 rounded-full" />
            </div>
          </div>
          <div>
            <label className="ml-6 font-roboto font-normal">Group Name</label>
            <input
              className="w-full rounded-md ml-6 pl-4 m-4 border border-brandGray-dark"
              type="text"
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />
          </div>
        </div>

        <AsyncSelect
          isMulti
          isSearchable={true}
          onChange={handleSelectChange}
          value={selectedUsers}
          loadOptions={getSearchUsers}
          name="usersOptions"
          className="basic-multi-select pt-3 m-4 mx-6 rounded-md"
          classNamePrefix="select"
        />
        {/* <div
          className="mr-2 ml-3 mt-5 overflow-y-scroll"
          style={{ height: "255px" }}
        >
          {userList.map(user => (
            <div
              key={user.id}
              className={`flex items-center gap-3 ml-3 mx-8 pb-5 pt-5 break-all border-b border-gray-300 ${
                selectedUsers.includes(user.username) ? "disabled" : ""
              }`}
              onClick={() => {
                if (!selectedUsers.includes(user.username)) {
                  setSelectedUsers([...selectedUsers, user.username]);
                }
              }}
            >
              <img
                className="w-16 h-16 rounded-full object-cover"
                src={user.profile_pic}
                alt="Profile_image"
              />
              <div className="gap-5">
                <p className="text-black text-lg">{user.username}</p>
                <p className="text-base font-light text-gray-600">
                  {user.club}
                </p>
              </div>
            </div>
          ))}
        </div> */}

        <div className="flex items-center justify-center mt-5 gap-8">
          <DeclineButton onClick={() => onCloseClick()} title="Cancel" />
          <AcceptButton
            onClick={handleSave}
            title={isLoading ? "Adding New Member..." : "Add New Member"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateGroupModal;
