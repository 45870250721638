import { useState } from 'react';
import AddClubSeasonHistory from './AddClubSeasonHistory/AddClubSeasonHistory';
import ManagedPlayer from './ManagedPlayer/index';
import ProfileAwards from '../../pages/Player/PlayerProfile/PlayerAwards/PlayerAwards';
import AddAwardsModal from '../../components/AddAwardsModal/AddAwardsModal';
import EditAwardModal from '../../components/EditAwardModal/EditAwardModal';
import DeleteAwardModal from '../../components/DeleteAwardModal/DeleteAwardModal';
import AddClubAgentModal from './AddClubAgentModal/AddClubAgentModal';
import AddClubCoachModal from './AddClubCoachModal/AddClubCoachModal';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import SeasonHistory from '../../components/SeasonHistory/SeasonHistory';
import { getAllSeasonHistories } from '../../services/routes/ClubHistories/seasonHistories';
import EditClubSeasonHistory from './EditClubSeasonHistory/EditClubSeasonHistory';
import DeleteClubSeasonHistory from './DeleteClubSeasonHistory/DeleteClubSeasonHistory';
import ClubInfo from './ClubInfo/ClubInfo';
import Equipment from './Equipment/Equipment';
import { getClubEquipment } from '../../services/routes/club';
import EditInfoModal from './EditInfoModal/EditInfoModal';
import EditEquipmentModal from './Equipment/EditEquipmentModal';
import {
  getAllManagedPlayers,
  getMyAllPlayerList
} from '../../services/routes/User';
import AddMyList from '../../components/MyList/AddMyList';
import EditMyList from '../../components/MyList/EditMyList';
import DeleteMyList from '../../components/MyList/DeleteMyList';
import AddManagedPlayerModal from './AddClubPlayerModal/AddClubPlayerModal';
import Sidebar from '../../components/Profile/Sidebar';
import ProfileBio from '../../components/Profile/ProfileBio';
import TabSwitcher from '../../components/Profile/TabSwitcher';

const ClubProfile = () => {
  const user = useSelector(state => state.user.user);

  // getting managed players/roaster
  const { data: managedPlayersData } = useQuery(
    'fetch_managedPlayers',
    getAllManagedPlayers
  );

  //fetching all season history
  const { data: allSeasonHistoryData } = useQuery(
    'fetch_allSeasonHistory',
    getAllSeasonHistories
  );

  //fetching equipments
  const { data: equipmentData, refetch: refetchEquipment } = useQuery(
    'fetch-equipment',
    getClubEquipment
  );

  const { data: myPlayerList } = useQuery(
    'fetch_myPlayerList',
    getMyAllPlayerList
  );

  const [addCoachModalOpen, setAddCoachModalOpen] = useState(false);
  const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);
  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState(false);
  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false);
  const [editEquipmentModal, setEditEquipmentModal] = useState(false);

  // Award State and function
  const [showAddAwardModal, setShowAddAwardModal] = useState(false);
  const [showEditAwardModal, setShowEditAwardModal] = useState(false);
  const [showDeleteAwardModal, setShowDeleteAwardModal] = useState(false);
  const [currentClubData, setCurrentClubData] = useState({});

  const editAwards = data => {
    setShowEditAwardModal(true);
    setCurrentClubData(data);
  };

  const deleteAwards = data => {
    setShowDeleteAwardModal(true);
    setCurrentClubData(data);
  };

  // Season History State and function
  const [showAddSeasonHistoryModal, setShowSeasonHistoryModal] =
    useState(false);
  const [showEditSeasonHistoryModal, setShowEditSeasonHistoryModal] =
    useState(false);
  const [currentSeasonHistory, setCurrentSeasonHistory] = useState({});
  const [deleteSeasonHistoryById, setDeleteSeasonHistoryById] = useState(null);
  const [showDeleteSeasonHistoryModal, setShowDeleteSeasonHistoryModal] =
    useState(false);

  const onEditPlayerClubHistoryClick = data => {
    setCurrentSeasonHistory(data);
    setShowEditSeasonHistoryModal(true);
  };

  const onDeletePlayerClubHistoryClick = id => {
    setDeleteSeasonHistoryById(id);
    setShowDeleteSeasonHistoryModal(true);
  };

  // My List and function
  const [showAddMyListModal, setShowAddMyListModal] = useState(false);
  const [editMyListModal, setEditMyListModal] = useState(false);
  const [currentMyList, setCurrentMyList] = useState({});
  const [showMyListDeleteModal, setShowMyListDeleteModal] = useState(false);
  const [deleteMyListById, setDeleteMyListById] = useState({});
  const [activeTab, setActiveTab] = useState('information');

  const onEditMyListClick = data => {
    setEditMyListModal(true);
    setCurrentMyList(data);
  };

  const onDeleteMyPlayerListClick = id => {
    setDeleteMyListById(id);
    setShowMyListDeleteModal(true);
  };

  // Managed Players states and functions
  const [currentManagedPlayer, setCurrentManagedPlayer] = useState([]);

  const onEditManagedPlayerClick = data => {
    setAddPlayerModalOpen(true);
    setCurrentManagedPlayer(data);
  };

  return (
    <>
      {showAddAwardModal && (
        <AddAwardsModal onCloseClick={() => setShowAddAwardModal(false)} />
      )}

      {/* All Edit Modals */}
      {showEditAwardModal && (
        <EditAwardModal
          onCloseClick={() => setShowEditAwardModal(false)}
          award={currentClubData}
        />
      )}
      {showEditSeasonHistoryModal && (
        <EditClubSeasonHistory
          onCloseClick={() => setShowEditSeasonHistoryModal(false)}
          setEdit={currentSeasonHistory}
        />
      )}
      {editEquipmentModal && (
        <EditEquipmentModal
          onCloseClick={() => setEditEquipmentModal(false)}
          data={equipmentData}
          refetch={refetchEquipment}
        />
      )}
      {editInfoModalOpen && (
        <EditInfoModal onCloseClick={() => setEditInfoModalOpen(false)} />
      )}
      {editMyListModal && (
        <EditMyList
          setData={currentMyList}
          onCloseClick={() => setEditMyListModal(false)}
        />
      )}

      {/* All Delete Modals */}
      {showDeleteAwardModal && (
        <DeleteAwardModal
          onCloseClick={() => setShowDeleteAwardModal(false)}
          award={currentClubData}
        />
      )}
      {showDeleteSeasonHistoryModal && (
        <DeleteClubSeasonHistory
          onCloseClick={() => setShowDeleteSeasonHistoryModal(false)}
          id={deleteSeasonHistoryById}
        />
      )}
      {showMyListDeleteModal && (
        <DeleteMyList
          id={deleteMyListById}
          onCloseClick={() => setShowMyListDeleteModal(false)}
        />
      )}

      {/* All Add Modals */}
      {addPlayerModalOpen && (
        <AddManagedPlayerModal
          data={currentManagedPlayer}
          onCloseClick={() => setAddPlayerModalOpen(false)}
        />
      )}
      {addAgentModalOpen && (
        <AddClubAgentModal onCloseClick={() => setAddAgentModalOpen(false)} />
      )}
      {addCoachModalOpen && (
        <AddClubCoachModal onCloseClick={() => setAddCoachModalOpen(false)} />
      )}
      {showAddSeasonHistoryModal && (
        <AddClubSeasonHistory
          onCloseClick={() => setShowSeasonHistoryModal(false)}
        />
      )}
      {showAddMyListModal && (
        <AddMyList onCloseClick={() => setShowAddMyListModal(false)} />
      )}

      <div className="flex w-full mt-8 gap-8">
        <div className="w-96">
          <Sidebar>
            <ProfileBio />
            {/* line */}
            <div className="border-b-2 border-brand-dark mx-8 my-6"></div>
            <Equipment
              isEditable={true}
              data={equipmentData}
              setEditEquipmentModal={setEditEquipmentModal}
            />
          </Sidebar>
        </div>

        <div className="flex flex-col flex-1">
          <TabSwitcher
            tabs={[
              {
                title: 'Information',
                name: 'information',
                component: <div>Information</div>
              },
              {
                title: 'Rooster',
                name: 'rooster',
                component: <div>Agent</div>
              },
              {
                title: 'Awards',
                name: 'awards',
                component: <div>Coach</div>
              },
              {
                title: 'Season History',
                name: 'season-history',
                component: <div>Award</div>
              }
            ]}
            activeTab={activeTab}
            setActiveTab={name => {
              setActiveTab(name);
            }}
          />

          {activeTab === 'information' && (
            <ClubInfo
              isEditable={true}
              club={user?.club}
              setEditInfoModalOpen={setEditInfoModalOpen}
            />
          )}

          {
            // Managed Players
            activeTab === 'rooster' && (
              <div>
                <ManagedPlayer
                  addModalOpen={setAddPlayerModalOpen}
                  data={managedPlayersData?.data}
                  onEditManagedPlayerClick={onEditManagedPlayerClick}
                  isClub={true}
                  title="Roster"
                  type="players"
                  isEditable={true}
                />
              </div>
            )
          }
          {activeTab === 'awards' && (
            <div>
              <ProfileAwards
                awards={user?.awards}
                onEditPlayerAwardClick={editAwards}
                setAddAwardModal={setShowAddAwardModal}
                deleteAwards={deleteAwards}
                isEditable={true}
              />
            </div>
          )}

          {activeTab === 'season-history' && (
            <div>
              <SeasonHistory
                histories={allSeasonHistoryData?.data}
                isEditable={true}
                addHistory={() => setShowSeasonHistoryModal(true)}
                editHistory={onEditPlayerClubHistoryClick}
                deleteHistory={onDeletePlayerClubHistoryClick}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClubProfile;
