import Modal from "../../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import AcceptButton from "../../../Buttons/AcceptButton";
import DeclineButton from "../../../Buttons/DeclineButton";
import { useMutation, useQueryClient } from "react-query";
import { deleteGroupChat } from "../../../../services/routes/chats";
import { setGroupChats } from "../../../../redux/userChats/UserChats";

const DeleteGroupChat = ({ onCloseClick }) => {
  const queryClient = useQueryClient();
  const groupId = useSelector(state => state.userChats.groupChat?.id);
  const groupSlice = useSelector(state => state.userChats.groupChat);

  console.log("grp slice", groupSlice);

  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(deleteGroupChat, {
    onSuccess: () => {
      dispatch(setGroupChats(null));
      queryClient.invalidateQueries("fetch_allChats");
      onCloseClick();
    },
    onError: err => {
      console.log("group chat error", err);
    }
  });

  return (
    <Modal onCloseClick={onCloseClick} title="Delete Chat">
      <div className="w-full relative p-4 bg-white rounded-b-lg max-h-72 overflow-y-scroll">
        <p className=" text-center text-lg w-full text-gray-700 font-medium">
          Do you want to Delete this Group Chat?
        </p>
        <div className="w-full py-8 flex justify-center gap-4">
          <AcceptButton
            title={isLoading ? "Deleting..." : "Yes"}
            onClick={() => mutate({ id: groupId, data: { chatId: groupId } })}
          />
          <DeclineButton title="No" onClick={() => onCloseClick()} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGroupChat;
