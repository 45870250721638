import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { ReactComponent as CirclePlus } from '../../../img/misc/circle-plus.svg';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import TimezoneSelect from 'react-timezone-select';
import countryList from 'react-select-country-list';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import DeclineButton from '../../../components/Buttons/DeclineButton';
import {
  getMyAllPlayerList,
  searchPlayer
} from '../../../services/routes/User';
import AsyncSelect from 'react-select/async';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createEvent } from '../../../services/routes/Schedule/Events/eventOwner';
import { ReactComponent as ArrowupSvg } from '../../../img/misc/arrow-up.svg';
import { ReactComponent as ArrowdownSvg } from '../../../img/misc/arrow-down.svg';
import { getValue } from '@mui/system';
import moment from 'moment';
import './modal.scss';
import { toast } from 'react-toastify';

const BreakLine = () => {
  return (
    <div className="w-full px-5">
      <hr className="w-full  text-gray-500" />
    </div>
  );
};

const AddEventModal = ({ onCloseClick, customStyles, refetch }) => {
  const { register, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      event: '',
      eventType: '',
      start_date: '',
      hour: '',
      minute: '',
      meridiem: '',
      timezone: '',
      repeat: false,
      country: '',
      location_details: '',
      tags: '',
      comments: '',
      assignments: [
        {
          assignment_name: '',
          assignment_description: ''
        }
      ],
      notify_team: ''
    }
  });

  //time-zne selectiion and format
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [timezoneFormat, setTimezoneFormat] = useState();

  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [displayOptionalDetails, setDisplayOptionalDetails] = useState(true);
  const [displayAssignmentDetails, setDisplayAssignmentDetails] =
    useState(true);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  const [disablePlayerInput, setDisablePlayerInput] = useState(false);
  const [disableTeamInput, setDisableTeamInput] = useState(false);
  const [assignements, setAssignements] = useState([{}]);

  const queryClient = useQueryClient();

  // Check if player or team is selected
  useEffect(() => {
    if (selectedPlayer) {
      setDisableTeamInput(true);
    } else if (selectedTeam) {
      setDisablePlayerInput(true);
    }
  }, [selectedPlayer, selectedTeam]);

  //fetching data from api of team and player
  const { mutate: createTeam, isLoading } = useMutation(createEvent, {
    onSuccess: data => {
      refetch();
      queryClient.invalidateQueries('fetch_allEvents');
      toast.success('Event created successfully');
      onCloseClick();
    },
    onError: () => {
      toast.error('Something went wrong while creating the event');
    }
  });

  //setting the value for react-select country
  useEffect(() => {
    setValue('country', selectedLocation);
  }, [selectedLocation, setValue]);

  //setting the value for assignments
  useEffect(() => {
    setValue('assignments', assignements);
  }, [assignements, setValue]);

  //options for type of event
  const options = useMemo(() => countryList().getData(), []);

  const typeOptions = [
    {
      value: 'physio',
      label: <div className="select-label">Physio</div>
    },
    {
      value: 'game',
      label: <div className="select-label">Game</div>
    },
    {
      value: 'training',
      label: <div className="select-label">Training</div>
    },
    {
      value: 'meeting',
      label: <div className="select-label">Meeting</div>
    },
    {
      value: 'practice',
      label: <div className="select-label">Practice</div>
    },
    {
      value: 'other',
      label: <div className="select-label">Other</div>
    }
  ];

  const { data } = useQuery('allPlayerLists', getMyAllPlayerList);

  //fetching the search api and displaying the name of player
  const getSearchUsers = async (inputValue = '', callback) => {
    const filteredUsers = await searchPlayer(inputValue);

    const options = filteredUsers.data.map(user => ({
      label: user?.contact?.first_name + ' ' + user?.contact?.last_name,
      value: user?.player.id
    }));

    callback(options);
  };

  //submitting data post request
  const onSubmit = data => {
    const formattedHour =
      data?.hour?.length === 1 ? `0${data?.hour}` : data?.hour;

    const formattedMinute =
      data?.minute?.length === 1 ? `0${data?.minute}` : data?.minute;
    const teamData = {
      eventName: data.event,
      eventType: selectedOptions.map(option => option.value),
      scheduledDate: moment(
        `${data.start_date} ${data.hour}:${data.minute} ${data.meridiem}`,
        'YYYY-MM-DD hh:mm A'
      ).format('YYYY-MM-DD HH:mm:ss'),
      scheduledTime: `${formattedHour}:${formattedMinute} ${data.meridiem}`,
      arrivalTime: `${formattedHour}:${formattedMinute} ${data.meridiem}`,
      timezone: selectedTimezone?.label,
      // repeat: data.repeat,
      location: selectedLocation.value,
      locationDetails: data.location_details,
      tags: data?.tags?.split(',').map(item => item.trim()),
      additionalComments: data.comments,
      notifyTeam: data.notify_team,

      assignments: assignements.map(each => ({
        name: each.assignment_name,
        description: each.assignment_description,
        playerId: each.playerId?.value
      })),

      teamId: selectedTeam?.value,
      trackAvailability: isChecked
    };
    createTeam(teamData);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Event Details" width="580px">
      <div
        className="w-full overflow-y-scroll bg-white"
        style={{ maxHeight: '80vh' }}
      >
        <form
          className="w-full  mb-4 md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full p-5 ">
            {/* Event Name */}
            <div className="">
              <label className="text-base font-light font-roboto">
                Name of Event
              </label>
              <input
                className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                name="event"
                id="event"
                ref={register({
                  type: 'event',
                  required: 'You must specify an event name'
                })}
              />
              {errors.event && <p>{errors.event.message}</p>}
            </div>

            {/* Event Type Select */}
            <div className="w-full my-3">
              <label className="text-base font-light font-roboto" htmlFor="">
                Type
              </label>
              <div className="mb-2 rounded-md border-gray-400 ">
                <Select
                  classNamePrefix="select-react"
                  isClearable={true}
                  defaultValue={[typeOptions[0]]}
                  options={typeOptions}
                  onChange={e => {
                    setSelectedOptions(
                      e.map(item => {
                        return {
                          label: item.value,
                          value: item.value
                        };
                      })
                    );
                    setValue('eventType', {
                      label: e.map(item => item.value),
                      value: e.map(item => item.value)
                    });
                  }}
                  isMulti
                  name="eventType"
                  styles={customStyles}
                />
                {errors.eventType && <p>{errors.eventType.message}</p>}
              </div>
            </div>

            <div className=" w-full flex items-center justify-between my-3 gap-6">
              {/* Team Select */}
              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">Team</label>
                <Select
                  isSearchable={true}
                  isClearable={true}
                  name="team"
                  onChange={team => {
                    setValue('team', team?.value);
                    setSelectedTeam(team);
                    setSelectedTeamPlayers(
                      data?.data
                        ?.filter(item => item?.id === team?.value)[0]
                        .players?.map(player => ({
                          label:
                            player?.User.contact?.first_name +
                            ' ' +
                            player?.User.contact?.last_name,
                          value: player?.User?.player?.id
                        }))
                    );
                  }}
                  value={getValue('team')}
                  options={data?.data?.map(team => ({
                    label: team?.name,
                    value: team?.id
                  }))}
                  styles={customStyles}
                  placeholder="Search for team..."
                  classNamePrefix="select"
                  isDisabled={disableTeamInput}
                />
              </div>

              <div className="font-normal mt-6 ">OR</div>

              {/* Players Select */}
              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">
                  Individual
                </label>
                <AsyncSelect
                  isMulti
                  isSearchable={true}
                  onChange={selected => {
                    setSelectedPlayer(selected);
                    setValue('usersOptions', selected);
                  }}
                  value={selectedPlayer}
                  loadOptions={getSearchUsers}
                  styles={customStyles}
                  placeholder="Search for players..."
                  name="usersOptions"
                  classNamePrefix="select"
                  isDisabled={disablePlayerInput}
                />
                {errors.usersOptions && <p>{errors.usersOptions.message}</p>}
              </div>
            </div>

            <div className="w-full  flex items-center justify-center gap-3 pt-2">
              {/* Start Date */}
              <div>
                <label className="text-base font-light font-roboto">
                  Start Date
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-2 text-black bg-white border border-gray-200 rounded-md "
                  type="date"
                  name="start_date"
                  ref={register({
                    required: 'You must specify a start date',
                    type: 'start_date'
                  })}
                />
              </div>
              {errors.start_date && <p>{errors.start_date.message}</p>}

              {/* Start Time */}
              <div className="">
                <label className=" text-base font-light font-roboto" htmlFor="">
                  Time
                </label>
                <div className="flex gap-2 ">
                  <input
                    className=" box  py-0.5 px-3 text-gray-500 bg-white border border-gray-200 rounded-md "
                    type="number"
                    placeholder="HH"
                    name="hour"
                    id="hour"
                    ref={register({
                      required: 'You must specify hour',
                      type: 'hour'
                    })}
                  />
                  {errors.hour && <p>{errors.hour.message}</p>}
                  <input
                    className="box  text-md py-0.5 px-4 text-gray-500 bg-white border border-gray-200 rounded-md "
                    type="number"
                    placeholder="MM"
                    name="minute"
                    id="minute"
                    ref={register({
                      required: 'You must specify minute',
                      type: 'miinute'
                    })}
                  />
                  {errors.minute && <p>{errors.minute.message}</p>}

                  <select
                    className="w-max font-roboto text-gray-700  bg-white border border-gray-200 rounded-md"
                    name="meridiem"
                    ref={register({
                      required: 'You must specify meridiem ',
                      type: 'meridiem'
                    })}
                  >
                    <option
                      className="text-base font-light font-roboto"
                      value="AM"
                    >
                      AM
                    </option>
                    <option
                      className="text-base font-light font-roboto"
                      value="PM"
                    >
                      PM
                    </option>
                  </select>
                </div>
              </div>

              {/* TimeZone */}
              <div className="w-60 font-light">
                <label className="text-transparent">Timezone</label>
                <TimezoneSelect
                  name="timezone"
                  className="rounded-md"
                  styles={customStyles}
                  value={selectedTimezone}
                  onChange={timeZone => {
                    setSelectedTimezone({
                      label: timeZone?.altName,
                      value: timeZone?.value
                    });
                    setValue('timezone', {
                      label: timeZone?.altName,
                      value: timeZone?.value
                    });
                  }}
                />
              </div>
            </div>

            {/* Repeating Event */}
            <div className="w-full my-4">
              <label className="text-base text-md font-light font-roboto">
                Repeat
              </label>
              <div className="text-base text-md font-light ">
                <select
                  className="form-select rounded-md p-1 h-12 font-roboto text-gray-700 py-0.5  bg-white border border-gray-200"
                  style={{ width: '200px' }}
                  name="repeat"
                  ref={register({
                    type: 'repeat'
                  })}
                  defaultValue={false}
                >
                  <option
                    className="text-base font-light font-roboto"
                    value={true}
                  >
                    Repeat
                  </option>
                  <option
                    className="text-base font-light font-roboto"
                    value={false}
                  >
                    Does not repeat
                  </option>
                </select>
              </div>
            </div>

            <div className=" w-full mb-3 flex gap-5 mt-4 ">
              {/* Country Location */}
              <div className="w-1/2 ">
                <label className=" text-base font-light " htmlFor="">
                  Location
                </label>

                <Select
                  name="country"
                  className=" rounded-md"
                  value={selectedLocation}
                  options={options}
                  onChange={country => {
                    setSelectedLocation(country);
                  }}
                  styles={customStyles}
                />
              </div>

              {/* Location Detail */}
              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">
                  Location Details
                </label>
                <input
                  className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                  name="location_details"
                  ref={register({
                    required: 'You must specify location-details',
                    type: 'location_details'
                  })}
                />
                {errors.location_details && (
                  <p>{errors.location_details.message}</p>
                )}
              </div>
            </div>
          </div>

          <BreakLine />

          {/* Tags and Notes */}
          <div className="w-full p-5 ">
            <div className="flex items-center justify-between">
              <div className="mb-3 text-gray-600  ">Optional Event Info</div>
            </div>

            <div>
              {/* Tags */}
              <label className="text-base font-light">Tags</label>
              <div className="flex gap-5 items-center">
                <input
                  className="w-1/2 text-left  text-md border rounded-md py-0.5 px-3 text-gray-700 bg-white"
                  name="tags"
                  ref={register({
                    required: 'You must specify tags ',
                    type: 'tags'
                  })}
                />
                {errors.tags && <p>{errors.tags.message}</p>}
                <div className="flex items-center justify-center gap-2 ">
                  <div className="p-1 rounded-md">
                    <Checkbox
                      className="rounded-md"
                      style={{ border: 'none' }}
                      name="trackAvailability"
                      defaultChecked={false}
                      // ref={register({
                      //   required: 'You must specify track-availibility ',
                      //   type: 'trackAvailability'
                      // })}
                      onChange={() => {
                        setIsChecked(!isChecked);
                        setValue('trackAvailability', !isChecked);
                      }}
                      checked={isChecked}
                    />
                  </div>
                  <p className="flex text-gray-500 font-light font-roboto">
                    Track Availibility
                  </p>
                </div>
              </div>

              {/* Comments/Notes */}
              <div className="mt-3 w-full">
                <label className="text-base font-light font-roboto" htmlFor="">
                  Additional Comments
                </label>
                <input
                  className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-gray-700 bg-white"
                  name="comments"
                  ref={register({
                    required: 'You must specify comments ',
                    type: 'comments'
                  })}
                />
                {errors.comments && <p>{errors.comments.message}</p>}
              </div>
            </div>
          </div>

          <BreakLine />

          <div className="w-full  p-5 ">
            <div className="flex items-center justify-between">
              <div className="mb-3 text-gray-600">Assignments</div>
            </div>
            <div>
              <div className=" mb-3">
                {assignements?.map((item, index) => (
                  <div key={item.id} className="flex gap-5 items-center mb-5">
                    <input
                      className="w-1/3 text-left text-md border rounded-md py-0.5 px-3 text-gray-700 bg-white"
                      placeholder="e.g bring something"
                      name="assignment_name"
                      value={assignements[index]?.assignment_name || ''}
                      onChange={e => {
                        const newAssignments = [...assignements];
                        newAssignments[index].assignment_name = e.target.value;
                        setAssignements(newAssignments);
                      }}
                    />

                    <input
                      type="text"
                      placeholder="description..."
                      className="w-1/3 rounded-md px-3 font-roboto text-gray-700  bg-white border border-gray-200"
                      name={`assignment_description`}
                      value={assignements[index]?.assignment_description || ''}
                      onChange={e => {
                        const newAssignments = [...assignements];
                        newAssignments[index].assignment_description =
                          e.target.value;
                        setAssignements(newAssignments);
                      }}
                    />

                    <Select
                      isSearchable={true}
                      name={`playerId[${index}].playerId`}
                      onChange={selected => {
                        const newAssignments = [...assignements];
                        newAssignments[index].playerId = selected;
                        setAssignements(newAssignments);
                      }}
                      value={assignements[index]?.playerId || ''}
                      options={selectedTeamPlayers}
                      classNamePrefix="select"
                      className="w-1/3"
                      styles={customStyles}
                    />
                  </div>
                ))}
              </div>

              {errors.assignments && <p>{errors.assignments.message}</p>}

              <div
                className="p-1.5 mt-5 flex items-center border yellow-border text-lg font-light font-roboto gap-3 text-brand-light"
                style={{ width: '120px' }}
                onClick={() =>
                  setAssignements([
                    ...assignements,
                    {
                      assignment_name: '',
                      assignment_description: ''
                    }
                  ])
                }
              >
                Add more &nbsp;
                <CirclePlus className="w-6 h-6 flex-initial ... primary-svg circle-svg" />
              </div>
            </div>
          </div>

          <BreakLine />

          {selectedTeamPlayers?.length !== 0 && (
            <>
              <div className="w-full p-5 ">
                <label
                  className="text-base font-normal text-gray-700 font-roboto"
                  htmlFor=""
                >
                  Team Members
                </label>
                <div className=" w-full grid grid-cols-3 gap-x-12 gap-y-6 my-2">
                  {selectedTeamPlayers?.map(player => (
                    <div
                      key={player.value}
                      className="flex items-center  gap-2 justify-center "
                    >
                      <Checkbox
                        className="rounded-md border-transparent"
                        style={{ border: 'transparent' }}
                        name={`selectPlayer${player.value}`}
                      />
                      <p className="text-gray-700 font-light font-roboto">
                        {player.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <BreakLine />
            </>
          )}
          <div className="w-full p-5 ">
            <label
              className="text-base font-normal text-gray-700 font-roboto"
              htmlFor=""
            >
              Notifications
            </label>
            <div className="flex items-center  gap-2 mt-3 ">
              <Checkbox
                className="rounded-md border-transparent"
                style={{ border: 'transparent' }}
                name="notifyTeams"
              />
              <p className="text-gray-500 font-light font-roboto">
                Notify your team
              </p>
            </div>
          </div>

          <BreakLine />

          <div className="p-5 w-full flex justify-center gap-4 ">
            <AcceptButton
              title={isLoading ? 'Creating...' : 'Create'}
              type="submit"
            />
            <DeclineButton title="Cancel" onClick={() => onCloseClick()} />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddEventModal;
