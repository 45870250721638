import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: {},
  notifications: {}
};

const VisitProfileReducer = createSlice({
  name: "userProfile",
  initialState,

  reducers: {
    setVisitProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setRequest: (state, action) => {
      state.notifications = action.payload;
    }
  }
});

export const { setVisitProfile, setRequest } = VisitProfileReducer.actions;

export default VisitProfileReducer.reducer;
