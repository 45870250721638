import React from 'react';

const TabSwitcher = ({ tabs, activeTab, setActiveTab, parent = true }) => {
  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-row justify-start ${
          parent && 'border-b-2 border-brand-light'
        } border-opacity-50`}
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`whitespace-nowrap flex flex-col items-center justify-center cursor-pointer p-2 px-4 text-lg ${
              activeTab === tab.name ? 'text-brand-dark' : 'text-gray-500'
            } ${
              activeTab === tab.name && activeTab && parent
                ? 'border-b-4 border-brand-dark -my-1'
                : ''
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            <span>{tab.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabSwitcher;
