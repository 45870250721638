import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState } from 'react';

function Carousel({ children: slides, postImage }) {
  const [curr, setCurr] = useState(0);

  const prev = () => {
    setCurr(curr => (curr === 0 ? slides.length - 1 : curr - 1));
  };

  const next = () => {
    setCurr(curr => (curr === slides.length - 1 ? 0 : curr + 1));
  };

  return (
    <div className="overflow-hidden  w-full relative">
      <div
        className="flex transition-transform max-w-full min-h-full duration-500 ease-out "
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides}
      </div>
      {postImage?.length > 1 && (
        <div className="absolute top-1/2 flex items-center justify-between w-full px-4">
          <ChevronLeftIcon
            onClick={prev}
            className="text-gray-800 cursor-pointer bg-gray-300 shadow hover:bg-white p-1 rounded-full"
            style={{ width: '24px', height: '24px' }}
          />
          <ChevronRightIcon
            onClick={next}
            className="text-gray-800 cursor-pointer bg-gray-300 shadow hover:bg-white p-1 rounded-full"
            style={{ width: '24px', height: '24px' }}
          />
        </div>
      )}

      {postImage?.length > 1 && (
        <div className="absolute bottom-4 left-0 right-0">
          <div className="flex items-center justify-center gap-2">
            {slides.map((_, i) => (
              <span
                key={i}
                className={`w-3 h-3 transition-all  bg-white rounded-full ${
                  curr === i ? 'p-2' : 'bg-opacity-50'
                }`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Carousel;
