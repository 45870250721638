import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import InviteClubModal from "../../../../components/InviteClubModal/InviteClubModal";
import Modal from "../../../../components/Modal/Modal";
import {
  createRoleRequest,
  searchClub
} from "../../../../services/routes/User";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { fetchUser } from "../../../../redux/userSlice";
import { useDispatch } from "react-redux";
import "./modal.scss";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import DeclineButton from "../../../../components/Buttons/DeclineButton";

function AddPlayerClubModal({ onCloseClick }) {
  //
  //
  const dispatch = useDispatch();
  const [club, setClub] = useState([]);
  const [inviteClub, setInviteClub] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);

  useEffect(() => {
    getSearchClub();
  });

  // Updating Club to send the Request
  const { mutate } = useMutation(createRoleRequest, {
    onSuccess: () => {
      dispatch(fetchUser());
    }
  });

  const getSearchClub = async (inputValue = "", callback) => {
    try {
      const filteredClub = await searchClub(inputValue);
      const options = filteredClub.data.map(club => ({
        label: club.contact.username,
        value: club.id
      }));
      callback(options);
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  const handleSelect = selectedClub => {
    setSelectedClub(selectedClub);
  };

  const sendRequest = () => {
    mutate({ toUser: selectedClub.value });
    onCloseClick();
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title={club.length > 0 ? "Edit Club" : "Add Club"}
    >
      {!inviteClub ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            loadOptions={getSearchClub}
            value={selectedClub}
            onChange={handleSelect}
            placeholder="Search for Club by username..."
            classNamePrefix="react-select"
            name="clubOptions"
          />
          {selectedClub ? (
            <div className="w-full flex justify-center mt-4">
              <button type="submit" className=" mt-4" onClick={sendRequest}>
                <AcceptButton title="Send Request" />
              </button>
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-xl flex-col justify-center mt-6 mb-2">
              Didn't Found the Club?
              <button
                onClick={() => setInviteClub(!inviteClub)}
                className=" mt-2"
              >
                <DeclineButton title="Invite Club" />
              </button>
            </span>
          )}
        </div>
      ) : (
        <InviteClubModal
          onCloseClick={onCloseClick}
          setInviteClub={setInviteClub}
          inviteClub={inviteClub}
        />
      )}
    </Modal>
  );
}

export default AddPlayerClubModal;
