import AxiosService from '../AxiosService';

export const updatePlayerInfo = async data => {
  const response = await AxiosService.put('/player/info', data);
  return response.data;
};

export const updatePlayerPosition = async data => {
  const response = await AxiosService.put('/player/position', data);
  return response.data;
};

export const getPlayerPosition = async () => {
  const response = await AxiosService.get('/player/position');
  return response.data;
};

export const getMyPosition = async () => {
  const response = await AxiosService.get('/player/position/my');
  return response.data;
};
