import DefaultProfilePng from '../../img/misc/profile-placeholder.png';
import Username from '../Username/Username';

const GameScheduledNotification = ({ user, date }) => {
  return (
    <div className="relative flex flex-col gap-2 mt-1 py-2 hover:bg-gray-200 p-1 cursor-pointer rounded-md">
      <div className="flex items-center gap-2">
        <img
          src={
            process.env.REACT_APP_BASE_URL +
              user?.contact?.profile_image?.url || DefaultProfilePng
          }
          className="w-14 h-14 object-cover rounded-full"
          alt=""
        />
        <h3 className="flex flex-col font-medium font-roboto">
          Game Reminder
          <span className="font-normal font-roboto">
            <Username user={user} /> scheduled for{' '}
            {new Date(date).toLocaleString()}
          </span>
        </h3>
      </div>
      <span className="w-2 h-2 right-2 rounded-full bg-brand-dark absolute"></span>
    </div>
  );
};

export default GameScheduledNotification;
