import AxiosService from "../../AxiosService";

export const addCoachAgentHistory = async data => {
  const response = await AxiosService.post("/coach/agent-history", data);
  return response.data;
};

export const getCoachAgentHistory = async () => {
  const response = await AxiosService.get("coach/agent-histories");
  return response.data;
};

export const editCoachAgentHistory = async ({ data, id }) => {
  const response = await AxiosService.put(`/coach/agent-history/${id}`, data);
  return response.data;
};

export const deleteCoachAgentHistory = async ({ id }) => {
  const response = await AxiosService.delete(`/coach/agent-history/${id}/`);
  return response.data;
};
