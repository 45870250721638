import React from 'react';
import EventScheduledNotification from './EventScheduledNotification';
import NotificationOption from '../NotificationOption/NotificationOption';
import FriendRequestSent from './FriendRequestSent';
import LikeNotification from './LikeNotification';
import CommentNotification from './CommentNotification';
import FriendRequestAcceptedNotification from './FriendRequestAccepted';
import { useSelector } from 'react-redux';

const Notify = ({ notification, handleMarkasread }) => {
  const myId = useSelector(state => state.auth.user?.id);

  return (
    <>
      {notification?.event && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <EventScheduledNotification
            key={notification?.id}
            handleMarkasread={handleMarkasread}
            date={notification?.createdAt}
            eventName={notification?.event?.name}
            id={notification?.event?.id}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
      {notification?.title === 'New Friend Request' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <FriendRequestSent
            key={notification?.friendRequest?.id}
            handleMarkasread={handleMarkasread}
            id={notification?.friendRequest?.id}
            content={notification?.content}
            sentDate={notification?.createdAt}
            friendUsername={
              notification?.friendRequest?.friendId === myId
                ? notification?.friendRequest?.user?.contact?.username
                : notification?.friendRequest?.friend?.contact?.username
            }
            profilePicture={
              notification?.interactionUser?.contact?.profile_image?.url
            }
            isPage={false}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
      {notification?.title === 'Role request received!' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          {notification?.roleRequest?.status !== 'accepted' && (
            <>
              <FriendRequestSent
                key={notification?.id}
                id={notification?.roleRequest?.id}
                handleMarkasread={handleMarkasread}
                content={`${notification?.roleRequest?.by?.contact?.first_name} ${notification?.roleRequest?.by?.contact?.last_name} sent you a role request`}
                sentDate={notification?.createdAt}
                status={notification?.roleRequest?.status}
                profilePicture={
                  notification?.interactionUser?.contact?.profile_image?.url
                }
                friendUsername={
                  notification?.interactionUser?.contact?.username
                }
                isPage={false}
              />
              <NotificationOption
                id={notification?.id}
                seenStatus={notification?.status}
              />
            </>
          )}
        </div>
      )}
      {notification?.title === 'Like added' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <LikeNotification
            key={notification?.id}
            id={notification?.id}
            postId={notification?.post?.id}
            handleMarkasread={handleMarkasread}
            content={notification?.content}
            likedDate={notification?.createdAt}
            profileImage={
              notification?.interactionUser?.contact?.profile_image &&
              notification?.interactionUser?.contact?.profile_image?.url
            }
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
      {notification?.title === 'Comment Added' && (
        <div className="relative min-w-full   hover:bg-gray-200 rounded-md">
          <CommentNotification
            key={notification?.id}
            id={notification?.id}
            content={notification?.content}
            commentedDate={notification?.createdAt}
            postId={notification?.post?.id}
            profileImage={
              notification?.interactionUser?.contact?.profile_image &&
              notification?.interactionUser?.contact?.profile_image?.url
            }
            handleMarkasread={handleMarkasread}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
      {notification?.title === 'New Accepted Friend Request' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <FriendRequestAcceptedNotification
            key={notification?.id}
            id={notification?.id}
            profile={
              myId === notification?.friendRequest?.userId
                ? notification?.friendRequest?.friend
                : notification?.friendRequest?.user
            }
            handleMarkasread={handleMarkasread}
            content={notification?.content}
            acceptedDate={notification?.createdAt}
            profileImage={notification?.profileImage}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
    </>
  );
};

export default Notify;
