import { configureStore } from '@reduxjs/toolkit';
import CustomCalendarSlice from './customCalendarSlice/CustomCalendarSlice';
import AuthSlice from './authSlice';
import userSlice from './userSlice';
import DeleteAwardModalOpen from './deleteAwardModalOpen/DeleteAwardModalOpen';
import VisitProfileReducer from './visitProfileSlice/VisitProfileReducer';
import ManagedUserSlice from './managedUserSlice/ManagedUserSlice';
import UserChats from './userChats/UserChats';
import gameDetail from './schedule/gameDetail';
import NotificationSlice from './notificationSlice/NotificationSlice';
import PostSlice from './postActionSlice/PostSlice';

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    customCalendarModal: CustomCalendarSlice,
    user: userSlice,
    deleteAwardModal: DeleteAwardModalOpen,
    visitProfile: VisitProfileReducer,
    managedUser: ManagedUserSlice,
    userChats: UserChats,
    gameDetail: gameDetail,
    notification: NotificationSlice,
    post: PostSlice
  }
});
