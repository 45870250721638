import { Link } from "react-router-dom";
import "./Footer.scss";
import classnames from "classnames";
const Footer = ({ noMargin, showLetsChat = false }) => {
  return (
    <div
      className={classnames({
        "Footer m-h-60 pt-6  bg-brandBrown": true,
        "mt-40": !noMargin
      })}
    >
      {showLetsChat && (
        <div className="additional-footer flex flex-col items-center justify-center w-full h-40">
          <div className="get-in-touch mb-3">
            <p>GET IN TOUCH</p>
          </div>
          <div className="flex items-center button-chat w-60 h-44 mb-10 cursor-pointer">
            <p className="text-center w-full">LET'S CHAT</p>
          </div>
        </div>
      )}
      <div className="mx-auto border-t-4 border-brand Footer-wrapper">
        <div className="text-white flex justify-between pt-10 px-20 Footer-row-wrapper flex-wrap">
          <div>
            <p className="brand-header mb-4 text-brand medium-spacing cursor-pointer hover:text-brand">
              ElEVEN HYPE
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="landing">HOME</Link>
            </p>

            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="register">REGISTER</Link>
            </p>
          </div>
          <div>
            <p className="brand-header mb-4 text-brand medium-spacing cursor-pointer hover:text-brand">
              ABOUT
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="landing">ABOUT US</Link>
            </p>

            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="privacy">PRIVACY POLICY</Link>
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="terms">TERMS OF SERVICE</Link>
            </p>
          </div>
          <div>
            <p className="brand-header mb-4 text-brand medium-spacing cursor-pointer hover:text-brand">
              HELP
            </p>

            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="contact">CONTACT US</Link>
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <Link to="faq">FAQ</Link>
            </p>
          </div>
          <div>
            <p className="brand-header mb-4 text-brand medium-spacing cursor-pointer hover:text-brand">
              CONNECT
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <a href="https://m.facebook.com/elevenhype/" target="_blank">
                FACEBOOK
              </a>
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <a href="https://www.instagram.com/11.hype/" target="_blank">
                INSTAGRAM
              </a>
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <a href="https://twitter.com/HypeEleven" target="_blank">
                TWITTER
              </a>
            </p>
            <p className="medium-spacing cursor-pointer hover:text-brand">
              <a
                href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQE6DHRNr-4lDwAAAXfJzI2QIHRzaUYXU6NxHKDNAzmOdXzsq7sHYr6wIF4AuPQF1d7P84qTS3JbH7wJoT_QKpSKLTKh5O0NoUbJQeCjovmt38wJBsvGlohVBf3zEdfbKjGB6Do=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Felevenhype"
                target="_blank"
              >
                LINKEDIN
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
