import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import AsyncSelect from "react-select/async";
import InvitePlayerModal from "./InvitePlayerModal";
import {
  createRoleRequest,
  searchPlayer
} from "../../../../services/routes/User";
import { useMutation } from "react-query";
import { fetchUser } from "../../../../redux/userSlice";
import { useDispatch } from "react-redux";

const AddAgentPlayerModal = ({ onCloseClick }) => {
  //
  //
  const [players, setPlayers] = useState([]);
  const dispatch = useDispatch();
  const [invitePlayer, setInvitePlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  useEffect(() => {
    getSearchPlayer();
  }, []);

  // Updating Agent to Send the request
  const { mutate } = useMutation(createRoleRequest, {
    onSuccess: () => {
      dispatch(fetchUser());
    },
    onError: err => {
      console.log(err.response.data.messsage);
    }
  });

  const handleSelect = selectedPlayer => {
    setSelectedPlayer(selectedPlayer);
  };

  const getSearchPlayer = async (inputValue = "", callback) => {
    const filteredPlayers = await searchPlayer(inputValue);
    const options = filteredPlayers.data.map(player => ({
      label: player.contact.first_name + " " + player.contact.last_name,
      value: player.player.id
    }));
    callback(options);
  };

  const sendRequest = () => {
    mutate({ toUser: selectedPlayer.value });
    onCloseClick();
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title={players.length > 0 ? "Edit Player" : "Add Player"}
    >
      {!invitePlayer ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            isSearchable={true}
            value={selectedPlayer}
            onChange={handleSelect}
            loadOptions={getSearchPlayer}
            classNamePrefix="react-select"
            name="playerOptions"
            placeholder="Search for Players..."
          />
          {selectedPlayer ? (
            <div className="w-full flex justify-center mt-4">
              <button
                type="submit"
                className="text-center px-12 focus:outline-none text-lg py-1 text-white bg-brand-light hover:bg-brand-dark theme-button mt-4"
                onClick={sendRequest}
              >
                Send Request
              </button>
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-xl flex-col justify-center mt-6 mb-2">
              Didn't Found any Player?
              <button
                onClick={() => setInvitePlayer(!invitePlayer)}
                className="bg-brand-light hover:bg-brand-dark px-6 py-2  text-white rounded-md mt-2"
              >
                Invite Player
              </button>
            </span>
          )}
        </div>
      ) : (
        <InvitePlayerModal
          onCloseClick={onCloseClick}
          setInvitePlayer={setInvitePlayer}
          invitePlayer={invitePlayer}
        />
      )}
    </Modal>
  );
};
export default AddAgentPlayerModal;
