import React from "react";
import AvatarEditor from "react-avatar-editor";
import "./AvatarEditor.css";

class MyEditor extends React.Component {
  onImageChange = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
    }
  };

  setEditorRef = editor => (this.editor = editor);

  render(props) {
    return (
      <div className=" p-2">
        <AvatarEditor
          ref={this.setEditorRef}
          image={this.props.image}
          color={[201, 148, 0, 1]}
          width={this.props.width || 170}
          height={this.props.height || 150}
          onImageChange={() => this.props.onImageChange(this.editor)}
          border={15}
          scale={this.props.scale || 2}
        />
      </div>
    );
  }
}

export default MyEditor;
