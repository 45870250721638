import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import AcceptButton from '../../../../components/Buttons/AcceptButton';
import DeclineButton from '../../../../components/Buttons/DeclineButton';
import { useMutation } from 'react-query';
import { deletePost } from '../../../../services/routes/Post';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../../redux/userSlice';

const DeletePostModal = ({ onCloseClick, post_id, refetch }) => {
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(deletePost, {
    onSuccess: () => {
      refetch();
      dispatch(fetchUser());
      onCloseClick();
    },
    onError: err => {
      console.error(err);
    }
  });

  return (
    <>
      <Modal title="Delete Contact" onCloseClick={onCloseClick}>
        <div className="w-full  py-5 px-5 bg-white">
          <div className="flex flex-wrap w-full">
            <p
              className="font-light font-roboto"
              style={{ color: '#58595B', fontSize: '16px' }}
            >
              Are you sure you want to delete this Post?
            </p>
            <div className="w-full justify-center mt-8 items-center flex gap-6">
              <button className="mx-1 my-2 " onClick={() => mutate(post_id)}>
                <AcceptButton title={isLoading ? 'Deleting...' : 'YES'} />
              </button>
              <button className="mx-1 my-2  " onClick={() => onCloseClick()}>
                <DeclineButton title="NO" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeletePostModal;
