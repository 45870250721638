import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import AcceptButton from "../../../components/Buttons/AcceptButton";
import DeclineButton from "../../../components/Buttons/DeclineButton";
import { deleteClubSeasonHistory } from "../../../services/routes/ClubHistories/seasonHistories";
import Modal from "../../../components/Modal/Modal";

function DeleteClubSeasonHistory({ onCloseClick, id }) {
  //
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteClubSeasonHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("fetch_allSeasonHistory");
      onCloseClick();
    },

    onError: err => {
      toast.error(err.response.data.message);
    }
  });

  const handleDelete = () => {
    mutate(id);
  };

  return (
    <Modal title="Delete Season History" onCloseClick={onCloseClick}>
      <div className="w-full  py-5 px-5 bg-white">
        <div className="flex flex-wrap w-full">
          <p
            className="font-light font-roboto"
            style={{ color: "#58595B", fontSize: "16px" }}
          >
            Are you sure you want to delete this Season History?
          </p>
          <div className="w-full justify-center mt-8 items-center flex gap-6">
            <button className=" my-2 " onClick={handleDelete}>
              <AcceptButton title={isLoading ? "Deleting..." : "Confirm"} />
            </button>
            <button className="my-2" onClick={() => onCloseClick()}>
              <DeclineButton title="Cancel" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteClubSeasonHistory;
