import { searchPlayer, updatePlayerList } from '../../services/routes/User';
import { useMutation, useQueryClient } from 'react-query';
import AsyncSelect from 'react-select/async';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Modal from '../Modal/Modal';
import AcceptButton from '../Buttons/AcceptButton';
import DeclineButton from '../Buttons/DeclineButton';
import './modal.scss';
import { toast } from 'react-toastify';

const CreatelistModal = ({ onCloseClick, setData }) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: setData?.name,
      description: setData?.description,
      region: setData?.region,
      tags: setData?.tags[0]?.name
    }
  });

  const alreadyParticipants = setData?.players?.map(participant => ({
    label:
      participant?.User?.contact?.first_name +
      ' ' +
      participant?.User?.contact?.last_name,
    value: participant?.User?.player?.id
  }));

  const [selectedUsers, setSelectedUsers] = useState(alreadyParticipants);

  const { mutate, isLoading } = useMutation(updatePlayerList, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetch_myPlayerList');
      toast.success('Player List Updated Successfully');
      onCloseClick();
    },
    onError: err => {
      toast.error('There was an error updating the Player List');
      console.log('error', err);
    }
  });

  const getSearchUsers = async (inputValue = '', callback) => {
    const filteredUsers = await searchPlayer(inputValue);

    const options = filteredUsers.data.map(user => ({
      label: user?.contact?.first_name + ' ' + user?.contact?.last_name,
      value: user?.player?.id
    }));

    callback(options);
  };

  const handleSelectChange = selectedOptions => {
    setSelectedUsers(selectedOptions);
  };

  const onSubmit = data => {
    const players = selectedUsers.map(option => option.value);
    mutate({
      id: setData?.edit,
      data: { ...data, tags: [data.tags], players }
    });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Edit Player List">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-5 py-4 bg-white flex-row rounded-b-md">
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">
              List Name
            </label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              type="text"
              name="name"
              ref={register({
                type: 'name',
                required: 'You Must enter the List Name'
              })}
            />
          </div>
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">
              Description
            </label>
            <textarea
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              name="description"
              rows="3"
              cols="5"
              ref={register({
                type: 'description'
              })}
            />
          </div>
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">Tags</label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              type="text"
              name="tags"
              ref={register({
                type: 'tags',
                required: 'You Must enter Tags'
              })}
            />
          </div>
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">
              Add Players
            </label>
            <AsyncSelect
              isMulti
              isSearchable={true}
              onChange={handleSelectChange}
              value={selectedUsers}
              loadOptions={getSearchUsers}
              name="usersOptions"
              className="basic-multi-select  rounded-md"
              classNamePrefix="select"
            />
          </div>
          <div className="w-full mb-3">
            <label className="text-sm font-medium text-gray-500">Region</label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              type="text"
              name="region"
              ref={register({
                type: 'region',
                required: 'You Must enter Tags'
              })}
            />
          </div>

          <div className="w-full flex items-center gap-4 my-6">
            <AcceptButton
              title={isLoading ? 'Updating...' : 'Update'}
              type="submit"
            />
            <DeclineButton title="Cancel" onClick={() => onCloseClick()} />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreatelistModal;
