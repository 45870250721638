import { push as PushMenu, slide as SlideMenu } from 'react-burger-menu';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import './LandingSideNav.css';
import LogoWhite from '../../img/logo-white.svg';
var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: 'white'
  },
  bmMenuWrap: {
    top: '0px',
    height: '100%'
  },
  bmMenu: {
    background: '#D09F2E',
    // padding: "2.5em 1em 0",
    fontSize: '1.15em',
    color: 'white'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: 'white',
    padding: '0.8em'
  },
  bmItem: {
    display: 'flex',
    flexDirection: 'column',

    height: '100%',
    margin: '0.5em 0em',
    ':hover': {
      color: '#FFBC00'
    }
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};

const LandingSideNav = ({
  isOpen,
  setSideNavOpen,
  windowWidth,
  landingMode
}) => {
  let history = useHistory();

  const handleLogOut = () => {
    //moveProgressForward(result.data[0].id);
    localStorage.setItem('user_id', '');
    localStorage.setItem('token', '');
    localStorage.setItem('email', '');
    localStorage.setItem('name', '');
    localStorage.setItem('type', '');
    localStorage.setItem('address', '');

    history.push('/login');
  };

  const landingPageContent = () => {
    return (
      <Fragment>
        <Link
          to="/home"
          smooth="true"
          className="cursor-pointer hover:text-brand"
        >
          HOME
        </Link>
        <Link
          smooth="true"
          offset={0}
          duration={500}
          to="about"
          className="cursor-pointer hover:text-brand"
        >
          ABOUT
        </Link>
        <Link
          to="what-we-do"
          smooth="true"
          offset={0}
          duration={500}
          className="cursor-pointer hover:text-brand"
        >
          WHAT WE DO
        </Link>
      </Fragment>
    );
  };

  const otherPageContent = () => {
    return (
      <Fragment>
        <RouterLink to="/landing" className="cursor-pointer hover:text-brand">
          HOME
        </RouterLink>
        <RouterLink to="/landing" className="cursor-pointer hover:text-brand">
          ABOUT
        </RouterLink>
        <RouterLink to="/landing" className="cursor-pointer hover:text-brand">
          WHAT WE DO
        </RouterLink>
      </Fragment>
    );
  };
  const menuContent = () => {
    return (
      <div className="">
        <img
          className="LandingSideNav-icon mb-4 -mt-3"
          src={LogoWhite}
          alt=""
        />
        <div className="flex flex-col h-full ml-1">
          {/* IF it is landing mode clicking home, about will scroll to respective section. 
          If it is another mode, it will just take us to landing page */}
          {landingMode ? landingPageContent() : otherPageContent()}

          <RouterLink to="/login" className="cursor-pointer hover:text-brand">
            LOGIN
          </RouterLink>
          <RouterLink
            to="/register"
            className="cursor-pointer hover:text-brand"
          >
            REGISTER
          </RouterLink>
        </div>
      </div>
    );
  };
  const menuType = () => {
    return (
      <SlideMenu
        onStateChange={state => setSideNavOpen(state.isOpen)}
        noOverlay
        right
        isOpen={isOpen}
        styles={styles}
        className={'LandingSideNav'}
        width={'20rem'}
      >
        {menuContent()}
      </SlideMenu>
    );
  };
  return menuType();
};

export default LandingSideNav;
