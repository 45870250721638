import React, { useState } from 'react';
import Modal from '../../../Modal/Modal';
import DefaultBackgroundPng from '../../../../img/misc/Default_pfp.png';
import { useDispatch, useSelector } from 'react-redux';
import { searchUsername } from '../../../../services/routes/User';
import AsyncSelect from 'react-select/async';
import AcceptButton from '../../../Buttons/AcceptButton';
import { accessChatToUser } from '../../../../services/routes/chats';
import { setUserChat } from '../../../../redux/userChats/UserChats';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import './messageModal.scss';

const MessageModal = ({ onCloseClick }) => {
  //
  //
  let history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const me = useSelector(state => state.auth.user);
  const [selectedUser, setSelectedUser] = useState({});

  const getSearchUsers = async (inputValue = '', callback) => {
    const filteredUsers = await searchUsername(inputValue);

    const options = filteredUsers.data
      .filter(user => user.id !== me.id)
      .map(user => ({
        label: user?.contact?.username,
        value: user?.id
      }));

    callback(options);
  };

  const handleSelectChange = selectedOptions => {
    setSelectedUser(selectedOptions);
  };

  const handleSendMessage = async () => {
    const friendId = selectedUser?.value;

    try {
      await accessChatToUser(friendId).then(data => {
        dispatch(setUserChat(data?.data));
        history.push('/message');
        onCloseClick();
      });
      queryClient.invalidateQueries('fetch_allChats');
    } catch (error) {}
  };

  return (
    <Modal onCloseClick={onCloseClick} title={'New Message'}>
      <div className="w-full py-5 bg-white rounded-b-lg">
        <label className="ml-6">To:</label>
        <AsyncSelect
          loadOptions={getSearchUsers}
          onChange={handleSelectChange}
          value={selectedUser}
          name="usersOptions"
          className="basic-multi-select py-2 m-2 mx-6 rounded-md"
          classNamePrefix="select"
        />
        <AcceptButton
          onClick={handleSendMessage}
          type="submit"
          title="Send Message"
        />
      </div>
    </Modal>
  );
};

export default MessageModal;
