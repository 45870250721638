import './WhatWeDo.css';
import { Element } from 'react-scroll';
import personalWebsiteSvg from '../../../img/landing/personal-website.svg';
import socialMediaSvg from '../../../img/landing/social-media.svg';
import publicRelationSvg from '../../../img/landing/public-relation.svg';
import historicalTimelineSvg from '../../../img/landing/historical-timeline.svg';
const WhatWeDo = () => {
  return (
    <Element name="what-we-do" className="InfoCardArea mt-10 mx-auto mb-40">
      <p className="text-brand text-3xl font-acumin font-bold">WHAT DO WE DO</p>
      <div className="WhatWeDoCard-wrapper flex-wrap flex text-brand mt-16 justify-between">
        <div className="WhatWeDoCard">
          <div className="card-inner">
            <div className="card-front">
              <img className="mx-auto  w-40" src={personalWebsiteSvg} alt="" />
              <p className="font-bold">
                PERSONALIZED PLAYER <br /> PROFILE
              </p>
            </div>
            <div className="card-back">
              Eleven Hype creates a personal player profile that you could use
              in your career development? Our platform mirrors your achievements
              and skills and gathers a collection of photos and videos, making
              sure you can easily find your best moments whenever you need them.
              We create a personal player CV and keep it up to date
              automatically
            </div>
          </div>
        </div>
        <div className="WhatWeDoCard">
          <div className="card-inner">
            <div className="card-front">
              <img className="mx-auto w-40" src={socialMediaSvg} alt="" />
              <p className="font-bold">
                SOCIAL MEDIA <br /> CAMPAIGN
              </p>
            </div>
            <div className="card-back">
              More than just a platform, Eleven Hype works as your content
              creator for other social media profiles: Facebook, Instagram,
              Twitter, and LinkedIn. We work to maximize your image as an
              athlete and make sure you don’t miss any opportunities.
            </div>
          </div>
        </div>
        <div className="WhatWeDoCard">
          <div className="card-inner">
            <div className="card-front">
              <img
                className="mx-auto w-40"
                src={historicalTimelineSvg}
                alt=""
              />
              <p className="font-bold">
                PLAYER HISTORICAL <br /> TIMELINE
              </p>
            </div>
            <div className="card-back">
              Through an automatic system of reminders and maintaining direct
              contact with your coaches, we secure a historical timeline of all
              your accomplishments. We gather videos, pictures, and testimonials
              and arrange them chronologically - everyone will easily see your
              fantastic evolution!
            </div>
          </div>
        </div>
        <div className="WhatWeDoCard">
          <div className="card-inner">
            <div className="card-front">
              <img className="mx-auto w-40" src={publicRelationSvg} alt="" />
              <p className="font-bold">
                PLAYER PUBLIC <br /> RELATION
              </p>
            </div>
            <div className="card-back">
              Use our platform for creating and maintaining the relationships
              that matter to you. We foster connections with players, reporters,
              clubs, and sponsors.
              <br />
              Did you move clubs; don’t worry. We will inform local newspapers
              and blogs, friends, colleagues and let those in the industry know
              about your recent move.
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default WhatWeDo;
