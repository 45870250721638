import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ReactComponent as FacebookIcon } from '../../../../img/misc/Facebook.svg';
import { ReactComponent as InstaIcon } from '../../../../img/misc/Instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../../img/misc/LinkedIn.svg';
import { ReactComponent as TwitterIcon } from '../../../../img/misc/Twitter.svg';
import { ReactComponent as YouTubeIcon } from '../../.././../img/misc/Youtube.svg';
import { ReactComponent as EditIcon } from '../../../../img/misc/edit.svg';

import classnames from 'classnames';
import { useForm } from 'react-hook-form';

const Media = ({ title, handleSave, value, handlePost }) => {
  const [disabled, setDisabled] = useState(false);
  const [currentLink, setCurrentLink] = useState('');
  const { register } = useForm();

  console.log('value', value);

  useEffect(() => {
    setCurrentLink(value);
  }, [value]);

  const onTextchange = e => {
    setCurrentLink(e.target.value);
  };

  const handleOk = () => {
    handleSave({ value: currentLink, title });
    setDisabled(false);
  };

  const onCancel = () => {
    setDisabled(false);
    setCurrentLink(value);
  };

  const SocialMediaIcon = () => {
    if (title === 'Facebook') {
      return (
        <div>
          <FacebookIcon
            className={classnames({
              'w-8 h-8 mr-2 ': true,
              'brand-svg': value !== ''
            })}
          />
        </div>
      );
    } else if (title === 'Instagram') {
      return (
        <div>
          <InstaIcon
            className={classnames({
              'w-8 h-8 mr-2 ': true,
              'brand-svg': value !== ''
            })}
          />
        </div>
      );
    } else if (title === 'Twitter') {
      return (
        <div>
          <TwitterIcon
            className={classnames({
              'w-8 h-8 mr-2 ': true,
              'brand-svg': value !== ''
            })}
          />
        </div>
      );
    } else if (title === 'LinkedIn') {
      return (
        <div>
          <LinkedinIcon
            className={classnames({
              'w-8 h-8 mr-2 ': true,
              'brand-svg': value !== ''
            })}
          />
        </div>
      );
    } else {
      return (
        <div>
          <YouTubeIcon
            className={classnames({
              'w-8 h-8 mr-2 ': true,
              'brand-svg': value !== ''
            })}
          />
        </div>
      );
    }
  };

  return (
    <div className="w-full mt-0 pt-0 flex justify-between socialmedia-text">
      <div className="flex items-center">
        <div className="mr-2 w-6">
          <SocialMediaIcon />
        </div>
      </div>

      <input
        // disabled={disabled}
        style={{ background: '#fff' }}
        value={currentLink}
        onChange={e => {
          onTextchange(e);
        }}
        name="socialInput"
        className=" bg-white font-roboto font-light w-full rounded-lg mx-4 px-4 py-1 "
        placeholder="Paste your profile here "
        ref={register({
          required: 'You must enter a value'
        })}
      />

      {disabled && (
        <CheckCircleOutlineOutlinedIcon
          onClick={handleOk}
          className="cursor-pointer hover:text-green-600 text-green-300"
        />
      )}

      {disabled && (
        <CancelIcon
          onClick={onCancel}
          className="cursor-pointer ml-2 hover:text-red-600 text-red-400"
        />
      )}
      {!disabled && (
        <EditIcon
          onClick={() => setDisabled(true)}
          className="cursor-pointer w-8 h-8 primary-svg"
        />
      )}
    </div>
  );
};

export default Media;
