import { Link } from 'react-router-dom';
import { ReactComponent as LogoutSvg } from '../../img/logout-svg.svg';
import { ReactComponent as SettingsSvg } from '../../img/settings-svg.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout as logoutRedux } from '../../redux/authSlice';
import { useQuery } from 'react-query';
import LargeLoader from '../Loader/LargeLoader';
import { logout } from '../../services/routes/auth';
import { useHistory } from 'react-router-dom';
import profileImageUrl from '../../config/config';
import DefaultProfileImage from '../../img/misc/Default_pfp.png';

const ProfileOption = () => {
  //
  const history = useHistory();
  const data = useSelector(state => state.user);
  const user = data?.user?.contact;

  const dispatch = useDispatch();

  const { refetch, isLoading } = useQuery('logout', logout, {
    onSuccess: () => {
      dispatch(logoutRedux());
      history?.push('/login');
    },
    enabled: false
  });

  if (isLoading) {
    return <LargeLoader />;
  }

  return (
    <div
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
      className="shadow-lg bg-white w-44 rounded-md "
    >
      <div className="px-4 pt-3 pb-2">
        <Link to="/my-profile" className="block text-base ">
          <div className="flex items-center gap-1 flex-col primary-svg">
            {/* <ProfileSvg className="w-8 h-8 " /> */}
            <div className="w-12 h-12 text-gray-600 hover:text-black ">
              <img
                src={
                  (user?.profile_image &&
                    profileImageUrl + user?.profile_image?.url) ||
                  DefaultProfileImage
                }
                alt=""
                className="w-12 h-12 rounded-full object-cover "
              />
            </div>
            <span className="text-gray-700 font-medium hover:text-black text-sm">
              {user?.first_name + ' ' + user?.last_name}
            </span>
          </div>
        </Link>
      </div>

      <div className="w-full divide-y ">
        <Link
          to="/settings"
          className="flex items-center primary-svg px-4 gap-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
        >
          <div className="flex items-center   gap-2 py-2 text-xs font-roboto ">
            <SettingsSvg className="w-5 h-5 " />
            Settings
          </div>
        </Link>
        <Link
          className="flex items-center primary-svg px-4 gap-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer "
          role="menuitem"
          onClick={() => refetch()}
        >
          <div className="flex items-center   gap-2 py-2 text-xs font-roboto">
            <LogoutSvg className="w-5 h-5 " />
            Log Out
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProfileOption;
