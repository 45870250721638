import React, { useState, useEffect } from 'react';
import { fetchUser } from '../../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import PlayerAwards from './PlayerAwards';
import EditPositionModal from './EditPositionModal';
import AddPlayerAgentModal from './AddPlayerAgentModal';
import AddPlayerCoachModal from './AddPlayerCoachModal';
import PlayerPosition from '../PlayerProfile/PlayerPosition/PlayerPosition';
import PersonRequestCard from '../../../components/PersonRequestCard/PersonRequestCard';
import EditAboutModal from './EditAboutModal';
import AddAwardModal from '../../../components/AddAwardsModal/AddAwardsModal';
import EditAwardModal from '../../../components/EditAwardModal/EditAwardModal';
import RequestTestimonialModal from '../PlayerTestimonial/RequestTestimonialModal';
import StarTestimonialModal from './StarTestimonialModal';
import AddPlayerClubModal from './AddPlayerClubModal/AddPlayerClubModal';
import PlayerInfo from './PlayerInfo/PlayerInfo';
import EditPlayerInfoModal from './EditPlayerInfoModal/EditPlayerInfoModal';
import UserHistoryTable from '../../../components/UserHistoryTable/UserHistoryTable';
import AddPlayerHistory from './PlayerHistory/AddPlayerHistory';
import AgentClubHistory from '../../../components/AgentClubHistory/AgentClubHistory';
import AddPlayerCoachHistory from './AddPlayerCoachHistory/AddPlayerCoachHistory';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import {
  deletePlayerCoachHisotry,
  getAllPlayerCoachHistory
} from '../../../services/routes/PlayerHistories/playerCoachHistories';
import EditPlayerCoachHistory from './EditPlayerCoachHistory/EditPlayerCoachHistory';
import {
  deletePlayerClubHistory,
  getAllPlayerClubHistory
} from '../../../services/routes/PlayerHistories/playerClubHistories';
import EditPlayerClubHistory from './EditPlayerClubHistory/EditPlayerClubHistory';
import {
  deletePlayerAgentHistory,
  getPlayerAgentHistory
} from '../../../services/routes/PlayerHistories/playerAgentHistories';
import AddPlayerAgentHistory from '../../../components/AgentClubHistory/AgentPlayer/AddPlayerAgentHistory';
import EditPlayerAgentHistory from '../../../components/AgentClubHistory/AgentPlayer/EditPlayerAgentHistory';
import 'rc-checkbox/assets/index.css';
import './PlayerProfile.css';
import { toast } from 'react-toastify';
import DeleteAwardModal from '../../../components/DeleteAwardModal/DeleteAwardModal';
import DeleteHistoryModal from '../../../components/DeleteHistoryModal/DeleteHistoryModal';
import Sidebar from '../../../components/Profile/Sidebar';
import ProfileBio from '../../../components/Profile/ProfileBio';
import TabSwitcher from '../../../components/Profile/TabSwitcher';

const PlayerProfile = () => {
  //
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [addCoachModalOpen, setAddCoachModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('information');
  const [addClubModalOpen, setAddClubModalOpen] = useState(false);
  const [editPositionModalOpen, setEditPositionModal] = useState(false);
  const [editPlayerInfoModalOpen, setEditPlayerInfoModalOpen] = useState(false);
  const [editAboutModalOpen, setEditAboutModal] = useState(false);

  const [requestTestimonailModal, setRequestTestimonailModal] = useState(false);
  const [starTestimonial, setStarTestimonial] = useState(false);

  // Award States
  const [addAwardModalOpen, setAddAwardModal] = useState(false);
  const [editAwardModalOpen, setEditAwardModal] = useState(false);
  const [currentPlayerAward, setCurrentPlayerAward] = useState({});
  const [showDeleteAwardModal, setShowDeleteAwardModal] = useState(false);
  const [currentAwardId, setCurrentAwardId] = useState(null);

  // Player-Coach History States
  const [addPlayerCoachHistoryModalOpen, setAddPlayerCoachHistoryModal] =
    useState(false);
  const [editPlayerCoachHistoryModalOpen, setEditPlayerCoachHistoryModal] =
    useState(false);
  const [currentCoachHistory, setCurrentCoachHistory] = useState({});
  const [showDeleteCoachHistoryModal, setShowDeleteCoachHistoryModal] =
    useState(false);
  const [deleteCoachHistoryById, setDeleteCoachHistoryById] = useState(null);

  // Player-Club History States
  const [addPlayerHistory, setAddPlayerHistory] = useState(false);
  const [editPlayerClubHistoryModal, setEditPlayerClubHistoryModal] =
    useState(false);
  const [currentClubHistory, setCurrentClubHistory] = useState({});
  const [showDeleteClubHistoryModal, setShowDeleteClubHistoryModal] =
    useState(false);
  const [deleteClubHistoryById, setDeleteClubHistoryById] = useState(null);

  //Player-Agent history States
  const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);
  const [addPlayerAgentHistoryModal, setAddPlayerAgentHistoryModal] =
    useState(false);
  const [deleteAgentHistory, setDeleteAgentHistory] = useState({});
  const [currentAgentHistory, setCurrentAgentHistory] = useState({});
  const [editPlayerAgentHistoryModal, setEditPlayerAgentHistoryModal] =
    useState(false);
  const [deleteAgentHistoryModal, setDeleteAgentHistoryModal] = useState(null);
  const [activeHistory, setActiveHistory] = useState('agent');

  const onEditPlayerAwardClick = data => {
    setEditAwardModal(true);
    setCurrentPlayerAward(data);
  };

  const onDeletePlayerAwardClick = id => {
    setShowDeleteAwardModal(true);
    setCurrentAwardId(id);
  };

  const onEditPlayerCoachHistoryClick = data => {
    setEditPlayerCoachHistoryModal(true);
    setCurrentCoachHistory(data);
  };

  const onEditPlayerClubHistoryClick = data => {
    setEditPlayerClubHistoryModal(true);
    setCurrentClubHistory(data);
  };

  const onEditPlayerAgentHistoryClick = data => {
    setEditPlayerAgentHistoryModal(true);
    setCurrentAgentHistory(data);
  };

  const onDeletePlayerCoachHistoryClick = id => {
    setDeleteCoachHistoryById(id);
    setShowDeleteCoachHistoryModal(true);
  };

  const onDeletePlayerClubHistoryClick = id => {
    setDeleteClubHistoryById(id);
    setShowDeleteClubHistoryModal(true);
  };

  const onDeletePlayerAgentHistoryClick = id => {
    setDeleteAgentHistory(id);
    setDeleteAgentHistoryModal(true);
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const [playerCoach, playerClub, playerAgent] = useQueries([
    { queryKey: ['playerCoach'], queryFn: getAllPlayerCoachHistory },
    { queryKey: ['playerClub'], queryFn: getAllPlayerClubHistory },
    { queryKey: ['playerAgent'], queryFn: getPlayerAgentHistory }
  ]);

  const { data: playerCoachData, refetch: refetchCoach } = playerCoach;
  const { data: playerAgentData, refetch: refetchAgent } = playerAgent;
  const { data: playerClubData } = playerClub;

  //delete apis

  //delete coach history
  const { mutate: mutateCoachhistory, isLoading: loadingCoachHistory } =
    useMutation(deletePlayerCoachHisotry, {
      onSuccess: () => {
        queryClient.invalidateQueries('playerCoach');
      },

      onError: err => {
        toast.error(err.response.data.message);
      }
    });

  //delete agent history
  const { mutate: mutateAgentHistory, isLoading: loadingAgentHistory } =
    useMutation(deletePlayerAgentHistory, {
      onSuccess: () => {
        queryClient.invalidateQueries('playerAgent');
      },
      onError: err => {
        toast.error(err.response.data.message);
      }
    });

  //delete club history

  const { mutate: mutateClubHistory, isLoading: loadingClubHHistory } =
    useMutation(deletePlayerClubHistory, {
      onSuccess: () => {
        queryClient.invalidateQueries('playerClub');
      },

      onError: err => {
        toast.error(err.response.data.message);
      }
    });

  return (
    <>
      {/* Tutorial Modals and steps*/}

      {starTestimonial && (
        <StarTestimonialModal
          data={[]}
          onCloseClick={() => setStarTestimonial(false)}
        />
      )}
      {requestTestimonailModal && (
        <RequestTestimonialModal
          data={[]}
          onCloseClick={() => setRequestTestimonailModal(false)}
        />
      )}
      {/* All Edit Modal */}
      {editPositionModalOpen && (
        <EditPositionModal onCloseClick={() => setEditPositionModal(false)} />
      )}
      {editAboutModalOpen && (
        <EditAboutModal
          aboutPlayer={user?.user?.contact?.bio}
          onCloseClick={() => setEditAboutModal(false)}
        />
      )}
      {editPlayerCoachHistoryModalOpen && (
        <EditPlayerCoachHistory
          onCloseClick={() => setEditPlayerCoachHistoryModal(false)}
          setData={currentCoachHistory}
        />
      )}
      {editPlayerAgentHistoryModal && (
        <EditPlayerAgentHistory
          onCloseClick={() => setEditPlayerAgentHistoryModal(false)}
          setData={currentAgentHistory}
        />
      )}

      {editAwardModalOpen && (
        <EditAwardModal
          award={currentPlayerAward}
          onCloseClick={() => setEditAwardModal(false)}
        />
      )}
      {editPlayerInfoModalOpen && (
        <EditPlayerInfoModal
          onCloseClick={() => setEditPlayerInfoModalOpen(false)}
        />
      )}
      {editPlayerClubHistoryModal && (
        <EditPlayerClubHistory
          setEditData={currentClubHistory}
          onCloseClick={() => setEditPlayerClubHistoryModal(false)}
        />
      )}

      {/*All Add Modal */}
      {addAwardModalOpen && (
        <AddAwardModal onCloseClick={() => setAddAwardModal(false)} />
      )}
      {addAgentModalOpen && (
        <AddPlayerAgentModal onCloseClick={() => setAddAgentModalOpen(false)} />
      )}
      {addCoachModalOpen && (
        <AddPlayerCoachModal onCloseClick={() => setAddCoachModalOpen(false)} />
      )}
      {addClubModalOpen && (
        <AddPlayerClubModal onCloseClick={() => setAddClubModalOpen(false)} />
      )}
      {addPlayerHistory && (
        <AddPlayerHistory onCloseClick={() => setAddPlayerHistory(false)} />
      )}
      {addPlayerCoachHistoryModalOpen && (
        <AddPlayerCoachHistory
          onCloseClick={() => setAddPlayerCoachHistoryModal(false)}
          refetch={refetchCoach}
        />
      )}
      {addPlayerAgentHistoryModal && (
        <AddPlayerAgentHistory
          onCloseClick={() => setAddPlayerAgentHistoryModal(false)}
          refetch={refetchAgent}
        />
      )}

      {/* All Delete Modal */}

      {showDeleteCoachHistoryModal && (
        <DeleteHistoryModal
          id={deleteCoachHistoryById}
          onCloseClick={() => setShowDeleteCoachHistoryModal(false)}
          mutate={mutateCoachhistory}
          isLoading={loadingCoachHistory}
        />
      )}
      {deleteAgentHistoryModal && (
        <DeleteHistoryModal
          onCloseClick={() => setDeleteAgentHistoryModal()}
          id={deleteAgentHistory}
          mutate={mutateAgentHistory}
          isLoading={loadingAgentHistory}
        />
      )}
      {showDeleteClubHistoryModal && (
        <DeleteHistoryModal
          id={deleteClubHistoryById}
          onCloseClick={() => setShowDeleteClubHistoryModal(false)}
          mutate={mutateClubHistory}
          isLoading={loadingClubHHistory}
        />
      )}
      {showDeleteAwardModal && (
        <DeleteAwardModal
          award={currentAwardId}
          onCloseClick={() => setShowDeleteAwardModal(false)}
        />
      )}

      {/* Layout Design */}
      <div className="flex w-full mt-8 gap-8">
        <div className="w-96">
          <Sidebar>
            <ProfileBio isEditable={true} />
            {/* line */}
            <div className="border-b-2 border-brand-dark mx-8 my-6 border-opacity-100"></div>
            <PlayerPosition
              setEditPositionModal={setEditPositionModal}
              positions={user.user.player.positions}
            />
          </Sidebar>
        </div>

        {/* tab that can be switched like navigation with heading Information, Agent, Coach, Award, History*/}
        <div className="flex flex-col flex-1">
          <TabSwitcher
            tabs={[
              {
                title: 'Information',
                name: 'information',
                component: <div>Information</div>
              },
              {
                title: 'Agent',
                name: 'agent',
                component: <div>Agent</div>
              },
              {
                title: 'Coach',
                name: 'coach',
                component: <div>Coach</div>
              },
              {
                title: 'Club',
                name: 'club',
                component: <div>Coach</div>
              },
              {
                title: 'Award',
                name: 'award',
                component: <div>Award</div>
              },
              {
                title: 'History',
                name: 'history',
                hasChild: true,
                component: <div>History</div>
              }
            ]}
            activeTab={activeTab}
            setActiveTab={name => {
              setActiveTab(name);
            }}
          />

          <div className="">
            <div className="">
              <div className="flex flex-col h-full flex-1">
                {/* Player Position and Info */}
                {activeTab === 'information' && (
                  <div className="position_info_container">
                    <PlayerInfo
                      setEditPlayerInfoModalOpen={setEditPlayerInfoModalOpen}
                      data={user.user}
                    />
                  </div>
                )}

                {/* Player's Contact */}
                <div className="flex md:flex-row flex-col items-center w-full gap-8 flex-1">
                  {activeTab === 'agent' && (
                    <PersonRequestCard
                      data={user?.user}
                      isEditable={true}
                      role="player"
                      personType="Agent"
                      type="agent"
                      setShowModalOpen={setAddAgentModalOpen}
                    />
                  )}
                  {activeTab === 'coach' && (
                    <PersonRequestCard
                      data={user?.user}
                      isEditable={true}
                      role="player"
                      personType="Coach"
                      type="coach"
                      setShowModalOpen={setAddCoachModalOpen}
                    />
                  )}
                  {activeTab === 'club' && (
                    <PersonRequestCard
                      data={user?.user}
                      isEditable={true}
                      role="player"
                      personType="Club"
                      type="club"
                      setShowModalOpen={setAddCoachModalOpen}
                    />
                  )}
                </div>

                {
                  // Player Awards
                  activeTab === 'award' && (
                    <div>
                      <PlayerAwards
                        awards={user?.user?.awards}
                        onEditPlayerAwardClick={onEditPlayerAwardClick}
                        setAddAwardModal={setAddAwardModal}
                        deleteAwards={onDeletePlayerAwardClick}
                        isEditable={true}
                      />
                    </div>
                  )
                }

                {
                  // Player-History
                  activeTab === 'history' && (
                    <div className="gap-8 flex-1 overflow-y-scroll w-full">
                      <div className="flex items-end justify-end">
                        <TabSwitcher
                          parent={false}
                          tabs={[
                            {
                              title: 'Club',
                              name: 'club',
                              component: <div>Club</div>
                            },
                            {
                              title: 'Agent',
                              name: 'agent',
                              component: <div>Agent</div>
                            },
                            {
                              title: 'Coach',
                              name: 'coach',
                              component: <div>Coach</div>
                            }
                          ]}
                          activeTab={activeHistory}
                          setActiveTab={name => {
                            setActiveHistory(name);
                          }}
                        />
                      </div>

                      <div className="w-full">
                        {
                          // Player-Agent History
                          activeHistory === 'agent' && (
                            <div>
                              <AgentClubHistory
                                isEditable={true}
                                role="Agent"
                                showAddHistoryModal={() =>
                                  setAddPlayerAgentHistoryModal(true)
                                }
                                histories={playerAgentData?.data}
                                editHistory={onEditPlayerAgentHistoryClick}
                                deleteHistory={onDeletePlayerAgentHistoryClick}
                              />
                            </div>
                          )
                        }

                        {
                          // Player-Coach History
                          activeHistory === 'coach' && (
                            <div className="mb-8">
                              <AgentClubHistory
                                isEditable={true}
                                role="Coach"
                                showAddHistoryModal={() =>
                                  setAddPlayerCoachHistoryModal(true)
                                }
                                histories={playerCoachData?.data}
                                editHistory={onEditPlayerCoachHistoryClick}
                                deleteHistory={onDeletePlayerCoachHistoryClick}
                              />
                            </div>
                          )
                        }

                        {
                          // Player-Club History
                          activeHistory === 'club' && (
                            <div className="mb-8">
                              <UserHistoryTable
                                histories={playerClubData?.data}
                                isEditable={true}
                                title="Player Club History"
                                role="player"
                                addHistory={() => setAddPlayerHistory(true)}
                                editHistory={onEditPlayerClubHistoryClick}
                                deleteHistory={onDeletePlayerClubHistoryClick}
                              />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerProfile;
