import React, { useState, useRef } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { ReactComponent as UploadSvg } from '../../../../img/misc/CreditCardPlus.svg';
import AvatarEditor from '../../../../components/AvatarEditor';
import dataURItoBlob from '../../../../utils/dataURItoBlob';
import AcceptButton from '../../../../components/Buttons/AcceptButton';
import { uploadFile } from '../../../../services/routes/Files';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../../redux/userSlice';
import { toast } from 'react-toastify';

import './modal.css';

const EditProfileModal = ({ onCloseClick, title }) => {
  const dispatch = useDispatch();
  const [scale, setScale] = useState(0);
  const [buildImage, setBuildImage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedFile, setUploadedFile] = useState();
  const uploadInputEl = useRef(null);

  const onImageChange = e => {
    setSelectedImage(e.target.files[0]);

    setBuildImage(e.target.files[0]);
  };

  const { mutate, isLoading } = useMutation(uploadFile, {
    onSuccess: () => {
      toast.success('Profile Picture Updated Successfully');
      dispatch(fetchUser());

      onCloseClick();
    },
    onError: err => {
      console.error(err);
    }
  });

  const onEditorImageChange = editor => {
    if (!editor) return;
    let canvas = editor.getImageScaledToCanvas();
    var image = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    var dataURL = canvas.toDataURL('image/png', 0.5);
    var blob = dataURItoBlob(dataURL);
    setUploadedFile(blob);
  };

  const uploadImage = e => {
    e.preventDefault();

    if (selectedImage != null) {
      const formData = new FormData();
      formData.append('profile_image', selectedImage);

      mutate(formData);
    } else {
      console.log('Error While Uploading');
    }
  };

  return (
    <Modal onCloseClick={onCloseClick} color="#919191" title={title}>
      <div className=" bg-white w-full h-full">
        <form className="w-full" onSubmit={uploadImage}>
          <div className="w-full flex justify-center ">
            {buildImage ? (
              <div className="flex flex-col gap-1">
                {buildImage && (
                  <div className="">
                    <AvatarEditor
                      image={buildImage}
                      onImageChange={onEditorImageChange}
                      scale={scale}
                      rotate={0}
                    />
                  </div>
                )}
                {buildImage && (
                  <div style={{ width: '200px', marginLeft: '28px' }}>
                    <div className="below-image mt-0.5 pb-2 font-light font-roboto ">
                      <span className="font-semibold">To crop</span> the image,
                      adjust the image on the frame
                    </div>
                    <div className="below-image font-light font-roboto">
                      <span className="font-semibold">To scale</span> the image,
                      slide the toggle to left and right
                    </div>

                    <div className=" my-0 py-0 ">
                      <input
                        name="scale"
                        className="bg-brand-dark rounded-2xl h-2"
                        type="range"
                        onChange={e => setScale(e.target.value)}
                        min={'1'}
                        max="2"
                        color={[201, 148, 0, 1]} // RGBA
                        step="0.01"
                        defaultValue="1"
                      />
                    </div>
                    <div className="below-image font-light font-roboto">
                      <span className="font-semibold">To position</span> click
                      on the image to Reposition it.
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full  flex flex-col items-center justify-center ">
                <div
                  onClick={() => uploadInputEl.current.click()}
                  className="flex h-48 upload-area mt-2"
                >
                  <UploadSvg className="primary-svg" />
                  <div className="text-form-small text-center font-light font-roboto">
                    <span className="text-blue text-form-small-1 underline">
                      Upload Image from your device
                    </span>
                  </div>
                  <input
                    ref={uploadInputEl}
                    onChange={onImageChange}
                    className="hidden"
                    type="file"
                    accept="image/*"
                  />
                </div>
                <div className=" mt-5 form-text-small font-light">
                  Recommended size: 1080x1080px
                </div>
              </div>
            )}
          </div>

          <div className="w-full flex justify-center mt-6">
            <AcceptButton
              title={isLoading ? 'Uploading...' : 'Upload Photo'}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditProfileModal;
