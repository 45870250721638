import { useForm } from 'react-hook-form';
import VisibilitySettings from './VisibilitySettings';
import { ReactComponent as SaveSvg } from '../../../img/landing/save.svg';
import { ReactComponent as CancelSvg } from '../../../img/landing/cancel.svg';
import { useState } from 'react';
import './PrivacySettings.scss';

const PrivacySettings = () => {
  const { register, handleSubmit, reset } = useForm();

  const [showCancel, setShowCancel] = useState(false);

  const onsubmit = data => {
    //TODO: Send this Data to the Server
  };

  return (
    <div className="md:w-full w-11/12  mt-4 md:mt-0 ">
      <div className="PrivacySettings">
        <div className="flex flex-col gap-5">
          {/*  */}
          <VisibilitySettings
            onChange={() => setShowCancel(true)}
            register={register}
            name="profileVisibilty"
            title="Profile Visibility"
          />
          <VisibilitySettings
            onChange={() => setShowCancel(true)}
            register={register}
            name="seePost"
            title="Who can see my posts?"
          />
          <VisibilitySettings
            onChange={() => setShowCancel(true)}
            register={register}
            name="contactMe"
            title="Who can contact me?"
          />
          <VisibilitySettings
            onChange={() => setShowCancel(true)}
            register={register}
            name="IdentifyMe"
            title="Who can identify you?"
          />

          <div className="w-1/2">
            <button
              type="submit"
              onClick={handleSubmit(onsubmit)}
              className="mt-4 flex items-center gap-2 justify-center mr-3"
            >
              {showCancel && (
                <CancelSvg
                  className="w-36"
                  onClick={() => {
                    setShowCancel(false);
                    reset();
                  }}
                />
              )}
              <SaveSvg className="w-36" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;
