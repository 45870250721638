import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userProfile } from "../../services/routes/User";
import { updateUserBio } from "../../services/routes/User";

export const updateBio = createAsyncThunk("/user/bio", (data, thunkApi) => {
  updateUserBio(data).then(() => {
    thunkApi.dispatch(fetchUser());
  });
});

export const fetchUser = createAsyncThunk("users/fetch", () => userProfile());

const UserSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(fetchUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateBio.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateBio.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateBio.rejected, (state, action) => {
      state.loading = false;
    });
  }
});

export const { setUser } = UserSlice.actions;

export default UserSlice.reducer;
