import React, { useState, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import busyIcon from '../../../img/busy.gif';
import { useEffect } from 'react';
import { updateUserProfile } from '../../../services/routes/User';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import './Form4.css';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Form4 = ({ moveProgressForward }) => {
  const {
    register,
    errors,
    handleSubmit,
    watch,
    setValue: setFormData,
    reset,
    getValues
  } = useForm({});

  const password = useRef({});

  const user = useSelector(state => state.user?.user);

  password.current = watch('password', '');
  const [value, setValue] = useState('us');
  const [phone, setPhone] = useState('');
  const [currentClub, setCurrentClub] = useState(true);
  const options = useMemo(() => countryList().getData(), []);
  const [saving, setSaving] = useState(false);

  const changeHandler = value => {
    setValue(value.value.toLowerCase());
    setFormData('country', value.value);
    console.log(getValues());
  };

  const { mutate, isLoading } = useMutation(updateUserProfile, {
    onSuccess: () => {
      moveProgressForward();
      toast.success('Profile Updated Successfully');
    },
    onError: error => {
      console.log(error);
    }
  });

  const onsubmit = () => {
    console.log(getValues());
    mutate({ ...getValues(), country: value, phone: phone });
  };

  useEffect(() => {
    reset({
      first_name: user?.contact?.first_name,
      last_name: user?.contact?.last_name,
      bio: user?.contact?.bio,
      birth_date: user?.contact?.birth_date,
      phone: user?.contact?.phone,
      country: user?.contact?.country,
      city: user?.contact?.city,
      postal_code: user?.contact?.postal_code,
      address: user?.contact?.address
    });
    setPhone(user?.contact?.phone);
  }, [user]);

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('registerData'));
    reset(saved);
    setPhone(saved.phone);
  }, []);

  return (
    <>
      <div className="block w-full text-center ">
        <div className="text-brand block text-left text-lg font-medium  font-roboto pl-2 mb-3 mt-8 md:mb-0 login-text">
          Account Information
        </div>
      </div>

      <form
        className="mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={e => e.preventDefault()}
      >
        <div className="w-full flex items-center justify-between gap-3">
          <div className="w-1/2">
            <label
              className="  text-base font-light font-roboto tracking-widest"
              htmlFor=""
            >
              First Name
            </label>
            <input
              className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
              type="text"
              name="first_name"
              id="first_name"
              ref={register({
                required: 'You must specify First Name'
              })}
            />
            {errors.first_name && <p>{errors.first_name.message}</p>}
          </div>
          <div className="ml-2 w-1/2">
            <label
              className="  text-base font-light font-roboto tracking-widest"
              htmlFor=""
            >
              Last Name
            </label>
            <input
              className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
              type="text"
              name="last_name"
              id="last_name"
              ref={register({
                required: 'You must specify a Last Name'
              })}
            />
            {errors.last_name && <p>{errors.last_name.message}</p>}
          </div>
        </div>
        <div className="w-full flex justify-between items-center gap-3 my-3">
          <div className="w-1/2">
            <label
              className="text-base font-light font-roboto tracking-widest"
              htmlFor=""
            >
              Date of birth
            </label>
            <input
              className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
              type="date"
              style={{ height: '36px' }}
              name="birth_date"
              id="birth_date"
              ref={register({
                required: 'You must specify a date of birth'
              })}
            />

            {errors.birth_date && <p>{errors.birth_date.message}</p>}
          </div>
          <div className="w-1/2 ml-2">
            <label
              className="  text-base font-light font-roboto tracking-widest"
              htmlFor=""
            >
              Gender
            </label>
            <select
              style={{ fontWeight: '400', fontSize: '17px' }}
              className="form-select font-normal block w-full text-left text-md border-normalGray-light text-base rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
              id="gender"
              name="gender"
              ref={register({
                required: 'You must specify a gender'
              })}
            >
              <option value="male">MALE</option>
              <option value="female">FEMALE</option>
            </select>
            {errors.gender && <p>{errors.gender.message}</p>}
          </div>
        </div>

        <div className="w-full mb-3">
          <label
            className=" text-base font-light font-roboto tracking-widest"
            htmlFor=""
          >
            Bio
          </label>
          <input
            className="block w-full text-left text-md border py-2 md:py-0.5 px-3 text-black border-normalGray-light bg-transparent"
            type="text"
            name="bio"
            id="bio"
            ref={register({
              required: 'You must fill the bio'
            })}
          />
          {errors.bio && <p>{errors.bio.message}</p>}
        </div>

        <div className="w-full mb-3">
          <label
            className=" text-base font-light font-roboto tracking-widest"
            htmlFor=""
          >
            Country
          </label>
          <Select
            name="country"
            // ref={register('country', {
            //   required: 'You must specify a country'
            // })}
            className="block w-full text-left text-md border-normalGray-light bg-white rounded-sm border px-2 text-black "
            options={options}
            onChange={changeHandler}
            styles={{
              control: provided => ({
                ...provided,
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'white',
                fontWeight: '400',
                fontSize: '17px'
              })
            }}
          />

          {errors.country && <p>{errors.country.message}</p>}
        </div>
        <div className="w-full ">
          <label
            className=" text-base font-light font-roboto tracking-widest"
            htmlFor=""
          >
            Address
          </label>
          <input
            className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
            type="text"
            name="address"
            id="address"
            ref={register({
              required: 'You must specify an address'
            })}
          />
          {errors.address && <p>{errors.address.message}</p>}
        </div>
        <div className="w-full my-2 gap-3 items-center flex justify-between">
          <div className="w-1/2">
            <label
              className=" text-base font-light font-roboto tracking-widest"
              htmlFor=""
            >
              City
            </label>
            <input
              className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
              id="city"
              name="city"
              type="text"
              ref={register({
                required: 'You must specify a city'
              })}
            />
            {errors.city && <p>{errors.city.message}</p>}
          </div>
          <div className="w-1/2 ml-2">
            <label
              className=" text-base font-light font-roboto tracking-widest"
              htmlFor=""
            >
              Postal Code
            </label>
            <input
              className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
              name="postal_code"
              id="postalcode"
              type="text"
              ref={register({
                required: 'You must specify a postalcode'
              })}
            />
            {errors.postal_code && <p>{errors.postal_code.message}</p>}
          </div>
        </div>

        <div className="w-full flex flex-col">
          {errors.email && <p>{errors.email.message}</p>}
          <label
            className=" text-base font-light font-roboto tracking-widest mt-3"
            htmlFor=""
          >
            Phone
          </label>
          <PhoneInput
            // {...register('phone', {
            //   required: 'You must specify your phone number'
            // })}
            className="block w-full text-left text-lg border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent font-medium"
            country={value}
            value={phone}
            onChange={phone => {
              setPhone(phone);
              setFormData('phone', phone);
            }}
            name="phone"
            id="phone"
            placeholder="Phone"
            inputStyle={{
              background: 'transparent',
              border: 'none',
              fontWeight: '400',
              fontSize: '17px'
            }}
            buttonStyle={{ background: 'transparent', border: 'none' }}
            dropdownStyle={{ background: 'white' }}
          />

          {errors.phone && <p>{errors.phone.message}</p>}
        </div>

        <div className="block w-full text-center mt-10">
          <button
            className="block w-2/6  m-auto "
            type="submit"
            onClick={handleSubmit(onsubmit)}
          >
            <AcceptButton title="Continue" />
          </button>
        </div>
      </form>
      {saving && <img className="busy" alt="Loading.." src={busyIcon} />}
    </>
  );
};

export default Form4;
