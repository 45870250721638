import React, { useState } from "react";
import "../../styles/main.css";
import "./Features.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";


const Features = () => {
  
  return (
    <div className="h-full">
      <Header />
      <div className="md:flex maincontent graybg">
        <div className="p-28">
          
          <div className="title">Features</div>
       
          
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Features;
