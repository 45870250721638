import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import { useQueryClient } from 'react-query';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  acceptFriendRequest,
  rejectFriendRequest
} from '../../services/routes/UserFriendRequest';
import profileImageUrl from '../../config/config';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import AcceptButton from '../Buttons/AcceptButton';
import DeclineButton from '../Buttons/DeclineButton';
import {
  acceptRequestNotification,
  declineRequestNotification
} from '../../services/routes/Notification';

const FriendRequestSent = ({
  id,
  friendRequestId,
  sentDate,
  content,
  status,
  handleMarkasread,
  isPage,
  profilePicture,
  friendUsername,
  isRoleRequest
}) => {
  //
  //

  const queryClient = useQueryClient();

  const handleDeleteFriendRequest = async () => {
    try {
      if (isRoleRequest) await declineRequestNotification(id);
      if (!isRoleRequest) await rejectFriendRequest({ id });
      toast.success(
        `You have declined ${
          isRoleRequest ? 'Role' : 'Friend'
        } request successfully`
      );
      queryClient.invalidateQueries('fetch_notification');
    } catch (error) {
      toast.error('Error while deleting request');
      console.log('error while deleting friend request', error);
    }
  };

  const handleAcceptFriendRequest = async () => {
    try {
      if (isRoleRequest) await acceptRequestNotification(id);
      if (!isRoleRequest) await acceptFriendRequest({ id: friendRequestId });
      toast.success(
        `You have accepted ${
          isRoleRequest ? 'Role' : 'Friend'
        } request successfully`
      );
      queryClient.invalidateQueries('fetch_notification');
    } catch (error) {
      toast.error('There was an error while accepting request');
      console.log('error while accepting friend request', error);
    }
  };

  return (
    <>
      {status !== 'Accepted' && (
        <div
          onClick={() => handleMarkasread(id)}
          className={`relative ${
            isPage ? 'w-full' : 'w-4/5'
          } flex flex-col gap-2 mt-1 py-3  p-1 cursor-pointer  `}
        >
          <>
            <div className="flex items-center justify-between gap-2">
              <Link
                to={`/user-profile/${friendUsername}`}
                className="flex items-center gap-2"
              >
                <img
                  src={
                    (profilePicture && profileImageUrl + profilePicture) ||
                    DefaultProfilePng
                  }
                  className="w-14 h-14 object-cover rounded-full"
                  alt=""
                />
                <div className=" flex  flex-1 ">
                  <h3 className="font-medium text-sm font-roboto">
                    {content}
                    <br />
                    <span className="font-normal text-xs text-gray-500 font-roboto">
                      {moment(sentDate).fromNow()}
                    </span>
                  </h3>
                </div>
              </Link>

              {isPage && (
                <div className="flex items-center text-gray-600 gap-2 pr-5">
                  <DeclineButton
                    width={'6rem'}
                    title={'Decline'}
                    onClick={handleDeleteFriendRequest}
                  />
                  <AcceptButton
                    width={'6rem'}
                    title={'Accept'}
                    onClick={handleAcceptFriendRequest}
                  />
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default FriendRequestSent;
