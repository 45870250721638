import { Link } from 'react-router-dom';
import Loader from '../../img/busy.gif';
import { useMutation, useQueryClient } from 'react-query';
import { markAllAsRead, markAsRead } from '../../services/routes/Notification';
import Notify from './Notify';

function NotificationModal({ isLoading, userNotifications }) {
  //
  const queryClient = useQueryClient();

  const { mutate } = useMutation(markAllAsRead, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetch_notification');
    },
    onError: err => {
      console.log(err);
    }
  });

  // passing this function to the each notification components and grabbing the id
  const handleMarkasread = async id => {
    try {
      await markAsRead({ id }).then(() => {
        queryClient.invalidateQueries('fetch_notification');
      });
    } catch (error) {}
  };

  return (
    <div
      className=" mt-4  w-80 rounded-md shadow-lg bg-white"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div
        className="pl-2 pr-4 py-3 overflow-y-scroll scrollbar-hide"
        style={{ maxHeight: '30rem' }}
      >
        {isLoading && (
          <div className="flex h-full w-full justify-center items-center">
            <img src={Loader} alt="" className="w-20 h-20 object-contain" />
          </div>
        )}
        <div className="p-1">
          <div className="w-full  flex justify-between items-center font-roboto">
            <strong>New</strong>

            <div className="text-sm flex text-gray-600  gap-1">
              <span
                className="cursor-pointer hover:text-gray-800 "
                onClick={() => mutate()}
              >
                Mark all as read
              </span>
              <p>|</p>
              <Link to="/notification" className="hover:text-gray-800">
                View All
              </Link>
            </div>
          </div>
          <>
            {userNotifications?.filter(
              notification => notification?.status !== 'seen'
            )?.length !== 0 ? (
              <>
                {
                  // Show only when there is new notification
                  userNotifications?.length !== 0 &&
                    userNotifications
                      ?.filter(notification => notification?.status !== 'seen')
                      ?.slice(0, 5)
                      ?.map(notification => (
                        <Notify
                          key={notification?.id}
                          handleMarkasread={handleMarkasread}
                          notification={notification}
                        />
                      ))
                }
              </>
            ) : (
              <p className="text-center my-5 text-gray-800">
                <b>No new notification to show</b>
              </p>
            )}
          </>

          {/* Break Line */}
          <div className="w-full  my-3 ">
            <hr />
          </div>

          {/* Only show notification which are seen */}
          {userNotifications?.length !== 0 &&
            userNotifications?.filter(
              notification => notification?.status === 'seen'
            )?.length !== 0 && (
              <>
                <strong>Previous</strong>
                {userNotifications
                  ?.filter(notification => notification?.status === 'seen')
                  ?.slice(0, 5)
                  ?.map(notification => (
                    <Notify
                      key={notification?.id}
                      handleMarkasread={handleMarkasread}
                      notification={notification}
                    />
                  ))}
              </>
            )}
        </div>

        {/* When there is no any notifications */}
        {userNotifications?.length === 0 && (
          <div className="min-w-full flex flex-col gap-2 justify-center items-center h-full">
            <span className="text-center text-gray-500 font-medium text-lg">
              You have no any notification.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationModal;
