import { ReactComponent as CloseSvg } from "../../../img/misc/close.svg";
import "./CustomModal.scss";

const CustomModal = ({ onCloseClick, title, children }) => {
  return (
    <div className="CustomModal  py-6 flex flex-col  justify-center sm:py-12">
      <div className="CustomModal-body  py-3 mx-auto">
        <div className="flex justify-between px-4 rounded-tl-lg rounded-tr-lg bg-brand-dark items-center py-2 bg-norm text-white text-lg">
          <p> {title}</p>
          <CloseSvg onClick={() => onCloseClick()} className="cursor-pointer" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
