import { useMutation, useQueryClient } from 'react-query';
import { deletePlayerList } from '../../services/routes/User';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import AcceptButton from '../Buttons/AcceptButton';
import DeclineButton from '../Buttons/DeclineButton';

const DeleteMyList = ({ onCloseClick, id }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deletePlayerList, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetch_myPlayerList');
      toast.success("You've successfully deleted a player list");
      onCloseClick();
    },

    onError: err => {
      toast.error('There was an error deleting the player list');
    }
  });

  const handleDelete = () => {
    mutate({ id });
  };

  return (
    <Modal title="Delete Player List" onCloseClick={onCloseClick}>
      <div className="w-full  py-5 px-5 bg-white">
        <div className="flex flex-wrap w-full">
          <p
            className="font-light font-roboto"
            style={{ color: '#58595B', fontSize: '16px' }}
          >
            Are you sure you want to delete this Player List?
          </p>
          <div className="w-full justify-center mt-8 items-center flex gap-6">
            <button className="mx-1 my-2 " onClick={handleDelete}>
              <AcceptButton title={isLoading ? 'Loading...' : 'YES'} />
            </button>
            <button className="mx-1 my-2 ">
              <DeclineButton title="NO" onClick={() => onCloseClick()} />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteMyList;
