import React from 'react';
import { ReactComponent as EditSvg } from '../../../../img/misc/edit.svg';
import FootballGroundPng from '../../../../img/player-profile/ground-field.png';
import MainPositionPng from '../../../../img/player-profile/main-pos.png';
import SecondaryPositionPng from '../../../../img/player-profile/sec-pos.png';
import { useSelector } from 'react-redux';
import './position.css';

function PlayerPosition({ setEditPositionModal, isEditable, positions }) {
  const getPlayerMainPositionCss = () => {
    switch (positions[0]?.poisiton?.slug) {
      case 'GK':
        return 'bottom-7 left-center';
      case 'CB':
        return 'bottom-12 left-center';
      case 'right-back':
        return 'bottom-14 right-12';
      case 'left-back':
        return 'bottom-14 left-12';
      case 'CM':
        return 'bottom-32 left-center';
      case 'CDM':
        return 'bottom-24 left-center';
      case 'CAM':
        return 'bottom-40 left-center';
      case 'CF':
        return 'top-6 left-center ';
      case 'SS':
        return 'top-12 left-32';
      case 'LWF':
        return 'top-12 left-12';
      case 'RWF':
        return 'top-12 right-12';
      default:
        break;
    }
  };
  const getPlayerSecondaryPositionCss = () => {
    switch (positions[0]?.poisiton?.slug) {
      case 'GK':
        return 'bottom-7 left-center';
      case 'CB':
        return 'bottom-12 left-center';
      case 'right-back':
        return 'bottom-14 right-12';
      case 'LB':
        return 'bottom-14 left-12';
      case 'CM':
        return 'bottom-32 left-center';
      case 'CDM':
        return 'bottom-24 left-center';
      case 'CAM':
        return 'bottom-36 left-center';
      case 'CF':
        return 'top-6 left-center ';
      case 'SS':
        return 'top-12 left-32';
      case 'LWF':
        return 'top-12 left-12';
      case 'RWF':
        return 'top-12 right-12';
      default:
        break;
    }
  };

  return (
    <div
      className="mt-6 w-full bg-white rounded-tr-lg rounded-tl-lg"
      style={{ height: '300px' }}
    >
      <div className="flex items-center justify-between h-11  rounded-t-lg  font-roboto text-brand-dark px-5">
        <p className="font-semibold text-brand-dark text-xl">Positions</p>
        <p className="flex items-center flex-col">
          {isEditable !== false && (
            <EditSvg
              className="w-22 h-22 cursor-pointer"
              onClick={() => setEditPositionModal(true)}
            />
          )}
        </p>
      </div>
      <div className="flex justify-between px-6 py-1 ">
        <div className="flex flex-col mt-4 gap-8">
          <span
            className="font-bold text-sm font-roboto"
            style={{ color: '##231F20' }}
          >
            Main Position
            {positions?.map((item, index) => {
              return (
                item.type === 'primary' && (
                  <p
                    key={index}
                    className="font-normal text-sm"
                    style={{ color: '##231F20' }}
                  >
                    {item?.position.name}
                  </p>
                )
              );
            })}
          </span>
          <span
            className="font-bold text-sm font-roboto whitespace-pre"
            style={{ color: '##231F20' }}
          >
            Secondary Position
            {positions?.map((item, index) => {
              return (
                item.type === 'secondary' && (
                  <p
                    key={index}
                    className="font-normal text-sm"
                    style={{ color: '##231F20' }}
                  >
                    {item?.position.name}
                  </p>
                )
              );
            })}
          </span>
        </div>
        <div
          className="my-2 relative"
          style={{ width: '250px', height: '230px' }}
        >
          <img
            src={FootballGroundPng}
            alt=""
            className="w-full h-full object-contain"
          />
          {positions && (
            <img
              src={MainPositionPng}
              className={`w-5 h-5 object-contain absolute ${getPlayerMainPositionCss()} `}
              alt=""
            />
          )}
          {positions && (
            <img
              src={SecondaryPositionPng}
              className={`w-5 h-5  absolute  ${getPlayerSecondaryPositionCss()}`}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PlayerPosition;
