import { useSelector } from 'react-redux';
import AgentProfile from '../Agent/AgentProfile';
import CoachProfile from '../Coach/CoachProfile';
import PlayerProfile from '../Player/PlayerProfile';
import ClubProfile from '../Club/ClubProfile';
import MainProfile from '../Player/MainProfile/MainProfile';
import SideNabWrapper from '../../pages/SideNavWrapper/';
import './userProfile.scss';

const User = () => {
  const user = useSelector(state => state.auth.user);

  return (
    <SideNabWrapper isFooter={true} width={'100%'}>
      <div className="w-full flex items-center justify-center">
        <div className="profile_container">
          <MainProfile />

          {user?.role === 'player' && <PlayerProfile />}
          {user?.role === 'agent' && <AgentProfile />}
          {user?.role === 'coach' && <CoachProfile />}
          {user?.role === 'club' && <ClubProfile />}
        </div>
      </div>
    </SideNabWrapper>
  );
};

export default User;
