import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import { ReactComponent as Edit } from '../../img/misc/edit.svg';
import { ReactComponent as CirclePlus } from '../../img/misc/circle-plus.svg';
import { Link } from 'react-router-dom';
import profileImageUrl from '../../config/config';

function PersonRequestCard({
  personType,
  type,
  setShowModalOpen,
  role,
  data,
  isEditable
}) {
  let userProfile;
  let userProfilePic;
  switch (role) {
    case 'coach':
      data = data?.coach;
      break;

    case 'player':
      data = data?.player;
      break;

    case 'agent':
      data = data?.agent;
      break;

    case 'club':
      data = data?.club;
      break;

    default:
      break;
  }

  switch (type) {
    case 'agent':
      userProfile = data?.agent?.User?.contact;
      userProfilePic = data?.agent?.User?.contact?.profile_image?.url;
      break;

    case 'club':
      userProfile = data?.club?.User?.contact;
      userProfilePic = data?.club?.User?.contact?.profile_image?.url;
      break;

    case 'coach':
      userProfile = data?.coach?.User?.contact;
      userProfilePic = data?.coach?.User?.contact?.profile_image?.url;
      break;

    default:
      break;
  }

  const handleAddPersonClub = () => {
    setShowModalOpen(true);
  };

  console.log(data);

  return (
    <div className="flex flex-col w-full mt-5">
      {/* pust at the right side */}
      <div className="flex justify-end w-full">
        <p className="flex items-end">
          {isEditable === true && (
            <>
              {userProfile && (
                <Edit
                  onClick={() => setShowModalOpen(true)}
                  className="ml-2 cursor-pointer w-5 h-6"
                />
              )}
            </>
          )}
        </p>
      </div>

      <div className="w-full flex-wrap flex">
        {userProfile && (
          <>
            <div className="flex w-full  items-center gap-4">
              <img
                src={
                  (userProfilePic && profileImageUrl + userProfilePic) ||
                  DefaultProfilePng
                }
                className="md:w-20 md:h-20 w-32 h-32 object-cover rounded-full"
                alt=""
              />

              <div className="flex flex-col items-center gap-1 font-roboto font-light text-base">
                <Link
                  to={`/user-profile/${userProfile?.username}`}
                  className="text-base font-medium font-roboto"
                >
                  {userProfile?.first_name + ' ' + userProfile?.last_name}
                </Link>
              </div>
            </div>
            <div className="flex flex-col w-full mt-10">
              <div className="flex justify-between p-4 bg-bright-color border-b-2 border-black border-opacity-25">
                <p className="w-full font-bold">Phone</p>
                <p className="w-full font-bold">Email</p>
                <p className="w-full font-bold">Company Name</p>
              </div>
              <div className="flex justify-between p-2">
                {console.log(userProfile)}
                <p className="w-full">{userProfile?.phone}</p>
                <p className="w-full">{userProfile?.email}</p>
                <p className="w-full">{userProfile?.phone}</p>
              </div>
            </div>
          </>
        )}

        {!userProfile && isEditable === true && (
          <div
            className="flex flex-col w-full justify-center"
            style={{ textAlign: 'center' }}
          >
            <CirclePlus
              onClick={handleAddPersonClub}
              className="mx-auto h-12 w-12 my-3 cursor-pointer grey-svg primary-svg"
            />
            <p className="text-normalGray text-base font-roboto">
              Add {personType}
            </p>
          </div>
        )}
        {!userProfile && isEditable === false && (
          <div className="w-full flex justify-center items-center h-full text-base text-gray-400 font-roboto">{`This ${role} doesn't have any ${personType}`}</div>
        )}
      </div>
    </div>
  );
}

export default PersonRequestCard;
