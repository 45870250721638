import AxiosService from "../../AxiosService";

export const addPlayerClubHistory = async data => {
  const response = await AxiosService.post("/player/club-history/", data);
  return response.data;
};

export const getAllPlayerClubHistory = async () => {
  const response = await AxiosService.get("/player/club-histories/");
  return response.data;
};

export const deletePlayerClubHistory = async ({ id }) => {
  const response = await AxiosService.delete(`/player/club-history/${id}`);
  return response.data;
};

export const editPlayerClubHistory = async ({ id, data }) => {
  const response = await AxiosService.put(`/player/club-history/${id}`, data);
  return response.data;
};
