import { useState } from 'react';
import { useQuery } from 'react-query';
import SuggestedEvent from './SuggestedEvent';
import { ReactComponent as ArrowupSvg } from '../../../img/activity/arrowUp.svg';
import { ReactComponent as ArrowdownSvg } from '../../../img/activity/arrowDown.svg';
import { ReactComponent as RefreshSvg } from '../../../img/activity/refresh.svg';
import { getRelatedEvents } from '../../../services/routes/User';
import SmallLoader from '../../../components/Loader/SmallLoader';

const RelatedEvents = () => {
  const { data: relatedEventsData, isLoading } = useQuery(
    'fetchRelatedEvents',
    getRelatedEvents,
    {
      retry: false
    }
  );

  const [showDetail, setShowDetail] = useState(true);

  return (
    <>
      {/* Controls Header */}
      <div
        className={
          showDetail
            ? 'mt-5 bg-white border  scrollbar-hide rounded-lg h-48 overflow-hidden  '
            : 'bg-white border rounded-lg  mt-5'
        }
        style={{ width: '250px' }}
      >
        <div className="flex  justify-between bg-brand-dark rounded-tr-lg rounded-tl-lg text-sm items-center px-3 py-2">
          <div className="flex items-center justify-center gap-3">
            <button className="w-5 h-5 font-bold">
              <RefreshSvg className="cursor-pointer right-widget" />
            </button>
            {showDetail ? (
              <ArrowupSvg
                onClick={() => setShowDetail(!showDetail)}
                className="cursor-pointer right-widget"
              />
            ) : (
              <ArrowdownSvg
                onClick={() => setShowDetail(!showDetail)}
                className="cursor-pointer right-widget "
              />
            )}
          </div>
          <h3 className="font-medium text-base text-white font-roboto">
            Related Events
          </h3>
        </div>

        {isLoading && <SmallLoader />}

        {showDetail && (
          <div className="px-3">
            {relatedEventsData?.data?.length !== 0 ? (
              <>
                {relatedEventsData?.data?.slice(0, 2)?.map((event, index) => (
                  <SuggestedEvent event={event} key={index} />
                ))}
              </>
            ) : (
              <div className="flex justify-center items-center h-32">
                <p className="text-base text-gray-400 font-roboto">
                  No Related Events
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RelatedEvents;
