import { useMutation, useQueryClient } from "react-query";
import {
  accessChatToGroup,
  removeMemberFromGroup
} from "../../../services/routes/chats";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useSelector } from "react-redux";

function ViewMemberOption({ onCloseClick, userId, currentUser }) {
  //
  //
  const queryClient = useQueryClient();
  const adminId = useSelector(state => state.userChats.groupChat.groupAdminId);

  const groupId = useSelector(state => state.userChats.groupChat.id);

  const me = useSelector(state => state.auth.user);
  const [isAdmin, setIsAdmin] = useState(false);

  const { mutate, isLoading } = useMutation(removeMemberFromGroup, {
    onSuccess: async () => {
      queryClient.invalidateQueries("fetch_allChats");
      await accessChatToGroup(groupId);
      onCloseClick();
    },
    onError: err => {
      console.log(err);
    }
  });

  //   Ensuring if current user is admin or not
  useEffect(() => {
    if (me?.id === adminId) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [adminId, isAdmin, me?.id]);

  const handleRemoveUser = async () => {
    mutate({ id: groupId, participants: [currentUser?.id] });
  };

  return (
    <div
      className="absolute right-12  top-14 max-w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div
        className="py-2 w-full text-sm px-2  text-gray-700  flex flex-col items-start gap-3"
        role="none"
      >
        <div className="block px-3 py-2 text-base  text-gray-600 hover:text-black">
          <Link
            to={`/user-profile/${currentUser?.contact?.username}`}
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {}}
          >
            <AccountCircleIcon
              className=""
              style={{ width: "2rem", height: "2rem" }}
            />
            View Profile
          </Link>
        </div>

        {/* Only show if user is Admin */}
        {isAdmin && (
          <div className="block px-3 py-2 text-base  text-gray-600 hover:text-black">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleRemoveUser}
            >
              <PersonRemoveIcon
                className=""
                style={{ width: "2rem", height: "2rem" }}
              />
              {isLoading ? "Removing..." : "Remove"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewMemberOption;
