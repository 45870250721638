import AxiosService from "../AxiosService";

export const deleteMessage = async ({ id, chatId }) => {
  const response = await AxiosService.delete(`/message/delete/${id}`, {
    data: { chatId: chatId }
  });
  return response.data;
};

// Single User
export const getAllChats = async () => {
  const response = await AxiosService.get("/chat/chats/");
  return response.data;
};

export const accessChatToUser = async id => {
  const response = await AxiosService.get(`/chat/room/${id}`);
  return response.data;
};

export const sendMessageToUser = async ({ id, data }) => {
  const response = await AxiosService.post(`/message/send/${id}`, data);
  return response.data;
};

export const getMessagesFromUser = async ({ id }) => {
  const response = await AxiosService.get(`/message/messages/${id}`);
  return response.data;
};

// Group Chat
export const createGroupChat = async data => {
  const response = await AxiosService.post("/group-chat/room", data);
  return response.data;
};

export const accessChatToGroup = async id => {
  const response = await AxiosService.get(`/group-chat/room/${id}`);
  return response.data;
};

export const addNewMemberToGroup = async ({ id, data }) => {
  const response = await AxiosService.put(
    `/group-chat/room/${id}/add-members/`,
    { participants: data }
  );
  return response.data;
};

export const removeMemberFromGroup = async ({ id, participants }) => {
  const response = await AxiosService.delete(
    `/group-chat/room/${id}/remove-members`,
    {
      data: {
        participants: participants
      }
    }
  );
  return response.data;
};

export const leaveGroupChat = async id => {
  const response = await AxiosService.delete(`/group-chat/room/${id}/leave`);
  return response.data;
};
export const deleteGroupChat = async ({ id, data }) => {
  const response = await AxiosService.delete(`/group-chat/room/${id}/`, data);
  return response.data;
};
