import { useEffect } from 'react';

function useClickOutside(refs, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        Array.isArray(refs) &&
        refs.every(ref => ref && !ref.current?.contains(event.target))
      ) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback]);
}

export default useClickOutside;
