import ContactPng from '../../../../img/misc/contacts.png';
import PublicPng from '../../../../img/misc/public.png';
import SendMessagePng from '../../../../img/misc/send-message.png';

function SharePostOption({ setShowSharePost, setsharedCount }) {
  const onSharedHandleClick = () => {
    setShowSharePost(false);
    setsharedCount(prevCount => prevCount + 1);
  };

  return (
    <div
      className=" mt-0 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="py-1" role="none">
        <div
          className="block px-3 py-2 text-sm  text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
        >
          <div
            className="flex items-center gap-2 cursor-pointer "
            onClick={onSharedHandleClick}
          >
            <img src={PublicPng} className="w-6 h-6 object-contain " alt="" />
            Share (Publlic)
          </div>
        </div>
        <div
          className="block px-3 py-2 text-sm  text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
        >
          <div
            className="flex items-center gap-2 cursor-pointer "
            onClick={onSharedHandleClick}
          >
            <img src={ContactPng} className="w-6 h-6 object-contain " alt="" />
            Share (Contacts)
          </div>
        </div>
        <div
          className="block px-3 py-2 text-sm  text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
        >
          <div
            className="flex items-center gap-2 cursor-pointer "
            onClick={onSharedHandleClick}
          >
            <img
              src={SendMessagePng}
              className="w-6 h-6 object-contain "
              alt=""
            />
            Send by message
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharePostOption;
