import HidePostSvg from '../../../../img/misc/hide_post.svg';
import EditSvg from '../../../../img/misc/edit.svg';
import DeleteSvg from '../../../../img/misc/delete.svg';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function MyPostOption({
  id,
  setShowPostOption,
  onDeletePostClick,
  onEditPostClick,
  post
}) {
  //
  //

  const me = useSelector(state => state.auth.user);

  const [postOption, setPostOption] = useState(false);

  useEffect(() => {
    if (me?.id !== post?.author?.id) {
      setPostOption(true);
    } else {
      setPostOption(false);
    }
  }, [me, post?.author?.id]);

  return (
    <div
      className="w-52 max-h-full  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
      style={{ zIndex: '9999' }}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      {/*  */}
      <div className="w-full flex flex-col text-sm ">
        <div
          className="flex items-center gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer primary-svg"
          onClick={() => setShowPostOption(true)}
        >
          <img className="w-5 h-5 object-contain" src={HidePostSvg} alt="" />
          Hide Post
        </div>

        {!postOption && (
          <>
            <div
              className="flex items-center gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer primary-svg"
              onClick={() => {
                onEditPostClick(post);
              }}
            >
              <img className="w-5 h-5 object-contain " src={EditSvg} alt="" />
              Edit Post
            </div>
            <div
              className="flex items-center gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer primary-svg rounded-b-md"
              onClick={() => {
                onDeletePostClick(id);
              }}
            >
              <img className="w-5 h-5 object-contain " src={DeleteSvg} alt="" />
              Delete Post
            </div>
          </>
        )}
      </div>
      {/*  */}
    </div>
  );
}

export default MyPostOption;
