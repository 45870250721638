import { useState, useEffect } from 'react';
import ProfileSideNav from './ProfileSideNav';
import Footer from '../../components/Footer';
import classNames from 'classnames';
import AuthenticatedHeader from '../../components/AuthenticatedHeader/AuthenticatedHeader';
import Analytics from '../Player/PlayerAnalytics';

import './SideNavWrapper.css';

const SideNavWrapper = ({
  width,
  children,
  showAnalytics,
  isFooter = false
}) => {
  //
  //
  const [isUserPage, setIsUserPage] = useState(false);
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    var query = window.location.search.substring(1);
    if (query !== '' && query.split('=')[0] !== 'name') {
      setIsUserPage(true);
    }

    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  const leftMargin = () => {
    //
    //
    let mainArea = document.querySelector('.MainProfile');
    let sideNavBar = document.querySelector('.ProfileSideNav');

    if (!mainArea || !sideNavBar) return;
    //Navbar comes from right. so width of left margin is reduced from calculation
    let totalDiff =
      windowWidth - (mainArea.offsetWidth + Math.max(mainArea.offsetLeft, 0));
    let sideNavBarWidth = sideNavBar.offsetWidth;
    let margin = '0px';
    if (totalDiff < sideNavBarWidth && isSideNavOpen) {
      margin = `${totalDiff - sideNavBarWidth - 40}px`;
    }
    return margin;
  };

  return (
    <div id="outer-container">
      <ProfileSideNav
        windowWidth={windowWidth}
        isOpen={isSideNavOpen}
        setSideNavOpen={setSideNavOpen}
      />

      <main
        id="page-wrap"
        className="min-h-screen flex flex-col justify-between"
        style={{
          position: 'relative',
          left: leftMargin()
        }}
      >
        <div id="page-wrap-inner">
          <AuthenticatedHeader
            setSideNavOpen={setSideNavOpen}
            isOpen={isSideNavOpen}
          />
          <div className="mb-20"></div>
          <div
            className={classNames({
              'SideNavWrapper-main-area': true
            })}
          >
            <div style={{ width: width }}>{children}</div>
          </div>
        </div>
        {!isFooter && <Footer noMargin={true} />}
      </main>
    </div>
  );
};

export default SideNavWrapper;
