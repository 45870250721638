import { ReactComponent as DeleteContactSvg } from '../../../img/misc/deleteContact.svg';
import { ReactComponent as MessageContactSvg } from '../../../img/misc/messageContact.svg';
import Username from '../../../components/Username/Username';
import DefaultProfilePng from '../../../img/misc/Default_pfp.png';
import profileImageUrl from '../../../config/config';

function UserContact({
  user,
  profilePicture,
  onClickDeleteContact,
  onClickMessageContact
}) {
  return (
    <div className="flex items-center md:w-60 w-52  justify-between  ">
      <img
        src={
          (profilePicture && profileImageUrl + profilePicture) ||
          DefaultProfilePng
        }
        className="md:w-14 md:h-14 w-12 h-12  object-cover rounded-full"
        alt=""
      />
      <div className="relative flex flex-col text-gray-600">
        <Username user={user} />
        <span className="md:text-sm text-xs text-gray-500 font-roboto font-light">
          {user?.contact?.username}
        </span>
        <p className="md:text-sm text-xs text-gray-500 font-light">
          {user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1) || 'User'}
        </p>
      </div>

      <div className="flex flex-col ">
        <button className="md:w-6 md:h-6 w-5 h-5">
          <MessageContactSvg
            className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 "
            onClick={() => onClickMessageContact(user?.id)}
          />
        </button>
        <button className="md:w-6 md:h-6 w-5 h-5">
          <DeleteContactSvg
            className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 "
            onClick={() => onClickDeleteContact(user?.id)}
          />
        </button>
      </div>
    </div>
  );
}

export default UserContact;
