import React, { useState } from 'react';
import { ReactComponent as SendMessage } from '../../img/messaging/sendmessage.svg';
import { ReactComponent as CreateGroup } from '../../img/messaging/creategroup.svg';
import { ReactComponent as Archive } from '../../img/messaging/archive.svg';
import MessageModal from './Modal/MessageModal/MessageModal';
import GroupModal from './Modal/GroupModal/GroupModal';
import './userMessage.scss';

const navbarItem = (Icon, title, onClick) => {
  return (
    <div className="flex flex-col items-center gap-1 ">
      <Icon className="primary-svg navbar-icon" onClick={onClick} />
      <label className="font-roboto text-sm font-light text-center  ">
        {title}
      </label>
    </div>
  );
};

const Navbar = () => {
  const [messageModal, setMessageModal] = useState(false);
  const [groupModal, setGroupModal] = useState(false);

  return (
    <>
      {messageModal && (
        <MessageModal onCloseClick={() => setMessageModal(false)} />
      )}
      {groupModal && <GroupModal onCloseClick={() => setGroupModal(false)} />}

      <div className="navbar flex gap-6 items-center justify-center">
        {navbarItem(SendMessage, 'New Message', () => setMessageModal(true))}

        {navbarItem(CreateGroup, 'New Group Chat', () => setGroupModal(true))}

        {navbarItem(Archive, 'Archived Message')}
      </div>
    </>
  );
};

export default Navbar;
