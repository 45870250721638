import MessageNotification from '../NotificationModal/MessageNotification';
import { Link } from 'react-router-dom';
import Loader from '../../img/busy.gif';
import NotificationOption from '../NotificationOption/NotificationOption';
import ChatIcon from '@mui/icons-material/Chat';
import AcceptButton from '../Buttons/AcceptButton';
import ConversationMessageModal from '../UserMessages/Modal/MessageModal/MessageModal';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { socket } from '../SocketIo';

function MessageModal({ isLoading, userNotifications, isError }) {
  //

  const history = useHistory();
  const [showConversationModal, setShowConversationModal] = useState(false);

  return (
    <>
      {showConversationModal && (
        <ConversationMessageModal
          onCloseClick={() => setShowConversationModal(false)}
        />
      )}

      <div
        className="mt-4 w-72 rounded-md shadow-lg bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none z-100"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div
          className="pl-2 pr-4 py-3 overflow-y-scroll scrollbar-hide"
          style={{ height: '25rem' }}
        >
          {isLoading && (
            <div className="flex h-full w-full justify-center items-center">
              <img src={Loader} alt="" className="w-20 h-20 object-contain" />
            </div>
          )}

          <div className="p-1">
            {userNotifications?.map((notification, index) => (
              <>
                <div
                  key={index}
                  className="relative min-w-full   hover:bg-gray-200 rounded-md"
                  onClick={() => {
                    // grab the chatId and join the chat by emitting the joinChat event using socket
                    socket.emit('joinChat', {
                      chatId: notification?.messages[0]?.chatId
                    });
                    history.push('/message');
                  }}
                >
                  <MessageNotification
                    key={notification?.id}
                    content={
                      notification?.messages[notification?.messages?.length - 1]
                        ?.content || 'Start a conversation'
                    }
                    userParticipants={notification?.participants}
                    messagedDate={
                      notification?.messages[notification?.messages?.length - 1]
                        ?.createdAt
                    }
                    sender={
                      notification?.messages[notification?.messages?.length - 1]
                        ?.sender
                    }
                  />
                  <NotificationOption
                    id={
                      notification?.messages[notification?.messages?.length - 1]
                        ?.id
                    }
                    chatId={
                      notification?.messages[notification?.messages?.length - 1]
                        ?.chatId
                    }
                    messageNotification={true}
                  />
                </div>
              </>
            ))}

            {/* If there is no new message, show this: */}
            {isError && (
              <>
                <div className="min-w-full flex flex-col gap-2 justify-center items-center h-full">
                  <ChatIcon
                    className="text-gray-600"
                    style={{ width: '12rem', height: '12rem' }}
                  />
                  <span className="text-center text-gray-500 font-medium text-lg">
                    You have no new message.
                  </span>
                  <AcceptButton
                    title={'Send Message'}
                    onClick={() => {
                      setShowConversationModal(true);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="w-full px-5 py-1 flex justify-between items-center font-roboto">
          {userNotifications?.map((notification, index) => (
            <div key={index}>
              {notification?.title === 'New Message' && (
                <span className="cursor-pointer text-gray-400 hover:text-gray-600">
                  Mark all as read
                </span>
              )}
            </div>
          ))}

          {/* View all Message Notificaiton */}
          <Link to="/message" className=" text-gray-400 hover:text-gray-600 ">
            View All
          </Link>
        </div>
      </div>
    </>
  );
}

export default MessageModal;
