import Post from './Post';

const Posts = ({ post, feedPage }) => {
  return (
    <>
      {post?.data
        ?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .map((post, index) => (
          <Post key={index} post={post} feedPage={feedPage} />
        ))}
    </>
  );
};

export default Posts;
