import Message from './Message';
import React, { useEffect, useRef, useState } from 'react';
import { getMessagesFromUser } from '../../services/routes/chats';
import { useDispatch, useSelector } from 'react-redux';
import { setMessages } from '../../redux/userChats/UserChats';
import { socket } from '../SocketIo';
import './userMessage.scss';

const Messages = () => {
  //
  const dispatch = useDispatch();
  const messages = useSelector(state => state.userChats.messages);

  const [typingData, setTypingData] = useState(null);

  const lastMessageRef = useRef(null);

  const friend = useSelector(state => state.userChats.userInfo);

  const groupChat = useSelector(state => state.userChats.groupChat);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        let id;

        if (groupChat?.isGroupChat === true) {
          id = groupChat?.id;
        } else {
          id = friend?.id;
        }
        if (id) {
          const messages = await getMessagesFromUser({ id });
          dispatch(setMessages(messages?.data));
        }
      } catch (error) {
        // Handle error here
      }
    };

    fetchMessages();
  }, [dispatch, friend?.id, groupChat?.id, groupChat?.isGroupChat]);

  useEffect(() => {
    socket.on('newMessage', data => {
      dispatch(setMessages([...messages, data]));
    });
  }, [messages, dispatch]);

  // Auto-scroll feature
  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Checking who is user typing
  useEffect(() => {
    socket.on('startTyping', data => {
      if (friend?.participants?.some(user => user?.id === data?.user?.id)) {
        setTypingData(data);
      } else if (
        groupChat?.participants?.some(user => user?.id === data?.user?.id)
      ) {
        setTypingData(data);
      } else {
        setTypingData(null);
      }
    });

    socket.on('stopTyping', data => {
      if (friend?.participants?.some(user => user?.id === data?.user?.id)) {
        setTypingData(null);
      } else if (
        groupChat?.participants?.some(user => user?.id === data?.user?.id)
      ) {
        setTypingData(null);
      } else {
        setTypingData(null);
      }
    });
  }, [socket, friend?.id, groupChat?.id]);

  return (
    <div className="messages overflow-scroll scrollbar-hide">
      {messages?.map((message, index) => (
        <>
          <Message
            key={message?.id}
            message={message}
            lastMessageRef={lastMessageRef}
          />
          {
            // show seen only the last message seen by the user
            message?.seenBy?.some(
              user => user?.userId !== message?.sender?.senderId
            ) &&
              messages[index + 1]?.seenBy?.length === 0 && (
                <p className="text-right">Seen</p>
              )
          }
        </>
      ))}
      {messages[messages?.length - 1]?.seenBy?.some(
        user =>
          user?.userId !== messages[messages?.length - 1]?.sender?.senderId
      ) && <p className="text-right">Seen</p>}
      {typingData && (
        <p className="absolute bottom-14 italic ml-1 animate-pulse text-gray-500 text-sm font-roboto font-normal">
          {`${typingData?.user?.contact?.first_name} is typing...`}
        </p>
      )}
    </div>
  );
};

export default Messages;
