import React, { useState } from 'react';
import Checkbox from 'rc-checkbox';

const NotifyTeamPlayers = ({ teamPlayers }) => {
  const [selectedNotifyPlayers, setSelectedNotifyPlayers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(teamPlayers?.length / 15);
  const startIndex = (currentPage - 1) * 15;
  const endIndex = startIndex + 15;

  const selectAllPlayers = () => {
    const allPlayerSelected =
      teamPlayers.length === selectedNotifyPlayers.length;

    const newPlayerSelected = allPlayerSelected
      ? []
      : teamPlayers.map(player => player.value);

    setSelectedNotifyPlayers(newPlayerSelected);
  };

  const selectNotifyPlayers = player => {
    const isPlayerSelected = selectedNotifyPlayers.includes(player.value);

    if (isPlayerSelected) {
      const newPlayerSelected = selectedNotifyPlayers.filter(
        id => id !== player.value
      );
      setSelectedNotifyPlayers(newPlayerSelected);
    } else {
      setSelectedNotifyPlayers([...selectedNotifyPlayers, player.value]);
    }
  };

  console.log('selectedNotifyPlayers', selectedNotifyPlayers);

  return (
    <div className="w-full p-5 ">
      <label
        className="text-base font-normal text-gray-700 font-roboto mb-2"
        htmlFor=""
      >
        Team Members
      </label>
      <div className="flex items-center  gap-2 ">
        <Checkbox
          className="rounded-md border-transparent"
          style={{ border: 'transparent' }}
          onChange={() => selectAllPlayers()}
        />
        <p className="text-gray-700 font-light font-roboto">Select All</p>
      </div>

      <div className=" w-full grid grid-cols-3 gap-x-12 gap-y-6 my-2">
        {teamPlayers?.slice(startIndex, endIndex).map(player => (
          <>
            <div
              key={player.value}
              className="flex items-center  gap-2 justify-center "
            >
              <Checkbox
                className="rounded-md border-transparent"
                style={{ border: 'transparent' }}
                onClick={() => selectNotifyPlayers(player)}
                checked={selectedNotifyPlayers.includes(player.value)}
              />
              <p className="text-gray-700 font-light font-roboto">
                {player.label}
              </p>
            </div>
          </>
        ))}
      </div>
      <div className="w-full mt-2 flex justify-center ">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            type="button"
            key={index + 1}
            onClick={() => setCurrentPage(index + 1)}
            className={`flex items-center justify-center font-medium mr-3 ${
              currentPage === index + 1
                ? 'w-6 h-6  bg-brand-dark rounded-full text-white  '
                : 'text-brand-light'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NotifyTeamPlayers;
