import { Link } from 'react-router-dom';
import ProfileModal from '../Profile/ProfileModal';
import { useState } from 'react';
import SendMessageModal from '../../pages/Contact/SendMessageModal';
import { setGroupChats, setUserChat } from '../../redux/userChats/UserChats';
import { accessChatToUser } from '../../services/routes/chats';
import { useDispatch } from 'react-redux';
import { socket } from '../SocketIo';
import Popup from 'reactjs-popup';

const Username = ({ user }) => {
  const dispatch = useDispatch();
  const [newConversation, setNewConversation] = useState(false);

  const startChat = async () => {
    try {
      setNewConversation(true);
      dispatch(setGroupChats(null));
      await accessChatToUser(user?.id).then(data => {
        dispatch(setUserChat(data?.data));
        socket.emit('joinChat', { chatId: data?.data?.id });
      });
    } catch (error) {}
  };

  return (
    <>
      {newConversation && (
        <SendMessageModal
          chatId={user?.id}
          onCloseClick={() => setNewConversation(false)}
        />
      )}

      <div className="relative">
        <Popup
          trigger={() => (
            <Link
              to={`/user-profile/${user?.contact?.username}`}
              className="text-gray-700 font-semibold max-w-max text-sm hover:underline  "
            >
              {user?.contact?.first_name + ' ' + user?.contact?.last_name}
            </Link>
          )}
          position={['bottom center', 'top center']}
          closeOnDocumentClick
          closeOnEscape
          on={['hover', 'focus']}
          arrow={true}
        >
          <ProfileModal user={user} startChat={startChat} />
        </Popup>
      </div>
    </>
  );
};

export default Username;
