import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { ReactComponent as EditSvg } from '../../img/misc/edit.svg';
import { ReactComponent as DeleteSvg } from '../../img/misc/delete.svg';
import moment from 'moment';
import './MainTable.scss';

function Table({ columns, data, onRowClick }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table
        {...getTableProps()}
        style={{
          width: '500px',
          borderCollapse: 'collapse',
          overflow: 'scroll',
          display: 'block',
          whiteSpace: 'nowrap'
        }}
        className="table"
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const HeaderDiv = (name, align) => {
  return (
    <div
      style={{
        textAlign: align,
        paddingLeft: '5px'
      }}
    >
      {name}
    </div>
  );
};

const DeleteCell = (id, align, setDeleteId) => {
  return (
    <div style={{ textAlign: align }}>
      <DeleteSvg
        onClick={e => {
          setDeleteId(id);
        }}
        className="primary-svg w-4 h-4"
      />
    </div>
  );
};

const EditCell = (id, data, align, setEditData) => {
  return (
    <div style={{ textAlign: align }}>
      <EditSvg
        onClick={e => {
          setEditData(data);
        }}
        className="primary-svg w-4 h-4"
      />
    </div>
  );
};

const HeaderCell = (row, align, type) => {
  if (type === 'date') {
    return (
      <div style={{ textAlign: align, font: ' Roboto 200', fontWeight: '300' }}>
        {moment(row.value).format('YYYY-MM-DD')}
      </div>
    );
  }

  return (
    <div
      style={{
        textAlign: align,
        paddingLeft: '5px'
      }}
    >
      {row.value}
    </div>
  );
};

function SeasonHistoryTable({
  histories,
  isEditable,
  editHistory,
  deleteHistory
}) {
  const columns = React.useMemo(() => [
    {
      accessor: 'seasonFrom',
      Header: row => HeaderDiv('Season From', 'left'),
      Cell: row => HeaderCell(new Date(row).getFullYear(), 'left', 'date')
    },
    {
      accessor: 'seasonTo',
      Header: row => HeaderDiv('Season To', 'left'),
      Cell: row => HeaderCell(new Date(row).getFullYear(), 'left', 'date')
    },
    {
      accessor: 'games',
      Header: row => HeaderDiv('Games', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'wins',
      Header: row => HeaderDiv('Wins', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'losses',
      Header: row => HeaderDiv('Losses', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'draws',
      Header: row => HeaderDiv('Draws', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'goals',
      Header: row => HeaderDiv('Goals', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'goalsConceded',
      Header: row => HeaderDiv('Goals Conceded', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      id: 'edit',
      accessor: 'id',
      Header: row => HeaderDiv('', 'left'),
      Cell: row =>
        isEditable && EditCell(row.value, row.row.values, 'center', editHistory)
    },
    {
      id: 'delete',
      accessor: 'id',
      Header: row => HeaderDiv('', 'left'),
      Cell: row => isEditable && DeleteCell(row.value, 'center', deleteHistory)
    }
  ]);

  return (
    <div className="VisitHistoryTable-MainTable w-96 md:w-full overflow-scroll">
      <Table columns={columns} data={histories} />
    </div>
  );
}

export default SeasonHistoryTable;
