import React from 'react';
import { ReactComponent as EditSvg } from '../../../../img/misc/edit.svg';
import InfoSetting from '../../../../components/InfoSetting/InfoSetting';
import moment from 'moment';
import profileImageUrl from '../../../../config/config';

function PlayerInfo({ setEditPlayerInfoModalOpen, isEditable, data }) {
  const user = data?.contact;
  const agent = data?.player?.agent?.User?.contact;
  const club = data?.player?.club?.User?.contact;
  const contract = data?.player?.contract_until;

  console.log(data);

  const contract_until = moment(contract).format('YYYY/MM/DD');

  return (
    <div className="w-full">
      <div className="flex justify-between items-end h-11  rounded-t-lg  font-roboto">
        <p className="flex items-end flex-col w-full">
          {isEditable !== false && (
            <EditSvg
              className="w-5 h-6 cursor-pointer"
              onClick={() => setEditPlayerInfoModalOpen(true)}
            />
          )}
        </p>
      </div>
      <div
        className="flex justify-around divide-x-2 divide-brandGray w-full"
        style={{ height: '80%' }}
      >
        <div className="flex flex-col gap-y-5 mr-12">
          <InfoSetting title="Nationality" value={user?.country} />
          <InfoSetting
            title="Date of Birth"
            value={moment(user?.birth_date).format('MM/DD/YYYY')}
          />
          <InfoSetting title="Gender" value={user?.gender} />
        </div>
        <div className="flex flex-col gap-y-5  px-6">
          <InfoSetting
            title="Height"
            value={
              data?.player?.height
                ? data?.player?.height
                : 'Not Specified'
            }
          />
          <InfoSetting
            title="Best Foot"
            value={
              data?.player?.best_foot
                ? data?.player?.best_foot
                : 'Not Specified'
            }
          />
          <InfoSetting
            title="Agent"
            value={agent ? agent?.first_name + ' ' + agent?.last_name : 'NONE'}
          />
        </div>
        <div className="flex flex-col gap-y-5 px-6">
          <InfoSetting
            title="Current Club"
            // value={club ? club?.first_name + ' ' + club?.last_name : 'NONE'}
          />
          <img
            src={`${profileImageUrl + club?.profile_image?.url}`}
            alt=""
            className="w-16 h-16 rounded-full text-center"
          />
          <InfoSetting
            title="Contract Until"
            value={contract ? contract_until : 'Not Signed Yet'}
          />
        </div>
      </div>
    </div>
  );
}

export default PlayerInfo;
