import { useQuery } from 'react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideNavWrapper from '../SideNavWrapper';
import GameEventDetails from './GameDetails/GameDetails';
import AddGameModal from './AddGameModal/AddGameModal';
import Calendar from './CalendarTemplate/CalendarTemplate';
import AddEventModal from './AddEventModal/AddEventModal';
import { setEventDetail, setGameDetail } from '../../redux/schedule/gameDetail';
import { getAllGames } from '../../services/routes/Schedule/Games/gameOwner';
import { getAllEvents } from '../../services/routes/Schedule/Events/eventOwner';
import { ReactComponent as CirclePlus } from '../../img/misc/circle-plus.svg';
import './Schedule.scss';

const Schedule = () => {
  //
  //
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const [addEventModalOpen, setAddEventModalOpen] = useState(false);
  const [addGameModalOpen, setAddGameModalOpen] = useState(false);

  // for both coach and club
  const {
    data: allGamesData,
    isLoading: gamesLoading,
    refetch: refetchGame
  } = useQuery('fetch_allGames', getAllGames, {
    onSuccess: data => {
      // Adding the Upcoming Game to the Redux

      const findLatestGame = data?.data
        ?.filter(
          game =>
            new Date(game?.scheduledDate) <= new Date() ||
            new Date(game?.scheduledDate) >= new Date()
        )
        .sort(
          (a, b) => new Date(b?.scheduledDate) - new Date(a?.scheduledDate)
        );

      const upcomingGame = findLatestGame[0];
      if (upcomingGame) {
        const gamesOnSameDate = findLatestGame.filter(
          game => game.scheduledDate === upcomingGame.scheduledDate
        );

        dispatch(setGameDetail(gamesOnSameDate));
      }
    }
  });

  const {
    data: allEventsData,
    isLoading: eventsLoading,
    refetch: refetchEvent
  } = useQuery('fetch_allEvents', getAllEvents, {
    onSuccess: data => {
      // Adding the Upcoming Game to the Redux for Coach and Club

      const findLatestEvent = data?.data
        ?.filter(
          event =>
            new Date(event?.scheduledDate) <= new Date() ||
            new Date(event?.scheduledDate) >= new Date()
        )
        .sort(
          (a, b) => new Date(b?.scheduledDate) - new Date(a?.scheduledDate)
        );

      const upcomingEvent = findLatestEvent[0];

      if (upcomingEvent) {
        const eventsOnSameDate = findLatestEvent.filter(
          event => event.scheduledDate === upcomingEvent.scheduledDate
        );
        dispatch(setEventDetail(eventsOnSameDate));
      }
    }
  });

  //custom styles for react-select components
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 25,
      padding: 0,
      margin: 'auto 0'
    }),
    option: (styles, state) => ({
      ...styles,
      // height: 25,
      padding: 0,
      margin: 'auto 0'
    }),
    input: base => ({
      ...base,
      padding: 0,
      margin: 'auto 0'
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  return (
    <SideNavWrapper isFooter={true} width={'100%'}>
      {addEventModalOpen && (
        <AddEventModal
          onCloseClick={() => setAddEventModalOpen(false)}
          customStyles={customStyles}
          refetch={refetchEvent}
        />
      )}

      {addGameModalOpen && (
        <AddGameModal
          onCloseClick={() => setAddGameModalOpen(false)}
          customStyles={customStyles}
          refetch={refetchGame}
        />
      )}

      {/* {user?.role === 'player' && (gamesLoading || eventsLoading) && (
        <LargeLoader />
      )} */}

      {/* Show only user role is Coach and club */}
      <div className="w-full flex justify-center">
        <div className="schedule_container flex  md:flex-row flex-col gap-4 ">
          {/* Calendar */}
          <div className=" flex items-center flex-col md:w-1/3 w-full ">
            <Calendar
              gamesData={allGamesData?.data}
              eventsData={allEventsData?.data}
            />
            <div className=" relative w-full  bg-white rounded-b-lg text-lg font-roboto  ">
              <div
                className={`flex items-center px-6 text-sm text-gray-600 ${
                  user?.role === 'player' && 'mb-3'
                }`}
              >
                <span className="w-3 h-3 rounded-full bg-brand-light mr-2" />
                Game
                <span
                  className="w-3 h-3 rounded-full ml-6 mr-2"
                  style={{ backgroundColor: '#5A6B34' }}
                />
                Event
              </div>

              {/* Create Game and Create Event Option */}
              {user?.role !== 'player' && (
                <div className="flex w-full  gap-5 px-6 my-6">
                  {/* Create Game */}

                  {['coach', 'club'].includes(user?.role) && (
                    <div
                      onClick={() => setAddGameModalOpen(true)}
                      className="w-1/2 flex justify-center gap-2 items-center bg-brand-dark rounded-lg text-white py-1 px-5 text-sm  "
                    >
                      <p className="font-semibold whitespace-pre ">
                        Create Game
                      </p>
                      <CirclePlus className="  cursor-pointer w-4 h-5 primary-svg" />
                    </div>
                  )}

                  {/* Create Event */}

                  <div
                    onClick={() => setAddEventModalOpen(true)}
                    className="w-1/2 flex justify-center gap-2 items-center  rounded-lg text-white  py-1 px-5 text-sm "
                    style={{ backgroundColor: '#5A6B34' }}
                  >
                    <p className="font-semibold whitespace-pre ">
                      Create Event
                    </p>
                    <CirclePlus className="cursor-pointer w-4 h-5 white-svg" />
                  </div>
                </div>
              )}
            </div>
          </div>

          <GameEventDetails
            customStyles={customStyles}
            refetchGame={refetchGame}
            refetchEvent={refetchEvent}
            onClickCreateGame={() => setAddGameModalOpen(true)}
            onClickCreateEvent={() => setAddEventModalOpen(true)}
          />
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default Schedule;
