/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import { useTable, useSortBy } from 'react-table';
import { ReactComponent as EditSvg } from '../../../img/misc/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../img/misc/delete.svg';
import DefaultProfilePng from '../../../img/player-profile/default-profile.png';
import './CoachMainTable.scss';

function Table({ columns, data, onRowClick, role }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table
        {...getTableProps()}
        style={{
          width: '500px',
          borderCollapse: 'collapse',
          overflow: 'scroll',
          display: 'block',
          whiteSpace: 'nowrap'
        }}
        className="table"
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const HeaderDiv = (name, align) => {
  return (
    <div
      style={{
        textAlign: align
      }}
    >
      {name}
    </div>
  );
};

const DeleteCell = (id, align, setCoachDeleteId) => {
  return (
    <div style={{ textAlign: align }}>
      <DeleteSvg
        onClick={() => {
          setCoachDeleteId(id);
        }}
        className="primary-svg w-5 h-5"
      />
    </div>
  );
};

const EditCell = (id, data, align, setHistory) => {
  return (
    <div style={{ textAlign: align }}>
      <EditSvg
        onClick={() => {
          setHistory(data);
        }}
        className="primary-svg w-5 h-5"
      />
    </div>
  );
};

const HeaderCell = (row, align, type) => {
  if (type === 'date') {
    return (
      <div style={{ textAlign: align }}>
        {moment(row.value).format('YYYY-MM-DD')}
      </div>
    );
  }
  return <div style={{ textAlign: align }}>{row.value}</div>;
};

const ImgCell = row => {
  return (
    <>
      <img
        alt=""
        className="img-user-agenthistory rounded-full h-12 w-12"
        src={row.row.original.imgPath || DefaultProfilePng}
      />
    </>
  );
};

function CoachHistoryTable({
  histories,
  isEditable,
  editHistory,
  deleteHistory
}) {
  const columns = React.useMemo(() => [
    {
      accessor: 'imgPath',
      Header: row => HeaderDiv('', 'left'),
      Cell: row => ImgCell(row, 'right')
    },
    {
      accessor: 'coachName',
      Header: row => HeaderDiv('Name', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'coachPhone',
      Header: row => HeaderDiv('Phone ', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'coachEmail',
      Header: row => HeaderDiv('Email', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'coachClub',
      Header: row => HeaderDiv('Current Club', 'left'),
      cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'coachType',
      Header: row => HeaderDiv('Position', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },

    {
      id: 'edit',
      accessor: 'id',
      Header: row => HeaderDiv('', 'left'),
      Cell: row =>
        isEditable === true &&
        EditCell(row.value, row.row.values, 'center', editHistory)
    },
    {
      id: 'delete',
      accessor: 'id',
      Header: row => HeaderDiv('', 'left'),
      Cell: row =>
        isEditable === true && DeleteCell(row.value, 'center', deleteHistory)
    }
  ]);

  return (
    <div className="VisitHistoryTable-MainTable w-96 md:w-full overflow-scroll md:overflow-hidden">
      <Table columns={columns} data={histories} />
    </div>
  );
}

export default CoachHistoryTable;
