import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Modal from "../Modal/Modal";
import AcceptButton from "../Buttons/AcceptButton";
import { fetchUser } from "../../redux/userSlice";
import { addAwards } from "../../services/routes/Awards";

const AddAwardModal = ({ onCloseClick }) => {
  //
  //
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({});
  const uploadInputEl = useRef(null);

  const { mutate } = useMutation(addAwards, {
    onSuccess: () => {
      dispatch(fetchUser());
      onCloseClick();
    },
    onError: error => {
      toast.error(error.response.data.message);
    }
  });

  const onSubmit = async data => {
    const newData = {
      ...data

      // id: new Date().getSeconds().toString()
    };
    mutate({
      ...newData,
      year: parseInt(newData.year)
    });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Add Award" width="400px">
      <div className="AddAwardModal px-4 py-5 bg-white">
        <form
          className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full bg-white mx-3">
            <div className="w-full mb-4">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Award Name
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                type="text"
                id="name"
                name="name"
                ref={register({
                  type: "name"
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div className="w-full mb-4">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Location
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                type="text"
                id="address"
                name="address"
                ref={register({
                  type: "address"
                })}
              />
              {errors.address && <p>{errors.address.message}</p>}
            </div>
            <div className="w-3/5 mb-6">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Date
              </label>
              <div className="w-full flex justify-between">
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="date"
                  id="year"
                  name="year"
                  ref={register({
                    type: "year"
                  })}
                />
              </div>
            </div>
            <div className="w-3/5 mb-6">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                By
              </label>
              <div className="w-full flex justify-between">
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  id="by"
                  name="by"
                  ref={register({
                    type: "by"
                  })}
                />
              </div>
            </div>
            {/*             
            <p className="font-roboto" style={{ color: "#58595B" }}>
              Upload photo
            </p>
            <div
              onClick={() => uploadInputEl.current.click()}
              className="upload-area mt-2"
            >
              <UploadSvg className="w-10 h-10" />
              <p className="text-sm text-center">
                Drag and drop or <span>browse</span>
              </p>
              <input ref={uploadInputEl} className="hidden" type="file" />
            </div> */}
            <div className="w-full flex justify-start">
              <button type="submit" className="w-28">
                <AcceptButton title="Save" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddAwardModal;
