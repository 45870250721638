import { useEffect, useState } from 'react';
import EditListModal from './EditListModal';
import AddAgentPlayerModal from './AddAgentPlayerModal/AddAgentPlayerModal';
import AddAgentCoachModal from './AddAgentCoachModal/AddAgentCoachModal';
import AddAwardsModal from '../../../components/AddAwardsModal/AddAwardsModal';
import EditAwardModal from '../../../components/EditAwardModal/EditAwardModal';
import DeleteAwardModal from '../../../components/DeleteAwardModal/DeleteAwardModal';
import { useQuery } from 'react-query';
import AgentInfo from './AgentInfo/AgentInfo';
import EditAgentInfoModal from './AgentInfo/EditAgentInfoModal/EditAgentInfoModal';
import MyList from '../../../components/MyList/MyList';
import AddMyList from '../../../components/MyList/AddMyList';
import EditMyList from '../../../components/MyList/EditMyList';
import {
  getAllManagedCoach,
  getAllManagedPlayers,
  getMyAllPlayerList
} from '../../../services/routes/User';
import DeleteMyList from '../../../components/MyList/DeleteMyList';
import AddManagedPlayerModal from '../../Club/AddClubPlayerModal/AddClubPlayerModal';
import './agentProfile.scss';
import TabSwitcher from '../../../components/Profile/TabSwitcher';
import Sidebar from '../../../components/Profile/Sidebar';
import ProfileBio from '../../../components/Profile/ProfileBio';
import AgentPlayers from './AgentPlayers';
import AgentCoaches from './AgentCoaches';
import Clients from './Clients';

const AgentProfile = () => {
  const [editListModalOpen, setEditListModal] = useState(false);
  const [selectedPlayerList, setSelectedPlayerList] = useState();
  const [createListModalOpen, setCreateListModalOpen] = useState(false);
  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState(false);
  const [addCoachModalOpen, setAddCoachModalOpen] = useState(false);
  const [addAward, setAddAward] = useState(false);
  const [showEditAwardModal, setShowEditAwardModal] = useState(false);
  const [showDeleteAwardModal, setShowDeleteAwardModal] = useState(false);
  const [currentAgentAwards, setCurrentAgentAwards] = useState({});
  const [totalClients, setTotalClients] = useState(0);

  const [showManagedPlayersModal, setShowManagedPlayersModal] = useState(false);

  const [editInfoModal, setEditInfoModal] = useState(false);

  let playerprofile;

  // My Player List
  const [editMyPlayerListModal, setEditMyPlayerListModal] = useState(false);
  const [currentMyList, setCurrentMyList] = useState({});
  const [showMyListDeleteModal, setShowMyListDeleteModal] = useState(false);
  const [deleteMyPlayerListById, setDeleteMyPlayerListById] = useState({});
  const [activeTab, setActiveTab] = useState('information');

  // getting managed players/roaster
  const { data: managedPlayersData } = useQuery(
    'fetch_managedPlayers',
    getAllManagedPlayers,
    {
      retry: false
    }
  );

  // getting managed coach
  const { data: managedCoachesData } = useQuery(
    'managedCoach',
    getAllManagedCoach,
    {
      retry: false
    }
  );

  useEffect(() => {
    if (managedPlayersData?.data && managedCoachesData?.data) {
      setTotalClients(
        managedPlayersData?.data?.length + managedCoachesData?.data?.length
      );
    }
  }, [managedPlayersData, managedCoachesData]);

  //get agent player list
  const { data: playerListData } = useQuery(
    'fetch_myPlayerList',
    getMyAllPlayerList,
    {
      retry: false
    }
  );

  const editPlayerList = id => {
    setEditListModal(true);

    setSelectedPlayerList(
      playerprofile.players_list.filter(p => p.id === id)[0]
    );
  };

  // agent MyPlayerList
  const onEditMyListClick = data => {
    setEditMyPlayerListModal(true);
    setCurrentMyList(data);
  };

  const onDeleteMyPlayerListClick = id => {
    setDeleteMyPlayerListById(id);
    setShowMyListDeleteModal(true);
  };

  // Managed Players states and functions

  const [currentManagedPlayer, setCurrentManagedPlayer] = useState([]);
  const [activeClients, setActiveClients] = useState('players');

  return (
    <>
      {/* Delete Modal */}
      {showDeleteAwardModal && (
        <DeleteAwardModal
          onCloseClick={() => setShowDeleteAwardModal(false)}
          award={currentAgentAwards}
        />
      )}

      {showMyListDeleteModal && (
        <DeleteMyList
          onCloseClick={() => setShowMyListDeleteModal(false)}
          id={deleteMyPlayerListById}
        />
      )}

      {/* Edit Modal */}
      {editListModalOpen && (
        <EditListModal
          selected={selectedPlayerList}
          onCloseClick={() => setEditListModal(false)}
        />
      )}

      {editInfoModal && (
        <EditAgentInfoModal onCloseClick={() => setEditInfoModal(false)} />
      )}

      {showEditAwardModal && (
        <EditAwardModal
          onCloseClick={() => setShowEditAwardModal(false)}
          award={currentAgentAwards}
        />
      )}

      {editMyPlayerListModal && (
        <EditMyList
          setData={currentMyList}
          onCloseClick={() => setEditMyPlayerListModal(false)}
        />
      )}

      {/* Add Modal */}
      {addAward && <AddAwardsModal onCloseClick={() => setAddAward(false)} />}

      {addPlayerModalOpen && (
        <AddAgentPlayerModal
          onCloseClick={() => setAddPlayerModalOpen(false)}
        />
      )}

      {addCoachModalOpen && (
        <AddAgentCoachModal onCloseClick={() => setAddCoachModalOpen(false)} />
      )}

      {createListModalOpen && (
        <AddMyList onCloseClick={() => setCreateListModalOpen(false)} />
      )}

      {showManagedPlayersModal && (
        <AddManagedPlayerModal
          data={currentManagedPlayer}
          onCloseClick={() => setShowManagedPlayersModal(false)}
        />
      )}

      {/* MainProfile Component */}
      <div className="flex w-full mt-8 gap-8">
        <div className="w-96">
          <Sidebar>
            <ProfileBio isEditable={true} />
            <div className="border-b-2 border-brand-dark mx-8 my-6 border-opacity-100"></div>
            <Clients totalClients={totalClients} />
          </Sidebar>
        </div>

        <div className="flex flex-col flex-1">
          <TabSwitcher
            tabs={[
              {
                title: 'Information',
                name: 'information',
                component: <div>Information</div>
              },
              {
                title: 'Clients',
                name: 'clients',
                component: <div>Agent</div>
              },
              {
                title: 'My List',
                name: 'my-list',
                component: <div>Coach</div>
              }
            ]}
            activeTab={activeTab}
            setActiveTab={name => {
              setActiveTab(name);
            }}
          />

          {activeTab === 'information' && (
            <AgentInfo
              setEditInfoModalOpen={setEditInfoModal}
              isEditable={true}
            />
          )}

          {activeTab === 'clients' && (
            <>
              <TabSwitcher
                parent={false}
                tabs={[
                  {
                    title: 'Players',
                    name: 'players',
                    component: <div>Players</div>
                  },
                  {
                    title: 'Coaches',
                    name: 'coaches',
                    component: <div>Coaches</div>
                  }
                ]}
                activeTab={activeClients}
                setActiveTab={name => {
                  setActiveClients(name);
                }}
              />
              {activeClients === 'players' && (
                <AgentPlayers
                  addModalOpen={setAddPlayerModalOpen}
                  data={managedPlayersData?.data}
                  isEditable={true}
                />
              )}
              {activeClients === 'coaches' && (
                <AgentCoaches
                  addModalOpen={setAddCoachModalOpen}
                  data={managedCoachesData?.data}
                  isEditable={true}
                />
              )}
            </>
          )}
          {activeTab === 'my-list' && (
            <MyList
              isEditable={true}
              myPlayerList={playerListData?.data}
              editMyList={onEditMyListClick}
              deleteMyList={onDeleteMyPlayerListClick}
              setCreateListModalOpen={setCreateListModalOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AgentProfile;
