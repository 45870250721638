import React from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { setUser } from "../../redux/authSlice";
import { me } from "../../services/routes/auth";
import Loader from "../../img/busy.gif";

const ProtectedRoute = ({ children, path, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);

  const { isLoading } = useQuery("user", me, {
    onSuccess: data => {
      dispatch(setUser(data.data));
    },
    onError: error => {
      history.push("/login");
    }
  });

  if (isLoading) {
    return (
      <div className="flex w-full h-screen justify-center items-center">
        <img src={Loader} alt="" className="w-28 h-28 object-contain" />
      </div>
    );
  }

  if (user) {
    return <Route path={path} render={() => children} {...rest} />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default ProtectedRoute;
