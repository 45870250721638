import { ReactComponent as CrossmarkSvg } from '../../../img/misc/crossed-mark.svg';
import { ReactComponent as AddCalendarSvg } from '../../../img/activity/addCalendar.svg';
import moment from 'moment';
import { useState } from 'react';

function SuggestedEvent({ event }) {
  //
  //
  const [addEvents, setAddEvents] = useState(false);
  const addEventHandler = () => {
    setAddEvents(true);
  };
  const cancelEventHandler = () => {
    setAddEvents(false);
  };
  return (
    <div className="flex items-center justify-between mt-3 border-b border-brand">
      <div className="flex items-center gap-3">
        <h2 className=" text-gray-500 font-semibold text-sm rounded-full flex text-center w-10 h-16 items-center justify-center">
          {moment(event?.scheduledDate)?.format('DD MMM')}
        </h2>

        <h2 className="font-semibold  text-sm ">{event?.eventName}</h2>
      </div>

      <div className="w-8 h-8  flex justify-center items-center ">
        {addEvents ? (
          <CrossmarkSvg
            onClick={cancelEventHandler}
            className="cursor-pointer "
          />
        ) : (
          <AddCalendarSvg
            className="cursor-pointer "
            onClick={addEventHandler}
          />
        )}
      </div>
    </div>
  );
}

export default SuggestedEvent;
