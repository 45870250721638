import { ReactComponent as CloseSvg } from '../../img/misc/close.svg';
import './Modal.scss';

const Modal = props => {
  return (
    <div
      className="Modal py-6 flex flex-col justify-center sm:py-12"
      ref={props.ref}
    >
      <div className="Modal-body py-3 mx-auto " style={{ width: props.width }}>
        <div
          className="flex justify-between px-4 py-2 bg-brand-dark rounded-t-lg items-center font-semibold  text-white text-base"
          style={{ backgroundColor: 'D09F2E' }}
        >
          <p> {props.title}</p>
          <CloseSvg onClick={props.onCloseClick} className="cursor-pointer" />
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
