import { useMutation } from 'react-query';
import Modal from '../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { updatePlayerInfo } from '../../../../services/routes/Player';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../../redux/userSlice';
import AcceptButton from '../../../../components/Buttons/AcceptButton';
import moment from 'moment';
import { toast } from 'react-toastify';

function EditPlayerInfoModal({ onCloseClick }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      country: user?.contact?.country,
      birth_date: user?.contact?.birth_date,
      gender: user?.contact?.gender,
      actual_club: user?.player?.club
        ? user?.player?.club?.User?.contact?.first_name +
          ' ' +
          user?.player?.club?.User?.contact?.last_name
        : 'Not Assigned',
      contract_until: moment(user?.player?.contract_until).format('YYYY-MM-DD'),
      best_foot: user?.player?.best_foot,
      height: user?.player?.height,
      agent: user.player?.agent
        ? user?.player?.agent?.User?.contact?.first_name +
          ' ' +
          user?.player?.agent?.User?.contact?.last_name
        : 'Not Assigned'
    }
  });

  const { mutate } = useMutation(updatePlayerInfo, {
    onSuccess: () => {
      toast.success('Player Info Updated Successfully');
      dispatch(fetchUser());
      onCloseClick();
    },
    onError: error => {
      toast.error("Couldn't Update Player Info");
    }
  });

  const onSubmit = data => {
    const newData = {
      ...data,
      agent: undefined,
      actual_club: undefined,
      contract_until: new Date(data.contract_until)
    };
    mutate(newData);
  };

  return (
    <Modal
      title="Edit Player Information"
      onCloseClick={onCloseClick}
      width="650px"
    >
      <form
        className="bg-white px-10 pt-6 rounded-b-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-4 items-center justify-center mb-3">
          <div className="flex w-full  flex-col py-2 pb-2">
            <label className="text-base font-normal text-black">
              Nationality
            </label>
            <input
              type="text"
              name="country"
              className="w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              placeholder="Enter your Country"
              ref={register({
                type: 'country'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Date of Birth
            </label>
            <input
              type="date"
              name="birth_date"
              className="w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'birth_date'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="  text-base font-normal text-black" htmlFor="">
              Best Foot
            </label>
            <select
              className="form-select w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              id="best_foot"
              name="best_foot"
              ref={register({
                required: 'You must specify a Best Fooot',
                type: 'best_foot'
              })}
              defaultValue={user?.player?.best_foot ? 'Right' : 'Left'}
            >
              <option value="Right">Right</option>
              <option value="Left">Left</option>
            </select>
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Actual Club
            </label>
            <input
              type="text"
              name="actual_club"
              className="w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'actual_club'
              })}
            />
          </div>
        </div>
        <div className="flex gap-4 items-center justify-center">
          <div className="flex w-full  flex-col py-2">
            <label className="  text-base font-normal text-black" htmlFor="">
              Gender
            </label>
            <select
              className="form-select w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              id="gender"
              name="gender"
              ref={register({
                required: 'You must specify Gender',
                type: 'gender'
              })}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">Height</label>
            <input
              type="text"
              name="height"
              className="w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              placeholder="in feet"
              ref={register({
                type: 'height'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">Agent</label>
            <input
              type="text"
              name="agent"
              className="w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'agent'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Contract Untill
            </label>
            <input
              type="date"
              name="contract_until"
              className="w-full text-left  text-base py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'contract_until'
              })}
            />
          </div>
        </div>

        <div className="w-full flex justify-start mt-5">
          <button type="submit" className="mb-5  ">
            <AcceptButton title="Save" />
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditPlayerInfoModal;
