import classNames from 'classnames';
import './postLeftNav.scss';

function PostLeftNav({ setSelectedNav, selectedNav, fetchSavedPost }) {
  return (
    <div className=" flex flex-col justify-center items-end mt-4 gap-2">
      <div
        className={classNames({
          'text-base cursor-pointer hover:text-brand': true,
          'text-brand font-semibold': selectedNav === 'your-posts'
        })}
        onClick={() => setSelectedNav('your-posts')}
      >
        Your Posts
      </div>
      <div
        className={classNames({
          'text-base cursor-pointer hover:text-brand': true,
          'text-brand font-semibold': selectedNav === 'saved-posts'
        })}
        onClick={() => {
          fetchSavedPost();
          setSelectedNav('saved-posts');
        }}
      >
        Saved Posts
      </div>
    </div>
  );
}

export default PostLeftNav;
