import { useState } from 'react';
import { ReactComponent as ArrowupSvg } from '../../../img/activity/arrowUp.svg';
import { ReactComponent as ArrowdownSvg } from '../../../img/activity/arrowDown.svg';
import { ReactComponent as RefreshSvg } from '../../../img/activity/refresh.svg';
import SuggestedPerson from './SuggestedPerson';
import { useQuery } from 'react-query';
import { getPossibleConnections } from '../../../services/routes/User';
import SmallLoader from '../../../components/Loader/SmallLoader';

export default function PossibleConnect() {
  //
  //
  const [page, setPage] = useState(1);
  const [showDetail, setShowDetail] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  const { isLoading, refetch } = useQuery(
    'fetchPossibleConnects',
    () => getPossibleConnections(page),
    {
      onSuccess: data => {
        setSuggestions(data?.data);
        setPage(prevPage => prevPage + 1);
      },
      onError: err => {
        console.log(err);
      },
      retry: 1
    }
  );

  return (
    <>
      {/* Header Control */}
      <div
        style={{ height: '230px', width: '250px' }}
        className={
          showDetail
            ? ' bg-white border rounded-lg  overflow-hidden scrollbar-hide'
            : 'bg-white border rounded-lg'
        }
      >
        <div className="flex justify-between bg-brand-dark py-2 rounded-tl-lg rounded-tr-lg text-white px-3 text-sm mb-0 items-center ">
          <div className="flex items-center gap-4 ">
            <button className="w-5 h-5 font-bold">
              <RefreshSvg
                className="cursor-pointer right-widget"
                onClick={refetch}
              />
            </button>
            {showDetail ? (
              <ArrowupSvg
                onClick={() => setShowDetail(!showDetail)}
                className="cursor-pointer right-widget"
              />
            ) : (
              <ArrowdownSvg
                onClick={() => setShowDetail(!showDetail)}
                className="cursor-pointer right-widget"
              />
            )}
          </div>
          <h3 className="font-medium font-roboto text-base">
            Possible Connects
          </h3>
        </div>

        {isLoading && <SmallLoader />}

        {showDetail && (
          <div className="px-3 flex flex-col gap-2">
            {suggestions?.map(profile => (
              <SuggestedPerson profile={profile} key={profile?.id} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
