import { Fragment, useState } from 'react';
import { ReactComponent as CirclePlus } from '../../../../img/misc/circle-plus.svg';
import PlayerAwardItem from './PlayerAwardItem';

const PlayerAwards = ({
  awards,
  setAddAwardModal,
  onEditPlayerAwardClick,
  deleteAwards,
  isEditable = false
}) => {
  const [showAwardDetail, setShowAwardDetail] = useState(true);

  return (
    <>
      {awards?.length > 0 && (
        <div className="w-full">
          {showAwardDetail && (
            <div className="flex flex-wrap">
              {awards?.map(award => (
                <PlayerAwardItem
                  id={award.id}
                  key={award.id}
                  award={award}
                  isEditable={isEditable}
                  onEditPlayerAwardClick={onEditPlayerAwardClick}
                  deleteAwards={deleteAwards}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {isEditable === true && (
        <div className="flex justify-center items-center flex-col mt-5">
          <p className="flex items-center gap-3">
            <CirclePlus
              onClick={() => setAddAwardModal(true)}
              className="ml-2 primary-svg"
            />
          </p>
          <p className="text-base mt-4">Add Awards</p>
        </div>
      )}
    </>
  );
};

export default PlayerAwards;
