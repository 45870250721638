import { ReactComponent as EditSvg } from '../../img/misc/camera-gray.svg';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import profileImageUrl from '../../config/config';
import { useSelector } from 'react-redux';
import './profileCard.css';

function ProfileCard({ setEditProfileModalVisible }) {
  const data = useSelector(
    state => state.user?.user?.contact?.profile_image?.url
  );

  return (
    <div className="PlayerCardCircle">
      <div className="flex flex-col items-center">
        <img
          src={(data && profileImageUrl + data) || DefaultProfilePng}
          className="object-cover w-52 h-52 bg-white rounded-full  z-0"
          alt=""
        />
        <div className="icon-wrapper">
          <EditSvg
            className="mx-auto cursor-pointer white-svg primary-svg w-6"
            onClick={() => setEditProfileModalVisible(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
