import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { editAwards } from "../../services/routes/Awards";
import { fetchUser } from "../../redux/userSlice";
import Modal from "../Modal/Modal";
import AcceptButton from "../Buttons/AcceptButton";

const EditAwardModal = ({ award, onCloseClick }) => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      name: award.name,
      address: award.address,
      by: award.by,
      year: moment(award.year).format("YYYY-MM-DD")
    }
  });

  const { mutate } = useMutation(editAwards, {
    onSuccess: () => {
      dispatch(fetchUser());
      onCloseClick();
    },
    onError: error => {
      toast.error(error.response.data.message);
    }
  });

  const onsubmit = async data => {
    const newData = {
      id: award.id,
      data: { ...data, year: parseInt(data.year) }
    };
    mutate(newData);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Edit Award" width="400px">
      <div className="AddAwardModal px-4 py-5 bg-white">
        <form
          className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onsubmit)}
        >
          <div className="w-full bg-white mx-3">
            <div className="w-full mb-4">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Award Name
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-800 shadow-sm"
                type="text"
                id="name"
                name="name"
                ref={register({
                  type: "name"
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div className="w-full mb-4">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Location
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-800 shadow-sm"
                type="text"
                id="address"
                name="address"
                ref={register({
                  type: "address"
                })}
              />
              {errors.address && <p>{errors.address.message}</p>}
            </div>
            <div className="w-full mb-4">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Awarded By
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-800 shadow-sm"
                type="text"
                id="by"
                name="by"
                ref={register({
                  type: "by"
                })}
              />
              {errors.by && <p>{errors.by.message}</p>}
            </div>
            <div className="w-3/5 mb-6">
              <label className="text-sm font-medium text-gray-500" htmlFor="">
                Date
              </label>
              <div className="w-full flex justify-between">
                <input
                  className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-800 shadow-sm"
                  type="date"
                  id="year"
                  name="year"
                  ref={register({
                    type: "year"
                  })}
                />
              </div>
            </div>
            {/*             
            <p className="font-roboto" style={{ color: "#58595B" }}>
              Upload photo
            </p>
            <div onClick={uploadBtnClick} className="upload-area mt-2">
              <UploadSvg className="w-10 h-10" />
              <p className="text-sm text-center">
                Drag and drop or <span>browse</span>
              </p>
              <input ref={uploadInputEl} className="hidden" type="file" />
            </div> */}
            <div className="w-full flex justify-start">
              <button type="submit" className="w-28">
                <AcceptButton title="Update" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditAwardModal;
