import React from "react";
import Modal from "../../../../components/Modal/Modal";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { editPlayerClubHistory } from "../../../../services/routes/PlayerHistories/playerClubHistories";
import moment from "moment";

const EditPlayerClubHistory = ({ onCloseClick, setEditData }) => {
  //
  const queryClient = useQueryClient();
  const {
    clubName,
    frequent_position,
    games,
    goals,
    assists,
    region,
    team,
    season
  } = setEditData;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      clubName: clubName,
      frequent_position: frequent_position,
      games: games,
      goals: goals,
      assists: assists,
      region: region,
      team: team,
      season: moment(season).format("YYYY-MM-DD")
    }
  });

  const { mutate, isLoading } = useMutation(editPlayerClubHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("playerClub");
      onCloseClick();
    },
    onError: err => {}
  });

  const onsubmit = data => {
    const { games, goals, assists } = data;

    mutate({
      id: setEditData.edit,
      data: {
        ...data,
        games: parseInt(games),
        goals: parseInt(goals),
        assists: parseInt(assists)
      }
    });
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title="Edit Player History"
      width="450px"
    >
      <div className="bg-white rounded-br-lg rounded-bl-lg">
        <form className="flex flex-col p-6" onSubmit={handleSubmit(onsubmit)}>
          <div className="w-full flex flex-col mb-8">
            <label className="text-sm font-medium text-gray-700">Club</label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
              type="text"
              id="clubName"
              name="clubName"
              ref={register({
                required: true
              })}
            />
            {errors.clubName && <p>{errors.clubName.message}</p>}
          </div>

          {/*  */}
          <div className="w-full  items-center gap-4 grid grid-cols-2 mb-8">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Season
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="date"
                name="season"
                id="season"
                ref={register({
                  required: true
                })}
              />
              {errors.season && <p>{errors.season.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">Team</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="team"
                id="team"
                ref={register({
                  required: true
                })}
              />
              {errors.team && <p>{errors.team.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Region
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="region"
                id="region"
                ref={register({
                  required: true
                })}
              />
              {errors.region && <p>{errors.region.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Frequent Position
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="frequent_position"
                id="frequent_position"
                ref={register({
                  required: true
                })}
              />
              {errors.frequent_position && (
                <p>{errors.frequent_position.message}</p>
              )}
            </div>
          </div>

          {/*  */}
          <div className="w-full flex items-center gap-4 mb-8">
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Games</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="games"
                id="games"
                ref={register({
                  required: true
                })}
              />
              {errors.games && <p>{errors.games.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Goals</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="goals"
                id="goals"
                ref={register({
                  required: true
                })}
              />
              {errors.goals && <p>{errors.goals.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">
                Assists
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                id="assists"
                name="assists"
                ref={register({
                  required: true
                })}
              />
              {errors.assists && <p>{errors.assists.message}</p>}
            </div>
          </div>

          {/* Submit */}
          <div className="w-full flex justify-start ">
            <button type="submit" className="w-28">
              <AcceptButton title={isLoading ? "Saving..." : "Save"} />
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditPlayerClubHistory;
