import './acceptButton.css';

function AcceptButton({ onClick, title, Icon, type, customCss, width }) {
  return (
    <div
      onClick={onClick}
      className="flex justify-center space-x-2"
      style={{ width: width }}
    >
      <button
        className={`accept-button whitespace-pre focus:outline-none focus:ring focus:ring-violet-300 ${
          customCss === true && 'text-lg'
        }`}
        type={type}
      >
        {Icon && <Icon className="mr-2" />}
        {title}
      </button>
    </div>
  );
}

export default AcceptButton;
