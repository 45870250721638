import { useSelector } from 'react-redux';
import AgentClubHistory from '../AgentClubHistory/AgentClubHistory';
import PlayerAwards from '../../pages/Player/PlayerProfile/PlayerAwards/PlayerAwards';
import PersonRequestCard from '../PersonRequestCard/PersonRequestCard';
import UserHistoryTable from '../UserHistoryTable/UserHistoryTable';
import './VisitPlayerProfile.css';
import Sidebar from '../Profile/Sidebar';
import ProfileBio from '../Profile/ProfileBio';
import PlayerPosition from '../../pages/Player/PlayerProfile/PlayerPosition/PlayerPosition';
import TabSwitcher from '../Profile/TabSwitcher';
import { useState } from 'react';
import PlayerInfo from '../../pages/Player/PlayerProfile/PlayerInfo/PlayerInfo';

const VisitPlayerProfile = () => {
  const user = useSelector(state => state.visitProfile.userProfile);
  // const user = useSelector(state => state.auth.user);
  const [activeTab, setActiveTab] = useState('information');
  const [activeHistory, setActiveHistory] = useState('club');

  return (
    <>
      <div className="w-full h-full flex md mt-8 gap-8">
        <div className="w-96">
          <Sidebar>
            <ProfileBio />
            {/* line */}
            <div className="border-b-2 border-brand-dark mx-8 my-6 border-opacity-100"></div>
            <PlayerPosition positions={user.player.positions} />
          </Sidebar>
        </div>
        <div className="flex flex-col flex-1">
          <TabSwitcher
            tabs={[
              {
                title: 'Information',
                name: 'information',
                component: <div>Information</div>
              },
              {
                title: 'Agent',
                name: 'agent',
                component: <div>Agent</div>
              },
              {
                title: 'Coach',
                name: 'coach',
                component: <div>Coach</div>
              },
              {
                title: 'Club',
                name: 'club',
                component: <div>Coach</div>
              },
              {
                title: 'Award',
                name: 'award',
                component: <div>Award</div>
              },
              {
                title: 'History',
                name: 'history',
                hasChild: true,
                component: <div>History</div>
              }
            ]}
            activeTab={activeTab}
            setActiveTab={name => {
              setActiveTab(name);
            }}
          />

          <div className="">
            <div className="">
              <div className="flex flex-col h-full flex-1">
                {/* Player Position and Info */}
                {activeTab === 'information' && (
                  <div className="position_info_container">
                    <PlayerInfo data={user} isEditable={false} />
                  </div>
                )}

                {/* Player's Contact */}
                <div className="flex md:flex-row flex-col items-center w-full gap-8 flex-1">
                  {activeTab === 'agent' && (
                    <PersonRequestCard
                      data={user}
                      role="player"
                      personType="Agent"
                      type="agent"
                      isEditable={false}
                    />
                  )}
                  {activeTab === 'coach' && (
                    <PersonRequestCard
                      data={user}
                      role="player"
                      personType="Coach"
                      type="coach"
                      isEditable={false}
                    />
                  )}
                  {activeTab === 'club' && (
                    <PersonRequestCard
                      data={user}
                      role="player"
                      personType="Club"
                      type="club"
                      isEditable={false}
                    />
                  )}
                </div>

                {
                  // Player Awards
                  activeTab === 'award' && (
                    <div>
                      <PlayerAwards awards={user?.awards} />
                    </div>
                  )
                }

                {
                  // Player-History
                  activeTab === 'history' && (
                    <div className="gap-8 flex-1 overflow-y-scroll w-full">
                      <div className="flex items-end justify-end">
                        <TabSwitcher
                          parent={false}
                          tabs={[
                            {
                              title: 'Club',
                              name: 'club',
                              component: <div>Club</div>
                            },
                            {
                              title: 'Agent',
                              name: 'agent',
                              component: <div>Agent</div>
                            },
                            {
                              title: 'Coach',
                              name: 'coach',
                              component: <div>Coach</div>
                            }
                          ]}
                          activeTab={activeHistory}
                          setActiveTab={name => {
                            setActiveHistory(name);
                          }}
                        />
                      </div>

                      <div className="w-full">
                        {
                          // Player-Agent History
                          activeHistory === 'agent' && (
                            <div>
                              <AgentClubHistory
                                role="Agent"
                                histories={user.player.player_agent_history}
                              />
                            </div>
                          )
                        }

                        {
                          // Player-Coach History
                          activeHistory === 'coach' && (
                            <div className="mb-8">
                              <AgentClubHistory
                                role="Coach"
                                histories={user.player.player_coach_history}
                              />
                            </div>
                          )
                        }

                        {
                          // Player-Club History
                          activeHistory === 'club' && (
                            <div className="mb-8">
                              <UserHistoryTable
                                histories={user.player.player_club_history}
                                title="Player Club History"
                                role="player"
                              />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitPlayerProfile;
