import { useForm } from 'react-hook-form';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link, useHistory } from 'react-router-dom';
import facebookSvg from '../../img/misc/facebook_no_circle.svg';
import googleSvg from '../../img/misc/g_google.svg';
import linkedInPng from '../../img/social/linked-in.png';
import { login } from '../../services/routes/auth';
import { useMutation } from 'react-query';
import AcceptButton from '../../components/Buttons/AcceptButton';
import { toast } from 'react-toastify';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Popup from 'reactjs-popup';
import './Login.scss';

const SocialAuth = ({ socialSvg, loginTitle }) => {
  return (
    <div className="flex items-center gap-3 w-72 border border-red-400 py-2 px-6 rounded-md">
      <img src={socialSvg} className="w-6 h-6 object-contain" alt="" />
      <span
        className="whitespace-pre text-lg"
        style={{ fontFamily: 'poppins, sans-serif' }}
      >
        Log in with {loginTitle}
      </span>
    </div>
  );
};

const Login = () => {
  //
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: data => {
      toast.success('You are logged in!');
      if (data.data.data.isCompleted === false) {
        history.push('/register');
      } else {
        history.push('/home');
      }
    },
    onError: error => {
      toast.error('Wrong Email or Password!!');
    }
  });

  const onSubmit = async data => {
    mutate(data);
  };

  return (
    <div className="flex h-screen flex-col justify-between Login">
      <div className="">
        <Header />
        <div
          className="relative min-h-screen  md:min-w-full max-w-md mx-auto flex items-center justify-center "
          style={{ marginTop: '-3rem' }}
        >
          <div className="bg-white rounded border shadow-lg border-gray-200  px-12 py-8 mx-auto login-container">
            <form
              autocomplete="off"
              className="mb-4 py-2 md:px-2 md:flex md:flex-wrap md:justify-between"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="w-full">
                <label
                  className="font-light"
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Poppins, sans-serif'
                  }}
                >
                  E-mail Address
                </label>
                <input
                  autoComplete="false"
                  className="block w-full text-left text-sm mb-6 border py-1 px-2 rounded-sm text-black bg-white"
                  type="email"
                  name="email"
                  id="email"
                  ref={register({
                    required: 'You must specify an email'
                  })}
                />
                {errors.email && (
                  <p
                    className="text-red-600 mb-2"
                    style={{ marginTop: '-18px' }}
                  >
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  className="font-light"
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Poppins, sans-serif'
                  }}
                >
                  Password
                </label>
                <div className="w-full flex bg-white rounded-sm border items-center px-2 mb-6">
                  <input
                    className="text-black text-sm "
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    ref={register({
                      required: 'You must specify a password',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                  />
                  <Popup
                    trigger={
                      <VisibilityIcon
                        onClick={() => setShowPassword(!showPassword)}
                        className="cursor-pointer primary-svg w-4 h-4 text-gray-600"
                      />
                    }
                    on={['hover', 'focus']}
                    position="bottom center"
                    arrow={'bottom center'}
                    keepTooltipInside={false}
                  >
                    <div className="bg-black text-sm rounded-sm text-white px-3 py-1">
                      {showPassword ? 'Hide' : 'Show'} Password
                    </div>
                  </Popup>
                </div>

                {errors.password && (
                  <p
                    className="text-red-600 mb-4"
                    style={{ marginTop: '-18px' }}
                  >
                    {errors.password.message}
                  </p>
                )}
              </div>

              <div className="block w-full text-center">
                <button type="submit" className={`w-2/6 `}>
                  <AcceptButton
                    customCss={true}
                    title={isLoading ? 'Login... ' : 'Login'}
                  />
                </button>
              </div>
            </form>
            <div className="flex w-full h-10">
              <hr className="flex items-center line flex-1 " />

              <div className="mx-4 flex items-center login-with">
                <p
                  className="text-base"
                  style={{ fontFamily: 'Poppins, sans-serif' }}
                >
                  Or login with
                </p>
              </div>
              <hr className="flex items-center line flex-1 " />
            </div>
            <div className="flex justify-center items-center flex-col my-3 gap-4 w-full login-with">
              <SocialAuth loginTitle={'Google'} socialSvg={googleSvg} />

              <SocialAuth loginTitle={'Facebook'} socialSvg={facebookSvg} />

              <SocialAuth loginTitle={'Linked In'} socialSvg={linkedInPng} />
            </div>
            <div className="w-full mt-6  flex justify-center">
              <Link
                to="/register"
                className="underline text-base text-gray-400"
                style={{
                  fontFamily: 'poppins, sans-serif'
                }}
              >
                Don't have an account yet?
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer showLetsChat={true} />
    </div>
  );
};

export default Login;
