import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import { useForm } from 'react-hook-form';
import './RequestTestimonialModal.css';
import busyIcon from '../../../../img/busy.gif';

const RequestTestimonialModal = ({ data, onCloseClick }) => {
  const [busy, setBusy] = useState(false);
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [splayer, setSplayer] = useState();
  const [p, setP] = useState();

  const onTextchange = (e) => {
    let fs = players.filter((s) => s.label.includes(e.target.value));
    setFilteredPlayers(fs);
    setP(e.target.value);
  };
  const { register, errors, handleSubmit } = useForm({});
  const onSubmit = async (data) => {
    setBusy(true);

   
  };

  const onClickSuggestion = (s) => {
    setSplayer(s.value);
    setP(s.label);
  };


  return (
    <Modal onCloseClick={onCloseClick} title="Request Testimonial">
      <div className="w-full  px-4 py-5 bg-white">
        <form
          className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="w-full bg-white">
            <div className=" mb-2">
              <label className="text-testimonial-modal" htmlFor="">
                Request
              </label>
              <div className="h-30">
                <input
                  value={p}
                  className="block w-full text-left text-md  border py-0.5 px-3 text-black yellow-border"
                  onChange={(e) => {
                    onTextchange(e);
                  }}
                />
                <div className="flex flex-wrap h-20 max-h-20 overflow-y-auto mt-1">
                  {p != '' &&
                    players.map((p) => (
                      <button
                        key={p.id}
                        className=" mx-1 my-1 suggeston"
                        onClick={(e) => {
                          onClickSuggestion(p);
                        }}
                      >
                        <p className=" px-2 text-sm w-full font-light">
                          {p.label}
                        </p>
                      </button>
                    ))}
                </div>
              </div>
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div className="w-full mt-0 mb-2">
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black yellow-border"
                type="text"
                id="topic"
                name="topic"
                placeholder="Topic:"
                ref={register}
              />
              {errors.topic && <p>{errors.topic.message}</p>}
            </div>
            <div className="w-full mt-0 mb-2">
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black yellow-border"
                type="text"
                id="professional_relationship"
                name="professional_relationship"
                placeholder="Professional Realtionship"
                ref={register}
              />
              {errors.topic && <p>{errors.topic.message}</p>}
            </div>
            <div className="mt-0 mb-6">
              <div className="w-full  flex justify-between">
                <textarea
                  className="w-full text-left h-48 text-md border py-0.5 px-3 text-black yellow-border"
                  type="date"
                  id="message"
                  name="message"
                  placeholder="message here"
                  ref={register}
                />
              </div>
            </div>

            <button
              onClick={handleSubmit(onSubmit)}
              className="text-center px-12 focus:outline-none text-lg py-1 text-sm text-white yellow-background request-testimonial-button theme-button mt-4"
            >
              Send
            </button>
            {busy && <img className="busy" src={busyIcon} />}
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default RequestTestimonialModal;
