import SideNavWrapper from '../SideNavWrapper';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { getPostById } from '../../services/routes/Post';
import Post from '../Player/PlayerHome/Post/Post';
import SmallLoader from '../../components/Loader/SmallLoader';
// import Post from '../Player/PlayerHome/Post/Post';

const NotifyPost = () => {
  const { id } = useParams();

  // taking id from url and fetching post data
  const { data: postData, isLoading } = useQuery('post', () => getPostById(id));

  return (
    <SideNavWrapper isFooter={true} width="100%">
      <div className="w-full flex justify-center">
        <>
          {isLoading ? (
            <SmallLoader />
          ) : (
            <div style={{ width: '40rem' }}>
              {postData?.data && <Post post={postData?.data} feedPage={true} />}
            </div>
          )}
        </>
      </div>
    </SideNavWrapper>
  );
};

export default NotifyPost;
