import Modal from "../../components/Modal/Modal";
import React, { useRef, useState } from "react";
import { ReactComponent as UploadSvg } from "../../img/misc/upload.svg";
import AcceptButton from "../../components/Buttons/AcceptButton";
import DeclineButton from "../../components/Buttons/DeclineButton";

const FileAComplaint = ({ onCloseClick }) => {
  const uploadInputEl = useRef(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadedImageDataURL, setUploadedImageDataURL] = useState(null);

  const handleImageUpload = event => {
    const file = event.target.files[0];
    setUploadImage(file);
    const reader = new FileReader();
    reader.onload = e => {
      setUploadedImageDataURL(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  const uploadBtnClick = () => {
    uploadInputEl.current.click();
  };

  return (
    <Modal title="File a Complaint" onCloseClick={onCloseClick} width="750px">
      <div className="bg-white w-full px-8 py-7 rounded-b-md">
        <div className="w-full">
          <label className="text-md font-medium text-gray-500">
            Who was envolved
          </label>
          <input
            className="w-full text-left  text-md py-0.5 px-3 text-black bg-gray-100 mt-1  rounded-sm shadow-sm h-11"
            type="text"
            placeholder="Name of the person"
          />
        </div>
        <div className="w-full mt-7">
          <label className="text-md font-medium text-gray-500">
            Complain Description
          </label>
          <textarea
            className="w-full py-0.5 px-3 text-black bg-gray-100 mt-1  rounded-sm "
            rows="7"
            cols="50"
          ></textarea>
        </div>
        <div className="mt-7">
          <label className="text-md font-medium text-gray-500">
            Upload Proof or suppport of your file
          </label>
          <input
            type="file"
            className="mt-1"
            ref={uploadInputEl}
            hidden
            onChange={handleImageUpload}
          />
          <div className="mt-4">
            <button
              className=" w-1/12 bg-brand-light text-white rounded-xl"
              onClick={uploadBtnClick}
            >
              <UploadSvg className="p-2 ml-4" />
            </button>
          </div>
        </div>
        <div className="w-full flex justify-start gap-10 mt-10">
          <button type="submit" className="mb-2">
            <DeclineButton title="Cancel" className="mt-7 " />
          </button>
          <button type="submit" className="mb-2">
            <AcceptButton title="Create" className="mt-7" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FileAComplaint;
