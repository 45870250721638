import React from 'react';
import Modal from '../../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import AcceptButton from '../../../../../components/Buttons/AcceptButton';
import { useSelector } from 'react-redux';

function EditAgentInfoModal({ onCloseClick }) {
  const user = useSelector(state => state.auth.user);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      nationality: user?.contact?.country,
      city: user?.contact?.city,
      companyName: user?.contact?.company_name,
      dateOfBirth: user?.contact?.birth_date
    }
  });

  console.log('user', user);

  const onSubmit = data => {
    console.log('Form data', data);
  };

  return (
    <Modal
      title="Edit Agent Information"
      onCloseClick={onCloseClick}
      width="450px"
    >
      <form
        className="bg-white px-10 pt-6 rounded-b-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-5 items-center justify-center mb-3">
          <div className="flex w-full  flex-col py-2 pb-2">
            <label className="text-base font-normal text-black">Country</label>
            <input
              type="nationality"
              name="nationality"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              placeholder="Enter your Country"
              ref={register({
                type: 'nationality'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">City</label>
            <input
              type="text"
              name="city"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'city'
              })}
            />
          </div>
        </div>
        <div className="flex gap-5 items-center justify-center pb-5">
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Company Name
            </label>
            <input
              type="text"
              name="comapnyName"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'companyName'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Year of foundation
            </label>
            <input
              type="date"
              name="dateOfBirth"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'dateOfBirth'
              })}
            />
          </div>
        </div>
        <div className="w-full flex justify-start mt-5">
          <button type="submit" className="mb-5  ">
            <AcceptButton title="Save" />
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditAgentInfoModal;
