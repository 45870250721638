import React from 'react';
import AcceptButton from '../../components/Buttons/AcceptButton';
import DeclineButton from '../../components/Buttons/DeclineButton';
import { useMutation } from 'react-query';
import { deleteFriendRequest } from '../../services/routes/UserFriendRequest';
import Modal from '../../components/Modal/Modal';

const DeleteContactModal = ({ onCloseClick, contactId, fetchMyFriends }) => {
  const { mutate, isLoading } = useMutation(deleteFriendRequest, {
    onSuccess: () => {
      fetchMyFriends();
      onCloseClick();
    },
    onError: err => {
      console.log('err', err);
    }
  });

  console.log('contact del id', contactId);

  const handleContactDelete = () => {
    // Add your Delete Logic Here
    mutate({ id: contactId });
  };

  return (
    <>
      <Modal title="Delete Contact" onCloseClick={onCloseClick}>
        <div className="w-full  py-5 px-5 bg-white">
          <div className="flex flex-wrap w-full">
            <p
              className="font-light font-roboto"
              style={{ color: '#58595B', fontSize: '16px' }}
            >
              Are you sure you want to delete this contact?
            </p>
            <div className="w-full justify-center mt-8 items-center flex gap-6">
              <button className="mx-1 my-2 ">
                <AcceptButton
                  title={isLoading ? 'Deleting...' : 'YES'}
                  onClick={handleContactDelete}
                />
              </button>
              <button className="mx-1 my-2">
                <DeclineButton title="NO" onClick={() => onCloseClick()} />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteContactModal;

// Delete Contact Function
