import './notifications.scss';

function NotificationSettings({ title, name, register, onChange }) {
  return (
    <div className="w-full">
      <label className="font-roboto font-medium text-base text-black">
        {title}
      </label>
      <select
        onChange={onChange}
        className="form-select w-full text-left mt-1 rounded-md text-md px-3 font-light text-base text-black bg-white"
        name={name}
        ref={register({ type: name })}
      >
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </select>
    </div>
  );
}

export default NotificationSettings;
