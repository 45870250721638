import React from "react";
import Modal from "../../../../components/Modal/Modal";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import { editCoachClubHistory } from "../../../../services/routes/CoachHistories/coachClubistories";

const EditClubHistoryModal = ({ onCloseClick, setEditData, refetch }) => {
  console.log("SetEditData", setEditData);
  //
  const queryClient = useQueryClient();
  const {
    clubName,
    jobPosition,
    region,
    team,
    games,
    wins,
    draws,
    seasonFrom,
    seasonTo,
    losses
  } = setEditData;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      clubName: clubName,
      jobPosition: jobPosition,
      team: team,
      games: games,
      wins: wins,
      draws: draws,
      region: region,
      losses: losses,
      seasonFrom: moment(seasonFrom).format("YYYY-MM-DD"),
      seasonTo: moment(seasonTo).format("YYYY-MM-DD")
    }
  });

  const { mutate, isLoading } = useMutation(editCoachClubHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("Club-History");
      refetch();
      onCloseClick();
    },
    onError: err => {}
  });

  const onsubmit = data => {
    const { games, wins, losses, draws } = data;

    mutate({
      id: setEditData.edit,
      data: {
        ...data,
        games: parseInt(games),
        wins: parseInt(wins),
        draws: parseInt(draws),
        losses: parseInt(losses)
      }
    });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Player History" width="450px">
      <div className="bg-white rounded-br-lg rounded-bl-lg">
        <form className="flex flex-col p-6" onSubmit={handleSubmit(onsubmit)}>
          <div className="w-full flex flex-col mb-8">
            <label className="text-sm font-medium text-gray-700">Club</label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
              type="text"
              name="clubName"
              ref={register({
                required: true
              })}
            />
            {errors.clubName && <p>{errors.clubName.message}</p>}
          </div>

          {/*  */}
          <div className="w-full  items-center gap-4 grid grid-cols-2 mb-6">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Season From
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="date"
                name="seasonFrom"
                ref={register({
                  required: true
                })}
              />
              {errors.seasonFrom && <p>{errors.seasonFrom.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Season To
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="date"
                name="seasonTo"
                ref={register({
                  required: true
                })}
              />
              {errors.seasonTo && <p>{errors.seasonTo.message}</p>}
            </div>

            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Job Position
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="jobPosition"
                ref={register({
                  required: true
                })}
              />
              {errors.joob_position && <p>{errors.job_position.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Region
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="region"
                ref={register({
                  required: true
                })}
              />
              {errors.region && <p>{errors.region.message}</p>}
            </div>
          </div>

          <div className="w-full flex items-center gap-4 mb-8">
            <div className="w-1/2 flex flex-col">
              <label className="text-sm font-medium text-gray-700">Team</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="team"
                ref={register({
                  required: true
                })}
              />
              {errors.team && <p>{errors.team.message}</p>}
            </div>
            <div className="w-1/2 flex flex-col">
              <label className="text-sm font-medium text-gray-700">Games</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="games"
                ref={register({
                  required: true
                })}
              />
              {errors.games && <p>{errors.games.message}</p>}
            </div>
          </div>
          <div className="w-full flex items-center gap-4 mb-8">
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Wins</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="wins"
                ref={register({
                  required: true
                })}
              />
              {errors.wins && <p>{errors.wins.message}</p>}
            </div>

            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">
                Looses
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="losses"
                ref={register({
                  required: true
                })}
              />
              {errors.looses && <p>{errors.looses.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Draw</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="draws"
                ref={register({
                  required: true
                })}
              />
              {errors.draws && <p>{errors.draws.message}</p>}
            </div>
          </div>

          {/* Submit */}
          <div className="w-full flex justify-start ">
            <button type="submit" className="w-28">
              <AcceptButton title={isLoading ? "Saving..." : "Save"} />
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditClubHistoryModal;
