import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupChat: null,
  userInfo: null,
  previousChats: null,
  chats: null,
  messages: []
};

const UserChats = createSlice({
  name: "userChat",
  initialState,
  reducers: {
    setUserChat: (state, action) => {
      state.userInfo = action.payload;
    },
    setPreviousChats: (state, action) => {
      state.previousChats = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setGroupChats: (state, action) => {
      state.groupChat = action.payload;
    }
  }
});

export const {
  setUserChat,
  setPreviousChats,
  setChats,
  setMessages,
  setGroupChats
} = UserChats.actions;

export default UserChats.reducer;
