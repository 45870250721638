import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import AcceptButton from "../Buttons/AcceptButton";
import { toast } from "react-toastify";

function InviteCoachModal({ onCloseClick, setInviteCoach, inviteCoach }) {
  //
  //
  const { handleSubmit, register, errors } = useForm();

  const { mutate } = useMutation({
    onSuccess: () => {},
    onError: error => {
      toast.error(error.response);
    }
  });

  const onSubmit = data => {
    mutate(data);
    onCloseClick();
  };

  return (
    <div className="w-full  py-5 px-5 bg-white">
      <form
        className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full bg-white">
          <div className="flex gap-3 items-center justify-center">
            <div className="w-full mb-6">
              <label className="text-sm font-medium" htmlFor="">
                Coach Name
              </label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                id="coach_name"
                name="name"
                ref={register({
                  type: "coach",
                  required: "You must specify a coach name"
                })}
              />
              {errors.coach_name && <p>{errors.coach_name.message}</p>}
            </div>
            <div className="w-full mb-6">
              <label className="text-sm font-medium">Email</label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                type="email"
                name="email"
                ref={register({
                  type: "email",
                  required: "You must specify email"
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <div className="w-full mb-4">
              <label className="text-sm font-medium">Phone Number</label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                type="tel"
                name="number"
                ref={register({
                  type: "number",
                  required: "You must specify an phone number"
                })}
              />
              {errors.number && <p>{errors.email.number}</p>}
            </div>
            <div className="w-full mb-4">
              <label className="text-sm font-medium">Club</label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                type="text"
                name="club"
                ref={register({
                  type: "club",
                  required: "Club name required"
                })}
              />
              {errors.club && <p>{errors.club.message}</p>}
            </div>
          </div>
          <div className="w-1/2 mb-4">
            <label className="text-sm font-medium" htmlFor="">
              Coach Type
            </label>
            <input
              className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
              type="text"
              name="coach_type"
              ref={register({
                type: "coach_type",
                required: "Coach type is required"
              })}
            />
            {errors.coach_type && <p>{errors.coach_type.message}</p>}
          </div>

          <button type="submit" className=" mt-4">
            <AcceptButton title="Send Request" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default InviteCoachModal;
