import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import InviteCoachModal from "../../../../components/InviteCoachModal/InviteCoachModal";
import Modal from "../../../../components/Modal/Modal";
import {
  searchCoach,
  createRoleRequest
} from "../../../../services/routes/User";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { fetchUser } from "../../../../redux/userSlice";

function AddAgentCoachModal({ onCloseClick }) {
  //
  //
  const [coach, setCoach] = useState([]);
  const [inviteCoach, setInviteCoach] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getSearchCoach();
  }, []);

  const { mutate } = useMutation(createRoleRequest, {
    onSuccess: () => {
      dispatch(fetchUser());
    }
  });

  const getSearchCoach = async (inputValue = "", callback) => {
    try {
      const filteredCoach = await searchCoach(inputValue);
      const options = filteredCoach.data.map(coach => ({
        label: coach.contact.first_name + " " + coach.contact.last_name,
        value: coach.id
      }));

      callback(options);
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  const handleSelect = selectedCoach => {
    setSelectedCoach(selectedCoach);
  };

  const sendRequest = () => {
    mutate({ toUser: selectedCoach.value });
    onCloseClick();
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title={coach.length > 0 ? "Edit Coach" : "Add Coach"}
    >
      {!inviteCoach ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            loadOptions={getSearchCoach}
            classNamePrefix="react-select"
            name="coachOptions"
            placeholder="Search for Coach..."
            value={selectedCoach}
            onChange={handleSelect}
          />
          {selectedCoach ? (
            <div className="w-full flex justify-center mt-4">
              <button
                type="submit"
                className="text-center px-12 focus:outline-none text-lg py-1 text-white bg-brand-light hover:bg-brand-dark theme-button mt-4"
                onClick={sendRequest}
              >
                Send Request
              </button>
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-xl flex-col justify-center mt-6 mb-2">
              Didn't Found the Coach?
              <button
                onClick={() => setInviteCoach(!inviteCoach)}
                className="bg-brand-light hover:bg-brand-dark px-6 py-2  text-white rounded-md mt-2"
              >
                Invite Coach
              </button>
            </span>
          )}
        </div>
      ) : (
        <InviteCoachModal
          onCloseClick={onCloseClick}
          setInviteCoach={setInviteCoach}
          inviteCoach={inviteCoach}
        />
      )}
    </Modal>
  );
}

export default AddAgentCoachModal;
