import DefaultProfilePng from '../../../img/misc/Default_pfp.png';
import { Link } from 'react-router-dom';
import './SearchProfileCard.css';

const ProfileCard = ({
  first_name,
  last_name,
  role,
  profilePicture,
  username
}) => {
  return (
    <Link to={`/user-profile/${username}`}>
      <div className="max-w-lg ">
        <div className=" flex items-center gap-3 p-4 mb-6 cursor-pointer hover:bg-white transition-colors duration-200 ease-in-out">
          <img
            src={profilePicture || DefaultProfilePng}
            className="w-14 h-14 object-cover rounded-full"
            alt=""
          />
          <div className="text-base font-medium ">
            <Link to={`user-profile/${username}`}>
              {first_name} {last_name}
            </Link>
            <br />
            <span className="font-normal">
              {role.charAt(0).toUpperCase() + role?.slice(1)}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default ProfileCard;
