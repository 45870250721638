import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonIcon from '@mui/icons-material/Person';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import OutboxIcon from '@mui/icons-material/Outbox';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';

const Options = ({ title, Icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center gap-3 px-4 py-3 text-sm hover:bg-gray-100 hover:text-gray-900 whitespace-pre cursor-pointer primary-svg"
    >
      {Icon && <Icon className="w-4 h-4 object-contain" />}
      {title}
    </div>
  );
};

const ContactFilter = ({
  setContactOption,
  onClickSentRequests,
  onClickFriendRequests,
  onClickSuggestions,
  onClickRoleRequest,
  onCloseClick
}) => {
  return (
    <div
      className=" mt-0  w-60  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-100"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="w-full flex flex-col gap-2 text-gray-500 ">
        <Options
          title="Friend Requests"
          Icon={UpcomingIcon}
          onClick={() => {
            onClickFriendRequests();
          }}
        />

        <Options
          title="Sent Requests"
          Icon={OutboxIcon}
          onClick={() => {
            onClickSentRequests();
          }}
        />

        {/* <Options
          title="Suggestions"
          Icon={PersonAddAlt1Icon}
          onClick={() => {
            onClickSuggestions();
          }}
        /> */}

        <Options
          title="Role Requests"
          Icon={CallMissedOutgoingIcon}
          onClick={() => {
            onClickRoleRequest();
          }}
        />

        <Options
          title="All Friends"
          Icon={PersonIcon}
          onClick={() => {
            setContactOption('all_friends');
            onCloseClick();
          }}
        />
      </div>
    </div>
  );
};

export default ContactFilter;
