import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: false,
  id: null
};

const DeleteAwardModalOpenSlice = createSlice({
  name: "deleteContactModalOpen",
  initialState,
  reducers: {
    openDeleteAwardModal: (state, action) => {
      state.modal = true;
    },
    closeDeleteAwardModal: (state, action) => {
      state.modal = false;
    },
    setDeleteId: (state, action) => {
      state.id = action.payload;
    }
  }
});

export const { openDeleteAwardModal, setDeleteId, closeDeleteAwardModal } =
  DeleteAwardModalOpenSlice.actions;

export default DeleteAwardModalOpenSlice.reducer;
