import { push as PushMenu, slide as SlideMenu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './ProfileSideNav.css';
import LogoWhite from '../../../img/logo-white.svg';
var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: 'white'
  },
  bmMenuWrap: {
    top: '0px',
    height: '100%'
  },
  bmMenu: {
    background: '#D09F2E',
    // padding: "2.5em 1em 0",
    fontSize: '1.15em',
    color: 'white'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: 'white',
    padding: '0.8em'
  },
  bmItem: {
    display: 'flex',
    flexDirection: 'column',

    height: '100%',
    margin: '0.5em 0em',
    ':hover': {
      color: '#FFBC00'
    }
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};

const ProfileSideNav = ({ isOpen, setSideNavOpen, windowWidth }) => {
  let history = useHistory();

  const handleLogOut = () => {
    history.push('/login');
  };

  const menuContent = () => {
    return (
      <div className="">
        <img
          className="ProfileSideNav-icon mb-4 -mt-3"
          src={LogoWhite}
          alt=""
        />
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-5">
            <Link className="cursor-pointer" to="/home">
              Home
            </Link>
            <Link className="cursor-pointer" to="/my-post">
              My Posts
            </Link>
            <Link className="cursor-pointer" to="/contact">
              Contacts
            </Link>
            <Link className="cursor-pointer" to="/message">
              Messages
            </Link>
            <Link className="cursor-pointer" to="/notification">
              Notifications
            </Link>
            <Link className="cursor-pointer" to="/schedule">
              Schedule
            </Link>
            <Link className="cursor-pointer" to="/my-profile">
              My Profile
            </Link>
            <Link className="cursor-pointer" to="/settings">
              Settings
            </Link>
          </div>
          <p className="cursor-pointer" onClick={handleLogOut}>
            Logout
          </p>
        </div>
      </div>
    );
  };
  const menuType = () => {
    return (
      <SlideMenu
        onStateChange={state => setSideNavOpen(state.isOpen)}
        noOverlay
        right
        styles={styles}
        pageWrapId={'page-wrap'}
        className={'ProfileSideNav'}
        outerContainerId={'outer-container'}
      >
        {menuContent()}
      </SlideMenu>
    );
  };
  return menuType();
};

export default ProfileSideNav;
