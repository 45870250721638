import React, { useState } from 'react';
import DeleteGameEvents from '../DeleteGameEvents';
import EditEventModal from './EditEventModal';
import EditGameModal from './EditGameModal';
import GameLayout from './GameLayout';
import EventLayout from './EventLayout';

const GameDetails = ({ customStyles, refetchGame, refetchEvent }) => {
  //
  //

  const [showDeleteGameModal, setShowDeleteGameModal] = useState(false);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);

  const [showEditGameModal, setShowEditGameModal] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);

  const [currentGameId, setCurrentGameId] = useState(null);

  const [currentEventId, setCurrentEventId] = useState(null);

  const onClickDeleteGame = id => {
    setCurrentGameId(id);
    setShowDeleteGameModal(true);
  };

  const onClickDeleteEvent = id => {
    setCurrentEventId(id);
    setShowDeleteEventModal(true);
  };

  return (
    <div className="result-div  rounded-lg">
      {/* edit modals */}
      {showEditEventModal && (
        <EditEventModal
          onCloseClick={() => setShowEditEventModal(false)}
          customStyles={customStyles}
          refetch={refetchEvent}
          editEvent={true}
        />
      )}

      {showEditGameModal && (
        <EditGameModal
          onCloseClick={() => setShowEditGameModal(false)}
          customStyles={customStyles}
          refetch={refetchGame}
          editGame={true}
        />
      )}

      {showDeleteGameModal && (
        <DeleteGameEvents
          type="game"
          title="Delete Game"
          id={currentGameId}
          onCloseClick={() => setShowDeleteGameModal(false)}
          refetchGame={refetchGame}
        />
      )}

      {showDeleteEventModal && (
        <DeleteGameEvents
          type="event"
          title="Delete Event"
          id={currentEventId}
          onCloseClick={() => setShowDeleteEventModal(false)}
          refetchEvent={refetchEvent}
        />
      )}

      <div className="w-full flex gap-4">
        {/* Games */}
        <GameLayout
          onClickDeleteGame={onClickDeleteGame}
          onClickEditGame={() => setShowEditGameModal(true)}
        />

        {/* Events */}
        <EventLayout
          onClickDeleteEvent={onClickDeleteEvent}
          onClickEditEvent={() => setShowEditEventModal(true)}
        />
      </div>
    </div>
  );
};

export default GameDetails;
