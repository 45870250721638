import React, { useState } from 'react';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import { ReactComponent as MoreSvg } from '../../img/misc/three-dot-horizontal.svg';
import Messages from './Messages';
import Input from './Input';
import MessageOption from './Modal/MessageOption';
import { useDispatch, useSelector } from 'react-redux';
import MessengerPng from '../../img/misc/messenger.png';
import profileImageUrl from '../../config/config';
import UpdateGroupModal from './Modal/GroupModal/UpdateGroupModal';
import ViewGroupMembers from './Modal/GroupModal/ViewGroupMembers';
import LeaveGroupChat from './Modal/GroupModal/LeaveGroupChat';
import DeleteGroupChat from './Modal/GroupModal/DeleteGroupChat';
import Popup from 'reactjs-popup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './userMessage.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setGroupChats, setUserChat } from '../../redux/userChats/UserChats';

function Chat() {
  const me = useSelector(state => state.auth.user);

  const history = useHistory();

  const dispatch = useDispatch();
  const userChatsSlice = useSelector(state => state.userChats.userInfo);

  const groupChatSlice = useSelector(state => state.userChats.groupChat);

  const [showGroupModal, setShowGroupModal] = useState(false);

  const [viewGroupMemebers, setViewGroupMemebers] = useState(false);

  const [toggle, setToggle] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const [showLeaveGrpChatModal, setShowLeaveGrpChatModal] = useState(false);

  return (
    <div className="chat relative">
      {showGroupModal && (
        <UpdateGroupModal onCloseClick={() => setShowGroupModal(false)} />
      )}

      {viewGroupMemebers && (
        <ViewGroupMembers onCloseClick={() => setViewGroupMemebers(false)} />
      )}

      {showLeaveGrpChatModal && (
        <LeaveGroupChat onCloseClick={() => setShowLeaveGrpChatModal(false)} />
      )}

      {showDeleteGroupModal && (
        <DeleteGroupChat onCloseClick={() => setShowLeaveGrpChatModal(false)} />
      )}

      {groupChatSlice ? (
        <>
          <div className="chatInfo flex justify-between items-center rounded-tr-lg">
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-4">
                <span
                  className="md:hidden flex"
                  onClick={() => {
                    dispatch(setGroupChats(null));
                    dispatch(setUserChat(null));
                    history.push('/message');
                  }}
                >
                  <ArrowBackIcon className="w-5 h-5 white-svg cursor-pointer" />
                </span>
                <img
                  src={DefaultProfilePng}
                  alt=""
                  className="w-12 h-12 object-cover bg-white rounded-full"
                />
              </div>
              <div className="flex flex-col text-white">
                <h3 className="font-roboto font-medium text-lg tracking-wider">
                  {groupChatSlice?.chatName}
                </h3>
                <span
                  className="font-roboto font-light text-base"
                  style={{ marginTop: '-4px' }}
                >
                  {groupChatSlice?.participants?.length + ' members'}
                </span>
              </div>
            </div>
            <Popup
              trigger={<MoreSvg className="more white-svg" />}
              closeOnDocumentClick
              keepTooltipInside={false}
              position={['bottom center']}
            >
              <MessageOption
                // setToggle={setToggle}
                setShowGroupModal={setShowGroupModal}
                setViewGroupMemebers={setViewGroupMemebers}
                setShowDeleteGroupModal={setShowDeleteGroupModal}
                setShowLeaveGrpChatModal={setShowLeaveGrpChatModal}
              />
            </Popup>
          </div>
          <Messages />
          <Input />
        </>
      ) : (
        <>
          {userChatsSlice?.participants
            ?.filter(friend => friend?.id !== me?.id)
            ?.map(friend => (
              <>
                <div
                  className="chatInfo flex justify-between items-center rounded-tr-lg"
                  key={friend?.id}
                >
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-4">
                      <span
                        className="md:hidden flex"
                        onClick={() => {
                          dispatch(setGroupChats(null));
                          dispatch(setUserChat(null));
                          history.push('/message');
                        }}
                      >
                        <ArrowBackIcon className="w-5 h-5 white-svg cursor-pointer" />
                      </span>

                      <img
                        src={
                          (friend?.contact?.profile_image &&
                            profileImageUrl +
                              friend?.contact?.profile_image?.url) ||
                          DefaultProfilePng
                        }
                        alt=""
                        className="w-12 h-12 object-cover bg-white rounded-full"
                      />
                    </div>
                    <div className="flex flex-col text-white">
                      <h3 className="font-roboto font-medium text-lg tracking-wider">
                        {friend?.contact?.first_name +
                          ' ' +
                          friend?.contact?.last_name}
                      </h3>
                      <span
                        className="font-roboto font-light text-base"
                        style={{ marginTop: '-4px' }}
                      >
                        {friend?.contact?.email}
                      </span>
                    </div>
                  </div>
                  <Popup
                    trigger={<MoreSvg className="more white-svg" />}
                    closeOnDocumentClick
                    keepTooltipInside={false}
                    position={['bottom center']}
                  >
                    <MessageOption
                      setShowGroupModal={setShowGroupModal}
                      setViewGroupMemebers={setViewGroupMemebers}
                      setShowDeleteGroupModal={setShowDeleteGroupModal}
                      setShowLeaveGrpChatModal={setShowLeaveGrpChatModal}
                    />
                  </Popup>
                </div>
                <Messages />
                <Input />
              </>
            ))}
        </>
      )}

      {groupChatSlice === null && userChatsSlice === null && (
        <div className="bg-white min-w-full min-h-full hidden md:flex justify-center items-center rounded-tr-lg">
          <div className="flex flex-col items-center gap-4">
            <img src={MessengerPng} alt="" className="w-48 h-48 object-cover" />
            <p className="font-medium text-gray-500 text-lg">
              Send private photos and messages to a friend or group
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
