import React from "react";
import Modal from "../../../../components/Modal/Modal";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { addPlayerClubHistory } from "../../../../services/routes/PlayerHistories/playerClubHistories";

const AddPlayerHistory = ({ onCloseClick }) => {
  //
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm();

  const { mutate, isLoading } = useMutation(addPlayerClubHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("playerClub");
      onCloseClick();
    },
    onError: err => {}
  });

  const onsubmit = data => {
    const formData = new FormData();

    const { games, goals, assists } = data;

    // formData.append("clubName", clubName);
    // formData.append("season", season);
    // formData.append("frequent_position", frequent_position);
    // formData.append("team", team);
    // formData.append("region", region);
    // formData.append("games", parseInt(games));
    // formData.append("goals", parseInt(goals));
    // formData.append("wins", parseInt(wins));

    mutate({
      ...data,
      games: parseInt(games),
      goals: parseInt(goals),
      assists: parseInt(assists)
    });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Player History" width="450px">
      <div className="bg-white rounded-br-lg rounded-bl-lg">
        <form className="flex flex-col p-6" onSubmit={handleSubmit(onsubmit)}>
          <div className="w-full flex flex-col mb-8">
            <label className="text-sm font-medium text-gray-700">Club</label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
              type="text"
              name="clubName"
              ref={register({
                required: true
              })}
            />
            {errors.clubName && <p>{errors.clubName.message}</p>}
          </div>

          {/*  */}
          <div className="w-full  items-center gap-4 grid grid-cols-2 mb-8">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Season
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="date"
                name="season"
                ref={register({
                  required: true
                })}
              />
              {errors.season && <p>{errors.season.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">Team</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="team"
                ref={register({
                  required: true
                })}
              />
              {errors.team && <p>{errors.team.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Region
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="region"
                ref={register({
                  required: true
                })}
              />
              {errors.region && <p>{errors.region.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Frequent Position
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="text"
                name="frequent_position"
                ref={register({
                  required: true
                })}
              />
              {errors.frequent_position && (
                <p>{errors.frequent_position.message}</p>
              )}
            </div>
          </div>

          {/*  */}
          <div className="w-full flex items-center gap-4 mb-8">
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Games</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="games"
                ref={register({
                  required: true
                })}
              />
              {errors.games && <p>{errors.games.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Goals</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="goals"
                ref={register({
                  required: true
                })}
              />
              {errors.goals && <p>{errors.goals.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Wins</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="assists"
                ref={register({
                  required: true
                })}
              />
              {errors.assists && <p>{errors.assists.message}</p>}
            </div>
          </div>

          {/* Submit */}
          <div className="w-full flex justify-start ">
            <button type="submit" className="w-28">
              <AcceptButton title={isLoading ? "Saving..." : "Save"} />
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddPlayerHistory;
