function VisibilitySettings({ title, name, register, onChange }) {
  return (
    <div className="w-full">
      <label className="font-roboto font-medium text-base text-black">
        {title}
      </label>
      <select
        name={name}
        onChange={onChange}
        className="form-select w-full text-left mt-1 text-md px-3 rounded-md font-light text-base  text-black bg-white"
        ref={register({ type: name })}
      >
        <option value="everyone">Everyone</option>
        <option value="myContacts">My Contacts</option>
      </select>
    </div>
  );
}

export default VisibilitySettings;
