import { useEffect, useState } from 'react';
import {
  deleteAllNotification,
  getAllUserRequestNotification,
  markAllAsRead,
  markAsRead
} from '../../services/routes/Notification';
import SideNavWrapper from '../SideNavWrapper';
import LoaderGif from '../../img/busy.gif';
import { toast } from 'react-toastify';
import Notification from './Notification';
import { useQuery, useQueryClient } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';

const Loader = () => {
  return (
    <div className="flex justify-center items-center max-h-full">
      <img
        src={LoaderGif} // Loader is an image
        alt=""
        className="w-20 h-20 object-contain"
      />
    </div>
  );
};

const Notifications = () => {
  //
  //

  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  const [hasMore, setHasMore] = useState(true);

  const [notifications, setNotifications] = useState([]);

  const { refetch, isLoading } = useQuery(
    'fetch_notification',
    () => getAllUserRequestNotification(page),
    {
      onSuccess: data => {
        setNotifications(prevData => [...prevData, ...data?.data]);
        data?.data?.length > 0 ? setHasMore(true) : setHasMore(false);
        setPage(prevPage => prevPage + 1);
      },
      onError: () => {
        setHasMore(false);
      }
    }
  );

  // passing this function to the each notification components and grabbing the id
  const handleMarkasread = async id => {
    try {
      await markAsRead({ id });
      queryClient.invalidateQueries('fetch_notification');
    } catch (error) {}
  };

  const onMarkallAsSeen = async () => {
    try {
      await markAllAsRead().then(() => {
        queryClient.invalidateQueries('fetch_notification');
      });
      toast.success('All notifications marked as read');
    } catch (error) {
      toast.error('Error while marking all notifications as read');
    }
  };

  const onDeleteAllNotification = async () => {
    try {
      await deleteAllNotification();
      toast.success('All notifications are deleted');
    } catch (error) {
      toast.error('Error while deleting all notifications');
    }
  };

  return (
    <SideNavWrapper width={'100%'} isFooter={true}>
      <div className="flex w-full items-center justify-center flex-col">
        <div className="md:w-2/5 w-full flex justify-between items-center mb-3 mt-2">
          <h3
            className="font-semibold text-brand-light text-xl md:pl-0 pl-4"
            onClick={refetch}
          >
            Notifications
          </h3>
        </div>

        <div className="md:w-2/5 w-11/12 py-2 pl-2  bg-white rounded-lg">
          <div className="flex flex-col h-full">
            {/* Only New Notification which are not seen */}
            <div className=" pl-2 ">
              <div className="flex px-2 py-1 items-center gap-2">
                <span
                  className={`  text-lg text-black rounded-full font-medium`}
                >
                  New
                </span>
                <span
                  onClick={onMarkallAsSeen}
                  className={` cursor-pointer text-sm text-black rounded-full font-light`}
                >
                  Mark all as read
                </span>
              </div>

              {/* // If there is no notification */}
              {!isLoading && notifications?.length === 0 && (
                <div className="flex justify-center items-center h-96">
                  <span className="text-center text-gray-500 font-medium text-lg">
                    You have no any notification.
                  </span>
                </div>
              )}

              {/* {isLoading && <Loader />} */}

              <div
                id="scroll-container"
                className="w-full overflow-y-scroll notification max-h-72 "
              >
                {notifications?.filter(
                  notification =>
                    notification?.title !== 'New Message' &&
                    notification?.status !== 'seen'
                )?.length !== 0 && (
                  <InfiniteScroll
                    dataLength={notifications?.length}
                    next={refetch}
                    hasMore={hasMore}
                    loader={<Loader />}
                    endMessage={
                      notifications?.filter(
                        notification =>
                          notification?.title !== 'New Message' &&
                          notification?.status !== 'seen'
                      )?.length === 0 && (
                        <p className="text-center my-5 text-gray-800">
                          <b>No new notification to show</b>
                        </p>
                      )
                    }
                  >
                    {notifications
                      ?.filter(notification => notification.status !== 'seen')
                      .map(notification => (
                        <Notification
                          key={notification.id}
                          notification={notification}
                          handleMarkasread={handleMarkasread}
                        />
                      ))}
                  </InfiniteScroll>
                )}
              </div>
            </div>

            <div className="w-full px-8 my-3 ">
              <hr />
            </div>
            {/* Only Show Notification which are seen */}
            {notifications?.length !== 0 &&
              notifications?.filter(
                notification => notification.status === 'seen'
              )?.length !== 0 && (
                <InfiniteScroll
                  dataLength={notifications?.length}
                  next={refetch}
                  hasMore={hasMore}
                  loader={<Loader />}
                >
                  <div className="pl-2 py-1">
                    <span
                      className={` px-2 py-1 text-lg text-black rounded-full font-medium`}
                    >
                      Previous
                    </span>
                    <div className="w-full  overflow-y-scroll notification max-h-60">
                      {notifications
                        ?.filter(notification => notification.status === 'seen') // Filter the notifications which are seen
                        .map(notification => (
                          <Notification
                            key={notification.id}
                            notification={notification}
                            handleMarkasread={handleMarkasread}
                          />
                        ))}
                    </div>
                  </div>
                </InfiniteScroll>
              )}
          </div>
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default Notifications;
