import AxiosService from '../../../AxiosService';

export const createGame = async data => {
  const response = await AxiosService.post('/games/create', data);
  return response.data;
};

export const updateGame = async ({ id, data }) => {
  const response = await AxiosService.put(`/games/game/${id}`, data);
  return response.data;
};

export const deleteGame = async ({ id }) => {
  const response = await AxiosService.delete(`/games/game/${id}/`);
  return response.data;
};

export const getAllGames = async () => {
  const response = await AxiosService.get('/games/games/');
  return response.data;
};

export const getGameByOwner = async ({ id }) => {
  const response = await AxiosService.get(`/games/game/${id}`);
  return response.data;
};

export const getGameByPlayer = async ({ id }) => {
  const response = await AxiosService.get(`/games/player-game/${id}`);
  return response.data;
};

//player list
