import { useState } from 'react';
import SideNavWrapper from '../SideNavWrapper';
import SettingsLeftNav from './SettingsLeftNav';
import SocialMedia from './SocialMedia';
import AccountSetting from './AccountSettings/index';
import PrivacySettings from './PrivacySettings/PrivacySettings';
import Notifications from './Notifications/Notifications';
import BlockUsers from './BlockUsers/BlockUsers';
import HeadingTitle from '../../components/HeadingTitle/HeadingTitle';
import './Settings.scss';

const Settings = () => {
  const [selectedNav, setSelectedNav] = useState('account-settings');

  return (
    <SideNavWrapper width="100%" isFooter={true}>
      <div className="w-full flex justify-center ">
        <div className="Settings" style={{ width: '40rem' }}>
          <HeadingTitle title="Settings" />

          <div className="flex md:flex-row flex-col items-center md:items-start mt-8 justify-center ">
            <div className="max-w-xs">
              <SettingsLeftNav
                selectedNav={selectedNav}
                setSelectedNav={setSelectedNav}
              />
            </div>
            {selectedNav === 'account-settings' && <AccountSetting />}
            {selectedNav === 'social-media' && <SocialMedia />}
            {selectedNav === 'privacy-settings' && <PrivacySettings />}
            {selectedNav === 'notifications' && <Notifications />}
            {selectedNav === 'block-users' && <BlockUsers />}
          </div>
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default Settings;
