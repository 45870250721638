import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Modal from "../../../components/Modal/Modal";
import AcceptButton from "../../../components/Buttons/AcceptButton";
import { addClubSeasonHistory } from "../../../services/routes/ClubHistories/seasonHistories";

const AddClubSeasonHistory = ({ onCloseClick }) => {
  //
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm();

  const { mutate, isLoading } = useMutation(addClubSeasonHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("fetch_allSeasonHistory");
      onCloseClick();
    },
    onError: err => {}
  });

  const onsubmit = data => {
    const { games, wins, losses, goals, draws, goalsConceded } = data;

    mutate({
      ...data,
      games: parseInt(games),
      goals: parseInt(goals),
      wins: parseInt(wins),
      losses: parseInt(losses),
      draws: parseInt(draws),
      goalsConceded: parseInt(goalsConceded)
    });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Player History" width="450px">
      <div className="bg-white rounded-br-lg rounded-bl-lg">
        <form className="flex flex-col p-6" onSubmit={handleSubmit(onsubmit)}>
          {/*  */}
          <div className="w-full  items-center gap-4 grid grid-cols-2 mb-8">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Season From
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="date"
                name="seasonFrom"
                ref={register({
                  required: true
                })}
              />
              {errors.seasonFrom && <p>{errors.seasonFrom.message}</p>}
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Season To
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="date"
                name="seasonTo"
                ref={register({
                  required: true
                })}
              />
              {errors.seasonTo && <p>{errors.seasonTo.message}</p>}
            </div>
          </div>

          {/*  */}

          <div className="w-full flex items-center gap-4 mb-8">
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Games</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="games"
                ref={register({
                  required: true
                })}
              />
              {errors.games && <p>{errors.games.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Wins</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="wins"
                ref={register({
                  required: true
                })}
              />
              {errors.wins && <p>{errors.wins.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">
                Losses
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="losses"
                ref={register({
                  required: true
                })}
              />
              {errors.losses && <p>{errors.losses.message}</p>}
            </div>
          </div>

          {/*  */}
          <div className="w-full flex items-center gap-4 mb-8">
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Draws</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="draws"
                ref={register({
                  required: true
                })}
              />
              {errors.draws && <p>{errors.draws.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">Goals</label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="goals"
                ref={register({
                  required: true
                })}
              />
              {errors.goals && <p>{errors.goals.message}</p>}
            </div>
            <div className="flex flex-col" style={{ width: "130px" }}>
              <label className="text-sm font-medium text-gray-700">
                Goals Conceded
              </label>
              <input
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                type="number"
                name="goalsConceded"
                ref={register({
                  required: true
                })}
              />
              {errors.goalsConceded && <p>{errors.goalsConceded.message}</p>}
            </div>
          </div>

          {/* Submit */}
          <div className="w-full flex justify-start ">
            <button type="submit" className="w-28">
              <AcceptButton title={isLoading ? "Saving..." : "Save"} />
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddClubSeasonHistory;
