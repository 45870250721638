import moment from 'moment';
import DefaultProfilePng from '../../img/misc/profile-placeholder.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import profileImageUrl from '../../config/config';

const FriendRequestAcceptedNotification = ({
  id,
  content,
  profileImage,
  acceptedDate,
  profile,
  handleMarkasread
}) => {
  const history = useHistory();

  const handleClickNotification = () => {
    handleMarkasread(id);
    history.push(`user-profile/${profile?.contact?.username}`);
  };

  return (
    <div
      onClick={handleClickNotification}
      className="relative w-4/5 flex flex-col gap-2 mt-1 py-2  p-1 cursor-pointer "
    >
      <div className="flex items-center gap-2">
        <img
          src={
            (profile?.contact?.profile_image?.url &&
              profileImageUrl + profile?.contact?.profile_image?.url) ||
            DefaultProfilePng
          }
          className="w-14 h-14 object-fill rounded-full"
          alt=""
        />
        <h3 className="flex flex-col font-medium text-sm  font-roboto">
          {content}
          <span className="font-normal font-roboto text-xs text-gray-500">
            {moment(acceptedDate)?.fromNow()}
          </span>
        </h3>
      </div>
    </div>
  );
};

export default FriendRequestAcceptedNotification;
