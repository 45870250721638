import React, { useEffect, useRef, useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MessageIcon from '@mui/icons-material/Message';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { ReactComponent as ArrowupSvg } from '../../img/activity/arrowUp.svg';
import { ReactComponent as ArrowdownSvg } from '../../img/activity/arrowDown.svg';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import UserProfileOption from '../Profile/UserProfileOption';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkFriendRequest,
  deleteFriendRequest,
  sendFriendRequest
} from '../../services/routes/UserFriendRequest';
import profileImageUrl from '../../config/config';
import './ProfileUserCard.css';
import SmallLoader from '../Loader/SmallLoader';
import useClickOutside from '../useClickOutside/useClickOutside';
import { accessChatToUser } from '../../services/routes/chats';
import { setGroupChats, setUserChat } from '../../redux/userChats/UserChats';
import { socket } from '../SocketIo';

export default function ProfileUserCard({
  setShowDirectMessageModal,
  setFriendRequest,
  isRequestReceived
}) {
  //
  const dispatch = useDispatch();
  const myId = useSelector(state => state.auth.user?.id);
  const user = useSelector(state => state.visitProfile.userProfile);
  const userId = useSelector(state => state.visitProfile.userProfile?.id);
  const [isLoading, setIsLoading] = useState(true);

  const [showDropdownItem, setShowDropdownItem] = useState(false);

  const [requestId, setRequestId] = useState({});

  // using ref to close modal when clicking outside of the modal
  const userProfileOptionRef = useRef();
  const [sentFriendRequest, setSentFriendRequest] = useState(false);
  const [requestStatus, setRequestStatus] = useState('NONE');

  // To See if the user has already sent the request or not
  useEffect(() => {
    checkUserFriendRequest();
  }, []);

  const checkUserFriendRequest = async () => {
    try {
      setIsLoading(true);
      await checkFriendRequest({ id: userId }).then(data => {
        setSentFriendRequest(data?.data?.userId === myId ? true : false);
        setRequestId(data?.data?.id);
        setFriendRequest(data?.data);
        setRequestStatus(data?.data?.status);
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Handle send Friend to the user
  const handleSendFriendRequest = async () => {
    try {
      await sendFriendRequest({ id: userId }).then(async () => {
        setSentFriendRequest(true);
        await checkUserFriendRequest();
      });
    } catch (error) {}
  };

  // Delete the sent friend Request
  const handleDeleteFriendRequest = async () => {
    try {
      await deleteFriendRequest({ id: requestId }).then(async () => {
        setSentFriendRequest(false);
        await checkUserFriendRequest().then(data => setRequestStatus('NONE'));
      });
    } catch (error) {}
  };

  const onClickMessageContact = async () => {
    try {
      setShowDirectMessageModal(true);
      dispatch(setGroupChats(null));
      await accessChatToUser(userId).then(data => {
        dispatch(setUserChat(data?.data));
        socket.emit('joinChat', { chatId: data?.data?.id });
      });
    } catch (error) {}
  };

  // Handle  Button while clicking outside of the modal
  useClickOutside([userProfileOptionRef], () => setShowDropdownItem(false));

  return (
    <div className="player-card relative">
      <div className="mt-36">
        <img
          src={
            (user?.contact?.profile_image &&
              profileImageUrl + user?.contact?.profile_image?.url) ||
            DefaultProfilePng
          }
          className="object-cover w-52 h-52 bg-white rounded-full z-0"
          alt=""
        />
      </div>
      <div
        className="absolute md:flex hidden items-center gap-3 bottom-4 left-52 "
        style={{ height: '36px' }}
      >
        {isRequestReceived && (
          <>
            <button
              className="w-40 cursor-not-allowed h-full flex justify-center items-center transition-all ease-out bg-brand-dark text-white rounded-md focus:outline-none focus:ring focus:ring-violet-300"
              disabled={true}
            >
              {isLoading ? (
                <SmallLoader />
              ) : (
                <>
                  <PersonRemoveIcon className="mr-2" />
                  Pending
                </>
              )}
            </button>
          </>
        )}
        <>
          {/* // if the users are friend */}
          {requestStatus === 'ACCEPTED' && (
            <>
              <button className="w-40 h-full flex justify-center items-center transition-all ease-out bg-brand-dark text-white rounded-md focus:outline-none focus:ring focus:ring-violet-300">
                {isLoading ? (
                  <SmallLoader />
                ) : (
                  <>
                    <PersonRemoveIcon className="mr-2" />
                    Friends
                  </>
                )}
              </button>
            </>
          )}

          {sentFriendRequest && requestStatus === 'PENDING' && (
            <button
              className="w-40 h-full flex justify-center items-center transition-all ease-out bg-brand-dark text-white rounded-md focus:outline-none focus:ring focus:ring-violet-300"
              onClick={handleDeleteFriendRequest}
            >
              {isLoading ? (
                <SmallLoader />
              ) : (
                <>
                  <PersonRemoveIcon className="mr-2" />
                  Cancel Request
                </>
              )}
            </button>
          )}

          {requestStatus === 'NONE' && (
            <button
              className="w-40 h-full flex justify-center items-center transition-all ease-out bg-brand-dark text-white rounded-md focus:outline-none focus:ring focus:ring-violet-300"
              onClick={handleSendFriendRequest}
            >
              <PersonAddIcon className="mr-2" />
              Send Request
            </button>
          )}
        </>

        <button
          onClick={onClickMessageContact}
          className="w-36 h-full flex justify-center items-center bg-brand-dark text-white rounded-md focus:outline-none focus:ring focus:ring-violet-300"
        >
          <MessageIcon className="mr-2" />
          Message
        </button>
        <span
          className="bg-brand-dark items-center flex justify-center px-4 rounded-md h-full cursor-pointer relative"
          onClick={() => setShowDropdownItem(!showDropdownItem)}
          ref={userProfileOptionRef}
        >
          {!showDropdownItem ? (
            <ArrowdownSvg className="right-widget" />
          ) : (
            <ArrowupSvg className="right-widget" />
          )}
          {showDropdownItem && (
            <div
              className="absolute right-44 w-full"
              style={{ bottom: '-4rem' }}
            >
              <UserProfileOption
                onCloseClick={() => setShowDropdownItem(false)}
              />
            </div>
          )}
        </span>
      </div>
    </div>
  );
}
