import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";

import CreatableSelect from "react-select/creatable";
import "./modal.css";
import busyIcon from "../../../../img/busy.gif";

import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const EditlistModal = ({ selected, onCloseClick }) => {
  /////// tagg///

  const [tags, setTags] = useState([]);

  //////
  const [selectedPlayerList, setSelectedPlayerList] = useState();
  const [listName, setListName] = useState("");
  const [description, setDescription] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const [message, setMessage] = useState("");

  const [busy, setBusy] = useState(false);

  const [newRegion, setNewRegion] = useState();

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const prepareRegionsList = () => {};

  useEffect(() => {
    prepareRegionsList();
    setSelectedPlayerList(selected);
    setListName(selected.name);
    setDescription(selected.description);
    const mapped = selected.tags.map(t => {
      return { id: t, text: t };
    });
    setTags(mapped);
  }, []);

  //////////////

  const handleInputChange = newValue => {
    if (newValue !== "") {
      setNewRegion(newValue);
    }
  };

  const createPlayerListNow = async region_id => {
    if (tags.length > 0) {
      var tagString = "";
      for (let t of tags) {
        tagString += t.text + ",";
      }
    }
  };

  const handleSave = async () => {
    if (
      selectedOption === undefined ||
      selectedOption.value === selectedOption.label
    ) {
      if (newRegion !== "") {
      }
      // }
    } else {
      createPlayerListNow(selectedOption.value);
    }
  };

  const onTextchange = e => {
    setListName(e.target.value);
  };

  const onTextchange1 = e => {
    setDescription(e.target.value);
  };

  const handleChange = selected => {
    setSelectedOption(selected);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Edit Player List">
      <div className="px-4 py-4 bg-white flex-row">
        <div className="w-full mb-6">
          <p className="py-2 text-black  ">Name</p>
          <input
            className="w-full text-left  text-md py-2 px-3 text-black bg-normalGray-light"
            type="text"
            id="name"
            name="name"
            value={listName}
            onChange={e => {
              onTextchange(e);
            }}
          />
        </div>
        <p className="py-2 text-black  ">Description</p>
        <textarea
          className="w-full bg-normalGray-light p-2 h-54"
          name=""
          id=""
          value={description}
          onChange={e => {
            onTextchange1(e);
          }}
          cols="20"
        ></textarea>

        <div className="w-full mb-6">
          <p className="py-2 text-black  ">Tags</p>
          {/* 
      <Select
        className="w-full "
        value={selectedOption}
        placeholder={"Search Players"}
       
        onChange={handleChange}
       
        isMulti
        isSearchable
        options={players}
      /> */}

          <ReactTags
            tags={tags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            delimiters={delimiters}
            className="tags-select"
          />
        </div>
        <div className="w-full mb-6">
          <p className="py-2 text-black  ">Region</p>

          <CreatableSelect
            className="CreatableSelect"
            value={selectedOption}
            placeholder={"Search/Add Regions"}
            onInputChange={handleInputChange}
            onChange={handleChange}
            isClearable
            isSearchable
            options={regions}
          />
        </div>

        <div className="w-1/3 flex flex-row  mt-16  mr-4">
          <button
            onClick={handleSave}
            className="w-2/3 text-center  focus:outline-none text-lg   text-white bg-normalGray hover:bg-brand  theme-button"
          >
            Save
          </button>
          <div className="w-1/3 my-auto">
            {busy && (
              <img
                style={{ width: "30px", height: "30px", marginTop: "5px" }}
                className="busy"
                src={busyIcon}
                alt=""
              />
            )}
          </div>
        </div>
        {message !== "" && <div className="text-red-600">{message}</div>}
      </div>
    </Modal>
  );
};
export default EditlistModal;
