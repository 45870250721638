import { useForm } from "react-hook-form";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import Modal from "../../../../components/Modal/Modal";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { editPlayerCoachHistory } from "../../../../services/routes/PlayerHistories/playerCoachHistories";

const EditPlayerCoachHistory = ({ onCloseClick, setData }) => {
  //
  //
  let countryValue = "us";
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      coachName: setData.coachName,
      coachEmail: setData.coachEmail,
      coachType: setData.coachType,
      coachClub: setData.coachClub,
      coachPhone: setData.coachPhone
    }
  });

  const queryClient = useQueryClient();

  const [phoneNumber, setPhoneNumber] = useState("");

  const { mutate, isLoading } = useMutation(editPlayerCoachHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("playerCoach");
      onCloseClick();
    },
    onError: err => {
      console.error(err);
    }
  });

  const onSubmit = async data => {
    const formData = new FormData();

    const { coachName, coachEmail, coachType, coachClub } = data;

    formData.append("coachName", coachName);
    formData.append("coachEmail", coachEmail);
    formData.append("coachType", coachType);
    formData.append("coachClub", coachClub);
    formData.append("coachPhone", phoneNumber);

    const sendDataToServer = { id: setData.edit, data: formData };

    mutate(sendDataToServer);
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title="Add Player Coach History"
      width="500px"
    >
      <div className="AddAwardModal px-4 py-5 bg-white">
        <form
          className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full bg-white mx-3">
            <div className="w-full flex items-center gap-4 mb-4">
              <div className="w-1/2 ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Name
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  id="coachName"
                  name="coachName"
                  ref={register({
                    type: "coachName"
                  })}
                />
                {errors.coachName && <p>{errors.coachName.message}</p>}
              </div>
              <div className="w-1/2 ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Email
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  id="coachEmail"
                  ref={register({
                    type: "coachEmail"
                  })}
                  name="coachEmail"
                />
                {errors.coachEmail && <p>{errors.coachEmail.message}</p>}
              </div>
            </div>

            <div className="w-full flex items-center gap-4 mb-4">
              <div className="w-1/2 ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Club
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  id="coachClub"
                  ref={register({
                    type: "coachClub"
                  })}
                  name="coachClub"
                />
                {errors.coachClub && <p>{errors.coachClub.message}</p>}
              </div>
              <div className="w-1/2 ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Type
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  id="coachType"
                  ref={register({
                    type: "coachType"
                  })}
                  name="coachType"
                />
                {errors.coachType && <p>{errors.coachType.message}</p>}
              </div>
            </div>

            <PhoneInput
              country={countryValue}
              value={phoneNumber}
              onChange={phone => setPhoneNumber(phone)}
              id="coachPhone"
              ref={register({
                type: "coachPhone"
              })}
              inputStyle={{
                background: "transparent",
                border: "none",
                fontWeight: "400",
                fontSize: "17px"
              }}
              className="block w-full text-left py-0.5 mb-4 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              name="coachPhone"
            />

            {/* <input
              type="file"
              multiple
              className=" border-gray-200 border rounded-sm shadow-sm w-max p-1 flex justify-center mb-4"
              onChange={handleFileChange}
            /> */}

            <div className="w-full flex justify-start">
              <button
                type="submit"
                className={`w-28 ${isLoading & "cursor-not-allowed"}`}
              >
                <AcceptButton title={isLoading ? "Saving..." : "Save"} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditPlayerCoachHistory;
