import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OptionModal from './OptionModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReactComponent as Flag } from '../../../img/misc/flag.svg';
import { ReactComponent as Location } from '../../../img/misc/location.svg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGameDetail } from '../../../redux/schedule/gameDetail';
import Popup from 'reactjs-popup';

const BreakLine = () => {
  return (
    <div className="w-full px-5 my-2">
      <hr className="w-full  text-gray-500" />
    </div>
  );
};

const GameLayoutDetail = ({ game, onClickDeleteGame, onClickEditGame }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [expandGameDetail, setExpandGameDetail] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);

  useEffect(() => {
    if (user?.role !== 'player') {
      setShowEditOption(!showEditOption);
    }
  }, [user?.role]);

  return (
    <div className="relative bg-white max-h-full rounded-lg shadow-md mb-2">
      <div className="w-full  relative flex justify-between items-center py-2 px-3 ">
        <span className="bg-transparent rounded-md py-1 border border-brand-dark text-brand-dark text-sm font-roboto font-medium px-4 ">
          Next Game
        </span>

        <Popup
          trigger={
            <MoreHorizIcon className="text-gray-500 hover:text-black cursor-pointer" />
          }
          closeOnDocumentClick
          keepTooltipInside={false}
          position={['bottom center']}
        >
          <OptionModal
            onDeleteOptionClick={() => {
              onClickDeleteGame(game?.id);
            }}
            onEditOptionClick={() => {
              dispatch(setGameDetail([game]));
              onClickEditGame();
            }}
          />
        </Popup>
      </div>

      {/* Displaying Games Details */}

      <>
        <div className="pl-4">
          <p className="next-game">
            {moment(game?.scheduledDate).format('dddd, MMMM Do [at] h:mm a')}
          </p>
          <p className="text-gray-600 text-sm">{`(arrive ${
            game?.arrivalTime ? game?.arrivalTime : 'at anytime'
          })`}</p>
          <div className=" flex items-center gap-3 my-2">
            <div className="flex items-baseline gap-2">
              <Flag className="w-5 h-5" />
              <p className="text-xs  text-gray-600"> vs F.C. Porto </p>
            </div>
            <div className="flex items-baseline gap-2">
              <Location className="w-5 h-5" />
              <p className="text-xs text-gray-600">
                {game?.playGround ? game?.playGround : 'Home'}
              </p>
            </div>
          </div>
        </div>

        {!expandGameDetail && (
          <div
            className="bg-brand-dark w-full rounded-b-lg flex justify-center cursor-pointer"
            onClick={() => setExpandGameDetail(true)}
          >
            <ExpandMoreIcon className="text-white" />
          </div>
        )}

        {/* Show this when on expand */}
        {expandGameDetail && (
          <>
            <BreakLine />
            <div className="flex flex-col px-4">
              <label
                htmlFor=""
                className="text-sm font-roboto text-gray-600 font-medium"
              >
                Optional Game Info
              </label>
              <span className="p-2 text-sm text-gray-800">None</span>
            </div>

            <BreakLine />
            <div className="flex flex-col px-4">
              <label
                htmlFor=""
                className="text-sm font-roboto text-gray-600 font-medium"
              >
                Assignments
              </label>
              {game?.assignments?.map(assignment => (
                <div key={assignment?.id} className="flex items-center gap-16">
                  <span className="py-3 px-2 text-sm text-gray-800">
                    {assignment?.name}
                  </span>
                  <p className="text-sm text-black font-normal">
                    {assignment?.player?.User?.contact?.first_name +
                      ' ' +
                      assignment?.player?.User?.contact?.last_name}
                  </p>
                </div>
              ))}
            </div>
            <BreakLine />
            <div className="flex flex-col px-4">
              <label
                htmlFor=""
                className="text-sm font-roboto text-gray-600 font-medium"
              >
                Team Members
              </label>

              <div className="grid grid-cols-3 ">
                {game?.players?.map(player => (
                  <span
                    key={player?.id}
                    className="py-3 px-2 text-sm text-gray-600 whitespace-pre truncate"
                  >
                    {player?.User?.contact?.first_name +
                      ' ' +
                      player?.User?.contact?.last_name}
                  </span>
                ))}
              </div>
            </div>

            <div
              className="bg-brand-dark w-full rounded-b-lg flex justify-center cursor-pointer"
              onClick={() => setExpandGameDetail(false)}
            >
              <ExpandLessIcon className="text-white" />
            </div>
          </>
        )}
      </>

      {/* Not in Use, Please don't delete this */}
      <>
        {/* <div className=" mt-3 flex justify-center ">
              <PlayerOnEvents
                title={'Going'}
                number={
                  game?.scheduleAvailability?.going?.length + markCount
                }
                className="border-r-0 border-2 rounded-l-xl hover:bg-green-400 "
                updateMark={markGoingGame}
                id={game?.id}
              />
              <PlayerOnEvents
                title={'Maybe'}
                number={
                  game?.scheduleAvailability?.maybe?.length + markCount
                }
                className="border-2 border-r-0  hover:bg-brand-light "
                updateMark={markMaybeGame}
                id={game?.id}
              />
              <PlayerOnEvents
                title={'No'}
                number={
                  game?.scheduleAvailability?.no?.length + markCount
                }
                className="border-2 rounded-r-xl hover:bg-red-500 "
                updateMark={markNoGame}
                id={game?.id}
              />
            </div> */}
      </>
    </div>
  );
};

export default GameLayoutDetail;
