import React from "react";
import { useForm } from "react-hook-form";
import AcceptButton from "../Buttons/AcceptButton";

function InviteClubModal({ onCloseClick, setInviteClub, inviteClub }) {
  //
  //
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = data => {
    onCloseClick();
  };
  return (
    <div className="w-full  py-5 px-5 bg-white">
      <form
        className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full bg-white">
          <div className="w-full mb-4">
            <label className="text-sm font-medium">Club Name</label>
            <input
              className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
              type="text"
              name="club"
              ref={register({
                type: "club",
                required: "Club name required"
              })}
            />
            {errors.club && <p>{errors.club.message}</p>}
          </div>{" "}
          <button type="submit" className="mt-4">
            <AcceptButton title="Send Request" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default InviteClubModal;
