import ArchivePng from '../../../img/misc/archive.png';
import DeletePng from '../../../img/misc/delete-convo.png';
import MarkAsReadPng from '../../../img/misc/mark-as-read.png';
import MutePng from '../../../img/misc/mute.png';
import BlockedPng from '../../../img/misc/blocked.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const MessageOption = ({
  setToogle,
  setShowGroupModal,
  setShowLeaveGrpChatModal,
  setViewGroupMemebers,
  setShowDeleteGroupModal
}) => {
  const [showGroupOption, setShowGroupOption] = useState(false);

  const groupChatSlice = useSelector(state => state.userChats.groupChat);

  useEffect(() => {
    if (groupChatSlice !== null) {
      setShowGroupOption(true);
    } else {
      setShowGroupOption(false);
    }
  }, [groupChatSlice]);

  const optionLabel = (imgSrc, Icon, label, onClick) => {
    return (
      <div className="block px-3 py-2 text-sm  text-gray-700 hover:bg-gray-100 hover:text-gray-900">
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => onClick()}
        >
          {imgSrc && (
            <img src={imgSrc} className="w-6 h-6 object-contain" alt="" />
          )}
          {Icon && <Icon className="w-6 h-6 object-contain text-gray-400" />}
          {label}
        </div>
      </div>
    );
  };

  return (
    <div
      className=" w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="py-1" role="none">
        {/* Delete */}
        {optionLabel(DeletePng, null, 'Delete Group', () => {
          setShowDeleteGroupModal(true);
          // setToogle(false);
        })}

        {showGroupOption && (
          <>
            {optionLabel(null, PersonAddIcon, 'Add New Member', () => {
              setShowGroupModal(true);
              // setToogle(false);
            })}

            {optionLabel(null, VisibilityIcon, 'View Members', () => {
              setViewGroupMemebers(true);
              // setToogle(false);
            })}

            {optionLabel(null, LogoutIcon, 'Leave Group', () => {
              setShowLeaveGrpChatModal(true);
              // setToogle(false);
            })}
          </>
        )}

        {/* Archive */}
        {!showGroupOption && (
          <>
            {optionLabel(ArchivePng, null, 'Archive', () => setToogle(false))}
          </>
        )}

        {/* Mark as Read */}
        {!showGroupOption && (
          <>
            {optionLabel(MarkAsReadPng, null, 'Mark as read', () =>
              setToogle(false)
            )}
          </>
        )}

        {/* Mute */}
        {!showGroupOption && (
          <>{optionLabel(MutePng, null, 'Mute', () => setToogle(false))}</>
        )}

        {/* Block */}
        {!showGroupOption && (
          <>{optionLabel(BlockedPng, null, 'Block', () => setToogle(false))}</>
        )}
      </div>
    </div>
  );
};

export default MessageOption;
