import React from 'react';
import NotificationOption from '../../components/NotificationOption/NotificationOption';
import EventScheduledNotification from '../../components/NotificationModal/EventScheduledNotification';
import GameScheduledNotification from '../../components/NotificationModal/GameScheduledNotification';
import FriendRequestSent from '../../components/NotificationModal/FriendRequestSent';
import LikeNotification from '../../components/NotificationModal/LikeNotification';
import CommentNotification from '../../components/NotificationModal/CommentNotification';
import FriendRequestAcceptedNotification from '../../components/NotificationModal/FriendRequestAccepted';
import { useSelector } from 'react-redux';

const Notification = ({ notification, handleMarkasread }) => {
  const myId = useSelector(state => state.auth.user?.id);

  return (
    <>
      {notification?.event && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <EventScheduledNotification
            key={notification?.id}
            handleMarkasread={handleMarkasread}
            date={notification?.createdAt}
            id={notification?.id}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}

      {notification?.title === 'New Friend Request' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <FriendRequestSent
            key={notification?.id}
            handleMarkasread={handleMarkasread}
            friendRequestId={notification?.friendRequest?.id}
            id={notification?.id}
            content={notification?.content}
            sentDate={notification?.createdAt}
            status={notification?.friendRequest?.status}
            profilePicture={
              notification?.interactionUser?.contact?.profile_image?.url
            }
            friendUsername={
              notification?.friendRequest?.friendId === myId
                ? notification?.friendRequest?.user?.contact?.username
                : notification?.friendRequest?.friend?.contact?.username
            }
            isPage={true}
            isRoleRequest={false}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}

      {notification?.title === 'Role request received!' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          {notification?.roleRequest?.status !== 'accepted' && (
            <>
              <FriendRequestSent
                key={notification?.id}
                id={notification?.roleRequest?.id}
                handleMarkasread={handleMarkasread}
                content={`${notification?.roleRequest?.by?.contact?.first_name} ${notification?.roleRequest?.by?.contact?.last_name} sent you a role request`}
                sentDate={notification?.createdAt}
                status={notification?.roleRequest?.status}
                profilePicture={
                  notification?.interactionUser?.contact?.profile_image?.url
                }
                friendUsername={
                  notification?.interactionUser?.contact?.username
                }
                isRoleRequest={true}
                isPage={true}
              />
              <NotificationOption
                id={notification?.id}
                seenStatus={notification?.status}
              />
            </>
          )}
        </div>
      )}

      {notification?.title === 'Like added' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <LikeNotification
            key={notification?.id}
            id={notification?.id}
            handleMarkasread={handleMarkasread}
            content={notification?.content}
            likedDate={notification?.createdAt}
            postId={notification?.post?.id}
            profileImage={
              notification?.interactionUser?.contact?.profile_image &&
              notification?.interactionUser?.contact?.profile_image?.url
            }
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}

      {notification?.title === 'Comment Added' && (
        <div className="relative min-w-full   hover:bg-gray-200 rounded-md">
          <CommentNotification
            key={notification?.id}
            id={notification?.id}
            content={notification?.content}
            commentedDate={notification?.createdAt}
            handleMarkasread={handleMarkasread}
            profileImage={
              notification?.interactionUser?.contact?.profile_image &&
              notification?.interactionUser?.contact?.profile_image?.url
            }
            postId={notification?.post?.id}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}

      {notification?.title === 'New Accepted Friend Request' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <FriendRequestAcceptedNotification
            key={notification?.id}
            id={notification?.id}
            handleMarkasread={handleMarkasread}
            content={notification?.content}
            acceptedDate={notification?.createdAt}
            profile={
              myId === notification?.friendRequest?.userId
                ? notification?.friendRequest?.friend
                : notification?.friendRequest?.user
            }
            friendUsername={
              notification?.friendRequest?.friendId === myId
                ? notification?.friendRequest?.user?.contact?.username
                : notification?.friendRequest?.friend?.contact?.username
            }
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
      {notification?.type === 'roleRequestAccepted' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <FriendRequestAcceptedNotification
            key={notification?.id}
            id={notification?.id}
            handleMarkasread={handleMarkasread}
            content={notification?.content}
            acceptedDate={notification?.createdAt}
            profile={notification?.interactionUser}
            friendUsername={
              notification?.friendRequest?.friendId === myId
                ? notification?.friendRequest?.user?.contact?.username
                : notification?.friendRequest?.friend?.contact?.username
            }
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}

      {notification?.type === 'gameScheduled' && (
        <div className="relative min-w-full hover:bg-gray-200 rounded-md">
          <GameScheduledNotification
            name={notification?.game?.name}
            date={notification?.game?.scheduledDate}
            user={notification?.interactionUser}
          />
          <NotificationOption
            id={notification?.id}
            seenStatus={notification?.status}
          />
        </div>
      )}
    </>
  );
};

export default Notification;
