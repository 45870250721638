import React, { useState } from "react";
import Loader from "../../img/busy.gif";
import { useForm } from "react-hook-form";
import AcceptButton from "../Buttons/AcceptButton";
import DeclineButton from "../Buttons/DeclineButton";

function InviteAgentModal({ onCloseClick, setInviteAgent, inviteAgent }) {
  //
  //
  const { handleSubmit, register, errors } = useForm();
  const [busy, setBusy] = useState(false);

  const onSubmit = data => {
    setBusy(true);
    onCloseClick();
  };

  return (
    <div className="w-full  py-5 px-5 bg-white">
      <form
        className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full bg-white">
          <div className="flex gap-3 items-center justify-center">
            <div className="w-full mb-6">
              <label className="text-sm font-medium font-roboto px-1">
                Agent Name
              </label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                id="agent_name"
                name="agent_name"
                ref={register({
                  type: "agent_name",
                  required: "You must specify an Agent name"
                })}
              />
              {errors.agent_name && <p>{errors.agent_name.message}</p>}
            </div>
            <div className="w-full mb-6">
              <label className="text-sm font-medium px-1">Email</label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                type="email"
                name="email"
                ref={register({
                  type: "email"
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <div className="w-full mb-4">
              <label className="text-sm font-medium px-1">Phone Number</label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                type="tel"
                name="phone"
                ref={register({
                  type: "phone",
                  required: "You must specify Phone Number"
                })}
              />
              {errors.phone && <p>{errors.phone.message}</p>}
            </div>
            <div className="w-full mb-4">
              <label className="text-sm font-medium px-1">Company Name</label>
              <input
                className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
                type="text"
                id="comapany_name"
                name="company_name"
                ref={register({
                  type: "company_name"
                })}
              />
              {errors.copmany_name && <p>{errors.copmany_name.message}</p>}
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <button type="submit" className=" mt-4">
              <AcceptButton title="Send Request" />
            </button>
            <button
              className="mt-4"
              onClick={() => setInviteAgent(!inviteAgent)}
            >
              <DeclineButton title="Search Agent" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InviteAgentModal;
