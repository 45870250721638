import AxiosService from '../AxiosService';

export const getMyPost = async pageParam => {
  const response = await AxiosService.get(`/post/my?page=${pageParam}`);
  return response.data;
};

export const addMyPost = async data => {
  const response = await AxiosService.post('/post/create', data);
  return response.data;
};

export const getAllPost = async pageParam => {
  const response = await AxiosService.get(`/post?page=${pageParam}`);
  return response.data;
};

export const getPostById = async id => {
  const response = await AxiosService.get(`/post/${id}`);
  return response.data;
};

export const deletePost = async id => {
  const response = await AxiosService.delete(`/post/delete/${id}`);
  return response.data;
};

export const updatePost = async ({ id, data }) => {
  const response = await AxiosService.put(`/post/update/${id}`, data);
  return response.data;
};

export const deleteImage = async id => {
  const response = await AxiosService.delete(`/post/delete/image/${id}`);
  return response.data;
};

//Like counts
export const AddLikeByPostId = async ({ id }) => {
  const response = await AxiosService.post(`/like/${id}`);
  return response.data;
};

export const getLikeCountByPostID = async ({ id }) => {
  const response = await AxiosService.get(`/like/count/${id}`);
  return response.data;
};

export const getLikesByPostID = async ({ id }) => {
  const response = await AxiosService.get(`/like/${id}`);
  return response.data;
};

export const deletePostLike = async ({ id }) => {
  const response = await AxiosService.delete(`/like/${id}`);
  return response.data;
};

//comment apis
export const addCommentByPostID = async data => {
  const response = await AxiosService.post(`/post/comment`, data);
  return response.data;
};

export const getCommentsByPostID = async ({ id }) => {
  const response = await AxiosService.get(`/post/comment/${id}`);
  return response.data;
};

export const deleteCommentByPostID = async ({ id }) => {
  const response = await AxiosService.delete(`/post/comment/${id}`);
  return response.data;
};

// bookmark apis

export const addToBookmark = async ({ id }) => {
  const response = await AxiosService.post(`/post/save/${id}`);
  return response.data;
};

export const getBookmarkPosts = async pageParam => {
  const response = await AxiosService.get(
    `/post/save/saved?limit=3&page=${pageParam || 1}`
  );
  return response.data;
};

export const deleteBookmark = async ({ id }) => {
  const response = await AxiosService.delete(`/post/save/${id}`);
  return response.data;
};
