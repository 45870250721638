import { useSelector } from 'react-redux';
import EventLayoutDetail from './EventLayoutDetail';

const EventLayout = ({ onClickDeleteEvent, onClickEditEvent }) => {
  const user = useSelector(state => state.auth.user);
  const recentEvent = useSelector(state => state.gameDetail.events);

  return (
    <div className="w-1/2">
      {/* If there is no Evebts */}
      {recentEvent?.length === 0 && (
        <div className="relative bg-white rounded-lg shadow-md mb-2">
          {/* If the role is except player then show this */}
          {['coach', 'club', 'agent'].includes(user?.role) &&
            recentEvent?.length === 0 && (
              <div className="p-4 min-w-full h-full">
                <div className="flex w-full h-full text-center text-sm text-gray-600 font-medium justify-center items-center">
                  You don't have any Events <br />
                  Please, Create an Event
                </div>
              </div>
            )}

          {/* If the role is player and don't have any events then show this */}
          {user?.role === 'player' && recentEvent?.length === 0 && (
            <div className="p-4 min-w-full h-full">
              <div className="flex w-full h-full text-center text-sm text-gray-600 font-medium justify-center items-center">
                You don't have any Events <br />
                Please, Tell your Coach or Club to assign you on an Event
              </div>
            </div>
          )}
        </div>
      )}

      {/* If there is Events then display the event detail */}
      {recentEvent?.map(game => (
        <EventLayoutDetail
          key={game?.id}
          game={game}
          onClickDeleteEvent={onClickDeleteEvent}
          onClickEditEvent={onClickEditEvent}
        />
      ))}
    </div>
  );
};

export default EventLayout;
