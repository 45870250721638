import { useEffect } from 'react';
import { socket } from '../../components/SocketIo';
import SideNavWrapper from '../SideNavWrapper/SideNavWrapper';
import Chat from '../../components/UserMessages/Chat';
import Sidebar from '../../components/UserMessages/Sidebar';
import '../../components/UserMessages/userMessage.scss';

const UserMessages = () => {
  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected Successfully');
    });
  }, []);

  return (
    <SideNavWrapper width="60rem" isFooter={true}>
      <div className="home  ml-4" style={{ display: 'flex' }}>
        <div className="container">
          <Sidebar />
          <Chat />
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default UserMessages;
