import React, { useState } from 'react';
import '../../styles/main.css';
import './About.css';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import person from '../../img/person.png';
import about from '../../img/about.png';

const About = () => {
  return (
    <div className="">
      <Header />
      <div className="main-div">
        <div className="flex ... h-100 w-5/6 main ">
          <div className="flex-1 ... about-image">
            {' '}
            <img className="" src={about} alt="title" />
          </div>
          <div className="flex-1 ... about-background">
            <div className=" about-text">About Us </div>
            <div className=" description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a ype specimen book. It has survived
              not only five centuries , but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised
              in the 1960s with t he release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem Ipsum.{' '}
            </div>
          </div>{' '}
        </div>
        <div className="pt-20 pb-20 meet-text center">MEET THE TEAM</div>
        <div className="flex ... center-image ">
          <div className="flex-1 ">
            <img className="" src={person} alt="title" />
          </div>
          <div className="flex-1 ">
            <img className="" src={person} alt="title" />
          </div>
          <div className="flex-1 ">
            <img className="" src={person} alt="title" />
          </div>
          <div className="flex-1 ">
            <img className="" src={person} alt="title" />
          </div>
        </div>
        <div className="about-title flex ... ">
          <div className="flex-1 title-align">TITLE</div>
          <div className="flex-1 title-align ">TITLE</div>
          <div className="flex-1 title-align ">TITLE</div>
          <div className="flex-1 title-align">TITLE</div>
        </div>
      </div>
      <div className=" flex bg-for-div w-full  h-50 call-button">
        <div className="flex-1 pt-10 cta">CALL TO ACTION </div>
        <div className="flex-1 pt-10 ">
          <button className="cta-btn"> Button</button>
        </div>
      </div>
      <Footer className="custom-footer" />
    </div>
  );
};

export default About;
