import { ReactComponent as BriefcaseSvg } from '../../../img/activity/Briefcase.svg';
import { ReactComponent as CoachSvg } from '../../../img/activity/Coach.svg';
import { ReactComponent as FootballSvg } from '../../../img/activity/Football.svg';
import { ReactComponent as BinocularsSvg } from '../../../img/activity/Binoculars.svg';
import { ReactComponent as PostSvg } from '../../../img/activity/Post.svg';
import { ReactComponent as ProfileSvg } from '../../../img/activity/Profile.svg';
import DefaultProfilePng from '../../../img/misc/Default_pfp.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAnalytics } from '../../../services/routes/User';
import { useQuery } from 'react-query';
import profileImageUrl from '../../../config/config';
import './Analytics.scss';

const Analytics = () => {
  const user = useSelector(state => state.user);

  const { data: analytics } = useQuery('fetch_analytics', getAnalytics);

  const ProfilePicture =
    user?.user?.contact?.profile_image &&
    profileImageUrl + user?.user?.contact?.profile_image?.url;

  return (
    <div className="Analytics bg-white rounded-lg">
      <div className="top-profile-wrapper bg-brand "></div>
      <Link to="/my-profile">
        <div className="w-full">
          <img
            className="profile-img bg-white"
            alt=""
            src={ProfilePicture || DefaultProfilePng}
          />
        </div>
      </Link>

      <div className="general-info">
        <div className="font-medium font-roboto text-base mt-1">
          {user?.user?.contact?.first_name +
            ' ' +
            user?.user?.contact?.last_name}
        </div>

        <div className="text-center break-words font-normal text-gray-600 font-roboto text-sm">
          {user?.user?.contact?.bio}
        </div>
      </div>
      <div className="bar-wrapper bg-brand-dark mt-3">
        Total Connections: {analytics?.data?.totalConnections}
      </div>
      <div className="flex flex-wrap ">
        <div
          className="box-item primary-svg bg-normalGray-light"
          style={{ color: '#58595B' }}
        >
          <BriefcaseSvg width="18px" height="18px" />
          <p className="title-text">Agents</p>
          <p className="number-text  ">{analytics?.data?.agents}</p>
        </div>
        <div
          className="box-item-right primary-svg"
          style={{ backgroundColor: '#EFF0F1', color: '#58595B' }}
        >
          <CoachSvg width="18px" height="18px" />
          <p className="title-text">Coach</p>
          <p className="number-text ">{analytics?.data?.coaches}</p>
        </div>
        <div
          className="box-item primary-svg"
          style={{ backgroundColor: '#EFF0F1', color: '#58595B' }}
        >
          <FootballSvg width="18px" height="18px" />
          <p className="title-text">Clubs</p>
          <p className="number-text ">{analytics?.data?.clubs}</p>
        </div>
        <div
          className="box-item-right primary-svg"
          style={{ backgroundColor: '#EFF0F1', color: '#58595B' }}
        >
          <BinocularsSvg width="18px" height="18px" />
          <p className="title-text">Players</p>
          <p className="number-text ">{analytics?.data?.players}</p>
        </div>
      </div>
      <div className="bar-wrapper bg-brand-dark " style={{ marginTop: '1px' }}>
        Profile Views: -
      </div>
      <div className="flex flex-wrap ">
        <div
          className="box-item primary-svg"
          style={{ backgroundColor: '#EFF0F1', color: '#58595B' }}
        >
          <PostSvg width="18px" height="18px" />
          <p className="title-text">Post Views</p>
          <p className="number-text ">
            {user?.user?.postViews ? user?.user?.postViews : 0}
          </p>
        </div>
        <div
          className="box-item-right primary-svg"
          style={{ backgroundColor: '#EFF0F1', color: '#58595B' }}
        >
          <ProfileSvg width="18px" height="18px" />
          <p className="title-text">Profile Views</p>
          <p className="number-text ">{user?.user?.profileViews}</p>
        </div>
      </div>

      <div
        className="border-t border-brand plan-area flex justify-between items-center  py-1 px-3 "
        style={{ backgroundColor: '#EFF0F1', color: '#58595B' }}
      >
        <p>Professional Plan</p>
        <button
          type="submit"
          className="bg-brand text-white px-3 text-xs py-2 mt-1"
        >
          Change
        </button>
      </div>
    </div>
  );
};
export default Analytics;
