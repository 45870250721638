import React, { useEffect, useState } from 'react';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import { ReactComponent as MoreSvg } from '../../img/misc/three-dot-horizontal.svg';
import { useSelector } from 'react-redux';
import profileImageUrl from '../../config/config';
import moment from 'moment';
import MessageOption from './Modal/MessageOption';
import MessageFeatureModel from './Modal/MessageFeatureModel';
import './userMessage.scss';

const Message = ({ message, lastMessageRef }) => {
  const me = useSelector(state => state.auth.user);
  const [owner, setOwner] = useState(false);

  const [showMessageOption, setShowMessageOption] = useState(false);

  const isMessageSeen = message?.seenBy?.some(user => user?.userId === me?.id);

  // check who is texting
  useEffect(() => {
    if (message?.sender?.id === me?.id) {
      setOwner(true);
    } else {
      setOwner(false);
    }
  }, [message?.sender?.id, me?.id]);

  return (
    <>
      <div className={`message ${owner && 'owner'}`}>
        <div className="messageInfo">
          <img
            src={
              profileImageUrl + message?.sender?.contact?.profile_image?.url ||
              DefaultProfilePng
            }
            className="object-cover"
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            alt=""
          />
          <span className="text-xs font-normal font-roboto">
            {moment(message?.createdAt).fromNow() || 'now'}
            <br />
          </span>
        </div>

        <div className="messageContent">
          {message?.content.trim() && <p>{message?.content}</p>}
          {message?.attachments?.map(attachement => (
            <>
              {attachement?.type === 'video/mp4' ? (
                <video controls>
                  <source
                    src={profileImageUrl + attachement?.url}
                    type={attachement?.type}
                  />
                </video>
              ) : (
                <img
                  src={profileImageUrl + attachement?.url}
                  key={attachement.id}
                  alt=""
                />
              )}
            </>
          ))}
        </div>
        <div className="relative">
          {owner && (
            <MoreSvg
              className=" cursor-pointer "
              style={{ width: '14px' }}
              onClick={() => setShowMessageOption(!showMessageOption)}
            />
          )}
          {showMessageOption && (
            <MessageFeatureModel
              messageId={message?.id}
              chatId={message?.chatId}
              onCloseClick={() => setShowMessageOption(false)}
            />
          )}
        </div>
      </div>
      <div ref={lastMessageRef} />
    </>
  );
};

export default Message;
