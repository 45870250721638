import AxiosService from '../../../AxiosService';

export const getAllPlayerEvents = async () => {
  const response = await AxiosService.get('/events/player-events/');
  return response.data;
};

export const getPlayerEvent = async ({ id }) => {
  const response = await AxiosService.get(`/events/player-event/${id}`);
  return response.data;
};

export const getPlayerEventAssignment = async ({ id }) => {
  const response = await AxiosService.get(`/events/player-assignment/${id}`);
  return response.data;
};

//events for players

export const getPlayerEventByID = async id => {
  return await AxiosService.get(`/events/player-event/${id}/`);
};

//for players
//mark as going in event
export const eventMarkGoing = async id => {
  const response = await AxiosService.put(
    `/events/player-event/mark-going/${id}`
  );
  return response.data;
};

export const eventMarkMaybe = async id => {
  const respoonse = await AxiosService.put(
    `/events/player-event/mark-maybe/${id}`
  );
  return respoonse.data;
};

export const eventMarkNo = async id => {
  const response = await AxiosService.put(`/events/player-event/mark-no/${id}`);
  return response.data;
};

//mark as going in game
export const gameMarkGoing = async id => {
  const response = await AxiosService.put(
    `/games/player-game/mark-going/${id}`
  );
  return response.data;
};

export const gameMarkMaybe = async id => {
  const response = await AxiosService.put(
    `/games/player-game/mark-maybe/${id}`
  );
  return response.data;
};

export const gameMarkNo = async id => {
  const response = await AxiosService.put(`/games/player-game/mark-no/${id}`);
  return response.data;
};
