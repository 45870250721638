import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { ReactComponent as EditSvg } from '../../../img/misc/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../img/misc/delete.svg';
import moment from 'moment';
import '../MainTable.scss';

function Table({ columns, data, onRowClick }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table
        {...getTableProps()}
        style={{
          width: '500px',
          borderCollapse: 'collapse',
          overflow: 'scroll',
          display: 'block',
          whiteSpace: 'nowrap'
        }}
        className="table"
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const HeaderDiv = (name, align) => {
  return (
    <div
      style={{
        textAlign: align,
        paddingLeft: '5px'
      }}
    >
      {name}
    </div>
  );
};

const DeleteCell = (id, align, setDeleteId) => {
  return (
    <div style={{ textAlign: align }}>
      <DeleteSvg
        onClick={e => {
          setDeleteId(id);
        }}
        className="primary-svg w-5 h-5"
      />
    </div>
  );
};

const EditCell = (id, data, align, setEditData) => {
  return (
    <div style={{ textAlign: align }}>
      <EditSvg
        onClick={e => {
          setEditData(data);
        }}
        className="primary-svg w-5 h-5"
      />
    </div>
  );
};

const HeaderCell = (row, align, type) => {
  if (type === 'date') {
    return (
      <div style={{ textAlign: align }}>
        {moment(row.value).format('YYYY-MM-DD')}
      </div>
    );
  }
  return (
    <div
      style={{
        textAlign: align,
        paddingLeft: '5px',
        font: ' Roboto 200',
        fontWeight: '300'
      }}
    >
      {row.value}
    </div>
  );
};

function MainTable({ histories, editHistory, deleteHistory, isEditable }) {
  const columns = React.useMemo(() => [
    {
      accessor: 'clubName',
      Header: row => HeaderDiv('Club', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'seasonFrom',
      Header: row => HeaderDiv('Season', 'right'),
      Cell: row => HeaderCell(row, 'right', 'date')
    },
    {
      accessor: 'seasonTo',
      Header: row => HeaderDiv('', 'left'),
      Cell: row => HeaderCell(row, 'left', 'date')
    },
    {
      accessor: 'team',
      Header: row => HeaderDiv('Team', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'jobPosition',
      Header: row => HeaderDiv('Job Position', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'region',
      Header: row => HeaderDiv('Region', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'games',
      Header: row => HeaderDiv('Games', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'wins',
      Header: row => HeaderDiv('Wins', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'losses',
      Header: row => HeaderDiv('Losses', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      accessor: 'draws',
      Header: row => HeaderDiv('Draws', 'left'),
      Cell: row => HeaderCell(row, 'left')
    },
    {
      id: 'edit',
      accessor: 'id',
      Header: row => HeaderDiv('', 'left'),
      Cell: row =>
        isEditable === true &&
        EditCell(row.value, row.row.values, 'center', editHistory)
    },
    {
      id: 'delete',
      accessor: 'id',
      Header: row => HeaderDiv('', 'left'),
      Cell: row =>
        isEditable === true && DeleteCell(row.value, 'center', deleteHistory)
    }
  ]);

  return (
    <div
      className="VisitHistoryTable-MainTable 
    w-96 md:w-full overflow-scroll md:overflow-hidden"
    >
      <Table columns={columns} data={histories} />
    </div>
  );
}

export default MainTable;
