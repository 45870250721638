import React from "react";
import Modal from "../../../../components/Modal/Modal";
import { useForm } from "react-hook-form";

function EditCoachInfoModal({ onCloseClick }) {
  const { handleSubmit, register } = useForm();

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <Modal title="Edit Coach Information" onCloseClick={onCloseClick}>
      <form className="bg-white px-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full  flex-col pt-4 pb-2">
          <label className="text-base font-normal text-black">
            Nationality
          </label>
          <input
            type="text"
            name="country"
            className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm"
            placeholder="Enter your Country"
            ref={register({
              type: "country"
            })}
          />
        </div>
        <div className="flex w-full  flex-col py-2">
          <label className="text-base font-normal text-black">
            Date of Birth
          </label>
          <input
            type="date"
            name="birth_date"
            className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm"
            placeholder="Enter your Birth Date"
            ref={register({
              type: "birth_date"
            })}
          />
        </div>
        <div className="flex w-full  flex-col py-2">
          <label className="text-base font-normal text-black">Height</label>
          <input
            type="text"
            name="preffered_formation"
            className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm"
            placeholder="Eg. 4x4x2"
            ref={register({
              type: "preffered_formation"
            })}
          />
        </div>

        {/* <div className="flex w-full  flex-col py-2">
          <label className="text-base font-normal text-black">Age</label>
          <input
            type="number"
            name="age"
            className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm"
            placeholder="Enter your Age"
            ref={register({
              type: "age"
            })}
          />
        </div> */}
        <div className="flex w-full  flex-col py-2">
          <label className="  text-base font-normal text-black" htmlFor="">
            Gender
          </label>
          <select
            className="form-select w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm"
            id="gender"
            name="gender"
            ref={register({
              required: "You must specify a Gender"
            })}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="w-full flex justify-center my-4">
          <button
            type="submit"
            className="mx-1 my-2 bg-brand-dark rounded-md text-white text-base tracking-widest flex justify-center items-center "
            style={{ width: "152px", height: "36px" }}
          >
            Save
          </button>
          <button
            className="mx-1 my-2 bg-normalGray rounded-md text-white text-base tracking-widest flex justify-center items-center "
            style={{ width: "152px", height: "36px" }}
            onClick={() => onCloseClick()}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditCoachInfoModal;
