import React from 'react';
import DefaultProfilePic from '../../img/misc/Default_pfp.png';
import profileImageUrl from '../../config/config';
import Username from '../../components/Username/Username';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import {
  acceptFriendRequest,
  getFriendRequests,
  rejectFriendRequest
} from '../../services/routes/UserFriendRequest';
import { toast } from 'react-toastify';

const IncomingRequests = ({
  user,
  profilePicture,
  status,
  requestId,
  setFriendRequestsData
}) => {
  //
  //

  const acceptRequest = async () => {
    try {
      await acceptFriendRequest({ id: requestId }).then(async () => {
        toast.success('You are now friends');
      });
      const { data } = await getFriendRequests();
      setFriendRequestsData(data);
    } catch (err) {
      setFriendRequestsData([]);
      // toast.error('There was an error while accepting friend request');
    }
  };

  const rejectRequest = async () => {
    try {
      await rejectFriendRequest({ id: requestId }).then(async () => {
        toast.success('You rejected friend request');
      });
      const { data } = await getFriendRequests();
      setFriendRequestsData(data);
    } catch (err) {
      toast.error('There was an error while rejecting friend request');
    }
  };

  return (
    <>
      {status === 'PENDING' && (
        <div className="flex items-center justify-between md:w-60 w-52 ">
          <img
            src={
              (profilePicture && profileImageUrl + profilePicture) ||
              DefaultProfilePic
            }
            className="w-14 h-14  object-cover rounded-full"
            alt=""
          />
          <div className="relative flex flex-col text-gray-600">
            <Username
              user={{ ...user, id: user?.contact?.profile_image?.userId }}
            />

            <p className="text-sm text-gray-500 font-normal">
              {user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1) ||
                'User'}
            </p>
          </div>

          <div className="flex flex-col ">
            <button className="w-6 h-6" onClick={acceptRequest}>
              <HowToRegIcon className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 text-green-700 " />
            </button>
            <button className="w-6 h-6" onClick={rejectRequest}>
              <PersonRemoveIcon className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 text-red-700" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default IncomingRequests;
