import { useSelector } from 'react-redux';
import ClubInfo from '../../pages/Club/ClubInfo/ClubInfo';
import Equipment from '../../pages/Club/Equipment/Equipment';
import ManagedPlayer from '../ManagedPlayer/ManagedPlayer';
import SeasonHistory from '../SeasonHistory/SeasonHistory';
import Sidebar from '../Profile/Sidebar';
import ProfileBio from '../Profile/ProfileBio';
import TabSwitcher from '../Profile/TabSwitcher';
import { useState } from 'react';
import ProfileAwards from '../../pages/Player/PlayerProfile/PlayerAwards/PlayerAwards';
import { useQuery } from 'react-query';
import { getAllManagedPlayers } from '../../services/routes/User';
import { getAllSeasonHistories } from '../../services/routes/ClubHistories/seasonHistories';

function VisitClubProfile() {
  const user = useSelector(state => state.visitProfile.userProfile);
  const [activeTab, setActiveTab] = useState('information');
  const [equipmentData, setEquipmentData] = useState([]);

  // getting managed players/roaster
  const { data: managedPlayersData } = useQuery(
    'fetch_managedPlayers',
    getAllManagedPlayers
  );

  //fetching all season history
  const { data: allSeasonHistoryData } = useQuery(
    'fetch_allSeasonHistory',
    getAllSeasonHistories
  );

  return (
    <div className="flex w-full mt-8 gap-8">
      <div className="w-96">
        <Sidebar>
          <ProfileBio isEditable={false} />
          {/* line */}
          <div className="border-b-2 border-brand-dark mx-8 my-6"></div>
          <Equipment isEditable={true} data={equipmentData} />
        </Sidebar>
      </div>

      <div className="flex flex-col flex-1">
        <TabSwitcher
          tabs={[
            {
              title: 'Information',
              name: 'information',
              component: <div>Information</div>
            },
            {
              title: 'Rooster',
              name: 'rooster',
              component: <div>Agent</div>
            },
            {
              title: 'Awards',
              name: 'awards',
              component: <div>Coach</div>
            },
            {
              title: 'Season History',
              name: 'season-history',
              component: <div>Award</div>
            }
          ]}
          activeTab={activeTab}
          setActiveTab={name => {
            setActiveTab(name);
          }}
        />

        {activeTab === 'information' && <ClubInfo club={user?.club} />}

        {
          // Managed Players
          activeTab === 'rooster' && (
            <div>
              <ManagedPlayer
                data={managedPlayersData?.data}
                isClub={true}
                title="Roster"
                type="players"
              />
            </div>
          )
        }
        {activeTab === 'awards' && (
          <div>
            <ProfileAwards awards={user?.awards} isEditable={true} />
          </div>
        )}

        {activeTab === 'season-history' && (
          <div>
            <SeasonHistory histories={allSeasonHistoryData?.data} />
          </div>
        )}
      </div>
    </div>
  );
}

export default VisitClubProfile;
