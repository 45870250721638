import { useState } from "react";
import classnames from "classnames";
import "./Footer.css";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import MainFooter from "../../../components/Footer";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const [buttonClicked, setButtonClicked] = useState(false);
  const triggerButtonClick = () => {
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
      history.push("/contact");
    }, 100);
  };
  return (
    <Element>
      <div
        name="footer"
        className="LandingFooter m-h-60 pt-6  bg-brandBrown mt-40 pb-20"
      >
        <p className="text-center text-2xl text-white medium-spacing font-bold">
          GET IN TOUCH
        </p>
        <div className="flex center mt-10 mb-10">
          <div
            className={classnames("relative", "mx-auto", {
              "button-clicked": buttonClicked,
            })}
          >
            <button
              onClick={triggerButtonClick}
              className="chat-btn text-brand focus:outline-none font-bold px-10 py-2"
            >
              LET'S CHAT
            </button>
            {!buttonClicked && (
              <div onClick={triggerButtonClick} className="new-layer"></div>
            )}
          </div>
        </div>
        <MainFooter noMargin={true} />
      </div>
    </Element>
  );
};
export default Footer;
