import { useState, createRef, useEffect } from "react";
import Modal from "../Modal";

const SharePostModal = props => {
  const [visibility, setVisibilty] = useState("PUBLIC");

  const onChange = value => {
    setVisibilty(value);
    props.setVisibility(value);
  };

  useEffect(() => {
    setVisibilty(props.visibility);
  }, []);

  return (
    <Modal title="Share Post" onCloseClick={props.onCloseClick}>
      <div className="w-full bg-white ">
        <div className="bg-white px-4 py-6 " style={{ width: "200px" }}>
          <table>
            <tbody>
              <tr>
                <td className="py-2 flex items-center ">
                  <input
                    className="form-radio h-5 w-5 yellow-border-radio yellow-text"
                    type="radio"
                    name="Public"
                    value={"PUBLIC"}
                    checked={visibility === "PUBLIC"}
                    onChange={() => onChange("PUBLIC")}
                  />
                  <span className="ml-2">Public</span>
                </td>
              </tr>
              <tr>
                <td className="py-2 flex items-center">
                  <input
                    className="form-radio h-5 w-5 yellow-border-radio yellow-text"
                    type="radio"
                    name="Contacts"
                    value={"CONTACTS"}
                    checked={visibility === "CONTACTS"}
                    onChange={() => onChange("CONTACTS")}
                  />
                  <span className="ml-2">Contacts</span>
                </td>
              </tr>
              <tr>
                <td className="py-2 flex items-center">
                  <input
                    className="form-radio h-5 w-5 yellow-border-radio yellow-text"
                    type="radio"
                    name="Agents"
                    value={"AGENTS"}
                    checked={visibility === "AGENTS"}
                    onChange={() => onChange("AGENTS")}
                  />
                  <span className="ml-2">Agents</span>
                </td>
              </tr>
            </tbody>
          </table>

          <button
            className="text-md theme-button  px-6"
            onClick={props.onCloseClick}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SharePostModal;
