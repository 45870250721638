import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  games: [],
  events: []
};

const gameDetail = createSlice({
  name: 'gameDetail',
  initialState,
  reducers: {
    setGameDetail: (state, action) => {
      state.games = action.payload;
    },
    setEventDetail: (state, action) => {
      state.events = action.payload;
    }
  }
});

export const { setGameDetail, setEventDetail } = gameDetail.actions;

export default gameDetail.reducer;
