import { useSortBy, useTable } from 'react-table';
import { ReactComponent as CirclePlus } from '../../../../img/misc/circle-plus.svg';
import { ReactComponent as Delete } from '../../../../img/misc/delete.svg';
import React from 'react';

import './index.css';
import Nationality from '../../../../components/Nationality';

const Table = ({ columns, data, onRowClick }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        autoResetHiddenColumns: true
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <div className="flex-1 w-full">
      <table
        {...getTableProps()}
        style={{
          width: '500px',
          borderCollapse: 'collapse',
          overflow: 'scroll',
          display: 'block',
          whiteSpace: 'nowrap'
        }}
        className="table"
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              className="text-sm"
              {...headerGroup.getHeaderGroupProps()}
              key={index}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps()}
                  className="text-base font-bold"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className=" pb-5">
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map(cell => {
                  return (
                    <td className="text-sm  pb-5" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const HeaderDiv = (name, align, className) => {
  return (
    <div
      style={{
        textAlign: align
      }}
      className={className}
    >
      {name}
    </div>
  );
};

const HeaderCell = (value, align, className) => {
  return (
    <div style={{ textAlign: align }} className={className}>
      {value}
    </div>
  );
};

const DeleteCell = (id, align, setMyList) => {
  return (
    <div style={{ textAlign: align }}>
      <Delete
        onClick={e => {
          setMyList(id);
        }}
        className="primary-svg w-8 h-5"
      />
    </div>
  );
};

const AgentCoaches = ({ isEditable, data, deleteMyList, addModalOpen }) => {
  const columns = React.useMemo(() => [
    {
      accessor: 'contact.profile_image.url',
      Header: row => <div className="w-10"></div>,
      Cell: row => (
        <img
          src={`${process.env.REACT_APP_BASE_URL}${row.value}`}
          className="w-10 h-10 rounded-full"
          alt="profile"
        />
      )
    },
    {
      accessor: 'contact',
      Header: row => HeaderDiv('Name', 'left', 'whitespace-pre'),
      Cell: row =>
        HeaderCell(
          row.value.first_name + ' ' + row.value.last_name,
          'left',
          'whitespace-pre'
        )
    },
    {
      accessor: 'contact.country',
      Header: row => HeaderDiv('Nationality', 'left', 'whitespace-pre'),
      Cell: row =>
        HeaderCell(
          <Nationality country={row.value} />,
          'left',
          'whitespace-pre'
        )
    },
    {
      accessor: 'contact.birth_date',
      Header: row => HeaderDiv('Age', 'left', 'whitespace-pre'),
      Cell: row =>
        HeaderCell(
          new Date().getFullYear() - new Date(row.value).getFullYear(),
          'left',
          'whitespace-pre'
        )
    },
    {
      accessor: 'player.club.name',
      Header: row => HeaderDiv('Club', 'left', 'whitespace-pre'),
      Cell: row =>
        HeaderCell(row.value || 'Not Specified', 'left', 'whitespace-pre')
    },
    {
      accessor: 'player.contract_until',
      Header: row => HeaderDiv('Contract', 'left', 'whitespace-pre'),
      Cell: row =>
        HeaderCell(
          row.value ? new Date(row.value).getFullYear() : 'Not Specified',
          'left',
          'whitespace-pre'
        )
    },
    {
      id: 'delete',
      accessor: 'id',
      Header: row => HeaderDiv('', 'center', 'whitespace-pre'),
      Cell: row =>
        isEditable === true && DeleteCell(row.value, 'right', deleteMyList)
    }
  ]);

  return (
    <div className="PlayerList-MainTable my-5">
      {data?.length > 0 && (
        <div className=" overflow-scroll w-full md:overflow-hidden">
          <Table columns={columns} data={data} />
        </div>
      )}

      {isEditable === true && (
        <div className="flex flex-col justify-center items-center w-full mt-10">
          <CirclePlus
            className="primary-svg ml-2"
            onClick={() => addModalOpen(true)}
          />
          <p className="text-xl mt-5 text-black">Add Coach</p>
        </div>
      )}
    </div>
  );
};

export default AgentCoaches;
