import {
  setGameDetail,
  setEventDetail
} from '../../../redux/schedule/gameDetail';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { getGameByOwner } from '../../../services/routes/Schedule/Games/gameOwner';
import { getEvent } from '../../../services/routes/Schedule/Events/eventOwner';
import './custom-calendar.css';

const CustomEventContent = ({
  dayNumberText,
  gameAssignDate,
  eventAssignDate
}) => {
  //

  const dispatch = useDispatch();
  const assignedDate = moment(dayNumberText?.date).format('YYYY/MM/DD');

  const renderGameBullets = gameAssignDate?.filter(
    dateObj => dateObj?.date === assignedDate
  );

  const renderEventBullet = eventAssignDate?.filter(
    dateObj => dateObj?.date === assignedDate
  );

  const handleGameClick = async () => {
    try {
      const gameObjects = gameAssignDate.filter(
        dateObj =>
          moment(dateObj?.date).format('D') ===
          moment(dayNumberText?.date).format('D')
      );

      // Wait for all promises to resolve
      const resolvedData = await Promise.all(
        gameObjects.map(async gameObj => {
          const data = await getGameByOwner({ id: gameObj?.id });
          return data?.data;
        })
      );

      // Dispatch once with all the data
      dispatch(setGameDetail([...resolvedData]));
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleEventClick = async () => {
    try {
      const event = eventAssignDate.filter(
        dateObj =>
          moment(dateObj?.date).format('D') ===
          moment(dayNumberText?.date).format('D')
      );

      // Wait for all promises to resolve
      const resolvedData = await Promise.all(
        event?.map(async eventObj => {
          const data = await getEvent({ id: eventObj?.id });
          return data?.data;
        })
      );

      // Dispatch once with all the data
      dispatch(setEventDetail([...resolvedData]));
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="flex flex-col-reverse  cursor-pointer  custom-event-content">
      <div
        className={`flex flex-col items-center font-normal text-sm dayNumberText`}
      >
        {dayNumberText?.dayNumberText}

        <div className="flex items-center  gap-1 " style={{ left: '-2px' }}>
          {renderGameBullets?.length > 1 ? (
            <div className="bullet" onClick={handleGameClick}>
              <span className="bullet__counter">
                {renderGameBullets.length}
              </span>
            </div>
          ) : (
            renderGameBullets?.map((game, index) => (
              <div
                key={`game-${index}`}
                className="bullet"
                onClick={handleGameClick}
              />
            ))
          )}

          {renderEventBullet?.length > 1 ? (
            <div className="additional-bullet" onClick={handleEventClick}>
              <span className="bullet__counter">
                {renderEventBullet.length}
              </span>
            </div>
          ) : (
            renderEventBullet?.map((event, index) => (
              <div
                key={`event-${index}`}
                className="additional-bullet"
                onClick={handleEventClick}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

function CalendarTemplate({ gamesData, eventsData }) {
  const gameAssignDate = gamesData?.map(date => ({
    date: moment(date.scheduledDate).format('YYYY/MM/DD'),
    id: date.id
  }));

  const eventAssignDate = eventsData?.map(date => ({
    date: moment(date.scheduledDate).format('YYYY/MM/DD'),
    id: date.id
  }));

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next'
        }}
        showNonCurrentDates={false}
        fixedWeekCount={false}
        selectable={true}
        dayCellContent={dayNumberText => (
          <CustomEventContent
            dayNumberText={dayNumberText}
            gameAssignDate={gameAssignDate}
            eventAssignDate={eventAssignDate}
          />
        )}
      />
    </>
  );
}

export default CalendarTemplate;
