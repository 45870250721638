import { ReactComponent as EditSvg } from '../../../img/misc/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../img/misc/delete.svg';

const Options = ({ title, IconSvg, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center text-sm gap-3 px-4 py-2 hover:bg-gray-100 hover:text-gray-900 cursor-pointer primary-svg hover:rounded-t-lg hover:rounded-b-lg"
    >
      <IconSvg className="w-4 h-4" />
      {title}
    </div>
  );
};

const OptionModal = ({ onDeleteOptionClick, onEditOptionClick }) => {
  return (
    <div
      className=" w-52  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="w-full flex flex-col text-gray-500 ">
        <Options title="Edit" IconSvg={EditSvg} onClick={onEditOptionClick} />
        <Options
          title="Delete"
          IconSvg={DeleteSvg}
          onClick={onDeleteOptionClick}
        />
      </div>
    </div>
  );
};

export default OptionModal;
