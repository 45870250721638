import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import GameLayoutDetail from './GameLayoutDetail';
import { useMutation, useQueryClient } from 'react-query';
import {
  gameMarkGoing,
  gameMarkMaybe,
  gameMarkNo
} from '../../../services/routes/Schedule/Events/player';

const GameLayout = ({ onClickDeleteGame, onClickEditGame, refetchGame }) => {
  const queryClient = useQueryClient();
  const user = useSelector(state => state.auth.user);
  const recentGame = useSelector(state => state.gameDetail.games);

  const [markCount, setMarkCount] = useState(0);

  //fetching the mark as apis update for game
  const { mutate: markGoingGame } = useMutation(gameMarkGoing, {
    onSuccess: () => {
      refetchGame();
      queryClient.invalidateQueries('fetch_allGames');
      setMarkCount(markCount + 1);
    }
  });

  const { mutate: markMaybeGame } = useMutation(gameMarkMaybe, {
    onSuccess: () => {
      refetchGame();
      queryClient.invalidateQueries('fetch_allGames');
      setMarkCount(markCount + 1);
    }
  });

  const { mutate: markNoGame } = useMutation(gameMarkNo, {
    onSuccess: () => {
      refetchGame();
      queryClient.invalidateQueries('fetch_allGames');
      setMarkCount(markCount + 1);
    }
  });

  return (
    <div className="w-1/2">
      {/* If there is no Games */}
      {recentGame?.length === 0 && (
        <div className="relative bg-white rounded-lg shadow-md mb-2">
          {/* If the role is except player then show this */}
          {['coach', 'club', 'agent'].includes(user?.role) &&
            recentGame?.length === 0 && (
              <div className=" min-w-full h-full p-4">
                <div className="flex w-full h-full text-center text-sm text-gray-600 font-medium justify-center items-center">
                  You don't have any Games <br />
                  Please, Create a Game
                </div>
              </div>
            )}

          {/* If the role is player and don't have any games then show this */}
          {user?.role === 'player' && recentGame?.length === 0 && (
            <div className=" min-w-full h-full  p-4">
              <div className="flex w-full h-full text-center text-sm text-gray-600 font-medium justify-center items-center">
                You don't have any Games <br />
                Please, Tell your Coach or Club to assign you on Games
              </div>
            </div>
          )}
        </div>
      )}

      {/* If there is Games then display the game detail */}
      {recentGame?.map(game => (
        <GameLayoutDetail
          key={game?.id}
          game={game}
          onClickDeleteGame={onClickDeleteGame}
          onClickEditGame={onClickEditGame}
        />
      ))}
    </div>
  );
};

export default GameLayout;
