import { ReactComponent as UploadSvg } from '../../../img/misc/CreditCardPlus.svg';
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Facebook from '../../../img/misc/Facebook.svg';
import Insta from '../../../img/misc/Instagram.svg';
import Linkedin from '../../../img/misc/LinkedIn.svg';
import Twitter from '../../../img/misc/Twitter.svg';
import Youtube from '../../../img/misc/Youtube.svg';
import { ReactComponent as DeleteIcon } from '../../../img/misc/delete.svg';
import AvatarEditor from '../../../components/AvatarEditor';
import dataURItoBlob from '../../../utils/dataURItoBlob';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import './FormBio.css';
import {
  createSocial,
  updateProfilePicture,
  userSocials
} from '../../../services/routes/User';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../services/routes/Files';

const FormBio = ({ moveProgressForward }) => {
  const [scale, setScale] = useState(1);
  const [uploadImage, setUploadImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState('');
  const [buildImage, setBuildImage] = useState();
  const [image, setImage] = useState();

  const uploadInputEl = useRef(null);
  let editorRef = useRef(null);

  const [openField, setOpenField] = useState('');

  const { mutate: createSocials } = useMutation(createSocial, {
    onSuccess: () => {
      console.log('Socials Created');
    },
    onError: err => {
      toast.error(err.response.data.message);
    }
  });

  const { mutate: updateSocial } = useMutation(userSocials, {
    onSuccess: () => {
      console.log('Socials Updated');
    },
    onError: err => {
      console.log(err.resonse);
    }
  });

  const [socialState, setSocialState] = useState({
    instagram: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    youtube: ''
  });

  const socialList = [
    { name: 'instagram', label: 'Instagram', icon: Insta },
    { name: 'facebook', label: 'Facebook', icon: Facebook },
    { name: 'twitter', label: 'Twitter', icon: Twitter },
    { name: 'linkedin', label: 'LinkedIn', icon: Linkedin },
    { name: 'youtube', label: 'Youtube', icon: Youtube }
  ];

  const onEditorImageChange = editor => {
    if (!editor) return;
    let canvas = editor.getImageScaledToCanvas();

    let image = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');

    let dataURL = canvas.toDataURL('image/png', 0.5);
    let blob = dataURItoBlob(dataURL);
    setSelectedFile(blob);

    setUploadImage(new Image(image));
  };

  const { handleSubmit } = useForm({});

  // Handle the dropped file here
  const handleDrop = e => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];
    setImage(droppedFile);
    setBuildImage(droppedFile);
  };

  const onImageChange = e => {
    setImage(e.target.files[0]);
    setBuildImage(e.target.files[0]);
  };

  const onSubmit = async () => {
    const postData = {
      instagram: socialState.instagram,
      facebook: socialState.facebook,
      youtube: socialState.youtube,
      linkedin: socialState.linkedin,
      twitter: socialState.twitter
    };

    if (
      !socialState.instagram &&
      !socialState.facebook &&
      !socialState.youtube &&
      !socialState.twitter &&
      !socialState.linkedin
    ) {
      createSocials(postData);
    } else {
      updateSocial(postData);
    }

    if (image != null) {
      const formData = new FormData();
      formData.append('profile_image', image);

      uploadProfilePhoto(formData);
    } else {
      console.log('Error While Uploading');
    }

    moveProgressForward(false);
  };

  const { mutate: uploadProfilePhoto } = useMutation(uploadFile, {
    onSuccess: () => {
      toast.success('Profile Picture Uploaded Successfully');
    },

    onError: () => {
      toast.error('Error While Uploading Profile Picture');
    }
  });

  return (
    <div className="flex items-center w-full bg-tealer ">
      <form
        className="w-full mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full bg-white">
          <label
            htmlFor=""
            className="w-full flex justify-center text-lg font-roboto font-normal text-center"
          >
            Upload Profile Picture
          </label>
          <div className="w-full flex justify-center ">
            {buildImage ? (
              <div className="flex flex-col gap-1">
                {buildImage && (
                  <div className="">
                    <AvatarEditor
                      image={buildImage}
                      onImageChange={onEditorImageChange}
                      ref={editorRef}
                      border={0}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={scale || 2}
                      rotate={0}
                    />
                  </div>
                )}
                {buildImage && (
                  <div style={{ width: '200px', marginLeft: '28px' }}>
                    <div className="below-image mt-0.5 pb-2 font-light font-roboto ">
                      <span className="font-semibold">To crop</span> the image,
                      adjust the image on the frame
                    </div>
                    <div className="below-image font-light font-roboto">
                      <span className="font-semibold">To scale</span> the image,
                      slide the toggle to left and right
                    </div>

                    <div className=" my-0 py-0 ">
                      <input
                        name="scale"
                        className="bg-brand-dark rounded-2xl h-2"
                        type="range"
                        onChange={e => setScale(e.target.value)}
                        min={'1'}
                        max="2"
                        color={[201, 148, 0, 1]} // RGBA
                        step="0.01"
                        defaultValue="1"
                      />
                    </div>
                    <div className="below-image font-light font-roboto">
                      <span className="font-semibold">To position</span> click
                      on the image to Reposition it.
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex flex-col items-center justify-center ">
                <div
                  onClick={() => uploadInputEl.current.click()}
                  onDrop={handleDrop}
                  className="flex initial h-48 w-1/2  upload-area mt-2"
                >
                  <UploadSvg className="primary-svg" />
                  <div className="text-form-small text-center font-light font-roboto">
                    <span className="text-blue text-form-small-1 underline">
                      Upload Image from your device
                    </span>
                  </div>
                  <input
                    ref={uploadInputEl}
                    onChange={onImageChange}
                    className="hidden"
                    type="file"
                    accept="image/*"
                  />
                </div>
                <div className=" mt-5 form-text-small font-light">
                  Recommended size: 1080x1080px
                </div>
              </div>
            )}
          </div>

          <span className="w-full flex justify-start font-roboto tracking-wide font-normal text-lg mt-4">
            Social Media
          </span>
          <div className=" w-full ">
            {socialList.map((item, index) => {
              return (
                <div className="w-full flex justify-between mt-2" key={index}>
                  <div className="flex items-center">
                    <img className="social mr-2" src={item.icon} alt="" />
                    {openField !== item.name && (
                      <h1 className="font-light font-roboto">{item.label}</h1>
                    )}
                  </div>
                  <div>
                    {openField !== item.name &&
                      socialState[item?.name].length === 0 && (
                        <button
                          onClick={() => setOpenField(item.name)}
                          className="theme-button bg-brand  px-4 py-0 mt-0 text-sm"
                        >
                          Link
                        </button>
                      )}

                    {openField === item.name && (
                      <div className="flex">
                        <input
                          onChange={e =>
                            setSocialState({
                              ...socialState,
                              [item.name]: e.target.value
                            })
                          }
                          className=" w-full mb-3 bg-white brand-input-text p-0 "
                          placeholder={'Paste your Instagram profile'}
                        />
                        <button
                          className="theme-button bg-brand mx-1 px-2 py-0 mt-0"
                          onClick={() => setOpenField('')}
                        >
                          SAVE
                        </button>
                        <button
                          className="theme-button bg-brand mx-1 px-2 py-0 mt-0"
                          onClick={() => setOpenField('')}
                        >
                          CANCEL
                        </button>
                      </div>
                    )}
                    {socialState[item?.name].length > 0 &&
                      openField !== item.name && (
                        <div className="flex items-center">
                          <button
                            onClick={() => setOpenField(item.name)}
                            className="theme-button checklisted-button theme-button bg-brand mr-0 px-2 py-0 mt-0 mb-0"
                          >
                            &#10003;
                          </button>
                          <DeleteIcon
                            className="primary-svg red-svg w-6 h-6"
                            onClick={e => {
                              setSocialState({
                                ...socialState,
                                [item.name]: ''
                              });
                              setOpenField('');
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="block w-full mt-10 text-center">
            <button className="block w-2/6  m-auto ">
              <AcceptButton title="Finish" type="submit" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormBio;
