import React from "react";
import { useForm } from "react-hook-form";
import AcceptButton from "../../../components/Buttons/AcceptButton";
import { useMutation } from "react-query";
import { verifyOtp } from "../../../services/routes/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OTP = ({ moveProgressForward }) => {
  const history = useHistory();
  const { register, handleSubmit, getValues, reset, errors } = useForm({
    defaultValues: {
      otp: ""
    }
  });

  const { mutate } = useMutation(verifyOtp, {
    onSuccess: data => {
      moveProgressForward();
      console.log(data.data);
      //   if (data.data.data.isCompleted === false) {
      //     // history.push("/register");
      //   } else {
      //     // history.push("/user-profile");
      //   }
    },
    onError: error => {
      console.log(error.response);
    }
  });

  const onSubmit = () => {
    mutate(getValues());
  };

  return (
    <>
      <form className="mb-4 p-2" onSubmit={e => e.preventDefault()}>
        <p className="yellow-text text-2xl md:text-lg font-roboto font-medium">
          We have sent you verification code, please check your email.
        </p>

        <div className="w-full mb-6">
          <label className="text-base font-light font-roboto" htmlFor="">
            Verification Code
          </label>
          <input
            className="block w-full text-left text-md border-normalGray-light  border py-2 md:py-0.5 px-3 text-black bg-transparent"
            // disabled={user?.contact?.email}
            style={{ borderRadius: "4px" }}
            type="text"
            name="otp"
            id="otp"
            ref={register({
              required: "You must enter the code",
              type: "text"
            })}
          />
          {errors.email && (
            <p className="input-errors ">{errors.email.message}</p>
          )}
        </div>

        {/* Continue Button */}
        <div className="block w-full mt-10 text-center">
          <button
            className="block w-2/6  m-auto "
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <AcceptButton title="Continue" />
          </button>
        </div>
      </form>
    </>
  );
};

export default OTP;
