import AxiosService from "../AxiosService";

export const login = async data => {
  return await AxiosService.post("/auth/login", data);
};

export const logout = async data => {
  return await AxiosService.get("/auth/logout", data);
};

export const register = async data => {
  return await AxiosService.post("/auth/register", data);
};

export const verifyOtp = async data => {
  return await AxiosService.post("/auth/verify-otp", data);
};

export const checkAvailableUsername = async username => {
  return await AxiosService.get(`/auth/available/?username=${username}`);
};

export const me = async () => {
  const response = await AxiosService.get("/auth/me");
  return response.data;
};
