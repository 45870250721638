import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import './StarTestimonial.css';
import busyIcon from '../../../../img/busy.gif';
import { ReactComponent as Star } from '../../../../img/star.svg';
import { ReactComponent as Star1 } from '../../../../img/star1.svg';
import moment from 'moment';

const StarTestimonial = ({ data, onCloseClick }) => {
  const [busy, setBusy] = useState(false);
  const [players, setPlayers] = useState([]);
  const [splayer, setSplayer] = useState();
  const [p, setP] = useState();

  const onClickSuggestion = (s) => {
    setSplayer(s.value);
    setP(s.label);
    setBusy(true);
  };

  const getAll = () => {};

  useEffect(() => {
    getAll();
  }, []);

  return (
    <Modal onCloseClick={onCloseClick} title="Request Testimonial">
      <div className="w-full  px-4 py-5 bg-white h-4/6 overflow-auto">
        <div className="w-full bg-white">
          <div className=" mb-2">
            <label className="text-testimonial-modal" htmlFor="">
              Star
            </label>
            <div>
              <div className="flex flex-wrap  overflow-y-auto mt-1">
                {players.map((testimonial) => (
                  <div className="w-full mt-4  mb-1">
                    <div className="py-3 pb-6 px-5 border-2 border-brandBrown flex">
                      <div>
                        {testimonial.starred && (
                          <Star1
                            key={testimonial.id}
                            onClick={(e) => {
                              onClickSuggestion(testimonial);
                            }}
                          />
                        )}
                        {!testimonial.starred && (
                          <Star
                            key={testimonial.id}
                            onClick={(e) => {
                              onClickSuggestion(testimonial);
                            }}
                          />
                        )}
                      </div>
                      <div className="w-full ml-4">
                        <p className="w-full ml-4 h-20 overflow-scroll">
                          {testimonial.message}
                        </p>
                        <p className="text-right mt-2">
                          {moment(testimonial.created_date).format(
                            'DD MMM YYYY'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {busy && <img className="busy" src={busyIcon} />}
        </div>
      </div>
    </Modal>
  );
};
export default StarTestimonial;
