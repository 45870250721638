import React, { useState, useRef } from 'react';
import search from '../../img/misc/search.svg';
import ProfileCard from './SearchProfileCard';
import { searchUsername } from '../../services/routes/User';
import profileImageUrl from '../../config/config';
import './SearchContact.css';

const SearchContact = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const onTextchange = async e => {
    let userName = e.target.value;

    setSearchValue(userName);

    if (userName !== '') {
      const { data } = await searchUsername(userName);

      const filteredData = data.filter(user =>
        user?.contact?.username.toLowerCase().includes(userName.toLowerCase())
      );

      setFilteredUsers(filteredData);
    } else {
      setFilteredUsers([]);
    }
  };

  return (
    <div className="SearchContact">
      <div className="w-full flex max-h-9 items-center bg-transparent border border-gray-300 rounded-lg">
        <img className="w-4 h-4 mx-2" src={search} alt="" />
        <input
          value={searchValue}
          placeholder="Search by username..."
          className="w-full block py-0 text-sm align-text-bottom  text-black bg-transparent font-light "
          onChange={e => {
            onTextchange(e);
          }}
        />
      </div>

      {searchValue !== '' && (
        <div className=" flex flex-col overflow-y-auto  mt-1  shadow-lg SearchContact-listarea max-h-96 overflow-auto  bg-normalGray-light">
          {filteredUsers.length !== 0 &&
            filteredUsers.map((user, index) => (
              <ProfileCard
                key={index}
                first_name={user?.contact?.first_name}
                last_name={user?.contact?.last_name}
                profilePicture={
                  user?.contact?.profile_image &&
                  profileImageUrl + user?.contact?.profile_image?.url
                }
                role={user?.role}
                username={user?.contact?.username}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default SearchContact;
