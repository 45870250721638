import ManagedPlayer from '../ManagedPlayer/ManagedPlayer';
import { useSelector } from 'react-redux';
import Sidebar from '../Profile/Sidebar';
import ProfileBio from '../Profile/ProfileBio';
import TabSwitcher from '../Profile/TabSwitcher';
import AgentInfo from '../../pages/Agent/AgentProfile/AgentInfo/AgentInfo';
import MyList from '../MyList/MyList';
import { useQuery } from 'react-query';
import { getAllManagedPlayers } from '../../services/routes/User';
import { getMyAllPlayerList } from '../../services/routes/User';
import React from 'react';

const VisitAgentProfile = () => {
  const user = useSelector(state => state.visitProfile.userProfile);
  const [activeTab, setActiveTab] = React.useState('information');

  // getting managed players/roaster
  const { data: managedPlayersData } = useQuery(
    'fetch_managedPlayers',
    getAllManagedPlayers
  );

  // Getting myPlayers List
  const { data: playerListData } = useQuery(
    'fetch_myPlayerList',
    getMyAllPlayerList
  );

  return (
    <div className="flex w-full mt-8 gap-8">
      <div className="w-96">
        <Sidebar>
          <ProfileBio />
        </Sidebar>
      </div>

      <div className="flex flex-col flex-1">
        <TabSwitcher
          tabs={[
            {
              title: 'Information',
              name: 'information',
              component: <div>Information</div>
            },
            {
              title: 'Clients',
              name: 'clients',
              component: <div>Agent</div>
            },
            {
              title: 'My List',
              name: 'my-list',
              component: <div>Coach</div>
            }
          ]}
          activeTab={activeTab}
          setActiveTab={name => {
            setActiveTab(name);
          }}
        />

        {activeTab === 'information' && <AgentInfo isEditable={false} />}

        {activeTab === 'clients' && (
          <ManagedPlayer
            isEditable={false}
            title="Managed Players"
            data={managedPlayersData?.data}
          />
        )}
        {activeTab === 'my-list' && (
          <MyList myPlayerList={playerListData?.data} isEditable={false} />
        )}
      </div>
    </div>
  );
};

export default VisitAgentProfile;
