import { useSelector } from 'react-redux';

function VisitProfileBio() {
  const userProfile = useSelector(state => state.visitProfile.userProfile);

  return (
    <>
      <div className="w-full flex flex-col mt-10">
        <div className="h-11 font-medium text-base flex items-center text-white bg-brand-dark px-4 py-1 rounded-t-md">
          Bio
        </div>
        <p className="p-4 h-full text-base bg-white text-gray-700">
          {userProfile?.contact?.bio}
        </p>
      </div>
    </>
  );
}

export default VisitProfileBio;
