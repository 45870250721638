import { ReactComponent as SaveSvg } from '../../../img/landing/save.svg';
import { ReactComponent as CancelSvg } from '../../../img/landing/cancel.svg';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchUser } from '../../../redux/userSlice';
import { useState } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import './AccountSettings.scss';

const AccountSettings = () => {
  //
  const data = useSelector(state => state.user);
  const user = data?.user?.contact;
  const dispatch = useDispatch();
  const [showCancel, setShowCancel] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      username: user?.username,
      first_name: user?.first_name,
      last_name: user?.last_name,
      birth_date: user?.birth_date,
      gender: user?.gender,
      email: user?.email
    }
  });

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const onSubmit = data => {
    if (data.password === data.confirm_password) {
      // TODO: SEND THE DATA TO THE SERVER
    }
  };

  return (
    <div className="md:w-full w-11/12">
      <div className="AccountSettings">
        <form
          onSubmit={handleSubmit(onSubmit)}
          onChange={() => setShowCancel(true)}
        >
          <div className="flex">
            <div className="w-full">
              <div className="flex flex-col ">
                <label className="text-base text-md font-normal text-black">
                  Username
                </label>
                <input
                  className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                  ref={register({
                    type: 'username'
                  })}
                  type="text"
                  name="username"
                  placeholder="Username here"
                />
                {errors.username && <p>{errors.username.message}</p>}

                {/*  */}
                <div className=" flex mt-4">
                  <div className="grid grid-cols-2 w-full gap-4">
                    <div className="flex flex-col">
                      <label className="text-base text-md font-normal text-black">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                        ref={register({
                          type: 'first_name'
                        })}
                        placeholder="First name here"
                      />
                      {errors.first_name && <p>{errors.first_name.message}</p>}
                    </div>
                    <div className="flex flex-col ">
                      <label className="text-base text-md font-normal text-black">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name here"
                        className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                        ref={register({
                          type: 'last_name'
                        })}
                      />
                      {errors.last_name && <p>{errors.last_name.message}</p>}
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-1/2">
              <label className="text-base text-md font-normal text-black">
                Birthday
              </label>
              <input
                className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                type="date"
                name="birth_date"
                ref={register({
                  type: 'birth_date'
                })}
              />
              {errors.birth_date && <p>{errors.birth_date.message}</p>}
            </div>
            <div className="w-1/2 ml-4">
              <label className="text-base text-md font-normal text-black">
                Gender
              </label>
              <select
                className="form-select text-base font-light w-full text-left mt-1 text-md px-3 text-black bg-white rounded-md"
                type="text"
                name="gender"
                ref={register({
                  type: 'gender'
                })}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          {/*  */}
          <div className="mt-4">
            <label className="text-base text-md font-normal text-black">
              Email
            </label>
            <input
              className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
              type="email"
              name="email"
              placeholder="email@here"
              ref={register({
                type: 'email'
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          {/*  */}
          <div className="mt-8">
            <label className="font-roboto font-medium text-md text-black">
              Change Password
            </label>
          </div>
          <div className="mt-3 ">
            <label className="text-base text-md font-normal text-black">
              Old Password
            </label>
            <div className="flex relative w-full items-center gap-2">
              <input
                className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                type="password"
                name="password"
                onChange={e => setPassword(e.target.value)}
                ref={register({
                  type: 'password',
                  minLength: {
                    value: 8
                  }
                })}
              />
              {password !== user?.password && (
                <CloseIcon className="absolute right-1 text-red-600" />
              )}
            </div>
            {errors.password && <p>{errors.password.message}</p>}
          </div>
          {/*  */}
          <div className="mt-4">
            <label className="text-base text-md font-normal text-black">
              New Password
            </label>
            <div className="flex relative items-center">
              <input
                className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                type="password"
                name="confirm_password"
                onChange={e => setConfirmPassword(e.target.value)}
                ref={register({
                  type: 'confirm_password',
                  minLength: {
                    value: 8
                  }
                })}
              />
              {confirmPassword.length >= 8 && (
                <DoneAllIcon className="absolute right-1 text-green-600" />
              )}
            </div>
          </div>

          <div className="mt-10">
            <label className="font-roboto font-medium text-mg text-black">
              Two Factor Authentication
            </label>
            <div className="mt-3 ">
              <label className="text-base text-md font-normal text-black">
                Mobile Number
              </label>
              <div className="flex relative w-full items-center gap-2">
                <input
                  className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                  type="phone"
                  name="phone"
                  ref={register({})}
                />
                {/* {phone !== user?.phone && (
                  <CloseIcon className="absolute right-1 text-red-600" />
                )} */}
              </div>
              {errors.phone && <p>{errors.phone.message}</p>}
            </div>
            <div className="mt-3 ">
              <label className="text-base text-md font-normal text-black">
                Email
              </label>
              <div className="flex relative w-full items-center gap-2">
                <input
                  className="w-full text-left mt-1 text-md py-1 px-3 text-black bg-white rounded-md font-roboto font-light text-base"
                  type="email"
                  name="email"
                  ref={register({})}
                />
                {/* {email !== user?.email && (
                  <CloseIcon className="absolute right-1 text-red-600" />
                )} */}
              </div>
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>

          {/*  */}
          <button type="submit" className="flex items-center gap-2 mt-8  mr-3 ">
            {showCancel && (
              <CancelSvg
                className="w-36"
                onClick={() => {
                  setShowCancel(false);
                  reset();
                }}
              />
            )}
            <SaveSvg className="w-36" />
          </button>
        </form>
      </div>
    </div>
  );
};
export default AccountSettings;
