import { ReactComponent as EditSvg } from '../../../../../img/misc/edit.svg';
import { ReactComponent as DeleteContactSvg } from '../../../../../img/misc/deleteContact.svg';
import { ReactComponent as AwardSvg } from '../../../../../img/misc/award.svg';
import moment from 'moment';
import './PlayerAwardItem.css';

const PlayerAwardItem = ({
  award,
  onEditPlayerAwardClick,
  deleteAwards,
  isEditable
}) => {
  //
  //

  return (
    <div className="PlayerAwardItem">
      <div className="flex w-full gap-1 justify-center">
        <AwardSvg className="w-16 h-16 object-contain" />
        <div className="flex flex-col">
          <h2 className="font-bold font-roboto  text-base">{award.name}</h2>
          <span className="font-light font-roboto text-sm">
            {award.address}
          </span>
          <p className="font-roboto font-light text-xs text-gray-600">
            {moment(award.received_date).format('DD MMM YYYY')}
          </p>
        </div>

        {isEditable === true && (
          <div className="flex flex-col items-center gap-2 ml-2">
            <EditSvg
              onClick={() => onEditPlayerAwardClick(award)}
              className=" primary-svg cursor-pointer h-4 w-4"
            />
            <DeleteContactSvg
              className="cursor-pointer h-6 w-6 primary-svg"
              onClick={() => deleteAwards(award.id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerAwardItem;
