import AxiosService from "../../AxiosService";

export const getAllSeasonHistories = async () => {
  const response = await AxiosService.get("/club/season-histories/");
  return response.data;
};

export const addClubSeasonHistory = async data => {
  const response = await AxiosService.post("/club/season-history/", data);
  return response.data;
};

export const editClubSeasonHistory = async ({ id, data }) => {
  const response = await AxiosService.put(`/club/season-history/${id}`, data);
  return response.data;
};

export const deleteClubSeasonHistory = async id => {
  const response = await AxiosService.delete(`/club/season-history/${id}`);
  return response.data;
};
