import { ReactComponent as CirclePlusSvg } from '../../img/misc/circle-plus.svg';

import CoachHistoryTable from './CoachMainTable';
import AgentHistoryTable from './AgentPlayer/AgentMainTable/AgentMainTable';
import { useState } from 'react';

const AgentClubHistory = ({
  histories,
  role,
  isEditable = false,
  showAddHistoryModal,
  deleteHistory,
  editHistory
}) => {
  const [showDetail, setShowDetail] = useState(true);

  return (
    <>
      {role === 'Agent' && histories?.length > 0 && (
        <div className="bg-white">
          {showDetail && (
            <AgentHistoryTable
              isEditable={isEditable}
              histories={histories}
              editHistory={editHistory}
              deleteHistory={deleteHistory}
            />
          )}
        </div>
      )}

      {role === 'Coach' && histories?.length > 0 && (
        <div className="bg-white">
          {showDetail && (
            <CoachHistoryTable
              histories={histories}
              isEditable={isEditable}
              editHistory={editHistory}
              deleteHistory={deleteHistory}
            />
          )}
        </div>
      )}
      {isEditable === true && (
        <div className="flex flex-col justify-center items-center w-full mt-10">
          <CirclePlusSvg
            className="primary-svg "
            onClick={() => showAddHistoryModal()}
          />
          <p className="text-xl mt-5">Add {role} History</p>
        </div>
      )}
      {!isEditable && (
        <div className="flex flex-col justify-center items-center w-full mt-10">
          <p className="text-xl mt-5">No {role} History</p>
        </div>
      )}
    </>
  );
};

export default AgentClubHistory;
