import logo from '../../img/logo.svg';
import iconOriginal from '../../img/icon-original.svg';
import SearchContact from '../SearchContact';
import { ReactComponent as HamburgerSvg } from '../../img/misc/hamburger.svg';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as HomeSvg } from '../../img/player-profile/home.svg';
import { ReactComponent as ContactSvg } from '../../img/player-profile/contacts.svg';
import { ReactComponent as MessageSvg } from '../../img/player-profile/msg.svg';
import { ReactComponent as NotificationSvg } from '../../img/player-profile/notify.svg';
import { ReactComponent as PostSvg } from '../../img/player-profile/post.svg';
import { ReactComponent as ScheduleSvg } from '../../img/misc/event.svg';
import { ReactComponent as ThreeDotHorizontalSvg } from '../../img/misc/three-dot-horizontal.svg';
import { useQuery } from 'react-query';
import { getAllUserRequestNotification } from '../../services/routes/Notification';
import NotificationModal from '../NotificationModal/NotificationModal';
import MessageModal from '../MessagesModal/MessageModal';
import ProfileOption from './ProfileOption';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setUserNotification } from '../../redux/notificationSlice/NotificationSlice';
import { setPreviousChats } from '../../redux/userChats/UserChats';
import { getAllChats } from '../../services/routes/chats';
import Popup from 'reactjs-popup';
import { useState } from 'react';
import './AuthenticatedHeader.scss';

const AuthenticatedHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(1);
  const messageNotification = useSelector(
    state => state.userChats.previousChats
  );

  const { isLoading: messageLoading, isError } = useQuery(
    'fetch_allChats',
    getAllChats,
    {
      onSuccess: data => {
        dispatch(setPreviousChats(data?.data));
      },
      onError: () => {
        dispatch(setPreviousChats([]));
      }
    }
  );

  const { data: notification, isLoading: notificationLoading } = useQuery(
    'fetch_notification',
    () => getAllUserRequestNotification(index),
    {
      onSuccess: data => {
        // Dispatch the action to notification slice
        dispatch(setUserNotification(data?.data));
      }
    }
  );

  const expandNavBar = () => {
    const hiddenExpandBtn = document.getElementById('react-burger-menu-btn');
    hiddenExpandBtn.click();
  };

  return (
    <nav className="AuthenticatedHeader shadow-sm ">
      <div className="auth_container px-6">
        <div className="logo_container">
          <Link to="/home">
            <img
              src={logo}
              className="hidden md:block w-44 cursor-pointer object-cover"
              alt=""
            />
            <img
              src={iconOriginal}
              className="block md:hidden w-12 cursor-pointer object-cover"
              alt=""
            />
          </Link>
        </div>

        <div className=" w-full md:w-11/12">
          <SearchContact />
        </div>

        <div className="items-center md:flex hidden navarea gap-x-8 justify-center">
          <Link
            className=" flex items-center flex-col hover:text-brand "
            to="/home"
          >
            <HomeSvg
              className={classNames({
                'primary-svg light-black-svg': true,
                'w-5 h-5': true,
                'brand-svg': location.pathname.includes('home')
              })}
              style={{ fill: 'white' }}
              alt=""
            />
            <p className="text-xs font-roboto mt-1">Home</p>
          </Link>
          <Link
            className="flex items-center flex-col hover:text-brand "
            to="/my-post"
          >
            <PostSvg
              className={classNames({
                'primary-svg light-black-svg': true,
                'w-5 h-5': true,
                'brand-svg': location.pathname.includes('post')
              })}
              style={{ fill: 'white' }}
              alt=""
            />
            <p className="text-xs font-roboto mt-1">Posts</p>
          </Link>
          <Link className="flex items-center flex-col " to="/contact">
            <ContactSvg
              className={classNames({
                'primary-svg light-black-svg': true,
                'w-5 h-5': true,
                'brand-svg': location.pathname === '/contact'
              })}
              style={{ fill: 'white' }}
              alt=""
            />
            <p className="text-xs font-roboto mt-1">Contacts</p>
          </Link>

          <div className="flex items-center flex-col relative">
            <Popup
              trigger={
                <MessageSvg
                  className={classNames({
                    'primary-svg light-black-svg': true,
                    'w-5 h-5': true,
                    'brand-svg': location.pathname === '/message'
                  })}
                  style={{ fill: 'white' }}
                />
              }
              position={['bottom center']}
              closeOnDocumentClick
              keepTooltipInside={false}
            >
              <MessageModal
                isLoading={messageLoading}
                isError={isError}
                userNotifications={messageNotification}
              />
            </Popup>

            {messageNotification?.length > 0 && (
              <p
                className="absolute bg-red-500 font-medium w-4 h-4 flex justify-center items-center rounded-full"
                style={{ color: 'white', top: '-8px', right: '4px' }}
              >
                {messageNotification?.length}
              </p>
            )}
            <p className="text-xs font-roboto mt-1">Messages</p>
          </div>

          <div className="flex items-center flex-col relative">
            <Popup
              trigger={
                <NotificationSvg
                  className={classNames({
                    'primary-svg light-black-svg': true,
                    'w-5 h-5': true,
                    'brand-svg': location.pathname === '/notification'
                  })}
                  style={{ fill: 'white' }}
                />
              }
              position={['bottom center']}
              closeOnDocumentClick
              keepTooltipInside={false}
            >
              <NotificationModal
                isLoading={notificationLoading}
                userNotifications={notification?.data}
              />
            </Popup>

            <p className="text-xs font-roboto mt-1">Notifications</p>

            {notification?.data?.filter(
              notification =>
                notification?.title !== 'New Message' &&
                notification?.status !== 'seen' &&
                notification?.roleRequest?.status !== 'accepted'
            )?.length > 0 && (
              <p
                className="absolute bg-red-500 font-medium font-roboto w-4 h-4 flex justify-center items-center rounded-full"
                style={{ color: 'white', top: '-7px', right: '15px' }}
              >
                {
                  // Show the number of unseen notifications
                  notification?.data?.filter(
                    notification =>
                      notification?.title !== 'New Message' &&
                      notification?.status !== 'seen' &&
                      notification?.roleRequest?.status !== 'accepted'
                  )?.length
                }
              </p>
            )}
          </div>

          <Link className="flex items-center flex-col" to="/schedule">
            <ScheduleSvg
              className={classNames({
                'primary-svg light-black-svg': true,
                'w-5 h-5': true,
                'brand-svg': location.pathname === '/schedule'
              })}
              style={{ fill: 'white' }}
              alt=""
            />
            <p className="text-xs font-roboto mt-1">Schedule</p>
          </Link>

          <div className="relative">
            <Popup
              trigger={
                <ThreeDotHorizontalSvg
                  className={classNames({
                    'primary-svg light-black-svg': true,
                    'w-5 h-5': true
                    // 'brand-svg': location?.pathname === ''
                  })}
                  style={{ fill: 'white' }}
                />
              }
              position={['bottom center']}
              closeOnDocumentClick
              keepTooltipInside={false}
            >
              <ProfileOption />
            </Popup>
          </div>
        </div>

        <div className="md:hidden flex items-center ">
          <HamburgerSvg
            onClick={() => {
              expandNavBar();
            }}
            className="primary-svg w-8 h-8 cursor-pointer"
          />
        </div>
      </div>
    </nav>
  );
};
export default AuthenticatedHeader;
