import { useState } from 'react';
import { ReactComponent as CirclePlusSvg } from '../../img/misc/circle-plus.svg';
import CoachMainTable from './CoachMainTable/CoachMainTable';
import PlayerMainTable from './PlayerMainTable/PlayerMainTable';

const UserHistoryTable = ({
  isEditable,
  histories,
  role,
  addHistory,
  editHistory,
  deleteHistory
}) => {
  //
  //

  const [showDetail, setShowDetail] = useState(true);

  return (
    <>
      {histories?.length > 0 && (
        <div className="">
          {role === 'player' && showDetail && (
            <PlayerMainTable
              histories={histories}
              role={role}
              isEditable={isEditable}
              editHistory={editHistory}
              deleteHistory={deleteHistory}
            />
          )}

          {role === 'coach' && showDetail && (
            <CoachMainTable
              histories={histories}
              role={role}
              isEditable={isEditable}
              editHistory={editHistory}
              deleteHistory={deleteHistory}
            />
          )}

          {isEditable === true && (
            <div className="flex flex-col justify-center items-center w-full mt-5">
              <CirclePlusSvg
                className="primary-svg "
                onClick={() => addHistory()}
              />
              <p className="text-xl mt-5">Add Club History</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserHistoryTable;
