import Modal from "../../../Modal/Modal";
import DefaultGroupPfp from "../../../../img/misc/Default_pfp.png";
import { ReactComponent as MoreSvg } from "../../../../img/misc/three-dot-horizontal.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import ViewMemberOption from "../ViewMemberOption";

const ViewGroupMembers = ({ onCloseClick }) => {
  const participants = useSelector(
    state => state.userChats.groupChat.participants
  );

  const [showViewMemberModal, setShowViewMemberModal] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);

  const onClickUserOption = data => {
    setShowViewMemberModal(!showViewMemberModal);
    setCurrentUser(data);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Group Members">
      <div className="w-full relative bg-white rounded-b-lg max-h-72 overflow-y-scroll">
        {showViewMemberModal && (
          <ViewMemberOption
            currentUser={currentUser}
            onCloseClick={() => setShowViewMemberModal(false)}
          />
        )}
        {participants?.map(participant => (
          <div
            className="flex py-5 w-full px-4 items-center justify-between "
            key={participant.id}
          >
            <div className="flex items-center gap-3">
              <img
                src={DefaultGroupPfp}
                className="w-14 h-14 rounded-full"
                alt=""
              />
              <span className=" font-medium font-roboto text-lg">
                {participant?.contact?.first_name +
                  " " +
                  participant?.contact?.last_name}{" "}
                <br />{" "}
                <p className="font-light text-base">
                  {participant?.contact?.phone}
                </p>
              </span>
            </div>
            <MoreSvg
              className="p-2 w-10 h-10 hover:bg-gray-200 hover:shadow cursor-pointer rounded-full"
              onClick={() => onClickUserOption(participant)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ViewGroupMembers;
