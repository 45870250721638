import { useState } from 'react';
import logoSvg from '../../img/logo.svg';
import whiteLogoSvg from '../../img/logo-gold-white.svg';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as HamburgerSvg } from '../../img/misc/hamburger.svg';
import LandingSideNav from '../LandingSideNav';
import './Header.css';

const Header = ({ whiteLogo }) => {
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const location = useLocation();
  return (
    <div className="w-full py-4 px-2 flex justify-center">
      <div className="flex md:max-w-5xl w-full md:mx-20 justify-between items-center mx-2">
        <div className="">
          <Link to="/">
            <img
              className="w-40 cursor-pointer"
              src={whiteLogo ? whiteLogoSvg : logoSvg}
              alt=""
            />
          </Link>
        </div>
        <LandingSideNav
          isOpen={isSideNavOpen}
          setSideNavOpen={setSideNavOpen}
        />
        <HamburgerSvg
          onClick={() => {
            setSideNavOpen(true);
          }}
          className="primary-svg w-8 h-8 cursor-pointer Header-hamburger"
        />
        <div className="Header-navarea hidden md:flex space-x-12 justify-end">
          <Link
            className={classNames({
              'font-md font-bold hover:text-brand cursor-pointer': true,
              'text-brand': location.pathname === '/contact',
              'text-gray-400': location.pathname !== '/contact'
            })}
            to="/contact"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            CONTACT
          </Link>
          <h2
            className="ml-10 relative h-full font-md font-bold hover:text-brand cursor-pointer text-gray-400"
            style={{ fontFamily: 'Poppins, sans-serif' }}
            onMouseOver={() => setShowAbout(true)}
            onMouseLeave={() => setShowAbout(false)}
            to="/"
          >
            ABOUT +
            {showAbout && (
              <div className="absolute top-8 flex items-center flex-col max-w-full">
                <Link
                  className={classNames({
                    'ml-4  font-md font-bold hover:text-brand cursor-pointer': true,
                    'text-brand': location.pathname === '/',
                    'text-gray-400': location.pathname !== '/'
                  })}
                  to="/"
                  style={{ fontFamily: 'Poppins, sans-serif' }}
                >
                  ABOUT
                </Link>
                <Link
                  className={classNames({
                    'ml-4 whitespace-pre font-md font-bold hover:text-brand cursor-pointer': true,
                    'text-brand': location.pathname === '/',
                    'text-gray-400': location.pathname !== '/'
                  })}
                  to="/"
                  style={{ fontFamily: 'Poppins, sans-serif' }}
                >
                  WHAT WE DO
                </Link>
              </div>
            )}
          </h2>
          <Link
            className={classNames({
              'ml-10 font-md font-bold hover:text-brand cursor-pointer': true,
              'text-brand': location.pathname === '/login',
              'text-gray-400': location.pathname !== '/login'
            })}
            to="/login"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            LOGIN
          </Link>
          <Link
            className={classNames({
              'ml-10 font-md font-bold hover:text-brand cursor-pointer': true,
              'text-brand': location.pathname === '/register',
              'text-gray-400': location.pathname !== '/register'
            })}
            to="/register"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            REGISTER
          </Link>
          <Link
            to="/"
            className={classNames({
              'ml-10 font-md font-bold hover:text-brand cursor-pointer': true,
              'text-brand': location.pathname === '/landing',
              'text-gray-400': location.pathname !== '/landing'
            })}
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            HOME
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Header;
