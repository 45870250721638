import AxiosService from '../AxiosService';

// Role Request Notification
export const getRequestNotification = async pageParam => {
  const response = await AxiosService.get(
    `/user/role-request?limit=3&page=${pageParam}`
  );
  return response.data;
};

export const acceptRequestNotification = async id => {
  const response = await AxiosService.get(`/user/role-request/${id}/accept`);
  return response.data;
};

export const declineRequestNotification = async id => {
  const response = await AxiosService.get(`/user/role-request/${id}/reject`);
  return response.data;
};

// User Request Notification
export const getAllUserRequestNotification = async pageParam => {
  const response = await AxiosService.get(
    `notifications?limit=6&page=${pageParam || 1}`
  );
  return response.data;
};

export const deleteNotification = async ({ id }) => {
  const response = await AxiosService.delete(`/notifications/delete/${id}`);
  return response.data;
};

export const deleteAllNotification = async () => {
  const response = await AxiosService.delete('/notifications/delete-all/');
  return response.data;
};

export const markAsRead = async ({ id }) => {
  const response = await AxiosService.put(`/notifications/mark-seen/${id}`);
  return response.data;
};

export const markAllAsRead = async () => {
  const response = await AxiosService.put('/notifications/mark-seen/');
  return response.data;
};
