import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import profileImageUrl from '../../config/config';
import { accessChatToUser } from '../../services/routes/chats';
import { setUserChat } from '../../redux/userChats/UserChats';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MessageNotification = ({
  content,
  messagedDate,
  sender,
  userParticipants
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const me = useSelector(state => state.auth.user);

  const [receivedMessage, setReceivedMessage] = useState(false);

  const filteredParticipant = userParticipants?.filter(
    participant => participant?.id !== me?.id
  )[0];

  useEffect(() => {
    if (sender?.id !== me?.id) {
      setReceivedMessage(true);
    } else {
      setReceivedMessage(false);
    }
  }, [me?.id, sender, sender?.id]);

  const startChat = async () => {
    try {
      await accessChatToUser(filteredParticipant?.id).then(data => {
        dispatch(setUserChat(data?.data));
        history.push('/message');
      });
      queryClient.invalidateQueries('fetch_allChats');
    } catch (error) {}
  };

  return (
    <div
      onClick={startChat}
      className="w-4/5 flex flex-col gap-2 mt-1 py-2 p-1 cursor-pointer "
    >
      <div className="flex items-center gap-3">
        <img
          src={
            (filteredParticipant?.contact?.profile_image &&
              profileImageUrl +
                filteredParticipant?.contact?.profile_image?.url) ||
            DefaultProfilePng
          }
          className="w-12 h-12 object-cover rounded-full"
          alt=""
        />
        <h3 className="w-10/12 flex flex-col font-medium text-sm font-roboto">
          {filteredParticipant?.contact?.first_name +
            ' ' +
            filteredParticipant?.contact?.last_name}
          <div className=" font-normal text-xs text-gray-500 font-roboto truncate ">
            {`${!receivedMessage ? 'You:' : ''} ${content}`}
          </div>
        </h3>
      </div>
    </div>
  );
};

export default MessageNotification;
