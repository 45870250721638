import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { ReactComponent as CirclePlus } from '../../../img/misc/circle-plus.svg';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import TimezoneSelect from 'react-timezone-select';
import countryList from 'react-select-country-list';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import DeclineButton from '../../../components/Buttons/DeclineButton';
import {
  getMyAllPlayerList,
  searchPlayer
} from '../../../services/routes/User';
import AsyncSelect from 'react-select/async';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateEvent } from '../../../services/routes/Schedule/Events/eventOwner';
import { ReactComponent as ArrowupSvg } from '../../../img/misc/arrow-up.svg';
import { ReactComponent as ArrowdownSvg } from '../../../img/misc/arrow-down.svg';
// import './modal.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const BreakLine = () => {
  return (
    <div className="w-full px-5">
      <hr className="w-full  text-gray-500" />
    </div>
  );
};

const EditEventModal = ({
  onCloseClick,
  customStyles,
  refetch,
  editEvent = false
}) => {
  const { register, errors, handleSubmit, setValue, reset, getValues } =
    useForm();

  //time-zne selectiion and format
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [timezoneFormat, setTimezoneFormat] = useState();
  const recentEvent = useSelector(state => state.gameDetail.events)[0];

  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [displayOptionalDetails, setDisplayOptionalDetails] = useState(true);
  const [displayAssignmentDetails, setDisplayAssignmentDetails] =
    useState(true);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [notifyTeam, setNotifyTeam] = useState(false);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  const [disablePlayerInput, setDisablePlayerInput] = useState(false);
  const [disableTeamInput, setDisableTeamInput] = useState(false);
  const [assignements, setAssignements] = useState([{}]);

  const queryClient = useQueryClient();

  // Check if player or team is selected
  useEffect(() => {
    if (selectedPlayer) {
      setDisableTeamInput(true);
      setDisablePlayerInput(false);
    } else if (selectedTeam) {
      setDisablePlayerInput(true);
      setDisableTeamInput(false);
    }
  }, [selectedPlayer, selectedTeam]);

  //fetching data from api of team and player
  const { mutate, isLoading } = useMutation(updateEvent, {
    onSuccess: data => {
      queryClient.invalidateQueries('fetch_allEvents');
      toast.success('Event updated successfully');
      onCloseClick();
    },
    onError: () => {
      toast.error('Something went wrong while updating event');
    }
  });

  //setting the value for react-select country
  useEffect(() => {
    setValue('country', selectedLocation);
  }, [selectedLocation, setValue]);

  //setting the value for assignments
  useEffect(() => {
    setValue('assignments', assignements);
  }, [assignements, setValue]);

  //options for type of event
  const options = useMemo(() => countryList().getData(), []);

  const typeOptions = [
    {
      value: 'physio',
      label: <div className="select-label">Physio</div>
    },
    {
      value: 'game',
      label: <div className="select-label">Game</div>
    },
    {
      value: 'training',
      label: <div className="select-label">Training</div>
    },
    {
      value: 'meeting',
      label: <div className="select-label">Meeting</div>
    },
    {
      value: 'practice',
      label: <div className="select-label">Practice</div>
    },
    {
      value: 'other',
      label: <div className="select-label">Other</div>
    }
  ];

  const { data: playerList } = useQuery('allPlayerLists', getMyAllPlayerList);

  //fetching the search api and displaying the name of player
  const getSearchUsers = async (inputValue = '', callback) => {
    const filteredUsers = await searchPlayer(inputValue);

    const options = filteredUsers.data.map(user => ({
      label: user?.contact?.first_name + ' ' + user?.contact?.last_name,
      value: user?.player.id
    }));

    callback(options);
  };

  //submitting data post request
  const onSubmit = data => {
    const tagsArray = data?.tags?.split(',')?.map(tag => tag?.trim());
    const formattedHour =
      data?.hour?.length === 1 ? `0${data?.hour}` : data?.hour;

    const formattedMinute =
      data?.minute?.length === 1 ? `0${data?.minute}` : data?.minute;
    const teamData = {
      id: data.id,
      eventName: data.event,
      eventType: selectedOptions.map(option => option.value),
      scheduledDate: moment(
        `${data.start_date} ${data.hour}:${data.minute} ${data.meridiem}`,
        'YYYY-MM-DD hh:mm A'
      ).format('YYYY-MM-DD HH:mm:ss'),
      scheduledTime: `${formattedHour}:${formattedMinute} ${data.meridiem}`,
      arrivalTime: `${formattedHour}:${formattedMinute} ${data.meridiem}`,
      timezone: selectedTimezone.value,
      repeat: Boolean(data.repeat),
      location: selectedLocation.value,
      locationDetails: data.location_details,
      tags: tagsArray,
      additionalComments: data.comments,
      notifyTeam: notifyTeam,

      assignments: assignements.map(each => ({
        name: each.name,
        description: each.description,
        playerId: each.playerId?.value
      })),

      teamId: data.team?.id,
      trackAvailability: isChecked
    };
    mutate({ id: recentEvent.id, data: teamData });
  };

  useEffect(() => {
    if (editEvent) {
      reset({
        id: recentEvent?.id,
        event: recentEvent?.eventName,
        eventType: recentEvent?.eventType,
        start_date: moment(recentEvent?.scheduledDate).format('YYYY-MM-DD'),
        hour: moment(recentEvent?.scheduledDate).format('hh'),
        minute: moment(recentEvent?.scheduledDate).format('mm'),
        meridiem: moment(recentEvent?.scheduledDate).format('A'),
        repeat: recentEvent?.repeat,
        country: recentEvent?.location,
        location_details: recentEvent?.locationDetails,
        tags: recentEvent?.tags?.map(tag => tag.name).join(','),
        comments: recentEvent?.additionalComments,
        notifyTeam: recentEvent?.notifyTeam,
        assignments: recentEvent?.assignments,
        team: recentEvent?.teamId
      });
      setSelectedOptions(
        recentEvent?.eventType?.map(option => ({
          value: option,
          label: option
        }))
      );
      setSelectedLocation({
        label: recentEvent?.location,
        value: recentEvent?.location
      });
      setSelectedTimezone({
        label: recentEvent?.timezone,
        value: recentEvent?.timezone
      });
      setNotifyTeam(recentEvent?.notifyTeam);

      const assignements = recentEvent?.assignments?.map(each => ({
        name: each?.name,
        description: each?.description,
        playerId: {
          label:
            each?.player?.User?.contact?.first_name +
            ' ' +
            each?.player?.User?.contact?.last_name,
          value: each?.playerId
        }
      }));

      setAssignements(assignements);
      setSelectedTeam({
        label: recentEvent?.team?.name,
        value: recentEvent?.team?.id
      });
      setSelectedTeamPlayers(
        recentEvent?.team?.players?.map(player => ({
          label:
            player?.User.contact?.first_name +
            ' ' +
            player?.User.contact?.last_name,
          value: player?.User?.player?.id
        }))
      );
      setIsChecked(recentEvent?.trackAvailability);
      setDisablePlayerInput(true);
      setDisableTeamInput(true);
      setTimezoneFormat(recentEvent?.timezone);
    }
  }, [editEvent, recentEvent]);

  useEffect(() => {
    if (editEvent) {
      setValue('team', {
        value: playerList?.data?.filter(
          team => team?.id === recentEvent?.teamId
        )[0]?.id,
        label: playerList?.data?.filter(
          team => team?.id === recentEvent?.teamId
        )[0]?.name
      });
      setSelectedTeamPlayers(
        playerList?.data
          ?.filter(item => item?.id === recentEvent?.team?.id)[0]
          ?.players?.map(player => ({
            label:
              player?.User.contact?.first_name +
              ' ' +
              player?.User.contact?.last_name,
            value: player?.User?.player?.id
          }))
      );
    }
  }, [editEvent, playerList]);

  return (
    <Modal onCloseClick={onCloseClick} title="Event Details" width="580px">
      <div
        className="w-full overflow-y-scroll bg-white"
        style={{ maxHeight: '80vh' }}
      >
        <form
          className="w-full  mb-4 md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full p-5">
            {/* Event Name */}
            <div className="">
              <label className="text-base font-light font-roboto">
                Name of Event
              </label>
              <input
                className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                name="event"
                id="event"
                ref={register({
                  required: 'You must specify an event name'
                })}
              />
              {errors.event && <p>{errors.event.message}</p>}
            </div>

            {/* Event Type Select */}
            <div className="w-full mb-4">
              <label className="text-base font-light font-roboto" htmlFor="">
                Type
              </label>
              <div className="mb-2 rounded-md border-gray-400 ">
                <Select
                  classNamePrefix="select-react"
                  isClearable={true}
                  defaultValue={[typeOptions[0]]}
                  options={typeOptions}
                  onChange={() => setSelectedOptions(selectedOptions)}
                  isMulti
                  name="eventType"
                  // ref={register({
                  //   required: 'You must specify a Event type'
                  // })}
                  styles={customStyles}
                />
                {/* {errors.eventType && <p>{errors.eventType.message}</p>} */}
              </div>
            </div>

            <div className=" w-full flex items-center justify-between my-3 gap-6">
              {/* Team Select */}
              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">Team</label>
                <Select
                  isSearchable={true}
                  isClearable={true}
                  name="team"
                  // ref={register({
                  //   required: false
                  // })}
                  onChange={team => {
                    setValue('team', team?.value);
                    setSelectedTeam(team);
                    setSelectedTeamPlayers(
                      playerList?.data
                        ?.filter(item => item?.id === team?.value)[0]
                        .players?.map(player => ({
                          label:
                            player?.User.contact?.first_name +
                            ' ' +
                            player?.User.contact?.last_name,
                          value: player?.User?.player?.id
                        }))
                    );
                  }}
                  value={selectedTeam}
                  options={playerList?.data?.map(team => ({
                    label: team?.name,
                    value: team?.id
                  }))}
                  styles={customStyles}
                  placeholder="Search for team..."
                  classNamePrefix="select"
                  isDisabled={disableTeamInput}
                />
              </div>

              <div className="font-light mt-6">OR</div>

              {/* Players Select */}
              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">
                  Individual
                </label>
                <AsyncSelect
                  isMulti
                  isSearchable={true}
                  onChange={selected => setSelectedPlayer(selected)}
                  value={selectedPlayer}
                  loadOptions={getSearchUsers}
                  styles={customStyles}
                  placeholder="Search for players..."
                  name="usersOptions"
                  classNamePrefix="select"
                  isDisabled={disablePlayerInput}
                />
                {/* {errors.individual && <p>{errors.individual.message}</p>} */}
              </div>
            </div>

            <div className="w-full  flex items-center justify-center gap-3 pt-2">
              {/* Start Date */}
              <div>
                <label className="text-base font-light font-roboto">
                  Start Date
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-2 text-black bg-white border border-gray-200 rounded-md "
                  type="date"
                  name="start_date"
                  ref={register({
                    required: 'You must specify a start date'
                  })}
                />
              </div>
              {errors.start_date && <p>{errors.start_date.message}</p>}

              {/* Start Time */}
              <div className="">
                <label className=" text-base font-light font-roboto" htmlFor="">
                  Time
                </label>
                <div className="flex gap-2 ">
                  <input
                    className=" box  py-0.5 px-3 text-gray-500 bg-white border border-gray-200 rounded-md "
                    type="number"
                    placeholder="HH"
                    name="hour"
                    id="hour"
                    ref={register({
                      required: 'You must specify hour'
                    })}
                  />
                  {errors.hour && <p>{errors.hour.message}</p>}
                  <input
                    className="box  text-md py-0.5 px-4 text-gray-500 bg-white border border-gray-200 rounded-md "
                    type="number"
                    placeholder="MM"
                    name="minute"
                    id="minute"
                    ref={register({
                      required: 'You must specify minute'
                    })}
                  />
                  {errors.minute && <p>{errors.minute.message}</p>}

                  <select
                    className=" p-1 font-roboto text-gray-400 py-0.5 bg-white border border-gray-200 rounded-md"
                    style={{ width: '60px' }}
                    name="meridiem"
                    ref={register({
                      required: 'You must specify meridiem '
                    })}
                    defaultValue="AM"
                  >
                    <option
                      className="text-base font-light font-roboto"
                      value="AM"
                    >
                      AM
                    </option>
                    <option
                      className="text-base font-light font-roboto"
                      value="PM"
                    >
                      PM
                    </option>
                  </select>
                </div>
              </div>

              {/* TimeZone */}
              <div className="w-60 font-light">
                <label className="text-transparent">Timezone</label>
                <TimezoneSelect
                  name="timezone"
                  className="rounded-md"
                  // ref={register({
                  //   required: 'You must specify timezone '
                  // })}
                  styles={customStyles}
                  value={selectedTimezone}
                  onChange={timeZone =>
                    setSelectedTimezone({
                      label: timeZone?.altName,
                      value: timeZone?.value
                    })
                  }
                />
              </div>
            </div>
            {/* Repeating Event */}
            <div className="w-full my-4">
              <label className="text-base text-md font-light font-roboto">
                Repeat
              </label>
              <div className="text-base text-md font-light ">
                <select
                  className="form-select rounded-md h-12 font-roboto text-gray-700 py-1  bg-white border border-gray-200"
                  style={{ width: '200px' }}
                  name="repeat"
                  ref={register({
                    required: false
                  })}
                  defaultValue="does not repeat"
                >
                  <option
                    className="text-base font-light font-roboto"
                    value={true}
                  >
                    Repeat
                  </option>
                  <option
                    className="text-base font-light font-roboto"
                    value={false}
                  >
                    Does not repeat
                  </option>
                </select>
              </div>
            </div>

            <div className=" w-full mb-3 flex gap-5 mt-4 ">
              {/* Country Location */}
              <div className="w-1/2 ">
                <label className=" text-base font-light " htmlFor="">
                  Location
                </label>

                <Select
                  name="country"
                  className=" rounded-md"
                  value={selectedLocation}
                  options={options}
                  onChange={country => {
                    setSelectedLocation(country);
                  }}
                  styles={customStyles}
                />
              </div>

              {/* Location Detail */}
              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">
                  Location Details
                </label>
                <input
                  className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                  name="location_details"
                  ref={register({
                    required: 'You must specify location-details'
                  })}
                />
                {errors.loation_details && (
                  <p>{errors.location_details.message}</p>
                )}
              </div>
            </div>
          </div>

          <BreakLine />

          {/* Tags and Notes */}
          <div className="w-full  p-5">
            <div className="flex items-center justify-between">
              <div className="mb-3 text-gray-600  ">Optional Event Info</div>
            </div>

            <div>
              {/* Tags */}
              <label className="text-base font-light">Tags</label>
              <div className="flex gap-5 items-center">
                <input
                  className="w-1/2 text-left  text-md border rounded-md py-0.5 px-3 text-gray-700 bg-white"
                  name="tags"
                  ref={register({
                    required: 'You must specify tags '
                  })}
                />
                {errors.tags && <p>{errors.tags.message}</p>}
                <div className="flex items-center justify-center gap-2 ">
                  <div className="p-1 rounded-md">
                    <Checkbox
                      className="rounded-md"
                      style={{ border: 'none' }}
                      name="trackAvailability"
                      defaultChecked={false}
                      // ref={register({
                      //   required: 'You must specify track-availibility '
                      // })}
                      onChange={() => setIsChecked(!isChecked)}
                      checked={isChecked}
                    />
                  </div>
                  <p className="flex text-gray-500 font-light font-roboto">
                    Track Availibility
                  </p>
                </div>
              </div>

              {/* Comments/Notes */}
              <div className="mt-3 w-full ">
                <label className="text-base font-light font-roboto" htmlFor="">
                  Additional Comments
                </label>
                <input
                  className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-gray-700 bg-white"
                  name="comments"
                  ref={register({
                    required: 'You must specify comments '
                  })}
                />
                {errors.comments && <p>{errors.comments.message}</p>}
              </div>
            </div>
          </div>

          <BreakLine />

          <div className="w-full p-5">
            <div className="flex items-center justify-between">
              <div className="mb-3 text-gray-600">Assignments</div>
            </div>

            <div>
              <div className=" mb-3">
                {assignements?.map((item, index) => (
                  <div key={item.id} className="flex gap-5 items-center mb-5">
                    <input
                      className="w-1/3 text-left text-md border rounded-md py-0.5 px-3 text-gray-700 bg-white"
                      placeholder="e.g bring something"
                      name="name"
                      value={assignements[index]?.name || ''}
                      onChange={e => {
                        const newAssignments = [...assignements];
                        newAssignments[index].name = e.target.value;
                        setAssignements(newAssignments);
                      }}
                    />

                    <input
                      type="text"
                      placeholder="description..."
                      className="w-1/3 rounded-md  font-roboto text-gray-700 px-2  bg-white border border-gray-200"
                      name={`description`}
                      value={assignements[index]?.description || ''}
                      onChange={e => {
                        const newAssignments = [...assignements];
                        newAssignments[index].description = e.target.value;
                        setAssignements(newAssignments);
                      }}
                    />

                    <Select
                      isSearchable={true}
                      name={`playerId[${index}].playerId`}
                      onChange={selected => {
                        const newAssignments = [...assignements];
                        newAssignments[index].playerId = selected;
                        setAssignements(newAssignments);
                      }}
                      value={assignements[index]?.playerId || ''}
                      options={selectedTeamPlayers}
                      classNamePrefix="select"
                      className="w-1/3"
                      styles={customStyles}
                    />
                  </div>
                ))}
              </div>

              {errors.assignments && <p>{errors.assignments.message}</p>}

              <div
                className="p-1.5 mt-5 flex items-center border yellow-border text-lg font-light font-roboto gap-3 text-brand-light"
                style={{ width: '120px' }}
                onClick={() =>
                  setAssignements([
                    ...assignements,
                    {
                      name: '',
                      description: '',
                      playerId: null
                    }
                  ])
                }
              >
                Add more &nbsp;
                <CirclePlus className="w-6 h-6 flex-initial ... primary-svg circle-svg" />
              </div>
            </div>
          </div>

          <BreakLine />

          <div className="w-full p-5 ">
            <label htmlFor="">Notifications</label>
            <div className="flex items-center  gap-2 mt-3 ">
              <Checkbox
                className="rounded-md border-transparent"
                style={{ border: 'transparent' }}
                name="notifyTeams"
              />
              <p className="text-gray-500 font-light font-roboto">
                Notify your team
              </p>
            </div>
          </div>

          <BreakLine />
          <div className="w-full p-5 flex justify-center gap-4 ">
            <AcceptButton
              title={isLoading ? 'Saving...' : 'Save'}
              type="submit"
            />
            <DeclineButton title="Cancel" onClick={() => onCloseClick()} />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditEventModal;
