import AxiosService from '../../AxiosService'

export const addCoachClubHistory = async (data) => {
    const response = await AxiosService.post('/coach/club-history', data)
    return response.data;
}   

export const getallCoachClubHistory = async () => {
    const response = await AxiosService.get('/coach/club-histories')
    return response.data;
}

export const editCoachClubHistory = async ({ data, id }) => {
    const response = await AxiosService.put(`/coach/club-history/${id}`, data)
    return response.data;
}

export const deleteCoachClubHistory = async ({ id }) => {
    const response = await AxiosService.delete(`/coach/club-history/${id}`)
    return response.data;
}