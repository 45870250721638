import React from 'react';
import { ReactComponent as EditSvg } from '../../../../img/misc/edit.svg';
import InfoSetting from '../../../../components/InfoSetting/InfoSetting';
import { useSelector } from 'react-redux';
import moment from 'moment';

const CoachClub = ({ setShowEditInfoModal, isEditable, infoData }) => {
  const data = useSelector(state => state.user);

  const user = data?.user?.contact;

  return (
    <div className="w-full">
      <div className="flex justify-between items-end h-11  rounded-t-lg  font-roboto">
        <p className="flex items-end flex-col w-full">
          {isEditable !== false && (
            <EditSvg
              className="w-5 h-6 cursor-pointer"
              onClick={() => setShowEditInfoModal(true)}
            />
          )}
        </p>
      </div>
      <div
        className="flex justify-around divide-x-2 divide-brandGray w-full"
        style={{ height: '80%' }}
      >
        <div className="flex flex-col gap-y-5 mr-12">
          <InfoSetting title="Nationality" value={user?.country} />
          <InfoSetting
            title="Date of Birth"
            value={moment(user?.birth_date).format('MM/DD/YYYY')}
          />
          <InfoSetting title="Gender" value={user?.gender} />
        </div>
        <div className="flex flex-col gap-y-5  px-8">
          <InfoSetting
            title="Agent"
            value={
              data?.user?.coach?.agent
                ? data?.user?.coach?.agent?.contact?.first_name +
                  ' ' +
                  data?.user?.coach?.agent?.contact?.last_name
                : 'Not Specified'
            }
          />
          <InfoSetting
            title="Contract Until"
            value={
              user?.contract_until ? user?.contract_until : 'Not Specified'
            }
          />
        </div>
        <div className="flex flex-col gap-y-5 px-6">
          <InfoSetting
            title="Club"
            value={
              user?.coach?.club ? user?.coach?.club?.name : 'Not Specified'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CoachClub;
