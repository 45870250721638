import React from 'react';
import { ReactComponent as EditSvg } from '../../../../img/misc/edit.svg';
import InfoSetting from '../../../../components/InfoSetting/InfoSetting';
import { useSelector } from 'react-redux';
import moment from 'moment';

function AgentInfo({ setEditInfoModalOpen, isEditable = true }) {
  const data = useSelector(state => state.user);

  const user = data?.user?.contact;

  return (
    <div className="w-full">
      <div className="flex justify-between items-end h-11  rounded-t-lg  font-roboto">
        <p className="flex items-end flex-col w-full">
          {isEditable !== false && (
            <EditSvg
              className="w-5 h-6 cursor-pointer"
              onClick={() => setEditInfoModalOpen(true)}
            />
          )}
        </p>
      </div>
      <div
        className="flex justify-around divide-x-2 divide-brandGray w-full"
        style={{ height: '80%' }}
      >
        <div className="flex flex-col gap-y-5 mr-12">
          <InfoSetting title="Nationality" value={user?.country} />
          <InfoSetting
            title="Date of Birth"
            value={moment(user?.birth_date).format('MM/DD/YYYY')}
          />
          <InfoSetting title="Gender" value={user?.gender} />
        </div>
        <div className="flex flex-col gap-y-5  px-8">
          <InfoSetting
            title="Company Name"
            value={
              data?.user?.player?.company_name
                ? data?.user?.player?.company_name
                : 'Not Specified'
            }
          />
          <InfoSetting
            title="Website"
            value={
              data?.user?.player?.website
                ? data?.user?.player?.website
                : 'Not Specified'
            }
          />
          <InfoSetting
            title="Phone Number"
            value={user?.phone_number ? user?.phone_number : 'Not Specified'}
          />
        </div>
        <div className="flex flex-col gap-y-5 px-6">
          <InfoSetting
            title="Email"
            value={user?.email ? user?.email : 'Not Specified'}
          />
        </div>
      </div>
    </div>
  );
}

export default AgentInfo;
