import Media from './Media';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../redux/userSlice';
import { useMutation } from 'react-query';
import { createSocial, userSocials } from '../../../services/routes/User';
import { ReactComponent as SaveSvg } from '../../../img/landing/save.svg';
import { toast } from 'react-toastify';
import './SocialMedia.scss';

const SocialMedia = () => {
  //
  //
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);

  const facebookUrl = user?.contact?.socials?.facebook;
  const instagramUrl = user?.contact?.socials?.instagram;
  const twitterUrl = user?.contact?.socials?.twitter;
  const youtubeUrl = user?.contact?.socials?.youtube;
  const linkedinUrl = user?.contact?.socials?.linkedin;

  const [instagram, setInstagram] = useState(instagramUrl);
  const [facebook, setFacebook] = useState(facebookUrl);
  const [youtube, setYoutube] = useState(youtubeUrl);
  const [twitter, setTwitter] = useState(twitterUrl);
  const [linkedIn, setLinkedIn] = useState(linkedinUrl);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const { mutate: createSocials } = useMutation(createSocial, {
    onSuccess: () => {
      dispatch(fetchUser());
    },
    onError: err => {
      toast.error(err.response.data.message);
    }
  });

  const handleSave = ({ value, title }) => {
    if (title === 'Instagram') {
      setInstagram(value);
    } else if (title === 'Facebook') {
      setFacebook(value);
    } else if (title === 'Twitter') {
      setTwitter(value);
    } else if (title === 'LinkedIn') {
      setLinkedIn(value);
    } else if (title === 'YouTube') {
      setYoutube(value);
    }

    console.log('Handle save', value, title);
  };

  const handlePost = ({ value }) => {
    console.log('Handle post', value);
  };

  const { mutate: updateSocial } = useMutation(userSocials, {
    onSuccess: () => {
      dispatch(fetchUser());
    },
    onError: err => {
      console.log(err.resonse);
    }
  });

  const handleSubmit = () => {
    const postData = {
      instagram,
      facebook,
      youtube,
      linkedin: linkedIn,
      twitter
    };
    if (!instagram && !facebook && !youtube && !twitter && !linkedIn) {
      createSocials(postData);
    } else {
      updateSocial(postData);
    }
  };

  return (
    <div className="md:w-full w-11/12  mt-4 md:mt-0 ">
      <div className="SocialMedia w-full ">
        <Media
          title={'Facebook'}
          handleSave={handleSave}
          value={facebook}
          handlePost={handlePost}
        />
        <Media
          title={'LinkedIn'}
          handleSave={handleSave}
          value={linkedIn}
          handlePost={handlePost}
        />
        <Media
          title={'Twitter'}
          handleSave={handleSave}
          value={twitter}
          handlePost={handlePost}
        />
        <Media
          title={'Instagram'}
          handleSave={handleSave}
          value={instagram}
          handlePost={handlePost}
        />
        <Media
          title={'YouTube'}
          handleSave={handleSave}
          value={youtube}
          handlePost={handlePost}
        />
      </div>

      <button
        type="submit"
        onClick={handleSubmit}
        className="flex items-center gap-2 ml-12 "
      >
        <SaveSvg className="w-36" />
      </button>
    </div>
  );
};

export default SocialMedia;
