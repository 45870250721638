import DefaultBackgroundPng from '../../img/misc/Default_pfp.png';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupChats, setUserChat } from '../../redux/userChats/UserChats';
import profileImageUrl from '../../config/config';
import {
  accessChatToGroup,
  accessChatToUser
} from '../../services/routes/chats';
import { socket } from '../SocketIo';
import DefaultGroupPfp from '../../img/misc/Default_pfp.png';
import SmallLoader from '../Loader/SmallLoader';
import { useEffect, useState } from 'react';
import './userMessage.scss';

const UserChats = () => {
  const dispatch = useDispatch();
  const me = useSelector(state => state.auth.user);
  const users = useSelector(state => state.userChats.previousChats);

  const [isLoading, setIsLoading] = useState(false);

  // Checking if the user chats is loading or not
  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [users]);

  const startSingleChat = async friendId => {
    try {
      dispatch(setGroupChats(null));
      await accessChatToUser(friendId).then(data => {
        dispatch(setUserChat(data?.data));
        socket.emit('joinChat', { chatId: data?.data?.id });
      });
    } catch (error) {}
  };

  const startGroupChat = async groupId => {
    try {
      await accessChatToGroup(groupId).then(data => {
        dispatch(setGroupChats(data?.data));
        socket.emit('joinChat', { chatId: data?.data?.id });
      });
    } catch (error) {}
  };

  const countUnseenMessages = messages => {
    let count = 0;
    messages?.map(message => {
      if (message?.senderId !== me?.id) {
        message?.seenBy.length === 0 ||
        (message?.seenBy?.filter(each => each.user?.id !== me?.id)?.length >
          0 &&
          message?.seenBy?.filter(each => each.user?.id !== me?.id)?.length > 0)
          ? (count += 1)
          : (count += 0);
      }
      return count;
    });
    return count;
  };

  return (
    <div className="userChats ">
      {isLoading && <SmallLoader />}

      {/* Rendering Single Chat */}
      {users?.map(({ participants, isGroupChat, messages }) => (
        <>
          {isGroupChat === false &&
            participants
              ?.filter(friend => friend.id !== me.id)
              ?.map(({ contact, id }, innerIndex) => (
                <div
                  className="user__chat flex items-center justify-between p-2 border-b border-brandGray-light mt-2 pb-3"
                  key={id}
                  onClick={() => {
                    startSingleChat(id);
                  }}
                >
                  <div className="flex items-center gap-3">
                    <img
                      className="w-10 h-10 rounded-full object-cover"
                      src={
                        (contact?.profile_image &&
                          profileImageUrl + contact?.profile_image?.url) ||
                        DefaultBackgroundPng
                      }
                      alt=""
                    />
                    <div className="gap-5">
                      <p className="text-black text-base font-roboto font-medium ">
                        {contact?.first_name + ' ' + contact?.last_name}
                      </p>
                      {messages.length !== 0 ? (
                        <p className="text-sm font-light text-gray-600">
                          {me?.id === messages[messages?.length - 1]?.sender?.id
                            ? 'You: ' + messages[messages.length - 1]?.content
                            : messages[messages.length - 1]?.content}
                        </p>
                      ) : (
                        <p className="text-sm font-light text-gray-600">
                          Start the conversation
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    {countUnseenMessages(messages) > 0 && (
                      <span className="bg-brand-dark font-normal rounded-full flex justify-center items-center text-white text-xs w-4 h-4">
                        {countUnseenMessages(messages)}
                      </span>
                    )}
                  </div>
                </div>
              ))}
        </>
      ))}

      {/* Rendering Group Chat */}
      {users?.map(
        ({ id, messages, isGroupChat, chatName }) =>
          isGroupChat === true && (
            <div
              className="user__chat flex items-center justify-between p-2 border-b border-brandGray-light mt-2 pb-3"
              key={id}
              onClick={() => {
                startGroupChat(id);
              }}
            >
              <div className="flex items-center gap-3">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={DefaultGroupPfp}
                  alt=""
                />
                <div className="gap-5">
                  <p className="text-black text-base font-roboto font-medium">
                    {chatName}
                  </p>
                  {messages.length !== 0 ? (
                    <p className="text-sm font-light text-gray-600">
                      {me?.id === messages[messages?.length - 1]?.sender?.id
                        ? 'You: ' + messages[messages.length - 1]?.content
                        : messages[messages.length - 1]?.content}
                    </p>
                  ) : (
                    <p className="text-sm font-light text-gray-600">
                      Start the conversation
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center">
                {/* <p className="font-light text-gray-400 text-sm font-roboto">
                  {moment(messages[messages.length - 1]?.createdAt).fromNow()}
                </p> */}
                <span className="bg-brand-dark font-medium rounded-full flex justify-center items-center text-white text-xs w-4 h-4">
                  1
                </span>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default UserChats;
