import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../components/Modal/Modal';
import AcceptButton from '../../components/Buttons/AcceptButton';
import DeclineButton from '../../components/Buttons/DeclineButton';
import { deleteGame } from '../../services/routes/Schedule/Games/gameOwner';
import { deleteEvent } from '../../services/routes/Schedule/Events/eventOwner';
import { toast } from 'react-toastify';

const DeleteGameEvents = ({
  type,
  id,
  title,
  onCloseClick,
  refetchGame,
  refetchEvent
}) => {
  //

  const queryClient = useQueryClient();

  const { mutate: mutateDeleteGame, isLoading: gameLoading } = useMutation(
    deleteGame,
    {
      onSuccess: () => {
        refetchGame();
        toast.success('Game deleted successfully');
        queryClient.invalidateQueries('fetch_allGames');
        onCloseClick();
      },
      onError: () => {
        toast.error('Something went wrong while deleting the game');
      }
    }
  );

  const { mutate: mutateDeleteEvent, isLoading: eventLoading } = useMutation(
    deleteEvent,
    {
      onSuccess: () => {
        refetchEvent();
        toast.success('Event deleted successfully');
        queryClient.invalidateQueries('fetch_allEvents');
        onCloseClick();
      },
      onError: () => {
        toast.error('Something went wrong while deleting the event');
      }
    }
  );

  const handleDelete = () => {
    if (type === 'game') {
      mutateDeleteGame({ id });
    } else {
      mutateDeleteEvent({ id });
    }
  };

  return (
    <Modal title={title} onCloseClick={onCloseClick}>
      <div className="w-full  py-5 px-5 bg-white">
        <div className="flex flex-wrap w-full">
          <p
            className="font-light font-roboto"
            style={{ color: '#58595B', fontSize: '16px' }}
          >
            {`Are you sure you want to delete this ${type}?`}
          </p>
          <div className="w-full justify-center mt-8 items-center flex gap-6">
            <button className="mx-1 my-2 " onClick={handleDelete}>
              <AcceptButton
                title={gameLoading || eventLoading ? 'Deleting...' : 'YES'}
              />
            </button>
            <button className="mx-1 my-2 ">
              <DeclineButton title="NO" onClick={() => onCloseClick()} />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGameEvents;
