import Select from 'react-select';
import React, { useState, useMemo, useEffect } from 'react';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { ReactComponent as CirclePlus } from '../../../img/misc/circle-plus.svg';
import Checkbox from 'rc-checkbox';
import TimezoneSelect from 'react-timezone-select';
import countryList from 'react-select-country-list';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import DeclineButton from '../../../components/Buttons/DeclineButton';
import { getMyAllPlayerList } from '../../../services/routes/User';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateGame } from '../../../services/routes/Schedule/Games/gameOwner';
import 'rc-checkbox/assets/index.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const BreakLine = () => {
  return (
    <div className="w-full px-5">
      <hr className="w-full  text-gray-500" />
    </div>
  );
};

const EditGameModal = ({ onCloseClick, customStyles, editGame = false }) => {
  //
  //
  const queryClient = useQueryClient();
  const recentGame = useSelector(state => state.gameDetail.games)[0];

  const options = useMemo(() => countryList().getData(), []);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  const [assignements, setAssignements] = useState([{}]);
  const [defaultDate, setDefaultDate] = useState();

  //setting the value for react-select country
  useEffect(() => {
    setFormValue('country', selectedLocation);
  }, [selectedLocation]);

  const {
    register,
    errors,
    handleSubmit,
    setValue: setFormValue,
    reset,
    getValues
  } = useForm();

  const { mutate, isLoading } = useMutation(updateGame, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetch_allGames');
      toast.success('Game Updated Successfully');
      onCloseClick();
    },
    onError: () => {
      toast.error('Something went wrong while updating the game');
    }
  });

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const handleSelectTimezone = event => {
    setSelectedTimezone({ label: event?.altName, value: event?.value });
  };

  const { data: playerList } = useQuery('allPlayerLists', getMyAllPlayerList);

  const onsubmit = () => {
    const data = getValues();
    const formattedHour =
      data?.hour?.length === 1 ? `0${data?.hour}` : data?.hour;

    const formattedMinute =
      data?.minute?.length === 1 ? `0${data?.minute}` : data?.minute;

    const teamData = {
      additionalComments: data?.commment,
      locationDetails: data?.locationDetails,
      arrivalTime: data?.scheduledTime,
      assignments: assignements.map(each => ({
        name: each.name,
        description: each.description,
        playerId: each.playerId?.value
      })),
      notifyTeam: data?.notifyTeam,
      location: selectedLocation.value,
      opponentId: data?.opponentId,
      playGround: data?.playGround,
      players: selectedTeamPlayers.map(each => each.value),
      scheduledDate: data?.scheduledDate,
      scheduledTime: `${formattedHour}:${formattedMinute} ${data.meridiem}`,
      tags: data?.tags?.split(',')?.map(item => item.trim()),
      teamId: selectedTeam?.value,
      timezone: selectedTimezone?.value,
      trackAvailability: isChecked
    };
    mutate({ id: recentGame?.id, data: teamData });
  };

  useEffect(() => {
    setFormValue('assignments', assignements);
  }, [assignements]);

  useEffect(() => {
    if (editGame) {
      const scheduledTime = recentGame?.scheduledTime?.split('T')[1];

      let formattedHour =
        scheduledTime?.split(':')[0]?.length === 1
          ? `0${scheduledTime?.split(':')[0]}`
          : scheduledTime?.split(':')[0];

      //convert into 12 hour format
      if (formattedHour > 12) {
        formattedHour = formattedHour - 12;
      }

      const formattedMinute =
        scheduledTime?.split(':')[1]?.length === 1
          ? `0${scheduledTime?.split(':')[1]}`
          : scheduledTime?.split(':')[1];

      const meridiem =
        new Date(recentGame?.scheduledTime).getHours() >= 12 ? 'pm' : 'am';
      reset({
        additionalComments: recentGame?.additionalComments,
        locationDetails: recentGame?.locationDetails,
        arrivalTime: recentGame?.arrivalTime,
        assignments: recentGame?.assignments,
        location: recentGame?.location,
        createdById: recentGame?.createdById,
        id: recentGame?.id,
        hour: formattedHour,
        minute: formattedMinute,
        meridiem: meridiem,
        notifyTeam: recentGame?.notifyTeam,
        opponentId: recentGame?.opponentId,
        playGround: recentGame?.playGround,
        players: recentGame?.players,
        scheduledDate: new Date(recentGame?.scheduledDate),
        scheduledTime: recentGame?.scheduledTime,
        tags: recentGame?.tags?.map(item => item.name.trim()).join(', '),
        team: {
          value: playerList?.data?.filter(
            team => team?.id === recentGame?.teamId
          )[0]?.id,
          label: playerList?.data?.filter(
            team => team?.id === recentGame?.teamId
          )[0]?.name
        },
        timezone: recentGame?.timezone,
        trackAvailability: recentGame?.trackAvailability,
        assignements: recentGame?.assignments
      });
      const assignements = recentGame?.assignments?.map(each => ({
        name: each?.name,
        description: each?.description,
        playerId: {
          label:
            each?.player?.User?.contact?.first_name +
            ' ' +
            each?.player?.User?.contact?.last_name,
          value: each?.playerId
        }
      }));

      setAssignements(assignements);
      setSelectedLocation({
        value: recentGame?.location,
        label: options?.filter(item => item?.value === recentGame?.location)[0]
          ?.label
      });
      setSelectedTeam({
        value: recentGame?.team?.id,
        label: recentGame?.team?.name
      });
      setSelectedTeamPlayers(
        playerList?.data
          ?.filter(item => item?.id === recentGame?.team?.id)[0]
          .players?.map(player => ({
            label:
              player?.User.contact?.first_name +
              ' ' +
              player?.User.contact?.last_name,
            value: player?.User?.player?.id
          }))
      );

      setIsChecked(recentGame?.trackAvailability);
      setSelectedTimezone({
        label: recentGame?.timezone,
        value: recentGame?.timezone
      });
      defaultDateSetter(recentGame?.scheduledDate);
    }
  }, [editGame, recentGame]);

  useEffect(() => {
    if (editGame) {
      setFormValue('team', {
        value: playerList?.data?.filter(
          team => team?.id === recentGame?.teamId
        )[0]?.id,
        label: playerList?.data?.filter(
          team => team?.id === recentGame?.teamId
        )[0]?.name
      });
      setSelectedTeamPlayers(
        playerList?.data
          ?.filter(item => item?.id === recentGame?.team?.id)[0]
          ?.players?.map(player => ({
            label:
              player?.User.contact?.first_name +
              ' ' +
              player?.User.contact?.last_name,
            value: player?.User?.player?.id
          }))
      );
    }
  }, [editGame, playerList]);

  const defaultDateSetter = date => {
    const newDate = new Date(date);

    // get the month in this format of 04, the same for months
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    const day = ('0' + newDate.getDate()).slice(-2);
    const year = newDate.getFullYear();

    const shortDate = `${year}-${month}-${day}`;
    setDefaultDate(shortDate);
    setFormValue('scheduledDate', shortDate);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Game Details" width="580px">
      <div
        className="w-full overflow-y-scroll bg-white"
        style={{ maxHeight: '80vh' }}
      >
        <form
          className="w-full  md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onsubmit)}
        >
          <div className="w-full p-5">
            <div className="w-full  flex items-center justify-center gap-3">
              <div>
                <label className="text-base font-light font-roboto">
                  Start Date
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-2 text-black bg-white border border-gray-200 rounded-md "
                  type="date"
                  id="scheduledDate"
                  name="scheduledDate"
                  {...register('scheduledDate', {
                    required: 'Please select a date'
                  })}
                  defaultValue={defaultDate}
                  onChange={e => {
                    setFormValue('scheduledDate', e.target.value);
                  }}
                />
              </div>

              <div className="">
                <label className=" text-base font-light font-roboto" htmlFor="">
                  Time
                </label>
                <div className="flex gap-2 ">
                  <input
                    className=" box  py-0.5 px-3 text-gray-500 bg-white border border-gray-200 rounded-md "
                    type="number"
                    placeholder="HH"
                    name="hour"
                    id="hour"
                    ref={register({
                      type: 'hour'
                    })}
                  />
                  <input
                    className="box  text-md py-0.5 px-4 text-gray-500 bg-white border border-gray-200 rounded-md "
                    type="number"
                    placeholder="MM"
                    name="minute"
                    id="minute"
                    ref={register({
                      type: 'minute',
                      required: 'Please select the minute required'
                    })}
                  />

                  <select
                    className="p-1 form-select font-roboto text-gray-700 py-0.5 bg-white border border-gray-200 rounded-md"
                    style={{ width: '60px' }}
                    name="meridiem"
                    ref={register({
                      required: 'Please select a meridiem',
                      type: 'meridiem'
                    })}
                    defaultValue="am"
                  >
                    <option
                      value="am"
                      className="text-base font-light font-roboto"
                    >
                      AM
                    </option>
                    <option
                      value="pm"
                      className="text-base font-light font-roboto"
                    >
                      PM
                    </option>
                  </select>
                </div>
              </div>

              <div className="w-60 font-light">
                <label className="text-transparent">Timezone</label>
                <TimezoneSelect
                  name="timezone"
                  className="rounded-md"
                  value={selectedTimezone}
                  onChange={handleSelectTimezone}
                  styles={customStyles}
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-center gap-5  mt-5">
              <div className="w-1/2">
                <label className="text-base text-md font-light">Team</label>
                <Select
                  placeholder="Select Team"
                  value={selectedTeam}
                  options={playerList?.data?.map(team => ({
                    label: team?.name,
                    value: team?.id
                  }))}
                  onChange={team => {
                    setFormValue('team', team?.value);
                    setSelectedTeam(team);
                    setSelectedTeamPlayers(
                      playerList?.data
                        ?.filter(item => item?.id === team?.value)[0]
                        .players?.map(player => ({
                          label:
                            player?.User.contact?.first_name +
                            ' ' +
                            player?.User.contact?.last_name,
                          value: player?.User?.player?.id
                        }))
                    );
                  }}
                  styles={customStyles}
                  classNamePrefix="select"
                />
              </div>

              <div className="w-1/2">
                <label className="text-base text-md font-light">Opponent</label>
                <select
                  className="form-select rounded-md  w-full font-roboto text-gray-700 py-0.5 bg-white border border-gray-200"
                  name="option"
                  ref={register({
                    type: 'option',
                    required: 'Please select a option'
                  })}
                  defaultValue="barcelona"
                >
                  <option
                    className="text-base font-light font-roboto"
                    value="barcelona"
                  >
                    Barcelona
                  </option>
                  <option
                    className="text-base font-light font-roboto"
                    value="madrid"
                  >
                    Real Madrid
                  </option>
                </select>
              </div>
            </div>

            <div className=" w-full mb-3 items-center flex gap-5 mt-4 ">
              <div className="w-1/2 ">
                <label className=" text-base font-light">Location</label>
                <Select
                  name="country"
                  className=" rounded-md"
                  value={selectedLocation}
                  options={options}
                  onChange={country => {
                    setSelectedLocation(country);
                  }}
                  styles={customStyles}
                />
              </div>

              <div className=" w-1/2">
                <label className="text-base font-light font-roboto">
                  Location Details
                </label>
                <input
                  className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                  id="locationDetails"
                  name="locationDetails"
                  ref={register({
                    type: 'locationDetails',
                    required: 'Please specify the Location Detail'
                  })}
                />
              </div>
            </div>
          </div>

          <BreakLine />

          <div className="w-full p-5 ">
            <div className="mb-3 text-gray-600">Optional Game Info</div>

            <div className="flex gap-5 items-center w-full">
              <div className=" w-1/2 ">
                <label className="text-md font-light font-roboto">
                  Home or away
                </label>

                <select
                  className="form-select rounded-md  w-full font-roboto text-gray-700 py-0.5 bg-white border border-gray-200 "
                  name="playground"
                  ref={register({
                    type: 'playground',
                    required: 'Please specifiy home or away'
                  })}
                  defaultValue="home"
                >
                  <option
                    className="text-base font-light font-roboto"
                    value="home"
                  >
                    Home
                  </option>
                  <option
                    className="text-base font-light font-roboto"
                    value="away"
                  >
                    Away
                  </option>
                </select>

                {/*  */}
              </div>
              <div className="w-1/2 ">
                <label className="text-md font-light font-roboto">
                  Arrival time
                </label>
                <input
                  className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                  type="time"
                  id="arrivalTime"
                  name="arrivalTime"
                  ref={register({
                    type: 'arrivalTime'
                  })}
                />
              </div>
            </div>

            <div className="mt-6">
              <label className="text-base font-light">Tags</label>
              <div className="flex gap-5 items-center">
                <input
                  className="w-1/2 text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                  id="tags"
                  name="tags"
                  ref={register({
                    type: 'tags'
                  })}
                />

                <div className="flex items-center justify-center gap-2 ">
                  <Checkbox
                    className="rounded-md border-transparent"
                    style={{ border: 'transparent' }}
                    id="trackAvailability"
                    name="trackAvailability"
                    onChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  />
                  <p className="flex text-gray-500 font-light font-roboto">
                    Track Availibility
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3 w-full ">
              <label className="text-base font-light font-roboto" htmlFor="">
                Additional Comments
              </label>
              <input
                className="w-full text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                id="additionalComments"
                name="additionalComments"
                ref={register({
                  type: 'additionalComments'
                })}
              />
            </div>
          </div>

          <BreakLine />

          <div className="w-full p-5">
            <div className="mb-3 text-gray-600">Assignments</div>
            {assignements?.map((field, index) => (
              <div
                className="flex gap-5 items-center w-full my-2"
                key={field?.id}
              >
                <input
                  className="w-1/3 text-left  text-md border rounded-md py-0.5 px-3 text-black bg-white"
                  id={`assignments[${index}].name`}
                  name={`assignments[${index}].name`}
                  placeholder="eg. Bring Towels"
                  value={assignements[index]?.name || ''}
                  onChange={e => {
                    const newAssignments = [...assignements];
                    newAssignments[index].name = e.target.value;
                    setAssignements(newAssignments);
                  }}
                />
                {errors.assignments && <p>{errors.assignments.message}</p>}

                <input
                  className="w-1/3  rounded-md p-1 font-roboto text-gray-700 py-0.5 bg-white border border-gray-200"
                  name={`assignments[${index}].description`}
                  placeholder="write a description"
                  value={assignements[index]?.description || ''}
                  onChange={e => {
                    const newAssignments = [...assignements];
                    newAssignments[index].description = e.target.value;
                    setAssignements(newAssignments);
                  }}
                />
                <Select
                  isSearchable={true}
                  name={`playerId[${index}].playerId`}
                  onChange={selected => {
                    const newAssignments =
                      assignements?.length > 0 ? [...assignements] : [];
                    newAssignments[index].playerId = selected;
                    setAssignements(newAssignments);
                  }}
                  value={assignements[index]?.playerId || ''}
                  options={selectedTeamPlayers}
                  classNamePrefix="select"
                  className="w-1/3"
                  styles={customStyles}
                />
              </div>
            ))}

            <div
              className="p-1.5 mt-5 flex items-center border yellow-border text-lg font-light font-roboto gap-3 text-brand-light"
              style={{ width: '120px' }}
              onClick={() =>
                setAssignements([
                  ...assignements,
                  {
                    name: '',
                    description: '',
                    playerId: ''
                  }
                ])
              }
            >
              Add More &nbsp;
              <CirclePlus className="w-6 h-6 flex-initial ... primary-svg circle-svg " />
            </div>
          </div>

          <BreakLine />

          <div className="w-full p-5 ">
            <label htmlFor="">Notifications</label>
            <div className="flex mt-3 items-center gap-8">
              <div className="flex items-center  gap-2 justify-center ">
                <Checkbox
                  className="rounded-md border-transparent"
                  style={{ border: 'transparent' }}
                  name="notifyTeams"
                />
                <p className="text-gray-500 font-light font-roboto">
                  Notify your team
                </p>
              </div>
              <div className="flex items-center  gap-2 justify-center ">
                <Checkbox
                  className="rounded-md border-transparent"
                  style={{ border: 'transparent' }}
                  name="notifySelectedTeamMembers"
                />
                <p className="text-gray-500 font-light font-roboto">
                  Notify selected team members
                </p>
              </div>
            </div>
          </div>

          <BreakLine />

          <div className="p-5 w-full flex justify-center gap-4 ">
            <AcceptButton
              title={isLoading ? 'Saving...' : 'Save'}
              type="submit"
            />
            <DeclineButton title="Cancel" onClick={() => onCloseClick()} />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditGameModal;
