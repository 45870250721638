import React, { useEffect } from 'react';
import { ReactComponent as CirclePlus } from '../../img/misc/circle-plus.svg';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../redux/userSlice';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import profileImageUrl from '../../config/config';

const RoasterLayout = ({ data }) => {
  return (
    <div className="my-1  flex flex-col gap-2">
      <div className="flex max-w-5xl flex-wrap items-center gap-2 gap-y-8">
        {data?.map(player => (
          <div key={player?.id} className=" flex items-center gap-3 px-3">
            <img
              src={
                (player?.contact?.profile_image?.url &&
                  profileImageUrl + player?.contact?.profile_image?.url) ||
                DefaultProfilePng
              }
              className="w-12 h-12 rounded-full object-cover"
              alt=""
            />
            <p className="text-gray-600 text-sm">
              {player?.contact?.first_name + ' ' + player?.contact?.last_name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

function ManagedPlayer({
  addModalOpen,
  title,
  data,
  isEditable = true,
  isClub,
  onEditManagedPlayerClick
}) {
  //
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const handleViewAll = data => {
    // dispatch(setManagedUser(data));
  };

  return (
    <div className="rounded-t-lg md:min-w-full sm:max-w-md  text-white font-roboto ">
      {/* Roasters */}
      <div className="max-h-96 overflow-y-scroll px-4 py-1 mx-auto">
        {data?.length !== 0 ? (
          <div className="w-full flex flex-col gap-4">
            <RoasterLayout data={data} roasterTitle="All Players/Roaster" />
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center items-center my-4 gap-4">
            <p className="text-gray-600 text-base">No Players Found</p>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-center items-center w-full mt-10">
        <CirclePlus
          className="primary-svg ml-2"
          onClick={() => addModalOpen(true)}
        />
        <p className="text-xl mt-5 text-black">Add Player</p>
      </div>
    </div>
  );
}

export default ManagedPlayer;
