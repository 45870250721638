import { useForm } from "react-hook-form";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import Modal from "../../../../components/Modal/Modal";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { addPlayerCoachHistory } from "../../../../services/routes/PlayerHistories/playerCoachHistories";

const AddPlayerCoachHistory = ({ onCloseClick, refetch }) => {
  //
  //
  let countryValue = "us";
  const { register, errors, handleSubmit } = useForm();

  const queryClient = useQueryClient();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDocument, setSelectedDocument] = useState([]);

  const { mutate, isLoading } = useMutation(addPlayerCoachHistory, {
    onSuccess: () => {
      queryClient.invalidateQueries("playerCoach");
      refetch();
      onCloseClick();
    },
    onError: err => {
      console.error(err);
    }
  });

  const handleFileChange = e => {
    let files = e.target.files;

    const updatedDocument = [...selectedDocument];

    for (let i = 0; i < files.lengthl; i++) {
      let file = files[i];

      updatedDocument.push(file);
    }

    setSelectedDocument(updatedDocument);
  };

  const onSubmit = async data => {
    const formData = new FormData();

    formData.append("coachName", data.coachName);
    formData.append("coachEmail", data.coachEmail);
    formData.append("coachType", data.coachType);
    formData.append("coachClub", data.coachClub);
    formData.append("coachPhone", phoneNumber);

    if (selectedDocument) {
      selectedDocument.forEach(images => {
        formData.append("documents", images);
      });
    }

    mutate(formData);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Coach History" width="500px">
      <div className="AddAwardModal py-5 bg-white  rounded-b-md">
        <form
          className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full bg-white mx-3">
            <div className="w-full flex items-center gap-4 mb-4">
              <div className="w-1/2 ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Name
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm h-11 "
                  type="text"
                  name="coachName"
                  ref={register({
                    type: "coachName"
                  })}
                />
                {errors.coachName && <p>{errors.coachName.message}</p>}
              </div>
              <div className="w-1/2 ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Email
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  ref={register({
                    type: "coachEmail"
                  })}
                  name="coachEmail"
                />
                {errors.coachEmail && <p>{errors.coachEmail.message}</p>}
              </div>
            </div>

            <div className="w-full flex items-center gap-4 mb-4">
              <div className="">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Club
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm h-11"
                  type="text"
                  ref={register({
                    type: "coachClub"
                  })}
                  name="coachClub"
                />
                {errors.coachClub && <p>{errors.coachClub.message}</p>}
              </div>
              <div className=" ">
                <label className="text-sm font-medium text-gray-500" htmlFor="">
                  Coach Type
                </label>
                <input
                  className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                  type="text"
                  ref={register({
                    type: "coachType"
                  })}
                  name="coachType"
                />
                {errors.coachType && <p>{errors.coachType.message}</p>}
              </div>
            </div>
            <div className="w-1/2">
              <PhoneInput
                country={countryValue}
                value={phoneNumber}
                onChange={phone => setPhoneNumber(phone)}
                ref={register({
                  type: "coachPhone"
                })}
                inputStyle={{
                  background: "transparent",
                  border: "none",
                  fontWeight: "400",
                  fontSize: "17px"
                }}
                className="block w-full text-left py-0.5 mb-4 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
                name="coachPhone"
              />
            </div>

            <input
              type="file"
              multiple
              className=" border-gray-200 border rounded-sm shadow-sm w-max p-1 flex justify-center mb-4"
              onChange={handleFileChange}
            />

            <div className="w-full flex justify-start">
              <button
                type="submit"
                className={`w-28 ${isLoading & "cursor-not-allowed"}`}
              >
                <AcceptButton title={isLoading ? "Saving..." : "Save"} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddPlayerCoachHistory;
