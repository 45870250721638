import { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { updateBio } from '../../../../redux/userSlice/';
import AcceptButton from '../../../../components/Buttons/AcceptButton';
import './modal.css';

const EditAboutModal = ({ aboutPlayer, onCloseClick }) => {
  //
  //
  const dispatch = useDispatch();
  const [aboutText, setAboutText] = useState('');

  useEffect(() => {
    setAboutText(aboutPlayer);
  }, [aboutPlayer]);

  const onSave = () => {
    dispatch(
      updateBio({
        bio: aboutText
      })
    );
    setAboutText('');
    onCloseClick();
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Bio">
      <div className="px-4 py-5 bg-white">
        <textarea
          className="w-full p-2 h-64  border border-brand-dark rounded-lg"
          value={aboutText}
          placeholder={aboutText.length < 0 && 'Tell us about yourself'}
          onChange={e => {
            setAboutText(e.target.value);
          }}
          cols="30"
        />

        <button onClick={onSave} className="mt-4">
          <AcceptButton title="Save" />
        </button>
      </div>
    </Modal>
  );
};
export default EditAboutModal;
