import AxiosService from '../../../AxiosService';

export const createEvent = async data => {
  const response = await AxiosService.post(`/events/create/`, data);
  return response.data;
};

export const deleteEvent = async ({ id }) => {
  const response = await AxiosService.delete(`/events/event/${id}/`);
  return response.data;
};

export const getAllEvents = async () => {
  const response = await AxiosService.get(`/events/events/`);
  return response.data;
};
export const getEvent = async ({ id }) => {
  const response = await AxiosService.get(`/events/event/${id}`);
  return response.data;
};

//update events
export const updateEvent = async ({ id, data }) => {
  const response = await AxiosService.put(`/events/event/${id}/`, data);
  return response.data;
};

//get all player list in agent
export const getPlayerListAgent = async () => {
  const response = await AxiosService.get(`/agents/players/list/`);
  return response.data;
};

//get all player list in coach
export const getPlayerListCoach = async () => {
  const response = await AxiosService.get(`/coach/players/list/`);
  return response.data;
};

//get all player list in player
export const getPlayerListPlayer = async () => {
  const response = await AxiosService.get(`/user/player-list/my`);
  return response.data;
};
