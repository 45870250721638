import React from 'react';
import Modal from '../../components/Modal/Modal';
import AcceptButton from '../../components/Buttons/AcceptButton';
import DeclineButton from '../../components/Buttons/DeclineButton';

const DeleteHistoryModal = ({
  onCloseClick,
  id,
  isLoading,
  mutate,
  parameter
}) => {
  const handleDelete = () => {
    mutate({ id: id });
  };

  return (
    <>
      <Modal
        title={`Delete ${parameter ? parameter : 'Contact'}`}
        onCloseClick={onCloseClick}
      >
        <div className="w-full  py-5 px-5 bg-white">
          <div className="flex flex-wrap w-full">
            <p
              className="font-light font-roboto"
              style={{ color: '#58595B', fontSize: '16px' }}
            >
              Are you sure you want to delete this {parameter}?
            </p>
            <div className="w-full justify-center mt-8 items-center flex gap-6">
              <button className="mx-1 my-2 " onClick={handleDelete}>
                <AcceptButton
                  title={isLoading ? 'Loading...' : 'YES'}
                  onClick={() => onCloseClick()}
                />
              </button>
              <button className="mx-1 my-2 ">
                <DeclineButton title="NO" onClick={() => onCloseClick()} />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteHistoryModal;
