import { Route, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import FAQ from './pages/FAQ/FAQ';
import Features from './pages/Features/Features';
import MyPost from './pages/MyPost/MyPost';
import Landing from './pages/Landing';
import About from './pages/About/About';
import Blog from './pages/Blog/Blog';
import Privacy from './pages/Privacy/Privacy';
import Help from './pages/Help/Help';
import Terms from './pages/Terms/Terms';
import GetStarted from './pages/GetStarted/GetStarted';
import Contact from './pages/Contact/Contact';
import Notifications from './pages/Notifications/Notifications';
import Settings from './pages/Settings';
import Schedule from './pages/Schedule';
import ProtectedRoute from './Layout/ProtectedRoute';
import NotFound from './Layout/NotFound';
import User from './pages/UserProfile';
import Home from './pages/Player/PlayerHome/Home';
import VisitUserProfile from './pages/VisitUserProfile/VisitUserProfile';
import UserMessages from './pages/UserMessages/UserMessages';
import 'react-toastify/dist/ReactToastify.css';
import NotifyPost from './pages/NotifyPost';

const Routes = () => {
  const protectedRoutes = [
    {
      path: '/home',
      component: <Home />,
      exact: true
    },
    {
      path: '/my-profile',
      component: <User />,
      exact: true
    },
    {
      path: '/my-post',
      component: <MyPost />,
      exact: true
    },
    {
      path: '/post/:id',
      component: <NotifyPost />,
      exact: true
    },
    {
      path: '/contact',
      component: <Contact />,
      exact: true
    },
    {
      path: '/message',
      component: <UserMessages />,
      exact: true
    },
    {
      path: '/notification',
      component: <Notifications />,
      exact: true
    },
    {
      path: '/schedule',
      component: <Schedule />,
      exact: true
    },
    {
      path: '/settings',
      component: <Settings />,
      exact: true
    },
    {
      path: '/user-profile/:username',
      component: <VisitUserProfile />,
      exact: true
    }
  ];

  return (
    <div style={{ minHeight: '100vh' }}>
      <Switch>
        {protectedRoutes.map((route, index) => (
          <ProtectedRoute key={index} path={route.path} exact={route.exact}>
            {route.component}
          </ProtectedRoute>
        ))}

        <Route exact path="/" component={Landing} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/contact" component={Contact} /> */}
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/about" component={About} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/getStarted" component={GetStarted} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/features" component={Features} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
