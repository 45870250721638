/* eslint-disable react-hooks/exhaustive-deps */
import SideNavWrapper from '../../SideNavWrapper/SideNavWrapper';
import Analytics from '../PlayerAnalytics/Analytics';
import CreatePost from './CreatePost/CreatePost';
import Posts from './Post/Posts';
import PossibleConnect from '../../SideNavWrapper/SuggestionRightNav/PossibleConnect';
import RelatedEvents from '../../SideNavWrapper/SuggestionRightNav/RelatedEvents';
import { getAllPost } from '../../../services/routes/Post';
import { useQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import SmallLoader from '../../../components/Loader/SmallLoader';
import { toast } from 'react-toastify';
import './home.scss';

function Home() {
  const [page, setPage] = useState(1);
  const [post, setPostsData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { refetch } = useQuery('fetch_allPost', () => getAllPost(page), {
    onSuccess: data => {
      setPostsData(prevData => [...prevData, ...data?.data?.posts]);
      data?.data?.hasMore ? setHasMore(true) : setHasMore(false);
      setPage(prevPage => prevPage + 1);
    },
    onError: () => {
      toast.error('Something went wrong while fetching posts');
      setHasMore(false);
    }
  });

  return (
    <SideNavWrapper width={'100%'} isFooter={true}>
      <div className="w-full  flex justify-center  ">
        <div className="flex gap-2 xl:justify-around ">
          <div className="md:block hidden ">
            <Analytics />
          </div>
          <div className="feed_container flex flex-col ">
            <CreatePost />
            <InfiniteScroll
              dataLength={post?.length}
              next={refetch}
              hasMore={hasMore}
              loader={<SmallLoader />}
              endMessage={
                post?.length > 0 ? (
                  <p className="text-center mb-5 text-gray-800">
                    <b>Yay! You have seen it all</b>
                  </p>
                ) : (
                  <p className="text-center my-5 text-gray-800">
                    <b>No post to show</b>
                  </p>
                )
              }
            >
              <Posts post={{ data: post }} feedPage={true} />
            </InfiniteScroll>
          </div>
          <div className="md:flex md:flex-col hidden ">
            <PossibleConnect />
            <RelatedEvents />
          </div>
        </div>
      </div>
    </SideNavWrapper>
  );
}

export default Home;
