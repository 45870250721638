import { createSlice } from '@reduxjs/toolkit';

const PostSlice = createSlice({
  name: 'post',
  initialState: {
    savedPost: []
  },
  reducers: {
    setSavedPost: (state, action) => {
      state.savedPost = action.payload;
    }
  }
});

export const { setSavedPost } = PostSlice.actions;

export default PostSlice.reducer;
