import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../img/logo.svg';
import Form1 from './Form1';
import Form2 from './Form2';
import Form4 from './Form4';
import FormBio from './FormBio';
import FormAgentInfo from './FormAgentInfo';
import FormCoachInfo from './FormCoachInfo';
import FormClubInfo from './FormClubInfo';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { fetchUser } from '../../redux/userSlice/index';
import { Stepper, Step } from 'react-form-stepper';
import DeclineButton from '../../components/Buttons/DeclineButton';
import { useDispatch, useSelector } from 'react-redux';
import OTP from './OTP';
import './Register.css';

const Register = props => {
  const dispatch = useDispatch();
  const [progressStep, setProgressStep] = useState(0);
  const history = useHistory();
  const user = useSelector(state => state.user?.user);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (user?.id) {
      setProgressStep(3);
    }
  }, [user?.id]);

  const moveBtnForward = () => {
    try {
      const nextBtn = document.querySelector('._hsN1w ');
      nextBtn.click();
    } catch (error) {}
  };

  const moveProgressForward = async (move = true) => {
    if (move) {
      setProgressStep(progressStep + 1);
      moveBtnForward();
    }

    if (move === false) {
      history.push('/home');
    }
  };

  return (
    <div>
      <Header />
      <div className="h-3/4 register_container bg-teal-lighter">
        <div className="mt-20 font-light font-roboto md:w-1/3">
          <Stepper
            className="stepper"
            activeStep={progressStep}
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: '400',
              backgroundColor: 'transparent'
            }}
            styleConfig={{
              completedBgColor: '#C99400',
              activeBgColor: '#C99400',
              activeTextColor: '#FFFFFF',
              inactiveTextColor: 'transparent',
              size: '2rem'
            }}
            connectorStyleConfig={{
              size: '3px'
            }}
          >
            <Step
              onClick={() => setProgressStep(0)}
              label="Question"
              disabled={user?.isEmailVerified}
            />
            <Step
              onClick={() => setProgressStep(1)}
              label="Account"
              disabled={user?.isEmailVerified}
            />
            <Step
              onClick={() => setProgressStep(2)}
              label="OTP"
              disabled={user?.isEmailVerified}
            />
            <Step onClick={() => setProgressStep(3)} label="Information" />
            <Step onClick={() => setProgressStep(4)} label="Complete Profile" />
          </Stepper>
        </div>
        <div className="flex items-center md:w-1/3 w-11/12 ">
          <div
            style={{ boxShadow: '0px 3px 20px #00000029' }}
            className="w-full bg-white rounded p-0 md:p-8 m-4 md:mx-auto md:border"
          >
            <div className="block w-full text-center">
              <img
                className="text-center hidden md:block text-grey-darkest  logo"
                src={logo}
                alt="Logo"
              />
            </div>

            {/* Account */}
            {progressStep === 0 && (
              <Form2 moveProgressForward={moveProgressForward} />
            )}

            {/* Account Type */}
            {progressStep === 1 && (
              <Form1 moveProgressForward={moveProgressForward} />
            )}
            {progressStep === 2 && (
              <OTP moveProgressForward={moveProgressForward} />
            )}

            {/* Player Information */}
            {/* {role === "player" && progressStep === 3 && (
              <>
                <Form4 moveProgressForward={moveProgressForward} />
              </>
            )} */}

            {progressStep === 3 && (
              <Form4 moveProgressForward={moveProgressForward} />
            )}

            {/* Agent Information */}
            {/* {progressStep === 3 && (
              <FormAgentInfo moveProgressForward={moveProgressForward} />
            )} */}

            {/* Coach Information */}
            {/* {user?.role === "coach" && progressStep === 3 && (
              <FormCoachInfo moveProgressForward={moveProgressForward} />
            )} */}

            {/*Club Information */}
            {/* {user?.role === "club" && progressStep === 3 && (
              <FormClubInfo moveProgressForward={moveProgressForward} />
            )} */}

            {/* Complete Profile */}
            {progressStep === 4 && (
              <FormBio moveProgressForward={moveProgressForward} />
            )}
            {console.log(user?.isEmailVerified)}

            {progressStep >= 1 && !user?.isEmailVerified && (
              <div className="block w-full mt-4 ">
                <button
                  className="block w-2/6  m-auto"
                  onClick={() => setProgressStep(prev => prev - 1)}
                >
                  <DeclineButton title="Back" customCss={true} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer showLetsChat={true} />
    </div>
  );
};

export default Register;
