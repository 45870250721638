import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import ProfileUserCard from './ProfileUserCard';
import DeclineButton from '../Buttons/DeclineButton';
import AcceptButton from '../Buttons/AcceptButton';
import { ReactComponent as FacebookSvg } from '../../img/social/facebook.svg';
import { ReactComponent as InstagramSvg } from '../../img/social/instagram.svg';
import { ReactComponent as LinkedinSvg } from '../../img/social/linkedin.svg';
import { ReactComponent as TwitterSvg } from '../../img/social/twitter.svg';
import { ReactComponent as YoutubeSvg } from '../../img/misc/Youtube.svg';
import { useSelector } from 'react-redux';
import SendMessageModal from '../../pages/Contact/SendMessageModal';
import {
  acceptFriendRequest,
  checkFriendRequest,
  rejectFriendRequest
} from '../../services/routes/UserFriendRequest';
import './MainProfileFrame.css';

const userRole = role => {
  return (
    <span className="bg-brand-dark text-white px-8 py-2 font-roboto font-normal rounded-sm shadow-xl">
      {role?.charAt(0).toUpperCase() + role?.slice(1)}
    </span>
  );
};

function MainProfileFrame() {
  const userProfile = useSelector(state => state.visitProfile.userProfile);

  const ProfileStatus = ({ title, SocialSvg }) => {
    return (
      <a
        target="_blank"
        href={`${userProfile.contact?.socials?.title}`}
        rel="noreferrer"
        className={`cursor-pointer ${
          title === 'youtube' && 'bg-white rounded-full'
        }`}
      >
        <SocialSvg className="w-6 h-6 primary-svg dark-grey-svg" />
      </a>
    );
  };

  const [showDirectMessageModal, setShowDirectMessageModal] = useState(false);

  const [friendRequest, setFriendRequest] = useState({});
  const [isRequestReceived, setIsRequestReceived] = useState(false);

  useEffect(() => {
    checkRequestReceived();
  });

  const checkRequestReceived = () => {
    if (userProfile?.id === friendRequest?.userId) {
      if (friendRequest?.status === 'PENDING') {
        setIsRequestReceived(true);
      }
    }
  };

  const handleDeclineButton = async () => {
    try {
      await rejectFriendRequest({ id: friendRequest?.id }).then(async () => {
        await checkUserFriendRequest();
        checkRequestReceived();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptButton = async () => {
    try {
      await acceptFriendRequest({ id: friendRequest?.id }).then(async () => {
        await checkUserFriendRequest();
        checkRequestReceived();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserFriendRequest = async () => {
    try {
      await checkFriendRequest({ id: userProfile?.id }).then(data => {
        setFriendRequest(data?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="MainProfile-wrapper ">
        <div
          className={classNames({
            MainProfile: true
          })}
        >
          {showDirectMessageModal && (
            <SendMessageModal
              onCloseClick={() => setShowDirectMessageModal(false)}
            />
          )}

          <div className="absolute mt-4 ml-3">{userRole(userProfile.role)}</div>

          <div className="relative ">
            <ProfileUserCard
              setShowDirectMessageModal={setShowDirectMessageModal}
              setFriendRequest={setFriendRequest}
              isRequestReceived={isRequestReceived}
            />
          </div>
          <div className="h-36 w-full MainProfile-background-area MainProfile-background-pic "></div>

          <div className="flex flex-col">
            <div className="h-10 w-full flex justify-between rounded-b-lg items-center bg-brand-dark  px-2  ">
              <div className="w-full min-h-full flex items-center text-xl text-white font-medium gap-3 pl-4 whitespace-pre">
                {userProfile?.contact?.first_name +
                  ' ' +
                  userProfile?.contact?.last_name}
                <span className="font-light text-base">
                  {userProfile?.contact?.country.charAt(0).toUpperCase() +
                    userProfile?.contact?.country.slice(1) +
                    ', ' +
                    userProfile?.contact?.city}
                </span>
              </div>

              {/* Show when user has linked thier other socials account into his/her profile */}
              {userProfile?.contact?.socials && (
                <div className="flex gap-4 text-white">
                  {userProfile.contact?.socials &&
                    userProfile.contact?.socials?.instagram !== '' && (
                      <ProfileStatus
                        title="instagram"
                        SocialSvg={InstagramSvg}
                      />
                    )}

                  {userProfile.contact?.socials &&
                    userProfile.contact?.socials?.facebook !== '' && (
                      <ProfileStatus title="facebook" SocialSvg={FacebookSvg} />
                    )}

                  {userProfile.contact?.socials &&
                    userProfile.contact?.socials?.linkedin !== '' && (
                      <ProfileStatus title="linkedin" SocialSvg={LinkedinSvg} />
                    )}

                  {userProfile.contact?.socials &&
                    userProfile.contact?.socials?.twitter !== '' && (
                      <ProfileStatus title="twitter" SocialSvg={TwitterSvg} />
                    )}

                  {userProfile.contact?.socials &&
                    userProfile.contact?.socials?.youtube !== '' && (
                      <ProfileStatus title="youtube" SocialSvg={YoutubeSvg} />
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Requeust Recieved */}
      {userProfile?.id === friendRequest?.userId && (
        <>
          {friendRequest?.status === 'PENDING' && (
            <div
              className="max-w-full flex justify-between mt-4 md:px-8 items-center "
              style={{ marginBottom: '-8px' }}
            >
              <p className="font-semibold md:text-base text-sm  ">
                {`${
                  userProfile?.contact?.first_name +
                  ' ' +
                  userProfile?.contact?.last_name
                } sent you a friend request`}
              </p>
              <div className="flex items-center gap-3">
                <DeclineButton
                  title="Decline"
                  width={'4rem'}
                  onClick={handleDeclineButton}
                />
                <AcceptButton
                  width={'4rem'}
                  title="Accept"
                  onClick={handleAcceptButton}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MainProfileFrame;
