import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import AcceptButton from "../../Buttons/AcceptButton";
import { useQueryClient, useMutation } from "react-query";
import { addPlayerAgentHistory } from "../../../services/routes/PlayerHistories/playerAgentHistories";

const AddPlayerAgentHistory = ({ onCloseClick, refetch }) => {
  const { register, handleSubmit, errors } = useForm();
  const queryClient = useQueryClient();
  const [value, setValue] = useState("us");
  const [agentPhone, setAgentPhone] = useState("");

  const { mutate } = useMutation(addPlayerAgentHistory, {
    onSuccess: () => {
      // queryClient.invalidateQueries("playerAgent");
      refetch();
      onCloseClick();
    }
  });

  const onSubmit = async data => {
    const formData = new FormData();

    formData.append("agentName", data.agentName);
    formData.append("agencyName", data.agencyName);
    formData.append("agentPhone", agentPhone);
    formData.append("agentEmail", data.agentEmail);
    mutate(formData);
  };

  return (
    <Modal onCloseClick={onCloseClick} title={"Agent-Hisotry"}>
      <div className="bg-white rounded-br-lg rounded-bl-lg p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full  items-center gap-6 grid grid-cols-2 mb-8">
            <div className=" w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Agent Name
              </label>
              <input
                type="text"
                name="agentName"
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                ref={register({
                  type: "agentName",
                  reguired: "Specify Agent Name"
                })}
              />
              {errors.agent_name && <p>{errors.agent_name.message}</p>}
            </div>
            <div className=" w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">Email</label>
              <input
                type="text"
                name="agentEmail"
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                ref={register({
                  type: "agentEmail",
                  reguired: "specify"
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>
          <div className="w-full  items-center gap-6 grid grid-cols-2">
            <div className=" w-full flex flex-col">
              <label className=" text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <PhoneInput
                className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-2 text-black bg-transparent"
                country={value}
                value={agentPhone}
                onChange={phone => {
                  setAgentPhone(phone);
                  setValue("agentPhone", phone);
                }}
                name="agentPhone"
                inputStyle={{
                  background: "transparent",
                  border: "none",
                  fontWeight: "400",
                  fontSize: "17px"
                }}
                buttonStyle={{
                  background: "transparent",
                  border: "none"
                }}
                dropdownStyle={{ background: "white" }}
              />
              {/* {errors.phone && <p>{errors.phone.message}</p>} */}
            </div>

            <div className="w-full flex flex-col">
              <label className="text-sm font-medium text-gray-700">
                Company Name
              </label>
              <input
                type="text"
                name="agencyName"
                className="w-full text-left  text-md py-0.5 px-3 border border-gray-200 bg-white text-gray-700 shadow-sm"
                ref={register({
                  type: "agencyName",
                  reguired: "Specify Agency Name"
                })}
              />
              {errors.agency_name && <p>{errors.agency_name.message}</p>}
            </div>
          </div>
          <div className="w-full flex justify-start mt-5">
            <button type="submit" className="w-28">
              <AcceptButton title="Save" />
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddPlayerAgentHistory;
