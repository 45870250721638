import React from 'react';
import { useSelector } from 'react-redux';
import UserHistoryTable from '../UserHistoryTable/UserHistoryTable';
import PersonRequestCard from '../PersonRequestCard/PersonRequestCard';
import Sidebar from '../Profile/Sidebar';
import ProfileBio from '../Profile/ProfileBio';
import PlayerPosition from '../../pages/Player/PlayerProfile/PlayerPosition/PlayerPosition';
import TabSwitcher from '../Profile/TabSwitcher';
import CoachClub from '../../pages/Coach/CoachProfile/CoachClub/CoachClub';
import Rooster from '../../pages/Coach/ManagedPlayer';
import MyList from '../MyList/MyList';
import AgentClubHistory from '../AgentClubHistory/AgentClubHistory';
import ProfileAwards from '../../pages/Player/PlayerProfile/PlayerAwards/PlayerAwards';

import {
  getAllManagedPlayers,
  getMyAllPlayerList
} from '../../services/routes/User';
import { useQuery } from 'react-query';
import { getallCoachClubHistory } from '../../services/routes/CoachHistories/coachClubistories';
import { getCoachAgentHistory } from '../../services/routes/CoachHistories/coachAgentHistories';

const VisitCoachProfile = () => {
  const user = useSelector(state => state.visitProfile.userProfile);
  let data = user;

  const [activeTab, setActiveTab] = React.useState('information');

  // getting managed players/roaster
  const { data: managedPlayersData } = useQuery(
    'fetch_managedPlayers',
    getAllManagedPlayers
  );

  // Getting myPlayers List
  const { data: myPlayerList } = useQuery(
    'fetch_myPlayerList',
    getMyAllPlayerList
  );

  //get club history
  const { data: ClubHistory, refetch: refetchClubHistory } = useQuery(
    'Club-history',
    getallCoachClubHistory
  );

  //get agent history
  const { data: agentHistory, refetch: refetchAgent } = useQuery(
    'Agent-history',
    getCoachAgentHistory
  );

  return (
    <div className="flex w-full mt-8 gap-8">
      <div className="w-96">
        <Sidebar>
          <ProfileBio />
          <div className="border-b-2 border-brand-dark mx-8 my-6 border-opacity-100"></div>
          <PlayerPosition />
        </Sidebar>
      </div>

      <div className="flex flex-col flex-1">
        {/* Coach Info and Club */}
        <TabSwitcher
          tabs={[
            {
              title: 'Information',
              name: 'information',
              component: <div>Information</div>
            },
            {
              title: 'Rooster',
              name: 'rooster',
              component: <div>Agent</div>
            },
            {
              title: 'My List',
              name: 'my-list',
              component: <div>Coach</div>
            },
            {
              title: 'Agent',
              name: 'agent',
              component: <div>Award</div>
            },
            {
              title: 'Awards',
              name: 'awards',
              component: <div>Award</div>
            },
            {
              title: 'History',
              name: 'history',
              component: <div>Award</div>
            }
          ]}
          activeTab={activeTab}
          setActiveTab={name => {
            setActiveTab(name);
          }}
        />

        {activeTab === 'information' && <CoachClub infoData={user} />}

        {activeTab === 'rooster' && (
          <div>
            <Rooster
              title="Roster"
              type="players"
              data={managedPlayersData?.data}
            />
          </div>
        )}

        {
          // My List
          activeTab === 'my-list' && (
            <div>
              <MyList myPlayerList={myPlayerList?.data} />
            </div>
          )
        }
        {
          // My List
          activeTab === 'agent' && (
            <div>
              <PersonRequestCard
                personType="Agent"
                type="agent"
                role="coach"
                data={data}
              />
            </div>
          )
        }

        {
          // Awards
          activeTab === 'awards' && (
            <div>
              <ProfileAwards awards={user?.awards} />
            </div>
          )
        }
        {activeTab === 'history' && (
          <div className="flex flex-col">
            <div>
              <UserHistoryTable
                role="coach"
                title="Club History"
                isEditable={true}
                histories={ClubHistory?.data}
              />
            </div>

            <div className="mt-5">
              <AgentClubHistory role="Agent" histories={agentHistory?.data} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VisitCoachProfile;
