import React from 'react';
import DefaultProfilePic from '../../img/misc/Default_pfp.png';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import profileImageUrl from '../../config/config';
import Username from '../../components/Username/Username';
import {
  acceptRequestNotification,
  declineRequestNotification,
  getRequestNotification
} from '../../services/routes/Notification';
import { toast } from 'react-toastify';

const UserRoleRequest = ({
  profilePicture,
  user,
  status,
  playerId,
  role,
  setRoleRequestsData,
  id
}) => {
  //

  const formattedUser = {
    contact: {
      first_name: user?.first_name,
      last_name: user?.last_name
    },
    id: playerId
  };

  const handleAcceptRoleRequest = async () => {
    try {
      acceptRequestNotification(id).then(async () => {
        toast.success('Role request accepted');
        const { data } = await getRequestNotification();
        setRoleRequestsData(data);
      });
    } catch (error) {}
  };

  const handleCancelRoleRequest = async () => {
    try {
      // TODO: cancel request from server
      await declineRequestNotification(id).then(async () => {
        toast.success('Role request cancelled');
        const { data } = await getRequestNotification();
        setRoleRequestsData(data);
      });
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-between md:w-60 w-52">
      {status === 'pending' && (
        <>
          <img
            src={
              (profilePicture && profileImageUrl + profilePicture) ||
              DefaultProfilePic
            }
            className="w-14 h-14  object-cover rounded-full"
            alt=""
          />
          <div className="relative flex flex-col text-gray-600">
            <Username user={formattedUser} />
            <p className="text-sm text-gray-500 font-normal">
              {role?.charAt(0).toUpperCase() + role?.slice(1) || 'No role'}
            </p>
          </div>

          <div className="flex flex-col gap-2 items-center">
            <button onClick={handleAcceptRoleRequest} className="w-6 h-6">
              <PersonAddIcon className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 text-green-700" />
            </button>
            <button onClick={handleCancelRoleRequest} className="w-6 h-6">
              <PersonRemoveIcon className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 text-red-700" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UserRoleRequest;
