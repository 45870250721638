import React, { useContext } from 'react';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { ReactComponent as ReportImg } from '../../img/misc/report.svg';
import UserContext from '../../components/PlayerProfile/UserContext';

const UserProfileOption = ({ onCloseClick }) => {
  const setAddComplainModal = useContext(UserContext);
  return (
    <div>
      <div
        className="absolute left-24 inset-0 flex items-center justify-center z-50 bg-transparent "
        style={{ width: '270px' }}
      >
        <div className="bg-white rounded-lg shadow-lg ">
          <div className="w-full flex flex-col text-left">
            <div
              className="flex items-center font-roboto font-normal cursor-pointer text-base rounded-lg hover:bg-normalGray-light "
              onClick={() => onCloseClick()}
            >
              <div className="p-3 flex items-center gap-1 rounded-lg  ">
                <AddToPhotosOutlinedIcon className="text-gray-400" /> Follow
              </div>
            </div>
            <div
              className="flex items-center font-roboto font-normal  rounded-lg hover:bg-normalGray-light cursor-pointer text-base "
              onClick={() => onCloseClick()}
            >
              <div className="p-3 flex items-center gap-1">
                <BlockOutlinedIcon className="text-gray-400" /> Block this User
              </div>
            </div>
            <div
              className="flex items-center font-roboto font-normal  rounded-lg hover:bg-normalGray-light cursor-pointer text-base "
              onClick={() => onCloseClick()}
            >
              <button onClick={() => setAddComplainModal(true)}>
                <div className="p-3 flex items-center gap-1">
                  <ReportImg /> File a complaint
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileOption;
