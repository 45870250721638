import React, { useState } from "react";
import Loader from "../../../../img/busy.gif";
import { useForm } from "react-hook-form";

function InvitePlayerModal({ onCloseClick, setInvitePlayer, invitePlayer }) {
  //
  //
  const { handleSubmit, register, errors } = useForm();
  const [busy, setBusy] = useState(false);

  const onSubmit = async data => {
    setBusy(true);
    onCloseClick();
  };

  return (
    <div className="w-full  py-5 px-5 bg-white">
      <form
        className="w-full  mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full bg-white">
          <div className="w-full mb-6">
            <label className="text-sm font-medium" htmlFor="">
              Player
            </label>
            <input
              className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
              id="player"
              name="player"
              ref={register({
                type: "player",
                required: "You must specify an Player name"
              })}
            />
            {errors.player && <p>{errors.player.message}</p>}
          </div>
          <div className="w-full mb-6">
            <label className="text-sm font-medium" htmlFor="">
              Player's Club
            </label>
            <input
              className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
              id="player"
              name="player"
              ref={register({
                type: "player"
              })}
            />
            {errors.player && <p>{errors.player.message}</p>}
          </div>
          <div className="w-full mb-4">
            <label className="text-sm font-medium" htmlFor="">
              Player's Email
            </label>
            <input
              className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
              type="text"
              id="email"
              name="email"
              ref={register({
                type: "email",
                required: "You must specify an email"
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div className="w-full mb-4">
            <label className="text-sm font-medium" htmlFor="">
              Player's Mobile Number
            </label>
            <input
              className="w-full text-left  text-md border py-0.5 px-3 text-black bg-white  border-gray-200 rounded-sm"
              type="text"
              id="phone"
              name="phone"
              ref={register({
                type: "phone"
              })}
            />
            {errors.phone && <p>{errors.phone.message}</p>}
          </div>
          <div className="w-full flex justify-between items-center">
            <button
              type="submit"
              className="text-center px-12 focus:outline-none text-lg py-1 text-white bg-brand-light hover:bg-brand-dark theme-button mt-4"
            >
              Send Request
            </button>
            <button
              className="text-center px-6 focus:outline-none text-lg py-1 text-white bg-brand-light hover:bg-brand-dark theme-button mt-4"
              onClick={() => setInvitePlayer(!invitePlayer)}
            >
              Search Player
            </button>
          </div>
          {busy && (
            <div className="flex w-full justify-center items-center">
              <img className="w-8 h-8" src={Loader} alt="" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default InvitePlayerModal;
