import { useState } from 'react';
import logo from '../../../img/logo-gold-white.svg';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as HamburgerSvg } from '../../../img/misc/hamburger.svg';
import LandingSideNav from '../../../components/LandingSideNav';
import './Hero.css';

const Hero = () => {
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowAbout(false);
    }, 2000);
  };

  return (
    <Element name="hero" className="Hero-wrapper px-3 md:px-10">
      <div className="flex justify-between items-center p-1 w-full md:p-4 pt-10 font-acumin">
        <img src={logo} className="w-40 Hero-logo" alt="" />
        <LandingSideNav
          isOpen={isSideNavOpen}
          setSideNavOpen={setSideNavOpen}
          landingMode={true}
        />
        <HamburgerSvg
          onClick={() => {
            setSideNavOpen(true);
          }}
          className="primary-svg w-8 h-8 cursor-pointer Hero-hamburger"
        />
        <div
          className="flex text-white  items-center font-bold Hero-navarea"
          style={{}}
        >
          <RouterLink
            to="contact"
            smooth="true"
            offset={0}
            duration={500}
            className="ml-10 cursor-pointer hover:text-brand"
            style={{ fontFamily: 'Poppins,sans-serif' }}
          >
            CONTACT
          </RouterLink>
          <>
            <a
              activeClass="text-brand"
              smooth="true"
              offset={0}
              duration={500}
              onMouseOver={() => setShowAbout(true)}
              onMouseLeave={() => handleMouseLeave()}
              className="ml-10 relative cursor-pointer hover:text-brand"
              style={{ fontFamily: 'Poppins,sans-serif' }}
            >
              ABOUT +
            </a>
            {showAbout && (
              <div
                className="absolute  top-16 flex flex-col items-center gap-1"
                style={{ right: '360px' }}
              >
                <RouterLink
                  to="/"
                  smooth="true"
                  offset={0}
                  duration={500}
                  className="cursor-pointer hover:text-brand"
                  style={{ fontFamily: 'Poppins,sans-serif' }}
                >
                  ABOUT
                </RouterLink>
                <RouterLink
                  to="/"
                  smooth="true"
                  offset={0}
                  duration={500}
                  className="cursor-pointer hover:text-brand"
                  style={{ fontFamily: 'Poppins,sans-serif' }}
                >
                  WHAT WE DO
                </RouterLink>
              </div>
            )}
          </>
          <RouterLink
            to="/register"
            smooth="true"
            offset={0}
            duration={500}
            className="ml-10 cursor-pointer hover:text-brand"
            style={{ fontFamily: 'Poppins,sans-serif' }}
          >
            REGISTER
          </RouterLink>

          <RouterLink
            to="/login"
            className="ml-10 cursor-pointer hover:text-brand"
          >
            LOGIN
          </RouterLink>
          <Link
            activeClass="text-brand"
            smooth="true"
            className="ml-10  cursor-pointer text-brand"
            style={{ fontFamily: 'Poppins,sans-serif' }}
          >
            HOME
          </Link>
        </div>
      </div>
      <div className="text-white mt-14  md:mt-36 font-roboto font-light">
        <p
          className="sm:text-sm Hero-title-text"
          style={{
            fontSize: '2.35rem',
            letterSpacing: '0.04em',
            lineHeight: '1.2'
          }}
        >
          You focus on playing <br /> the beautiful game
        </p>
        <p
          className="mt-10 leading-normal Hero-sub-text"
          style={{ fontSize: '2.35rem', letterSpacing: '0.04em' }}
        >
          We focus on creating <br />
          history and marketing to the world
        </p>
      </div>
    </Element>
  );
};

export default Hero;
