import AxiosService from "../AxiosService";

export const addAwards = async data => {
  const response = await AxiosService.post("/user/awards", data);
  return response.data;
};

export const editAwards = async ({ id, data }) => {
  const response = await AxiosService.put(`/user/awards/${id}`, data);
  return response.data;
};

export const deleteAwards = async ({ id }) => {
  const response = await AxiosService.delete(`/user/awards/${id}`);
  return response.data;
};
