import React from 'react';
import DeclineButton from '../Buttons/DeclineButton';
import AcceptButton from '../Buttons/AcceptButton';
import Modal from '../Modal/Modal';
import { createMyList, searchPlayer } from '../../services/routes/User';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { useState } from 'react';
import { toast } from 'react-toastify';

const AddMyList = ({ onCloseClick }) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();

  const [selectedUsers, setSelectedUsers] = useState(null);

  const { mutate, isLoading } = useMutation(createMyList, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetch_myPlayerList');
      toast.success('You have successfully created a new player list');
      onCloseClick();
    },
    onError: err => {
      toast.error('There was an error creating a new player list');
      console.log('error', err);
    }
  });

  const getSearchUsers = async (inputValue = '', callback) => {
    const filteredUsers = await searchPlayer(inputValue);
    console.log(filteredUsers.data);

    const options = filteredUsers.data.map(user => ({
      label: user?.contact?.first_name + ' ' + user?.contact?.last_name,
      value: user?.player.id
    }));

    callback(options);
  };

  const handleSelectChange = selectedOptions => {
    setSelectedUsers(selectedOptions);
  };

  const onSubmit = data => {
    const tagsData = data.tags.split(',')[0];

    const playerValue = selectedUsers.map(player => player.value);

    const formattedData = {
      name: data.name,
      description: data.description,
      tags: [tagsData],
      region: data.region,
      players: playerValue
    };

    mutate(formattedData);
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Create New Player List">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-5 py-4 bg-white flex-row rounded-b-md">
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">
              List Name
            </label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              type="text"
              name="name"
              ref={register({
                type: 'name',
                required: 'You Must enter the List Name'
              })}
            />
          </div>
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">
              Description
            </label>
            <textarea
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              name="description"
              rows="3"
              cols="20"
              ref={register({
                type: 'description'
              })}
            />
          </div>
          <div className="w-full mb-6">
            <label className="text-sm font-medium text-gray-500">Tags</label>
            <input
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm shadow-sm"
              type="text"
              name="tags"
              ref={register({
                type: 'tags',
                required: 'You Must enter Tags'
              })}
            />
            <div className="w-full mt-6">
              <label className="text-base font-normal text-black px-1 5">
                Total Players
              </label>
              <AsyncSelect
                isMulti
                isSearchable={true}
                onChange={handleSelectChange}
                value={selectedUsers}
                loadOptions={getSearchUsers}
                name="usersOptions"
                className="shadow-sm rounded-md"
                classNamePrefix="select"
              />
            </div>

            {/*  */}
          </div>
          <div className="w-full mb-3">
            <label className="text-base font-normal text-black px-1 5">
              Region
            </label>
            <input
              className="w-full text-left mt-1 text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto shadow-sm"
              type="text"
              name="region"
              placeholder=""
              ref={register({
                type: 'region',
                required: 'You must enter region'
              })}
            />
          </div>

          <div className="w-full flex items-center gap-4 my-6">
            <AcceptButton
              title={isLoading ? 'Creating...' : 'Create'}
              type="submit"
            />
            <DeclineButton title="Cancel" onClick={() => onCloseClick()} />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddMyList;
