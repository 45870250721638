import { useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import {
  checkAvailableUsername,
  register as registerUser
} from '../../../services/routes/auth';
import facebookSvg from '../../../img/misc/facebook_no_circle.svg';
import googleSvg from '../../../img/misc/g_google.svg';
import { useMutation } from 'react-query';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import linkedInPng from '../../../img/social/linked-in.png';
import './Form1.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const SocialAuth = ({ socialSvg, loginTitle }) => {
  return (
    <div className="flex items-center gap-3 w-72 border border-red-400 py-2 px-6 rounded-md">
      <img src={socialSvg} className="w-6 h-6 object-contain" alt="" />
      <span
        className="whitespace-pre text-lg"
        style={{ fontFamily: 'poppins, sans-serif' }}
      >
        Sign up with {loginTitle}
      </span>
    </div>
  );
};

const Form1 = ({ moveProgressForward }) => {
  //
  const [username, setUsername] = useState('');
  const [isAvailable, setAvailablity] = useState(false);
  const { register, errors, handleSubmit, watch, getValues, reset, setValue } =
    useForm();

  const user = useSelector(state => state.user?.user);

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('registerData'));
    setUsername(saved?.username);
    user &&
      reset({
        email: user?.contact?.email,
        username: user?.contact?.username,
        role: saved?.role
      });
    setValue('role', saved?.role);
  }, [user]);

  const password = useRef({});
  password.current = watch('password', '');

  const { mutate, isLoading } = useMutation(registerUser, {
    onSuccess: data => {
      moveProgressForward();
    },
    onError: error => {
      toast.error(error.response.data.message);
    }
  });

  const onSubmit = () => {
    mutate({
      ...getValues(),
      role: JSON.parse(localStorage.getItem('registerData'))?.role
    });
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      checkAvailablity();
    }, 2000);

    // Unmount
    return () => clearTimeout(delay);
  }, [username]);

  const checkAvailablity = () => {
    if (username?.length > 0) {
      checkAvailableUsername(username)
        .then(data => {
          setAvailablity(data.data.data.isAvailable);
        })
        .catch(error => {
          setAvailablity(false);
          setAvailablity(false);
        });
    } else {
      setAvailablity(false);
    }
  };

  return (
    <>
      <form
        className="mb-4 p-2 md:flex md:flex-wrap md:justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Email Address */}
        <div className="w-full mb-6">
          <label className="text-base font-light font-roboto" htmlFor="">
            E-mail Address
          </label>
          <input
            className="block w-full text-left text-md border-normalGray-light  border py-2 md:py-0.5 px-3 text-black bg-transparent"
            disabled={user?.contact?.email}
            style={{ borderRadius: '4px' }}
            type="email"
            name="email"
            id="email"
            ref={register({
              required: 'You must specify an email',
              type: 'email'
            })}
          />
          {errors.email && (
            <p className="input-errors ">{errors.email.message}</p>
          )}
        </div>
        {/* Username */}
        <div className="w-full relative mb-6">
          <label className="text-base font-light font-roboto" htmlFor="">
            Username
          </label>
          <div className="relative">
            <input
              disabled={user?.contact?.email}
              value={username}
              style={{ borderRadius: '4px' }}
              onChange={e => setUsername(e.target.value.trim())}
              className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent "
              type="text"
              name="username"
              id="username"
              ref={register({
                required: 'You must use an available username'
              })}
            />
            {isAvailable ? (
              <DoneAllIcon color="success" className="absolute right-5 top-2" />
            ) : (
              <CloseIcon
                sx={{ color: 'red' }}
                className="absolute right-5 top-2"
              />
            )}
            {errors.username && (
              <p className="input-errors">{errors.username.message}</p>
            )}
          </div>
        </div>
        {/* Password  */}
        <div className="w-full mb-6">
          <label className="text-base font-light font-roboto" htmlFor="">
            Password
          </label>
          <input
            disabled={user?.contact?.email}
            className="block w-full text-left text-md  border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
            style={{ borderRadius: '4px' }}
            name="password"
            type="password"
            ref={register({
              required: 'You must specify a password',
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters'
              }
            })}
          />
          {errors.password && (
            <p className="input-errors">{errors.password.message}</p>
          )}
        </div>
        {/* Confirm-password */}
        <div className="w-full mb-6">
          <label className="text-base font-light font-roboto" htmlFor="">
            Confirm Password
          </label>
          <input
            disabled={user?.contact?.email}
            className="block w-full text-left text-md border-normalGray-light rounded-sm border py-2 md:py-0.5 px-3 text-black bg-transparent"
            style={{ borderRadius: '4px' }}
            name="confirm_password"
            type="password"
            ref={register({
              required: 'You must specify a password',
              minLength: {
                value: 8,
                message: 'Confirm Password must match password'
              },
              validate: value =>
                value === password.current || 'The passwords do not match'
            })}
          />
          {errors.confirm_password && (
            <p className="input-errors">{errors.confirm_password.message}</p>
          )}
        </div>
        {/* Submit */}
        {user?.contact?.email ? (
          <div className="w-full flex justify-center text-center">
            <button className="block">
              <AcceptButton
                title="Next"
                onClick={() => moveProgressForward()}
              />
            </button>
          </div>
        ) : (
          <div className="w-full flex justify-center text-center">
            <button className="block  " type="submit" disabled={isLoading}>
              <AcceptButton title="Sign Up" customCss={true} />
            </button>
          </div>
        )}
      </form>

      {/*  */}
      <div className="flex w-full h-10">
        <hr className="flex items-center line flex-1 " />

        <div className="mx-4 flex items-center login-with">
          <p className="text-sm">Or Sign up with</p>
        </div>
        <hr className="flex items-center line flex-1 " />
      </div>

      {/* Google */}
      <div className="w-full flex flex-col justify-center items-center text-center mt-2 gap-4 ">
        <SocialAuth socialSvg={googleSvg} loginTitle="Google" />

        {/* Facebook */}
        <SocialAuth socialSvg={facebookSvg} loginTitle="Facebook" />

        {/*  Linked In*/}
        <SocialAuth socialSvg={linkedInPng} loginTitle="Linked In" />
      </div>
    </>
  );
};

export default Form1;
