import { useSortBy, useTable } from 'react-table';
import { ReactComponent as CirclePlus } from '../../img/misc/circle-plus.svg';
import { ReactComponent as Delete } from '../../img/misc/delete.svg';
import { ReactComponent as EditSvg } from '../../img/misc/edit.svg';
import { ReactComponent as ViewSvg } from '../../img/misc/Icon ionic-ios-eye.svg';
import Tags from '../Tags';
import React from 'react';

function Table({ columns, data, onRowClick }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table
        {...getTableProps()}
        className="w-full text-left border-collapse border table"
        style={{
          width: '500px',
          borderCollapse: 'collapse',
          overflow: 'scroll',
          display: 'block',
          whiteSpace: 'nowrap'
        }}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              className="text-sm"
              {...headerGroup.getHeaderGroupProps()}
              key={index}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps()}
                  className="text-base font-bold"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map(cell => {
                  return (
                    <td className="text-sm" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const HeaderDiv = (name, align, className) => {
  return (
    <div
      style={{
        textAlign: align
      }}
      className={className}
    >
      {name}
    </div>
  );
};

const HeaderCell = (value, align, className) => {
  return (
    <div style={{ textAlign: align }} className={className}>
      {value}
    </div>
  );
};

const DeleteCell = (id, align, setMyList) => {
  return (
    <div style={{ textAlign: align }}>
      <Delete
        onClick={e => {
          setMyList(id);
        }}
        className="primary-svg w-8 h-5"
      />
    </div>
  );
};

const EditCell = (id, data, align, setEditData) => {
  return (
    <div style={{ textAlign: align }}>
      <EditSvg
        onClick={e => {
          setEditData(data);
        }}
        className="primary-svg w-8 h-5"
      />
    </div>
  );
};

const ViewCell = (id, data, align, setViewData) => {
  return (
    <div style={{ textAlign: align }}>
      <ViewSvg
        onClick={e => {
          setViewData(data);
        }}
        className="primary-svg w-8 h-5"
      />
    </div>
  );
};

const MyList = ({
  setCreateListModalOpen,
  isEditable = true,
  myPlayerList,
  editMyList,
  deleteMyList,
  viewMyList
}) => {
  const columns = React.useMemo(() => [
    {
      accessor: 'name',
      Header: row => HeaderDiv('List Name', 'left', 'whitespace-pre'),
      Cell: row => HeaderCell(row.value, 'left', 'whitespace-pre')
    },
    {
      accessor: 'tags',
      Header: row => HeaderDiv('Tags', 'left', 'whitespace-pre'),
      Cell: row => <Tags tags={row.value} />
    },
    {
      accessor: 'players',
      Header: row => HeaderDiv('Total Players', 'left', 'whitespace-pre'),
      Cell: row => HeaderCell(row.value.length || 0, 'left')
    },
    {
      accessor: 'coaches',
      Header: row => HeaderDiv('Total Coaches', 'left', 'whitespace-pre'),
      Cell: row => HeaderCell(row.value?.length || 0, 'left')
    },
    {
      id: 'view',
      accessor: 'id',
      Header: row => HeaderDiv('', 'center', 'whitespace-pre'),
      Cell: row => (
        <div className="flex gap-1">
          {ViewCell(row.value, row.row.values, 'right', viewMyList)}
          {isEditable === true &&
            EditCell(row.value, row.row.values, 'right', editMyList)}
          {isEditable === true && DeleteCell(row.value, 'right', deleteMyList)}
        </div>
      )
    }
  ]);

  return (
    <div className="PlayerList-MainTable mt-5">
      {myPlayerList?.length > 0 && (
        <div className=" overflow-scroll w-full md:overflow-hidden">
          <Table columns={columns} data={myPlayerList} />
        </div>
      )}

      {isEditable === true && (
        <div className="flex flex-col justify-center items-center w-full mt-10">
          <CirclePlus
            onClick={() => setCreateListModalOpen(true)}
            className=" primary-svg"
          />
          <p className="text-xl mt-5 text-black">Add List</p>
        </div>
      )}
    </div>
  );
};

export default MyList;
