import Loader from "../../img/busy.gif";

function SmallLoader() {
  return (
    <div className="flex h-full justify-center items-center w-70rem">
      <img src={Loader} alt="" className="w-28 h-28 object-contain" />
    </div>
  );
}

export default SmallLoader;
