import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from 'rc-checkbox';
import { checks } from './checks';
import EditAboutModal from '../../../pages/Player/PlayerProfile/EditAboutModal/EditAboutModal';
import { ReactComponent as Edit } from '../../../img/misc/edit.svg';
import './profileBio.scss';

const ProfileBio = ({ name, isEditable = false }) => {
  const user = useSelector(state => state.user);

  const [editAboutModalOpen, setEditAboutModal] = useState(false);
  let [profileCheckCount, setProfileCheckCount] = useState(0);

  const [profileChecked, setProfileChecked] = useState({
    profilePicture: user?.user?.contact?.profile_image?.path,
    bio: user?.user?.contact?.bio?.length > 0,
    playerHistory: user?.user?.player?.player_history,
    roster: user?.user?.coach?.players,
    clubEquipment: user?.user?.club?.clubEquipment,
    managedPlayer: user?.user?.agent?.players,
    socialMedia: false,
    ...name
  });

  useEffect(() => {
    const newData = { ...profileChecked };
    const socials = user.user.contact.socials;
    if (
      socials?.facebook.length ||
      socials?.instagram.length ||
      socials?.linkedin.length ||
      socials?.twitter.length ||
      socials?.youtube.length
    ) {
      newData.socialMedia = true;
    }
    setProfileChecked(newData);
    let count = 0;
    for (let value of Object.values(newData)) {
      count += value ? 1 : 0;
    }
    setProfileCheckCount(count);
  }, [user]);

  return (
    <>
      {/* Bio Edit */}
      {editAboutModalOpen && (
        <EditAboutModal
          aboutPlayer={user.user.contact.bio}
          onCloseClick={() => setEditAboutModal(false)}
        />
      )}
      {/* Checks */}
      <div className="profile_bio_container flex flex-col md:flex-row justify-between">
        {/* {profileCheckCount < 4 && (
          <div className="profile_completion w-1/3">
            <div className="h-9 w-full rounded-t-lg text-base bg-brand-dark flex items-center px-4 font-medium text-white  ">
              <p>Profile Completion ({profileCheckCount}/4)</p>
            </div>
            <div className="w-full h-60 bg-white rounded-b-lg flex flex-col gap-4 p-4 PlayerProfile-completion">
              {checks[user?.user?.role].map((item, index) => {
                return (
                  <div
                    className="flex justify-between items-center"
                    key={index}
                  >
                    <label className="text-sm">{item.label}</label>
                    <CheckBox
                      type="checkbox"
                      checked={profileChecked[item.name]}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )} */}
        {/* bio */}
        <div className="bio rounded-lg font-roboto">
          <div className="flex justify-between items-center px-4 rounded-t-md">
            <div className="h-9 flex items-center font-semibold text-brand-dark text-xl">
              Bio
            </div>
            {isEditable && (
              <Edit
                onClick={() => setEditAboutModal(true)}
                className="w-[21px] h-[21px] cursor-pointer"
                style={{ color: '#ffffff' }}
              />
            )}
          </div>

          <p className="w-full p-4 break-words text-gray-700 text-base ">
            {user.user.contact.bio}
          </p>
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row justify-between mt-10">
        <div className="md:mt-0  w-full bg-white rounded-lg font-roboto PlayerProfile-bio">
          <div className="flex justify-between bg-brand-dark px-4 py-1 rounded-t-md">
            <div className="text-center font-bold text-xl">Bio</div>
            <div className="flex gap-4 items-center font-light text-lg">
              Edit
              <Edit
                onClick={() => setEditAboutModal(true)}
                width="21px"
                height="21px"
                className="cursor-pointer brand-dark-svg"
                style={{ color: '#ffffff' }}
              />
            </div>
          </div>

          <p
            className="w-full mt-2 px-4 py-1 text-gray-700"
            style={{ fontSize: '16px' }}
          >
            {user.user.contact.bio}
          </p>
        </div>
      </div> */}
    </>
  );
};

export default ProfileBio;
