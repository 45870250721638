import { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import AcceptButton from '../../components/Buttons/AcceptButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMutation } from 'react-query';
import { sendMessageToUser } from '../../services/routes/chats';
import { useSelector } from 'react-redux';

function SendMessageModal({ onCloseClick }) {
  //
  //
  const [messageText, setMessageText] = useState('');
  const chatId = useSelector(state => state.userChats.userInfo);

  const history = useHistory();

  const { mutate, isLoading } = useMutation(sendMessageToUser, {
    onSuccess: () => {
      onCloseClick();
      history.push('/message');
      setMessageText('');
    },
    onError: error => {
      console.log('error sending message', error);
    }
  });

  const handleSendMessage = () => {
    const formData = new FormData();
    formData.append('content', messageText);
    mutate({ id: chatId?.id, data: formData });
  };

  return (
    <Modal onCloseClick={onCloseClick} title="Message">
      <div className="w-full  py-5 px-5 bg-white">
        <div className="flex flex-wrap w-full">
          <p
            className="font-light font-roboto"
            style={{ color: '#58595B', fontSize: '16px' }}
          >
            Send a Message
          </p>

          <input
            type="text"
            className="w-full border border-brand-dark rounded-md px-4 text-black text-sm"
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />

          <div className="w-full justify-center mt-8 items-center flex">
            <button className="mx-1 my-2 " onClick={handleSendMessage}>
              <AcceptButton title={isLoading ? 'Sending Message...' : 'Send'} />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SendMessageModal;
