import { useMutation } from 'react-query';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import AcceptButton from '../../../components/Buttons/AcceptButton';
import moment from 'moment';
import { updateClubProfile } from '../../../services/routes/ClubProfile';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../redux/userSlice';
import { toast } from 'react-toastify';

function EditInfoModal({ onCloseClick }) {
  const dispatch = useDispatch();
  const club = useSelector(state => state.auth.user?.club);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      country: club?.country,
      foundation_year: moment(club?.foundation_year).format('YYYY-MM-DD'),
      website: club?.website,
      city: club?.city,
      presidentName: club?.presidentName,
      rankings: club?.rankings
    }
  });

  const { mutate, isLoading } = useMutation(updateClubProfile, {
    onSuccess: () => {
      toast.success('Club info updated successfully');
      dispatch(fetchUser());
      onCloseClick();
    },
    onError: error => {
      toast.error("Couldn't update club info, please try again later!!!");
    }
  });

  const onSubmit = data => {
    const newData = {
      ...data,
      name: 'Club1'
    };
    mutate(newData);
  };

  return (
    <Modal
      title="Edit Player Information"
      onCloseClick={onCloseClick}
      width="450px"
    >
      <form
        className="bg-white px-10 pt-6 rounded-b-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-5 items-center justify-center mb-3">
          <div className="flex w-full  flex-col py-2 pb-2">
            <label className="text-base font-normal text-black">Country</label>
            <input
              type="text"
              name="country"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              placeholder="Enter your Country"
              ref={register({
                type: 'country'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">City</label>
            <input
              type="text"
              name="city"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'city'
              })}
            />
          </div>
        </div>
        <div className="flex gap-5 items-center justify-center">
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Year of foundation
            </label>
            <input
              type="date"
              name="foundation_year"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'foundaion_year'
              })}
            />
          </div>
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              President{' '}
            </label>
            <input
              type="text"
              name="presidentName"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'height'
              })}
            />
          </div>
        </div>
        <div className="flex gap-5 items-center justify-center">
          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">
              Official Website
            </label>
            <input
              type="text"
              name="website"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'website'
              })}
            />
          </div>

          <div className="flex w-full  flex-col py-2">
            <label className="text-base font-normal text-black">Ranking</label>
            <input
              type="text"
              name="rankings"
              className="w-full text-left  text-md py-0.5 px-3 text-black bg-white border border-gray-200 rounded-sm font-roboto"
              ref={register({
                type: 'rankings'
              })}
            />
          </div>
        </div>

        <div className="w-full flex justify-start mt-5">
          <button type="submit" className="mb-5  ">
            <AcceptButton title={isLoading ? 'Saving...' : 'Save'} />
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditInfoModal;
