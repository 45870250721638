import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { ReactComponent as LikeSvg } from '../../../../img/misc/like.svg';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ReactComponent as CommentSvg } from '../../../../img/misc/comment.svg';
import { ReactComponent as ShareSvg } from '../../../../img/misc/share.svg';
import { ReactComponent as DeleteSvg } from '../../../../img/misc/delete.svg';
import BookMarkFilled from '../../../../img/misc/book-mark-filled.png';
import BookMark from '../../../../img/misc/book-mark.png';
import Carousel from '../../../../components/Carousel/Carousel';
import { useSelector } from 'react-redux';
import SharePostOption from './SharePostOption';
import PostOption from './PostOption';
import DefaultPostOption from './DefaultPostOption';
import Username from '../../../../components/Username/Username';
import DefaultProfileImage from '../../../../img/misc/Default_pfp.png';
import moment from 'moment/moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AddLikeByPostId,
  getLikeCountByPostID,
  deletePostLike,
  addCommentByPostID,
  deleteCommentByPostID,
  addToBookmark,
  deleteBookmark,
  getCommentsByPostID
} from '../../../../services/routes/Post';
import profileImageUrl from '../../../../config/config';
import instance from '../../../../services/AxiosService';
import DeleteHistoryModal from '../../../../components/DeleteHistoryModal/DeleteHistoryModal';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import './post.scss';

const Post = ({ post, feedPage, onDeletePostClick, onEditPostClick }) => {
  //fetching user redux
  const queryClient = useQueryClient();
  const user = useSelector(state => state.user.user);

  const [bookedMark, setBookedMark] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [postState, setPostState] = useState(post);

  // Running this useEffect when the post changes
  useEffect(() => {
    setPostState(post);
  }, [post]);

  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [commentCount, setCommentCount] = useState(post?.comments_count);
  const [sharedCount, setsharedCount] = useState(0);
  const [isLiked, setIsLiked] = useState(post?.isLiked);
  const [deleteCommentModal, setDeleteCommentModal] = useState(false);
  const [deleteById, setDeleteById] = useState(null);

  const [ytUrl, setYtUrl] = useState(null); // youtube url

  // check if there is a URL in the post
  useEffect(() => {
    const checkUrl = () => {
      const regex = /(https?:\/\/[^\s]+)/g;
      const found = postState?.body_text?.match(regex);

      if (found) {
        found.forEach(url => {
          setPostState(prevState => ({
            ...prevState,
            body_text: prevState?.body_text?.replace(url, '')
          }));
          setYtUrl(url);
        });
      }
    };
    postState && checkUrl();
  }, [postState]);

  // // check if the post has already bookmarked or not
  useEffect(() => {
    if (postState?.isSaved) {
      const isBookmarked = postState?.isSaved;
      setBookedMark(isBookmarked);
    } else {
      setBookedMark(false);
    }
  }, [postState?.isSaved]);

  //fetching like apis
  const { data: getLikes } = useQuery(`isLiked${postState?.id}`, () =>
    getLikeCountByPostID({ id: postState?.id })
  );

  //Posting like apis
  const { mutate: addLikePost } = useMutation(AddLikeByPostId, {
    onSuccess: () => {
      queryClient.invalidateQueries(`isLiked${postState?.id}`);
      setIsLiked(isLiked => isLiked === true);
    }
  });

  //deleting like apis
  const { mutate: deleteLikePost } = useMutation(deletePostLike, {
    onSuccess: data => {
      queryClient.invalidateQueries(`isLiked${postState?.id}`);
      setIsLiked(isLiked => isLiked === false);
    }
  });

  //fetching comment apis
  const { data: fetchComment } = useQuery(`isCommented${postState?.id}`, () =>
    getCommentsByPostID({ id: postState?.id })
  );

  //Posting comment apis
  const { mutate: addCommentPost, isLoading: postingComment } = useMutation(
    addCommentByPostID,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`isCommented${postState?.id}`);
        setCommentCount(prevCount => prevCount + 1);
      }
    }
  );

  //delete comment apis
  const { mutate: deleteCommentPost, isLoading: loadingCommentPost } =
    useMutation(deleteCommentByPostID, {
      onSuccess: () => {
        queryClient.invalidateQueries(`isCommented${postState?.id}`);
        setCommentCount(prevCount => prevCount - 1);
      }
    });

  const addComment = e => {
    e.preventDefault();
    setComments([...comments, ...comment]);
    setComment('');
    // setCommentCount(prevCount => prevCount + 1);
    addCommentPost({ postId: postState?.id, body_text: comment });
  };

  const onClickLike = () => {
    if (isLiked === false) {
      addLikePost({ id: postState?.id });
      setIsLiked(true);
    } else {
      deleteLikePost({ id: postState?.id });
    }
  };

  const deleteComment = id => {
    setDeleteCommentModal(true);
    setDeleteById(id);
  };

  const addPostToBookmark = async id => {
    try {
      await addToBookmark({ id }).then(() => {
        setBookedMark(true);
        queryClient.invalidateQueries(`fetch_bookmark_post${postState.id}`);
        toast.success('You have bookmarked this post');
      });
    } catch (error) {
      toast.error('Error on bookmark of post');
    }
  };

  const removePostFromBookmark = async id => {
    try {
      await deleteBookmark({ id }).then(() => {
        setBookedMark(false);
        queryClient.invalidateQueries(`fetch_bookmark_post${postState.id}`);
        toast.success('You have removed this post from bookmark');
      });
    } catch (error) {
      toast.error('Error on removing the post from bookmark');
    }
  };

  return (
    <div className="bg-white shadow-sm my-5 border rounded-lg relative">
      {deleteCommentModal === true && (
        <DeleteHistoryModal
          id={deleteById}
          onCloseClick={setDeleteCommentModal}
          mutate={deleteCommentPost}
          loading={loadingCommentPost}
          parameter="comment"
        />
      )}
      {/* Header */}
      <div className=" flex items-center p-4 gap-3 ">
        <img
          src={
            (postState?.author?.contact?.profile_image &&
              instance.defaults.baseURL +
                postState?.author?.contact?.profile_image?.url) ||
            DefaultProfileImage
          }
          alt=""
          className="h-12 w-12 rounded-full object-cover"
        />
        <div className="flex flex-col justify-between w-full">
          <Username user={postState?.author} />
          <div className="flex items-center gap-1">
            <h6 className="text-xs text-gray-400">
              {moment(postState?.createdAt).fromNow()}
            </h6>
            <PublicOutlinedIcon
              className=" text-gray-500 "
              style={{
                width: '1rem'
              }}
            />
          </div>
        </div>

        <Popup
          trigger={
            <MoreHorizOutlinedIcon className="h-5 text-gray-400 cursor-pointer hover:text-black" />
          }
          position={['bottom center', 'top center']}
          closeOnDocumentClick
          keepTooltipInside={false}
          arrow={true}
        >
          <>
            {feedPage ? (
              <DefaultPostOption
                user={postState?.author}
                userId={postState?.author.id}
                id={postState?.id}
                post={postState}
              />
            ) : (
              <PostOption
                id={postState?.id}
                post={postState}
                user={postState?.author}
                onDeletePostClick={onDeletePostClick}
                onEditPostClick={onEditPostClick}
              />
            )}
          </>
        </Popup>
      </div>

      {/* Status */}
      <p
        className="px-3 mb-1 w-full text-gray-800"
        dangerouslySetInnerHTML={{ __html: postState?.body_text }}
      />

      {/* Images */}
      <div className="max-w-full">
        <Carousel postImage={postState?.images}>
          {[
            ...postState?.images?.map(({ url }, index) => (
              <img
                key={index}
                src={profileImageUrl + url}
                alt=""
                className="w-full px-3 pb-3  min-w-full object-cover h-96"
              />
            )),

            ytUrl && <ReactPlayer controls={true} height={300} url={ytUrl} />
          ]}
        </Carousel>
      </div>

      {/* Like, comment and share count */}
      <div className="flex mt-3 px-4 gap-4 items-center text-sm">
        <div className="w-full flex items-center  flex-1">
          <LikeSvg className="w-5 h-5 cursor-pointer mr-1" />

          <span style={{ color: '#707070' }}>
            {getLikes?.data !== 0 && getLikes?.data}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex  items-center">
            <CommentSvg className="w-5 h-5 mx-2 ml-1 cursor-pointer" />
            {commentCount >= 1 && (
              <span className="mr-2" style={{ color: '#707070' }}>
                {fetchComment?.data?.length}
              </span>
            )}
          </div>
          <div className="flex items-center">
            <ShareSvg className="w-5 h-5 cursor-pointer  " />
            {sharedCount >= 1 && (
              <span className="ml-1 " style={{ color: '#707070' }}>
                {sharedCount}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="px-2 my-2">
        <hr className="border-gray-300 w-full" />
      </div>

      {/* Buttons */}
      <div className="flex px-4 justify-between pt-2 ">
        <div className="flex items-center text-base">
          <div
            className={`flex item-center mr-4 ${isLiked ? 'brand-svg' : ''}`}
            onClick={onClickLike}
          >
            <LikeSvg className="w-6 h-6 mr-3 cursor-pointer primary-svg" />
            <span className="hover:text-black  text-gray-400  duration-150 cursor-pointer">
              {isLiked ? 'Liked' : 'Like'}
            </span>
          </div>

          <div
            className={`flex items-center mr-4 ${
              comments.length !== 0 ? 'brand-svg' : ''
            }`}
            onClick={() => {
              setShowComments(!showComments);
            }}
          >
            <CommentSvg className="w-6 h-6 mr-3 cursor-pointer primary-svg" />
            <span className="hover:text-black text-gray-400  duration-150 cursor-pointer">
              Comment
            </span>
          </div>

          <Popup
            trigger={
              <div className="flex item-center relative">
                <ShareSvg
                  className={`w-6 h-6 cursor-pointer primary-svg mr-3 ${
                    sharedCount !== 0 ? 'brand-svg' : ''
                  }`}
                />
                <span className="hover:text-black text-gray-400  duration-150 cursor-pointer">
                  Share
                </span>
              </div>
            }
            position={['bottom center', 'top center']}
            closeOnDocumentClick
            arrow={true}
            keepTooltipInside={false}
          >
            <SharePostOption setsharedCount={setsharedCount} />
          </Popup>
        </div>

        {!bookedMark ? (
          <img
            src={BookMark}
            className="w-6 h-6 object-contain cursor-pointer"
            alt="bookmark"
            onClick={() => addPostToBookmark(postState?.id)}
          />
        ) : (
          <img
            src={BookMarkFilled}
            className="w-6 h-6 object-contain cursor-pointer"
            alt="bookmark-filled"
            onClick={() => removePostFromBookmark(postState?.id)}
          />
        )}
      </div>

      {/* other user's comments */}
      {fetchComment?.data && (
        <div
          className={`my-4 px-3 ${
            fetchComment?.data?.length > 3 &&
            'h-48 overflow-scroll scrollbar-hide '
          }`}
        >
          {fetchComment?.data?.allComments
            ?.sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            })
            ?.map(userComment => (
              <div
                className="flex flex-col my-3  bg-gray-200 border p-2 rounded-sm"
                key={userComment.id}
              >
                <div className="flex items-center justify-between ml-2">
                  <Username
                    user={{ ...userComment?.author, id: userComment?.authorId }}
                  />
                  <span className="font-light text-xs">
                    {moment(userComment.createdAt).fromNow()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <p className="truncate ml-2 text-sm left-content">
                    {userComment.body_text}
                  </p>
                  {user?.id === userComment?.authorId && (
                    <button
                      className="mr-2 primary-svg"
                      onClick={() => deleteComment(userComment.id)}
                    >
                      <DeleteSvg className="primary-svg w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Comment input box */}
      <div className="px-3 mb-3 rounded-sm">
        {showComments && (
          <div className="flex h-12  items-center border bg-gray-200 rounded-lg">
            <form className="w-full flex justify-between">
              <input
                type="text"
                className="bg-transparent px-3 text-base "
                placeholder="Add comment..."
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
              <button
                className="text-brand-light font-semibold pr-2"
                type="submit"
                onClick={addComment}
              >
                {postingComment ? 'Posting...' : 'Post'}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;
