import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import InviteCoachModal from "../../../components/InviteCoachModal/InviteCoachModal";
import AsyncSelect from "react-select/async";
import {
  createRoleRequest,
  searchCoach
} from "../../../services/routes/User";
import "./modal.scss";
import { useMutation } from "react-query";
import { fetchUser } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";

const AddClubCoachModal = ({ onCloseClick }) => {
  //
  //
  const dispatch = useDispatch();
  const [coaches, setCoaches] = useState({});
  const [inviteCoach, setInviteCoach] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);

  useEffect(() => {
    getSearchCoach();
  }, []);

  // Updating Coach to Send the Request
  const { mutate } = useMutation(createRoleRequest, {
    onSuccess: () => {
      dispatch(fetchUser());
    }
  });

  const getSearchCoach = async (inputValue = "", callback) => {
    const filterCoach = await searchCoach(inputValue);
    const options = filterCoach.data.map(coach => ({
      label: coach.contact.first_name + " " + coach.contact.last_name,
      value: coach.id
    }));
    callback(options);
  };

  const handleSelect = selectedCoach => {
    setSelectedCoach(selectedCoach);
  };

  const sendRequest = () => {
    mutate({ toUser: selectedCoach.value });
    onCloseClick();
  };

  return (
    <Modal
      onCloseClick={onCloseClick}
      title={coaches.length > 0 ? "Edit Coach" : "Add Coach"}
    >
      {!inviteCoach ? (
        <div className="w-full  py-5 px-5 bg-white">
          <AsyncSelect
            loadOptions={getSearchCoach}
            value={selectedCoach}
            onChange={handleSelect}
            classNamePrefix="react-select"
            name="coachOptions"
            placeholder="Search for Coach..."
          />
          {selectedCoach ? (
            <div className="w-full flex justify-center mt-4">
              <button
                type="submit"
                className="text-center px-12 focus:outline-none text-lg py-1 text-white bg-brand-light hover:bg-brand-dark theme-button mt-4"
                onClick={sendRequest}
              >
                Send Request
              </button>
            </div>
          ) : (
            <span className="flex items-center font-light w-full text-xl flex-col justify-center mt-6 mb-2">
              Didn't Found a Coach?
              <button
                onClick={() => setInviteCoach(!inviteCoach)}
                className="bg-brand-light hover:bg-brand-dark px-6 py-2  text-white rounded-md mt-2"
              >
                Invite Coach
              </button>
            </span>
          )}
        </div>
      ) : (
        <InviteCoachModal
          onCloseClick={onCloseClick}
          setInviteCoach={setInviteCoach}
          inviteCoach={inviteCoach}
        />
      )}
    </Modal>
  );
};
export default AddClubCoachModal;
