import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { sendMessageToUser } from '../../services/routes/chats';
import { useSelector } from 'react-redux';
import { socket } from '../SocketIo';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Picker from 'emoji-picker-react';
import { ReactComponent as CrossMarkSvg } from '../../img/misc/crossed-mark.svg';
import './userMessage.scss';

const Input = () => {
  const imageRef = useRef();
  const messageRef = useRef(null);

  const chatId = useSelector(state => state.userChats.userInfo);

  const groupChat = useSelector(state => state.userChats.groupChat);
  const [message, setMessage] = useState('');

  const [selectedImage, setSelectedImage] = useState([]);

  const [buildImage, setBuildImage] = useState([]);

  const [showEmoji, setShowEmoji] = useState(false);

  const { mutate, isLoading } = useMutation(sendMessageToUser, {
    onSuccess: () => {},
    onError: () => {}
  });

  const uploadMedia = e => {
    let files = e.target.files;

    const updatedImages = [...selectedImage];

    const updatedBuildImages = [...buildImage];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      let url = URL.createObjectURL(file);
      updatedImages.push(file);
      updatedBuildImages.push({
        url,
        id: Math.random() * 1000
      });
    }

    setSelectedImage(updatedImages);

    setBuildImage(updatedBuildImages);
  };

  const handleTyping = e => {
    let id;

    if (groupChat?.isGroupChat === true) {
      id = groupChat?.id;
    } else {
      id = chatId?.id;
    }

    if (message === '') {
      socket.emit('stopTyping', { chatId: id });
    }

    //if event is keydown and message is empty
    if (e.type === 'keydown' && message === '') {
      socket.emit('stopTyping', { chatId: id });
    }

    //if event is keyup and message is not empty
    if (e.type === 'keyup' && message !== '') {
      socket.emit('startTyping', { chatId: id });
    }

    //if event is keyup and message is empty
    if (e.type === 'keyup' && message === '') {
      socket.emit('stopTyping', { chatId: id });
    }

    //if event is keydown and message is not empty
    if (e.type === 'keydown' && message !== '') {
      socket.emit('startTyping', { chatId: id });
    }

    e.key === 'Enter' && sendMessage();
  };

  const sendMessage = () => {
    try {
      let id;

      if (groupChat?.isGroupChat === true) {
        id = groupChat?.id;
      } else {
        id = chatId?.id;
      }

      const formData = new FormData();

      if (selectedImage) {
        selectedImage.forEach(images => {
          formData.append('attachments', images);
        });
      }

      if (selectedImage.length !== 0 && message === '') {
        formData.append('content', '  ');
      } else {
        formData.append('content', message);
      }

      mutate({ id, data: formData });
    } catch (error) {
      console.log(error);
    }
    setMessage('');
    setSelectedImage([]);
    setBuildImage([]);
  };

  const handleEmojiClick = emojiObject => {
    messageRef.current.focus();
    setMessage(prev => prev + emojiObject.emoji);
    setShowEmoji(false);
  };

  return (
    <div className="relative input flex items-center justify-between ">
      {buildImage.length < 3 ? (
        <div className="absolute flex gap-6 bottom-16">
          {buildImage.map((img, index) => (
            <>
              <div className="relative">
                <img
                  key={index}
                  src={img.url}
                  className=" h-28 w-28  p-1 shadow-lg  rounded-sm "
                  alt=""
                />

                <CrossMarkSvg
                  className="absolute top-0 right-0 p-1 bg-white rounded-full"
                  onClick={() => {
                    setBuildImage(
                      buildImage.filter(image => image.id !== img.id)
                    );
                  }}
                />
              </div>
            </>
          ))}
        </div>
      ) : (
        <div className="absolute grid grid-cols-3 gap-1 bottom-16">
          {buildImage.map((img, index) => (
            <>
              <div className="relative">
                <img
                  key={index}
                  src={img.url}
                  className=" h-16 w-16  p-1 shadow-lg  rounded-sm "
                  alt=""
                />
                <CrossMarkSvg
                  className="absolute top-0 right-0 p-1 bg-white rounded-full"
                  onClick={() => {
                    setBuildImage(
                      buildImage.filter(image => image.id !== img.id)
                    );
                  }}
                />
              </div>
            </>
          ))}
        </div>
      )}

      {showEmoji && (
        <div className="absolute bottom-16 right-4 ">
          <Picker
            emojiStyle="apple"
            width="100%"
            height="350px"
            onEmojiClick={handleEmojiClick}
          />
        </div>
      )}
      <input
        type="text"
        className="text-sm"
        placeholder="Type Something..."
        ref={messageRef}
        onChange={e => setMessage(e.target.value)}
        value={message}
        onKeyDown={handleTyping}
        onKeyUp={handleTyping}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={imageRef}
        onChange={uploadMedia}
      />
      <div className="flex items-center gap-5">
        <AddPhotoAlternateIcon
          className="iconSvg brand-svg hover:scale-110 duration-100 ease-in"
          onClick={() => imageRef.current.click()}
        />
        <EmojiEmotionsIcon
          className="iconSvg  brand-svg hover:scale-110 duration-100 ease-in"
          onClick={() => setShowEmoji(!showEmoji)}
        />
        <SendIcon
          className="iconSvg brand-svg hover:scale-110 duration-100 ease-in"
          onClick={sendMessage}
          disabled={isLoading}
          tabIndex="0"
        />
      </div>
    </div>
  );
};

export default Input;
