import AxiosService from '../AxiosService';

export const getCoachPlayers = async () => {
  const response = await AxiosService.get('/coach/players');
  return response.data;
};

export const addPlayerList = async data => {
  const response = await AxiosService.post('/coach/players/list/', data);
  return response.data;
};

export const getMyPlayersList = async () => {
  const response = await AxiosService.get('/coach/players/list/');
  return response.data;
};

export const updateCoachPlayerList = async ({ id, data }) => {
  const response = await AxiosService.put(`/coach/players/list/${id}/`, data);
  return response.data;
};

export const deleteCoachPlayerList = async ({ id }) => {
  const response = await AxiosService.delete(`/coach/players/list/${id}`);
  return response.data;
};
