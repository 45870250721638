import { createSlice } from '@reduxjs/toolkit';

const customCalendarSlice = createSlice({
  name: 'modal',
  initialState: {
    value: false
  },
  reducers: {
    open: (state, action) => {
      state.value = true;
    },
    close: (state) => {
      state.value = false;
    }
  }
});

export const { open, close } = customCalendarSlice.actions;

export const selectModal = (state) => state.value;

export default customCalendarSlice.reducer;
