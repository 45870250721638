import { useState, useEffect } from 'react';
import SendMessageModal from './SendMessageModal';
import SideNavWrapper from '../SideNavWrapper';
import DeleteContactModal from './DeleteContactModal';
import { ReactComponent as Search } from '../../img/misc/search.svg';
import DefaultProfilePng from '../../img/misc/Default_pfp.png';
import UserContact from './UserContact/UserContact';
import {
  getFriendRequests,
  getMyFriends,
  getSentFriendRequests
} from '../../services/routes/UserFriendRequest';
import SmallLoader from '../../components/Loader/SmallLoader';
import HeadingTitle from '../../components/HeadingTitle/HeadingTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupChats, setUserChat } from '../../redux/userChats/UserChats';
import { accessChatToUser } from '../../services/routes/chats';
import { socket } from '../../components/SocketIo';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeclineButton from '../../components/Buttons/DeclineButton';
import ContactFilter from './ContactFilter';
import IncomingRequests from './IncomingRequests';
import SentRequests from './SentRequests';
import { toast } from 'react-toastify';
import { getPossibleConnections } from '../../services/routes/User';
import SuggestionUser from './SuggestionUser';
import { getRequestNotification } from '../../services/routes/Notification';
import UserRoleRequest from './UserRoleRequest';
import Popup from 'reactjs-popup';
import './Contact.css';
import { useQuery } from 'react-query';

const LoadMoreButton = ({ onClick }) => {
  return (
    <div className="w-full flex justify-center" onClick={onClick}>
      <button className="bg-brand-dark text-white px-6 py-2 rounded-sm font-medium">
        Load More
      </button>
    </div>
  );
};

const Contact = () => {
  const dispatch = useDispatch();
  const me = useSelector(state => state.auth.user);

  const [myFriendsIndex, setMyFriendsIndex] = useState(1);
  const [friendRequestIndex, setFriendRequestIndex] = useState(1);
  const [sentRequestIndex, setSentRequestIndex] = useState(1);
  const [roleRequestIndex, setRoleRequestIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [filteredFriends, setFilteredFriends] = useState([]);
  const [contactOption, setContactOption] = useState('all_friends');

  const [friendRequestsData, setFriendRequestsData] = useState([]);
  const [sentRequestsData, setSentRequestsData] = useState([]);
  const [roleRequestsData, setRoleRequestsData] = useState([]);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  // Contact States
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [contactId, setContactId] = useState(null);

  const {
    refetch: refetchMyFriends,
    isLoading: myFriendsLoading,
    isError
  } = useQuery('myFriends', () => getMyFriends(myFriendsIndex), {
    onSuccess: data => {
      setFilteredFriends(prevData => [...prevData, ...data?.data]);
      data?.data?.length > 0 ? setHasMore(true) : setHasMore(false);
      setMyFriendsIndex(prevIndex => prevIndex + 1);
    },
    onError: error => {
      console.log(error);
      setHasMore(false);
    },
    retry: false
  });

  const { refetch: refetchFriendRequests, isLoading: friendRequestLoading } =
    useQuery('friendRequests', () => getFriendRequests(friendRequestIndex), {
      onSuccess: data => {
        setFriendRequestsData(prevData => [...prevData, ...data?.data]);
        data?.data?.length > 0 ? setHasMore(true) : setHasMore(false);
        setFriendRequestIndex(prevIndex => prevIndex + 1);
      },
      onError: () => {
        toast.error('You have no friend requests yet');
        setHasMore(false);
      },
      enabled: false,
      retry: false
    });

  const { refetch: refetchSentRequests, isLoading: sentRequestLoading } =
    useQuery(
      'sentFriendRequests',
      () => getSentFriendRequests(sentRequestIndex),
      {
        onSuccess: data => {
          setSentRequestsData(prevData => [...prevData, ...data?.data]);
          data?.data?.length > 0 ? setHasMore(true) : setHasMore(false);
          setSentRequestIndex(prevIndex => prevIndex + 1);
        },
        onError: () => {
          toast.error('You have not sent any friend requests yet');
        },
        enabled: false,
        retry: false
      }
    );

  const { refetch: refetchRoleRequests, isLoading: roleRequestLoading } =
    useQuery('roleRequests', () => getRequestNotification(roleRequestIndex), {
      onSuccess: data => {
        setRoleRequestsData(prevData => [...prevData, ...data?.data?.allReq]);
        data?.data?.length > 0 ? setHasMore(true) : setHasMore(false);
        setRoleRequestIndex(prevIndex => prevIndex + 1);
      },
      onError: () => {
        toast.error('You have no role requests yet');
        setHasMore(false);
      },
      enabled: false,
      retry: false
    });

  useEffect(() => {
    if (searchValue !== '') {
      const filteredData = filteredFriends?.data?.filter(
        user =>
          user?.user?.contact?.first_name
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          user?.user?.contact?.last_name
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      );

      setFilteredFriends(filteredData);
    }
  }, [filteredFriends?.data, me?.id, searchValue]);

  const onClickMessageContact = async id => {
    try {
      setShowMessageModal(true);
      dispatch(setGroupChats(null));
      await accessChatToUser(id).then(data => {
        dispatch(setUserChat(data?.data));
        socket.emit('joinChat', { chatId: data?.data?.id });
      });
    } catch (error) {}
  };

  const onClickDeleteContact = id => {
    setContactId(id);
    setShowDeleteContactModal(true);
  };

  const onClickFriendRequests = async () => {
    refetchFriendRequests();

    setContactOption('friend_requests');
  };

  const onClickSentRequests = async () => {
    refetchSentRequests();
    setContactOption('sent_requests');
  };

  const onClickRoleRequest = () => {
    refetchRoleRequests();
    setContactOption('role_requests');
  };

  return (
    <SideNavWrapper width={'100%'} isFooter={true}>
      <div className="PlayerContact">
        {/* Delete and Send-Message Modal */}
        {showDeleteContactModal && (
          <DeleteContactModal
            contactId={contactId}
            fetchMyFriends={refetchMyFriends}
            onCloseClick={() => setShowDeleteContactModal(false)}
          />
        )}
        {showMessageModal && (
          <SendMessageModal onCloseClick={() => setShowMessageModal(false)} />
        )}

        <div className="contact_container flex flex-col my-2">
          <HeadingTitle title="Contacts" />

          <div className="flex min-w-full items-center justify-between my-2 gap-4">
            <div className="search_container flex items-center gap-3 w-full bg-white rounded-md px-3  ">
              <Search className="w-5 h-5 text-brand-light" />
              <input
                type="text"
                className="ml-1 text-sm font-roboto w-full text-black"
                placeholder="Search by username"
                onChange={e => setSearchValue(e.target.value)}
              />
            </div>
            <div>
              <Popup
                trigger={
                  <div>
                    <DeclineButton title={'Filter'} Icon={FilterListIcon} />
                  </div>
                }
                position={['bottom center']}
                closeOnDocumentClick
                closeOnEscape
                keepTooltipInside={false}
              >
                <ContactFilter
                  onClickFriendRequests={onClickFriendRequests}
                  onClickSentRequests={onClickSentRequests}
                  onClickRoleRequest={onClickRoleRequest}
                  setContactOption={setContactOption}
                />
              </Popup>
            </div>
          </div>

          <div className="w-full h-full ">
            <div className="rounded-md bg-white" style={{ minHeight: '10rem' }}>
              {(myFriendsLoading ||
                sentRequestLoading ||
                friendRequestLoading ||
                roleRequestLoading) && <SmallLoader />}

              {/* All friends */}
              {contactOption === 'all_friends' && (
                <div className="py-4 px-4 w-full md:gap-x-2 md:gap-y-4 flex justify-start flex-wrap gap-4">
                  {filteredFriends?.map((profile, index) => (
                    <>
                      <div key={profile?.id}>
                        <UserContact
                          user={{
                            ...(profile?.friendId === me?.id
                              ? profile?.user
                              : profile?.friend),
                            id:
                              me?.id === profile?.userId
                                ? profile?.friendId
                                : profile?.userId
                          }}
                          onClickDeleteContact={onClickDeleteContact}
                          onClickMessageContact={onClickMessageContact}
                          profilePicture={
                            profile?.friendId === me?.id
                              ? profile?.user?.contact?.profile_image?.url
                              : profile?.friend?.contact?.profile_image?.url
                          }
                        />
                      </div>
                      {(index + 1) % 3 === 0 &&
                        index + 1 !== filteredFriends?.data?.length && (
                          <hr className="w-full" />
                        )}
                    </>
                  ))}

                  {(!myFriendsLoading ||
                    !sentRequestLoading ||
                    !friendRequestLoading ||
                    !roleRequestLoading) &&
                    hasMore && (
                      <LoadMoreButton onClick={() => refetchMyFriends()} />
                    )}

                  {isError && (
                    <div className="w-full flex justify-center items-center flex-col gap-2">
                      <img
                        src={DefaultProfilePng}
                        className="w-28 h-28 object-cover"
                        alt=""
                      />
                      <p className="text-center my-5 text-gray-800">
                        <b>
                          You don't have any friends, yet!
                          <br />
                          Go to Home page to find new friends
                        </b>
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* Friend Requests */}
              {contactOption === 'friend_requests' && (
                <div className="py-4 px-4 w-full md:gap-x-2 md:gap-y-4 flex justify-start flex-wrap gap-4">
                  {friendRequestsData?.map((profile, index) => (
                    <>
                      <IncomingRequests
                        key={profile?.id}
                        requestId={profile?.id}
                        user={profile?.user}
                        status={profile?.status}
                        setFriendRequestsData={setFriendRequestsData}
                        profilePicture={
                          profile?.user?.contact?.profile_image !== null &&
                          profile?.user?.contact?.profile_image?.url
                        }
                      />
                      {(index + 1) % 3 === 0 &&
                        index + 1 !== friendRequestsData?.data?.length && (
                          <hr className="w-full" />
                        )}
                    </>
                  ))}
                  {hasMore && (
                    <LoadMoreButton onClick={() => refetchFriendRequests()} />
                  )}
                </div>
              )}

              {/* Sent Requests */}
              {contactOption === 'sent_requests' && (
                <div className="py-4 px-4 w-full md:gap-x-2 md:gap-y-4 flex justify-start flex-wrap gap-4">
                  {sentRequestsData?.map((profile, index) => (
                    <>
                      <SentRequests
                        key={profile?.id}
                        requestId={profile?.id}
                        setSentRequestsData={setSentRequestsData}
                        user={profile?.friend}
                        status={profile?.status}
                        profilePicture={
                          profile?.friendId === me?.id
                            ? profile?.user?.contact?.profile_image?.url
                            : profile?.friend?.contact?.profile_image?.url
                        }
                      />
                      {(index + 1) % 3 === 0 &&
                        index + 1 !== sentRequestsData?.data?.length && (
                          <hr className="w-full" />
                        )}
                    </>
                  ))}
                  {hasMore && (
                    <LoadMoreButton onClick={() => refetchSentRequests()} />
                  )}
                </div>
              )}

              {/* Role Requests */}
              {contactOption === 'role_requests' && (
                <div className="py-4 px-4 w-full md:gap-x-2 md:gap-y-4 flex justify-start flex-wrap gap-4">
                  {roleRequestsData?.map((profile, index) => (
                    <>
                      <UserRoleRequest
                        key={profile?.id}
                        id={profile?.id}
                        playerId={profile?.by?.id}
                        user={profile?.by?.contact}
                        status={profile?.status}
                        role={profile?.role}
                        setRoleRequestsData={setRoleRequestsData}
                        profilePicture={
                          profile?.by?.contact?.profile_image?.url
                        }
                      />
                      {(index + 1) % 3 === 0 &&
                        index + 1 !== roleRequestsData?.data?.length && (
                          <hr className="w-full" />
                        )}
                    </>
                  ))}

                  {roleRequestsData?.length === 0 && (
                    <div className="w-full flex justify-center items-center flex-col gap-2">
                      <img
                        src={DefaultProfilePng}
                        className="w-28 h-28 object-cover"
                        alt=""
                      />
                      <p className="text-center my-5 text-gray-800">
                        <b>
                          You don't have any role requests, yet! <br />
                          Go to Home page to find new friends
                        </b>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SideNavWrapper>
  );
};

export default Contact;
