import React from 'react';
import DefaultProfilePic from '../../img/misc/Default_pfp.png';
import profileImageUrl from '../../config/config';
import Username from '../../components/Username/Username';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  cancelOngoingRequest,
  getSentFriendRequests
} from '../../services/routes/UserFriendRequest';
import { toast } from 'react-toastify';

const SentRequests = ({
  user,
  profilePicture,
  status,
  requestId,
  setSentRequestsData
}) => {
  const cancelRequest = async () => {
    try {
      await cancelOngoingRequest({ id: requestId }).then(async () => {
        toast.success('You cancelled the ongoing friend request');
      });

      const { data } = await getSentFriendRequests();
      setSentRequestsData(data);
    } catch (err) {
      setSentRequestsData([]);
      // toast.error('Something went wrong while cancelling friend request');
    }
  };

  return (
    <>
      {status === 'PENDING' && (
        <div className="flex items-center justify-between md:w-60 w-52">
          <img
            src={
              (profilePicture && profileImageUrl + profilePicture) ||
              DefaultProfilePic
            }
            className="w-14 h-14  object-cover rounded-full"
            alt=""
          />
          <div className="relative flex flex-col text-gray-600">
            <Username
              user={{ ...user, id: user?.contact?.profile_image?.userId }}
            />

            <p className="text-sm text-gray-500 font-normal">
              {user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1) ||
                'User'}
            </p>
          </div>

          <div className="flex flex-col ">
            <button className="w-6 h-6 cursor-not-allowed">
              <AccessTimeIcon className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 text-yellow-400 " />
            </button>
            <button className="w-6 h-6">
              <PersonRemoveIcon
                className="w-full h-full cursor-pointer opacity-70 hover:opacity-100 text-red-700"
                onClick={cancelRequest}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SentRequests;
