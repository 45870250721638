import { useState } from 'react';
import { ReactComponent as ArrowupSvg } from '../../img/activity/arrowUp.svg';
import { ReactComponent as ArrowdownSvg } from '../../img/activity/arrowDown.svg';
import { ReactComponent as CirclePlusSvg } from '../../img/misc/circle-plus.svg';
import SeasonHistoryTable from './SeasonHistoryTable';

const SeasonHistory = ({
  isEditable,
  histories,
  addHistory,
  editHistory,
  deleteHistory
}) => {
  //
  //

  const [showDetail, setShowDetail] = useState(true);

  return (
    <div className="mt-5">
      {showDetail && histories?.length > 0 && (
        <SeasonHistoryTable
          deleteHistory={deleteHistory}
          editHistory={editHistory}
          histories={histories}
          isEditable={isEditable}
        />
      )}

      {isEditable === true && (
        <div className="flex flex-col justify-center items-center w-full mt-5">
          <CirclePlusSvg
            className="primary-svg "
            onClick={() => addHistory()}
          />
          <p className="text-xl mt-5">Add Club History</p>
        </div>
      )}
    </div>
  );
};

export default SeasonHistory;
