import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AcceptButton from '../Buttons/AcceptButton';
import DeclineButton from '../Buttons/DeclineButton';
import { ReactComponent as ThreeDotHorizontalSvg } from '../../img/misc/three-dot-horizontal.svg';
import DefaultProfilePng from '../../img/misc/profile-placeholder.png';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  checkFriendRequest,
  sendFriendRequest
} from '../../services/routes/UserFriendRequest';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SmallLoader from '../Loader/SmallLoader';
import profileImageUrl from '../../config/config';

const ProfileModal = ({ onMouseEnter, onMouseLeave, user, startChat }) => {
  //

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showUserProfileOption, setShowUserProfileOption] = useState(false);

  const [requestStatus, setRequestStatus] = useState('');

  const [isFriend, setIsFriend] = useState(false);

  const me = useSelector(state => state.auth.user);

  useEffect(() => {
    setIsLoading(true);

    checkUserFriendRequest().then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle send Friend to the user
  const handleSendFriendRequest = async () => {
    try {
      await sendFriendRequest({ id: user?.id }).then(async () => {
        await checkUserFriendRequest();
      });
    } catch (error) {}
  };

  const checkUserFriendRequest = async () => {
    try {
      const data = await checkFriendRequest({ id: user?.id });
      setRequestStatus(data?.data?.status);
    } catch (error) {
      setIsFriend(true);
    }
  };

  return (
    <div
      className=" flex items-center justify-center z-50 bg-white  "
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <>
        {/* {showUserProfileOption && (
            <UserProfileOption
              user={user}
              onCloseClick={() => setShowUserProfileOption(false)}
            />
          )} */}
        <div className="bg-white rounded-lg shadow-lg p-4 w-80">
          {isLoading ? (
            <SmallLoader />
          ) : (
            <div className="w-full flex flex-col">
              <div className="flex w-full justify-between">
                <div className="flex gap-3">
                  <img
                    src={
                      (user?.contact?.profile_image &&
                        profileImageUrl + user?.contact?.profile_image?.url) ||
                      DefaultProfilePng
                    }
                    alt=""
                    className="w-14 h-14 rounded-full object-cover"
                  />

                  <div
                    className="flex flex-col gap-1 items-center"
                    style={{ color: '#919191' }}
                  >
                    <Link
                      to={`user-profile/${user?.contact?.username}`}
                      className="font-roboto text-base font-medium text-black"
                    >
                      {user?.contact?.first_name +
                        ' ' +
                        user?.contact?.last_name}
                    </Link>
                    <span className="flex w-full ml-1 items-center gap-2 font-roboto font-normal text-sm ">
                      <LocationOnIcon className="w-5 h-5" />
                      {user?.contact?.address}
                    </span>
                    <span className="flex w-full ml-1 items-center gap-2 font-roboto font-normal text-sm">
                      <PersonIcon className="w-5 h-5" />
                      {user?.role
                        ? user?.role?.charAt(0).toUpperCase() +
                          user?.role?.slice(1)
                        : 'User'}
                    </span>
                  </div>
                </div>

                <ThreeDotHorizontalSvg
                  className="w-5 h-5 opacity-60 cursor-pointer hover:opacity-100"
                  onClick={() =>
                    setShowUserProfileOption(!showUserProfileOption)
                  }
                />
              </div>
              <div className="w-full flex justify-center mt-6  gap-4">
                {/* If they're are not friends and user is not me */}
                {isFriend && user?.id !== me?.id && (
                  <DeclineButton
                    width={'8rem'}
                    title="Add"
                    Icon={PersonAddAlt1OutlinedIcon}
                    onClick={handleSendFriendRequest}
                  />
                )}

                {/* If the user is me */}
                {user?.id === me?.id && (
                  <DeclineButton
                    width={'8rem'}
                    title="View Profile"
                    onClick={() => {
                      history.push('/my-profile');
                    }}
                    Icon={VisibilityIcon}
                  />
                )}

                {/* If they're Freinds */}
                {requestStatus === 'ACCEPTED' && (
                  <DeclineButton
                    width={'8rem'}
                    title="Friends"
                    Icon={Diversity3Icon}
                  />
                )}

                {/* If Request is sent, but user didn't accepted the request yet */}
                {requestStatus === 'PENDING' && (
                  <DeclineButton
                    width={'8rem'}
                    title="Pending"
                    Icon={ScheduleIcon}
                  />
                )}

                {/* If the user is not me */}
                {user?.id !== me?.id ? (
                  <AcceptButton
                    width={'8rem'}
                    title="Message"
                    onClick={startChat}
                    Icon={MessageOutlinedIcon}
                  />
                ) : (
                  <AcceptButton
                    width={'8rem'}
                    title="GoTo Message"
                    Icon={MessageOutlinedIcon}
                    onClick={() => history.push('/message')}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default ProfileModal;
