import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userNotifications: null
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setUserNotification: (state, action) => {
      state.userNotifications = action.payload;
    }
  }
});

export const { setUserNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
