import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from 'react-query';
import { deleteNotification } from '../../services/routes/Notification';
import { useQueryClient } from 'react-query';
import Loader from '../../img/busy.gif';
import { deleteMessage } from '../../services/routes/chats';

const NotificationOption = ({
  id,
  messageNotification,
  chatId,
  seenStatus
}) => {
  //
  const queryClient = useQueryClient();

  const { mutate: deleteMessageNotification, isLoading: deletingMessage } =
    useMutation(deleteMessage, {
      onSuccess: () => {
        queryClient.invalidateQueries('fetch_notification');
      },
      onError: err => {
        console.log(err);
      }
    });

  const { mutate: normalNotification, isLoading } = useMutation(
    deleteNotification,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetch_notification');
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  const handleDeleteNotification = () => {
    if (messageNotification) {
      deleteMessageNotification({ id, chatId });
    } else {
      normalNotification({ id });
    }
  };

  return (
    <div className="flex absolute p-1 right-0 top-1  items-center gap-1">
      {
        // if the notification is not seen
        seenStatus === 'delivered' && (
          <span
            style={{ width: '8px', height: '8px' }}
            className=" rounded-full bg-brand-dark "
          />
        )
      }

      {isLoading || deletingMessage ? (
        <img src={Loader} style={{ width: '14px', height: '14px' }} alt="" />
      ) : (
        <ClearIcon
          className="text-brand-dark cursor-pointer"
          style={{ width: '14px', height: '14px' }}
          onClick={handleDeleteNotification}
        />
      )}
    </div>
  );
};

export default NotificationOption;
