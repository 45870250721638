import React from 'react';

function InfoSetting({ title, value }) {
  return (
    <>
      <h2 className="flex flex-col font-bold text-sm whitespace-pre font-roboto">
        {title}
        <span className="font-normal text-sm">{value}</span>
      </h2>
    </>
  );
}

export default InfoSetting;
