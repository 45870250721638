import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import AcceptButton from "../../../../components/Buttons/AcceptButton";
import DeclineButton from "../../../../components/Buttons/DeclineButton";
import DefaultProfilePng from "../../../../img/misc/Default_pfp.png";
import { useMutation, useQueryClient } from "react-query";
import Carousel from "../../../../components/Carousel/Carousel";
import { deleteImage, updatePost } from "../../../../services/routes/Post";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const DeletePostModal = ({ onCloseClick, refetch, postData }) => {
  // Getting Images from the server
  const postImage = postData?.images;

  const queryClient = useQueryClient();

  const [updateStatus, setUpdateStatus] = useState(postData?.body_text);

  const [updatedImages, setUpdatedImages] = useState(postImage);

  const { mutate, isLoading } = useMutation(updatePost, {
    onSuccess: () => {
      queryClient.invalidateQueries("fetch_mypost");
      refetch();
      setUpdateStatus("");
      onCloseClick();
    },
    onError: err => {
      console.log(err);
    }
  });

  const handleDeleteImage = async id => {
    try {
      await deleteImage(id);
      const updatingImage = updatedImages.filter(image => image.id !== id);
      queryClient.invalidateQueries("fetch_mypost");
      refetch();
      setUpdatedImages(updatingImage);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleEditPost = e => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("body_text", updateStatus);

    const data = {
      id: postData?.id,
      data: formData
    };

    mutate(data);
  };

  const onCancel = () => {
    setUpdatedImages(postImage);
    onCloseClick();
  };

  return (
    <>
      <Modal title="Edit Post" onCloseClick={onCloseClick} width="40%">
        <div className="w-full  p-5 bg-white">
          <div className="flex items-center gap-1 mb-3 ">
            <img
              src={DefaultProfilePng}
              alt=""
              className="h-12 w-12 rounded-full object-cover mr-3"
            />
            <p className="font-semibold w-max ">
              {postData?.author?.contact?.username}
            </p>
          </div>
          <textarea
            cols="1"
            rows="2"
            value={updateStatus}
            onChange={e => setUpdateStatus(e.target.value)}
            className="border-gray-300 border bg-transparent text-black form-input outline-none px-3 w-full rounded-md "
          />
          {/* Rendering Images */}
          <div className="relative">
            <Carousel postImage={updatedImages}>
              {updatedImages &&
                updatedImages.map(({ url, id }, index) => (
                  <div className="relative min-w-full max-h-96" key={index}>
                    <img
                      src={process.env.REACT_APP_BASE_URL + url}
                      key={index}
                      alt=""
                      className="min-w-full max-h-96 object-contain"
                    />
                    <div className="absolute top-2 left-4">
                      <DeleteOutlineOutlinedIcon
                        className="bg-gray-300 text-gray-700 hover:bg-white cursor-pointer hover:text-black p-1 rounded-full shadow"
                        style={{ fontSize: "27px" }}
                        onClick={() => handleDeleteImage(id)}
                      />
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
          <div className="w-full justify-center mt-8 items-center flex gap-6">
            <button className="mx-1 my-2" onClick={handleEditPost}>
              <AcceptButton title={isLoading ? "Updating..." : "Update"} />
            </button>
            <button className="mx-1 my-2  " onClick={onCancel}>
              <DeclineButton title="Cancel" />
            </button>
          </div>
          ;
        </div>
      </Modal>
    </>
  );
};

export default DeletePostModal;
