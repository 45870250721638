import AxiosService from "../../AxiosService";

export const addPlayerCoachHistory = async data => {
  const response = await AxiosService.post("/player/coach-history/", data);
  return response.data;
};

export const editPlayerCoachHistory = async ({ id, data }) => {
  const response = await AxiosService.put(`/player/coach-history/${id}`, data);
  return response.data;
};

export const getAllPlayerCoachHistory = async () => {
  const response = await AxiosService.get("/player/coach-histories/");
  return response.data;
};

export const deletePlayerCoachHisotry = async ({ id }) => {
  const response = await AxiosService.delete(`/player/coach-history/${id}/`);
  return response.data;
};
