import Modal from '../../../../components/Modal/Modal';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  updatePlayerPosition,
  getPlayerPosition
} from '../../../../services/routes/Player';
import { ReactComponent as CirclePlus } from '../../../../img/misc/circle-plus.svg';
import AcceptButton from '../../../../components/Buttons/AcceptButton';
import { useState } from 'react';

function EditSkillsModal({ onCloseClick }) {
  const [positions, setPositions] = useState([]);
  const [primaryPosition, setPrimaryPosition] = useState([]);
  const [secondaryPosition, setSecondaryPosition] = useState([]);
  //

  const { data: fetchPlayerPosition } = useQuery(
    'fetch_playerPositions',
    getPlayerPosition,
    {
      onSuccess: data => {
        const newOptions = data?.data?.map(item => {
          return {
            value: item?.id,
            label: item?.name
          };
        });

        setPositions(newOptions);
      }
    }
  );

  const { mutate } = useMutation(updatePlayerPosition, {
    onSuccess: () => {
      onCloseClick();
    }
  });

  const onSubmit = e => {
    const newPositions = [...primaryPosition, ...secondaryPosition];
    mutate({ positions: newPositions });
  };

  return (
    <Modal title="Edit Positions" onCloseClick={onCloseClick} width="400px">
      <div className="bg-white px-6 rounded-b-md">
        <div className="w-full flex items-center gap-6 pt-4 ">
          <div className="w-1/2 py-2">
            <div className="flex w-full flex-col ">
              <label className="text-base text-center font-medium text-gray-700">
                Main Position
              </label>
              {primaryPosition?.map((item, index) => {
                return (
                  <select
                    key={item?.id}
                    className="font-roboto font-normal px-2 text-sm w-full border shadow-sm border-gray-300 rounded-sm form-select"
                    name={`mainPosition[${index}].main_position`}
                    defaultValue={item?.id}
                    onChange={e => {
                      console.log(e.target.value);
                      const newPrimaryPosition = [...primaryPosition];
                      newPrimaryPosition[index] = {
                        id: Number(e.target.value),
                        type: 'primary'
                      };
                      setPrimaryPosition(newPrimaryPosition);
                    }}
                  >
                    {positions?.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                );
              })}
            </div>
            <CirclePlus
              className="mx-auto w-10 h-10 my-4  cursor-pointer secondary-svg"
              onClick={() =>
                setPrimaryPosition([
                  ...primaryPosition,
                  { id: positions[0]?.value, type: 'primary' }
                ])
              }
            />
          </div>
          <div className="flex w-1/2 flex-col py-2">
            <label className="text-base text-center font-medium text-gray-700">
              Secondary Position
            </label>
            {secondaryPosition?.map((item, index) => {
              return (
                <select
                  className="font-roboto font-normal px-2 text-sm w-full border shadow-sm border-gray-300 rounded-sm form-select pt-2 pb-2"
                  name={`secondaryPosition[${index}].secondary_position`}
                  defaultValue={item?.id}
                  onChange={e => {
                    const newSecondaryPosition = [...secondaryPosition];
                    newSecondaryPosition[index] = {
                      id: Number(e.target.value),
                      type: 'secondary'
                    };
                    setSecondaryPosition(newSecondaryPosition);
                  }}
                >
                  {positions?.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </select>
              );
            })}

            <CirclePlus
              className="mx-auto w-10 h-10 my-4  cursor-pointer secondary-svg"
              onClick={() =>
                setSecondaryPosition([
                  ...secondaryPosition,
                  { id: positions[0]?.value, type: 'secondary' }
                ])
              }
            />
          </div>
        </div>

        <div className="w-full flex justify-start px-4">
          <button type="submit" className="w-2/6 my-4" onClick={onSubmit}>
            <AcceptButton title="Save" />
          </button>
          {/* <button onClick={() => onCloseClick()} className="w-2/6 my-4">
            <DeclineButton title="Cancel" />
          </button> */}
        </div>
      </div>
    </Modal>
  );
}

export default EditSkillsModal;
