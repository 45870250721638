import AxiosService from '../AxiosService';

export const userProfile = async () => {
  const response = await AxiosService.get('/user/profile');
  return response.data;
};
export const updateUserProfile = async data => {
  const response = await AxiosService.put('/user/profile', data);
  return response.data;
};

export const updateProfilePicture = async data => {
  const response = await AxiosService.put('/user/profile-picture', data);
  return response.data;
};

export const createSocial = async data => {
  const response = await AxiosService.post('/user/social', data);
  return response.data;
};

export const userSocials = async data => {
  const response = await AxiosService.put('/user/social', data);
  return response.data;
};

export const updateUserBio = async bio => {
  const response = await AxiosService.put('/user/bio/', bio);
  return response.data;
};

export const getMyPosition = async () => {
  const response = await AxiosService.get('/player/position/my');
  return response.data;
};

// Managed Players endpoints
export const addManagedPlayer = async data => {
  const response = await AxiosService.post('user/player-list', data);
  return response.data;
};

export const getAllManagedPlayers = async () => {
  const response = await AxiosService.get('/user/player-list');
  return response.data;
};

export const getAllManagedCoach = async () => {
  const response = await AxiosService.get('/user/coach-list');
  return response.data;
};

export const getMyAllPlayerList = async () => {
  const response = await AxiosService.get('/user/player-list/my');
  return response.data;
};

export const deletePlayerList = async ({ id }) => {
  const response = await AxiosService.delete(`/user/player-list/my/${id}`);
  return response.data;
};

export const updatePlayerList = async ({ id, data }) => {
  const response = await AxiosService.put(`/user/player-list/my/${id}`, data);
  return response.data;
};

export const createMyList = async data => {
  const response = await AxiosService.post('/user/player-list/my', data);
  return response.data;
};

//
export const searchAgent = async name => {
  const response = await AxiosService.get(`/agents?search=${name}`);
  return response.data;
};

export const searchCoach = async name => {
  const response = await AxiosService.get(`/coach?search=${name}`);
  return response.data;
};

export const searchClub = async name => {
  const response = await AxiosService.get(`/club?search=${name}`);
  return response.data;
};

export const searchPlayer = async name => {
  const response = await AxiosService.get(`/player?search=${name}`);
  return response.data;
};

export const createRoleRequest = async id => {
  const response = await AxiosService.put('/user/role-request', id);
  return response.data;
};

export const searchUsername = async username => {
  const response = await AxiosService.get(`/user/search/${username}`);
  return response.data;
};

export const loadProfileWithUsername = async username => {
  const response = await AxiosService.get(`/user/search/profile/${username}`);
  return response.data;
};

export const getAnalytics = async username => {
  const response = await AxiosService.get(`/user/friendship/connections`);
  return response.data;
};

export const getPossibleConnections = async pageParam => {
  const response = await AxiosService.get(
    `/user/possible-connect/?limit=3&page=${pageParam}`
  );
  return response.data;
};

export const getRelatedEvents = async () => {
  const response = await AxiosService.get('/events/related-events');
  return response.data;
};

// Managed User
