import AxiosService from '../AxiosService';

export const getAllBlockUsers = async () => {
  const response = await AxiosService.get('/user/block/');
  return response.data;
};

export const blockUser = async id => {
  const response = await AxiosService.post(`/user/block/${id}`);
  return response.data;
};

export const unblockUser = async id => {
  const response = await AxiosService.delete(`/user/unblock/${id}`);
  return response.data;
};
