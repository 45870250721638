import React from 'react';
import Loader from '../../img/busy.gif';

const LargeLoader = () => {
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <img src={Loader} alt="" className="w-28 h-28 object-contain" />
    </div>
  );
};

export default LargeLoader;
